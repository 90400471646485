import { CoverageType } from '@nirvana/api/non-fleet';

export type AncillaryCoverageRecord = {
  coverageType: CoverageType;
  label: string;
};

const AncillaryCoverages: { [coverage: string]: AncillaryCoverageRecord[] } = {
  [CoverageType.CoverageAutoLiability]: [
    {
      coverageType: CoverageType.CoveragePersonalInjuryProtection,
      label: 'PIP',
    },
    { coverageType: CoverageType.CoverageUm, label: 'UM' },
    { coverageType: CoverageType.CoverageUim, label: 'UIM' },
    { coverageType: CoverageType.CoverageUmuimBodilyInjury, label: 'UMBI' },
    { coverageType: CoverageType.CoverageUmuimPropertyDamage, label: 'UMPD' },
    {
      coverageType: CoverageType.CoveragePropertyProtectionInsurance,
      label: 'PPI',
    },
    { coverageType: CoverageType.CoverageMedicalPayments, label: 'MedPay' },
    {
      coverageType: CoverageType.CoverageBroadenedPollution,
      label: 'Broaden Pollution',
    },
    {
      coverageType: CoverageType.CoverageBlanketAdditional,
      label: 'Blanket Additional Insured',
    },
    {
      coverageType: CoverageType.CoverageBlanketWaiverOfSubrogation,
      label: 'Blanket Waiver of Subrogation',
    },
    { coverageType: CoverageType.CoverageTerrorism, label: 'Terrorism' },
  ],
  [CoverageType.CoverageAutoPhysicalDamage]: [
    {
      coverageType: CoverageType.CoverageTrailerInterchange,
      label: 'Trailer Interchange',
    },
    {
      coverageType: CoverageType.CoverageTowingLaborAndStorage,
      label: 'Towing, labor & storage',
    },
    {
      coverageType: CoverageType.CoverageRentalReimbursement,
      label: 'Rental Reimbursement',
    },
    { coverageType: CoverageType.CoverageUiia, label: 'UIIA' },
  ],
  [CoverageType.CoverageMotorTruckCargo]: [
    { coverageType: CoverageType.CoverageReefer, label: 'Reefer Breakdown' },
    {
      coverageType: CoverageType.CoverageDebrisRemoval,
      label: 'Debris Removal',
    },
  ],
  [CoverageType.CoverageGeneralLiability]: [],
};

export default AncillaryCoverages;
