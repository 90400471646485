import * as React from 'react';
import NumberFormat from 'react-number-format';
import {
  FormHelperText,
  OutlinedInput,
  OutlinedInputProps,
} from '@material-ui/core';

interface NumberFormatCustomProps {
  onChange: (event: {
    target: { name: string; value: string; raw: string };
  }) => void;
  name: string;
  value: string | number;
}

const getFormattedValue = (a: string) => {
  if (a?.length < 6) {
    return a;
  }

  if (a?.length > 5) {
    return a.substring(0, 5) + '-' + a.substring(5, 9);
  }

  return a;
};

const NumberFormatCustom = React.forwardRef<
  NumberFormat,
  NumberFormatCustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: getFormattedValue(values.value),
            raw: values.value,
          },
        });
      }}
      allowNegative={false}
      format={getFormattedValue}
    />
  );
});
export interface IInputZipProps extends OutlinedInputProps {
  helperText?: string;
}

/**
 * ZIP input UI component, which is an extension of MUI's Outlined Input,
 * and uses [react-number-format](https://github.com/s-yadav/react-number-format)
 * to format the input value.
 * Refer: https://next.material-ui.com/api/outlined-input for usage and props
 * Note: This component makes sure that we always render an outlined text input.
 * @component
 *
 * @param {Object} props - Material UI Outlined Input props.
 *
 * @example
 * return <InputZip placeholder="Enter a ZIP code" />
 */
const InputZip = React.forwardRef<HTMLInputElement, IInputZipProps>(
  ({ helperText, ...rest }, ref) => {
    return (
      <>
        <OutlinedInput
          {...rest}
          inputComponent={NumberFormatCustom as any}
          inputProps={{}}
          inputRef={ref}
        />
        {!!helperText && (
          <FormHelperText error={rest.error}>{helperText}</FormHelperText>
        )}
      </>
    );
  },
);

export default InputZip;
