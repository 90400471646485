const debounce = (fn: any, delay: number) => {
  let timer: any = null;

  return function () {
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(null, args);
    }, delay);
  };
};

export default debounce;
