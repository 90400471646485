import { useSnackbar as useNotiSnackbar, OptionsObject } from 'notistack';
import { ReactNode } from 'react';
import { Snackbar } from '@nirvana/ui-kit';

const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotiSnackbar();

  const pushSnackbar = (
    title: string,
    description: ReactNode,
    options: OptionsObject,
  ) => {
    enqueueSnackbar(title, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: 3000,
      ...options,
      content: (key) => {
        const { variant } = options || { variant: 'info' };

        return (
          <Snackbar
            id={key}
            title={title}
            subheader={description}
            type={variant || 'success'}
            onClose={closeSnackbar}
          />
        );
      },
    });
  };

  return pushSnackbar;
};

export default useSnackbar;
