import { IChipProps } from '@nirvana/ui-kit';
import { RecommendationImpact } from 'src/types/graphql-types';

export default function getChipProps(impact: RecommendationImpact): IChipProps {
  switch (impact) {
    case RecommendationImpact.High:
      return { color: 'success', label: impact, uppercase: true };
    case RecommendationImpact.Medium:
      return { color: 'info', label: impact, uppercase: true };
    case RecommendationImpact.Low:
      return {
        color: 'warning',
        label: impact,
        uppercase: true,
      };
  }
}
