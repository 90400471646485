import { useEffect } from 'react';

import { NewClaimProvider } from 'src/contexts/newClaim';
import { analytics } from 'src/helpers';

import { NewClaimForm } from './components/NewClaimForm';

export const NewClaim = () => {
  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.FNOLFormPageView,
    });
  }, []);

  return (
    <div className="flex flex-col bg-gray-50">
      <NewClaimProvider>
        <NewClaimForm />
      </NewClaimProvider>
    </div>
  );
};
