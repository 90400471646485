import * as React from 'react';
import { ReactNode } from 'react';
import {
  Card,
  CardHeader,
  Typography,
  styled,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { VariantType, SnackbarKey } from 'notistack';

import { ITheme } from '../../assets/themes';
import IconSuccess3D from '../../assets/icons/success-3d.svg';
import IconError3D from '../../assets/icons/snackbar-error.svg';
import IconClose from '../../assets/icons/close.svg';

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: '18px',
  },
  subheader: {
    lineHeight: '22px',
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

const getIconByType = (type: VariantType) => {
  switch (type) {
    case 'success':
      return IconSuccess3D;
    case 'error':
      return IconError3D;
    default:
      return '';
  }
};

const ContentCard = styled(Card)(({ theme }: { theme: ITheme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

export interface ISnackbarContentProps {
  id: SnackbarKey;
  type: VariantType;
  title: ReactNode;
  subheader?: ReactNode;
  onClose: (key?: SnackbarKey) => void;
}

const SnackbarContent = ({
  id,
  type,
  title,
  subheader,
  onClose,
}: ISnackbarContentProps) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose(id);
  };

  return (
    <ContentCard>
      <CardHeader
        sx={{
          paddingTop: 1.2,
          paddingBottom: 1.2,
          paddingLeft: 2,
          paddingRight: 2,
        }}
        avatar={<img src={getIconByType(type)} />}
        title={
          <Typography
            variant="subtitle1"
            color="text.primary"
            className={classes.title}
          >
            {title}
          </Typography>
        }
        subheader={
          <Typography
            variant="caption"
            color="text.hint"
            className={classes.subheader}
          >
            {subheader}
          </Typography>
        }
        action={
          <IconButton size="small" onClick={handleClose}>
            <img src={IconClose} alt="x" />
          </IconButton>
        }
      />
    </ContentCard>
  );
};

export default SnackbarContent;
