export function UpperBound(props: any) {
  const { x, y, width } = props.viewBox;
  const { offset, value } = props;
  return (
    <text
      x={width + x}
      y={y + offset}
      fill="#FA3252"
      fontSize={12}
      fontWeight={400}
    >
      {value}
    </text>
  );
}

export function LowerBound(props: any) {
  const { x, y, width } = props.viewBox;
  const { offset, value } = props;
  return (
    <text
      x={width + x}
      y={y + offset}
      fill="#FF823C"
      fontSize={12}
      fontWeight={400}
    >
      {value}
    </text>
  );
}
