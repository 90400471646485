import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { ApplicationDetail, ApplicationState } from '@nirvana/api/quoting';
import clsx from 'clsx';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconBack from '../../assets/icons/arrow-left.svg';

import Show from '../show';
import { useStyles } from './styles';

export interface IApplicationHeader {
  details: ApplicationDetail;
  elevation?: boolean;
  onBack: () => void;
  showBDInHeader?: boolean;
  showHeaderBanner?: boolean;
}

const AppStateToShowBDName: ApplicationState[] = [
  ApplicationState.ApplicationStateInProgress,
  ApplicationState.ApplicationStateIndicationGenerated,
  ApplicationState.ApplicationStateIndicationSelected,
  ApplicationState.ApplicationStateIndicationDelay,
  ApplicationState.ApplicationStateProcessingEldTelematics,
  ApplicationState.ApplicationStatePanic,
];

const ApplicationHeader = ({
  details,
  elevation = true,
  onBack,
  showBDInHeader = false,
}: IApplicationHeader) => {
  const classes = useStyles();

  const handleBackButtonClick = () => {
    onBack();
  };

  return (
    <>
      <AppBar
        classes={{
          root: clsx(classes.appBar, { [classes.elevation]: elevation }),
        }}
        position="static"
        color="inherit"
        elevation={0}
      >
        <Toolbar classes={{ root: classes.toolbar }}>
          <Grid container flexWrap="nowrap" justifyContent="space-between">
            <Grid item container alignItems="center">
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={handleBackButtonClick}
                  size="small"
                  sx={{ mr: 2 }}
                >
                  <img src={IconBack} alt="Go Back" />
                </IconButton>
                <Typography variant="body2" fontWeight={600}>
                  # {details?.summary?.shortID}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
            >
              <Grid
                container
                flexWrap="nowrap"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography
                    variant="body2"
                    color="secondary"
                    ml={1}
                    fontWeight="fontWeightMedium"
                  >
                    {details?.summary?.companyName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{
                      height: 16,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="secondary"
                    fontWeight="fontWeightMedium"
                  >
                    DOT: {details?.summary?.dotNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Show
              when={
                !showBDInHeader ||
                !details?.summary?.bdName ||
                !AppStateToShowBDName.includes(details?.summary?.state)
              }
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" className={classes.colorLight}>
                    Underwriter:&nbsp;
                  </Typography>
                  <Typography variant="body2" color="secondary">
                    {details?.summary?.underwriterName} &nbsp;
                  </Typography>
                  <Tooltip title={<>{details?.summary?.underwriterEmail}</>}>
                    <InfoOutlinedIcon
                      className={classes.infoIcon}
                      fontSize="small"
                    />
                  </Tooltip>
                </Box>
              </Grid>
            </Show>
            <Show
              when={
                showBDInHeader &&
                details?.summary?.bdName &&
                AppStateToShowBDName.includes(details?.summary?.state)
              }
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" className={classes.colorLight}>
                    BD:&nbsp;
                  </Typography>
                  <Typography variant="body2" color="secondary">
                    {details?.summary?.bdName} &nbsp;
                  </Typography>
                  <Tooltip title={<>{details?.summary?.bdEmail}</>}>
                    <InfoOutlinedIcon
                      className={classes.infoIcon}
                      fontSize="small"
                    />
                  </Tooltip>
                </Box>
              </Grid>
            </Show>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default ApplicationHeader;
