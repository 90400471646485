import clsx from 'clsx';
import { Column } from 'react-table';
import { OosSummary } from 'src/types/graphql-types';

export const accidentColumns: Array<Column<OosSummary>> = [
  { Header: 'Category', accessor: 'category' },
  { Header: 'Inspections', accessor: 'inspections' },
  { Header: 'OOS Violations', accessor: 'oOSViolations' },
  {
    Header: 'National Average %',
    accessor: 'nationalAverage',
    Cell: ({ value }) => `${value}%`,
  },
  {
    Header: 'OOS %',
    accessor: 'oOSPercent',
    Cell: ({ row, value }) => {
      const { nationalAverage } = row.original;
      if (!isNaN(nationalAverage) && !isNaN(value)) {
        const isDanger = value > nationalAverage;
        return (
          <span
            className={clsx('font-semibold', {
              'text-error-main': isDanger,
            })}
          >
            {(value ?? 0).toFixed(2)}%
          </span>
        );
      }
      return null;
    },
  },
];
