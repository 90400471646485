import * as React from 'react';
import clsx from 'clsx';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { ITheme } from '../../assets/themes';
import Checkbox from './checkbox';

const useStyles = makeStyles((theme: ITheme) => ({
  checkboxContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 2px 3px rgba(128, 145, 196, 0.06)',
    cursor: 'pointer',
    padding: theme.spacing(1),

    '&:hover': {
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.extraLight,
    },

    '&$selected': {
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.extraLight,
    },
  },
  selected: {},
  content: {
    padding: theme.spacing(0.5),
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  checkboxRoot: {
    padding: 0,
  },
}));

interface ICheckboxIconTextProps {
  label: React.ReactNode;
  icon: any;
  checked: boolean;
  value: string | number;
  onToggleCheck: (checked: boolean) => void;
  onValueChange: () => void;
}

/**
 * Checkbox component with label, icon, and textfield.
 * Note: All props except label and className are passed down to MuiCheckbox.
 * @component
 *
 * @param {Object} props - Extends MUI Checkbox (Refer: https://next.material-ui.com/components/checkboxes)
 * @param {string} props.label - Checkbox label to describe usage.
 * @param {string} props.className - Additional classes passed on to checkbox container.
 *
 * @example
 * return <Checkbox label="Check something here" disabled />
 */
const CheckboxIconText = ({
  label,
  icon,
  checked,
  onToggleCheck,
}: ICheckboxIconTextProps) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.checkboxContainer, {
        [classes.selected]: checked,
      })}
      onClick={() => onToggleCheck(!checked)}
    >
      <Grid container spacing={1} display="flex">
        <Grid item>
          <Box className={classes.content}>{icon}</Box>
        </Grid>
        <Grid flex={1} item>
          <Box className={clsx(classes.content, classes.labelContainer)}>
            {typeof label === 'string' ? (
              <Typography variant="body2">{label}</Typography>
            ) : (
              label
            )}
          </Box>
        </Grid>
        <Grid item>
          <Checkbox checked={checked} className={classes.checkboxRoot} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckboxIconText;
