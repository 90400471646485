import { Button } from '@material-ui/core';
import { Show, Switch } from '@nirvana/ui-kit';
import React, { useState } from 'react';
import { FaChevronDown, FaChevronLeft } from 'react-icons/fa';

const DefaultTitle = (
  <h2 className="p-5 text-xl font-semibold text-secondary-main">Filters</h2>
);

export const FilterContainer = ({
  title = DefaultTitle,
  children,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(true);
  const toggleFilter = () => setOpen(!open);
  return (
    <>
      <div className="mb-4 bg-white rounded-md shadow">
        <div className="flex items-center justify-between">
          {title}
          <Button onClick={toggleFilter}>
            <Switch>
              <Switch.Match when={open}>
                <FaChevronDown className="w-4 h-4" />
              </Switch.Match>
              <Switch.Match when={!open}>
                <FaChevronLeft className="w-4 h-4" />
              </Switch.Match>
            </Switch>
          </Button>
        </div>
        <Show when={open}>{children}</Show>
      </div>
    </>
  );
};
