import ClaimsImage from 'src/assets/images/claim.svg';
import { Tabs } from '../constants/types';

export const EmptyResults = ({ type }: { type: Tabs }) => {
  return (
    <div
      className="flex flex-col items-center justify-center p-4"
      data-testid="no-claims-found"
    >
      <div className="text-center">
        <p className=" text-secondary-main">
          <img src={ClaimsImage} className="m-auto" aria-hidden="true" />
          <h2 className="mt-8 mb-2 text-xl">
            You haven’t filed any {type === Tabs.Claims ? 'claims' : 'reports'}
          </h2>
        </p>
      </div>
    </div>
  );
};
