import { Link, useParams } from 'react-router-dom';

import { Button } from '@material-ui/core';

import { ReactComponent as SubmitSuccess } from 'src/assets/icons/submit-success.svg';

export default function SubmittedSuccessfully() {
  const { reportId = '' } = useParams();

  return (
    <div
      className="flex flex-col gap-10 justify-center items-center w-full mt-10"
      data-testid="submitted-successfully"
    >
      <SubmitSuccess />
      <h1 className="text-3xl font-bold" data-testid="claim-submitted-title">
        Your claim has been submitted!
      </h1>
      <p
        className="text-base text-center font-light text-secondary-dark w-80"
        data-testid="claim-submitted-description"
      >
        Check your inbox for an email confirmation with the submission details.
        Next, we&apos;ll review the information and follow up within 1 business
        day.
      </p>

      <Link to={`/${reportId}/claims`}>
        <Button
          data-testid="submit-fnol-button"
          type="button"
          variant="contained"
        >
          View All Claims
        </Button>
      </Link>
    </div>
  );
}
