import * as React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';

// Defined this variable to avoid production errors caused by @apollo/client
// @ts-ignore
window.__DEV__ = import.meta.env.DEV;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
