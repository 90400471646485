import * as React from 'react';
import clsx from 'clsx';
import { HiChevronDown } from 'react-icons/hi';
import AccordionContext from './accordion-context';

type AccordionSummaryProps = {
  id?: string;
  children: React.ReactNode;
  onClick?: (isOpen: boolean) => void;
};

export default function AccordionSummary({
  id,
  children,
  onClick = () => {},
}: AccordionSummaryProps) {
  const { isOpen, handleClick } = React.useContext(AccordionContext);

  return (
    <div
      id={id}
      className={clsx(
        'flex items-center justify-between p-4 text-sm font-medium text-gray-700 border-b cursor-pointer',
        isOpen ? 'bg-primary-dark/[0.02]' : 'bg-white',
      )}
      onClick={() => {
        handleClick();
        onClick(!isOpen);
      }}
    >
      {children}
      <div
        className={clsx(
          'flex items-center justify-center flex-none w-10 h-8 transition-transform text-xl',
          isOpen ? 'rotate-180' : 'rotate-0',
        )}
      >
        <HiChevronDown />
      </div>
    </div>
  );
}
