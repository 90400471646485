import { useContext, useMemo } from 'react';
import { Button, Divider } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { format } from 'date-fns';
import { Attachment, NewClaimContext } from 'src/contexts/newClaim';
import {
  policyCoveragesMapping,
  noticeTypeMapping,
  usStatesMapping,
  Step,
} from './constants';
import { FileListItem } from './FileListItem';

type ReviewAndSubmitProps = {
  onEdit: (step: Step) => void;
};

export const ReviewAndSubmit = ({ onEdit }: ReviewAndSubmitProps) => {
  const { getValues } = useContext(NewClaimContext);
  const form = getValues();

  const insuredVehicleVins = useMemo(() => {
    return form.insuredVehicleVins.filter((vin) => vin.length > 0);
  }, [form.insuredVehicleVins]);

  const otherVehicleVins = useMemo(() => {
    return form.otherVehicleVins.filter((vin: string) => vin.length > 0);
  }, [form.otherVehicleVins]);

  return (
    <div className="flex flex-col">
      <div>
        <h3 className="font-bold text-3xl">Review & Submit</h3>
        <p className="text-xl text-text-hint mt-2">
          Please ensure all information is accurate before sumitting.
        </p>
      </div>

      <div className="flex flex-col w-full mt-8 bg-white rounded-lg shadow p-8">
        <div className="flex flex-row items-center">
          <h5 className="font-bold text-xl">Reporter Information</h5>
          <Button
            data-testid="edit-reporter-information-button"
            onClick={() => onEdit('reporter-information')}
          >
            <Edit className="mr-2" /> Edit
          </Button>
        </div>
        <Divider className="py-2" />

        <div className="mt-4">
          <div className="flex">
            <p className="font-semibold flex-1 max-w-72">Name</p>
            <p className="font-normal">
              {`${form.reporter.firstName} ${form.reporter.lastName}`}
            </p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Email</p>
            <p className="font-normal">{form.reporter.email}</p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Phone</p>
            <p className="font-normal">{form.reporter.phone || '-'}</p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Insured Name</p>
            <p className="font-normal">{form.insuredName}</p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Notice Type</p>
            <p className="font-normal">{noticeTypeMapping[form.noticeType]}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full mt-8 bg-white rounded-lg shadow p-8">
        <div className="flex flex-row items-center">
          <h5 className="font-bold text-xl">Incident Details</h5>
          <Button
            data-testid="edit-incident-details-button"
            onClick={() => onEdit('incident-details')}
          >
            <Edit className="mr-2" /> Edit
          </Button>
        </div>
        <Divider className="py-2" />

        <div className="mt-4">
          <div className="flex">
            <p className="font-semibold flex-1 max-w-72">Date & Time</p>
            <p className="font-normal">
              {format(form.lossDate, 'MM-dd-yyyy hh:mm a')}
            </p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">State</p>
            {!form.lossState ? (
              <p className="font-bold text-rose-600 justify-self-end">
                Missing State
              </p>
            ) : (
              <p className="font-normal">{usStatesMapping[form.lossState]}</p>
            )}
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Location Details</p>
            <p className="font-normal">{form.lossLocation}</p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Policy Number</p>
            {!form.policyNumber ? (
              <p className="font-bold text-rose-600 justify-self-end">
                Missing Policy Number
              </p>
            ) : (
              <p className="font-normal">{form.policyNumber}</p>
            )}
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Line of Business</p>
            <p className="text-normal font-normal">
              {policyCoveragesMapping[form.lineOfBusiness]}
            </p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">
              Was your vehicle involved?
            </p>

            <div>
              <p className="font-normal capitalize">
                {form.ownVehiclesInvolved}
              </p>

              {insuredVehicleVins.map((vin: string, index: number) => (
                <p key={index} className="font-normal mt-2">
                  {vin}
                </p>
              ))}
            </div>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">
              Was another vehicle involved?
            </p>

            <div>
              <p className="font-normal capitalize">
                {form.otherVehiclesInvolved}
              </p>

              {otherVehicleVins.map((vin: string, index: number) => (
                <p key={index} className="font-normal mt-2">
                  {vin}
                </p>
              ))}
            </div>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">
              Was there police at the scene?
            </p>
            <p className="font-normal capitalize">{form.police.onTheScene}</p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Was anyone injured?</p>
            <p className="font-normal capitalize">{form.injureds}</p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Police Agency Name</p>
            <p className="font-normal capitalize">{form.police.agencyName}</p>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">
              Police Report Number
            </p>
            <p className="font-normal">{form.police.reportNumber}</p>
          </div>

          <div className="flex mt-4">
            <div className="flex-1 max-w-72">
              <p className="font-semibold">Incident Description</p>
            </div>

            <div className="flex-1">
              <p className="font-normal whitespace-pre-wrap">
                {form.description}
              </p>
            </div>
          </div>

          <div className="flex mt-4">
            <p className="font-semibold flex-1 max-w-72">Attachments</p>

            <div className="flex flex-col">
              {form.attachments.map((attachment: Attachment, index: number) => (
                <div
                  className="border border-solid border-primary-tint3 rounded-md mb-2 p-1"
                  key={`file-${index}`}
                >
                  <FileListItem file={attachment.file} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
