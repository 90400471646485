import { useFlags } from 'launchdarkly-react-client-sdk';

export const ANONYMOUS_USER_KEY = '00000000-0000-0000-0000-000000000000';
export enum Feature {
  DRIVER_SAFETY_SCORE = 'driverSafetyScore',
  DISABLE_SPECIFIC_DOT_TRS = 'disableSpecificDotTrs',
  PERKS_TAB = 'featurePerksTabInSafetyApp',
  REPORT_DOWNLOAD = 'safetyAppReportDownload',
  RECOMMENDATIONS_FEEDBACK_MODAL = 'safetyRecommendationFeedbackModal',
  TRAINING_TAB = 'featureTrainingTabInSafetyApp',
  INVITE = 'safetyInvite',
  SHOW_RECOMMENDATION = 'safetyVehiculesRecommendations',
  SHOW_EXPIRING_VIOLATIONS = 'safetyCsaViolationTimeline',
  SHOW_BOOKING_BUTTON = 'safetyBasicRecommendationsBooking',
  SHOW_CLAIMS = 'safetyClaims',
  CLAIMS_FEEDBACK = 'safetyClaimsFeedback',
  SHOW_DRIVER_NAME = 'safetyShowDriverName',
  CLAIMS_FNOLS_STATUS = 'safetyfnols',
  REPORT_A_CLAIM_BUTTON = 'reportFnolForm',
  REPORT_FNOL_ENABLED_BY_DOT = 'reportFnolEnabledByDot',
  SHOW_DRIVERS = 'safetyDriversPage',
}

export function useFeatureEnabled(flag: string): boolean {
  const flags = useFlags();

  return flags[flag];
}

export function useFeatureValue(flag: string): string {
  const flags = useFlags();

  return flags[flag];
}
