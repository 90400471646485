import { differenceInMonths } from 'date-fns';
import parseISO from 'date-fns/parseISO';

export const getWeightForViolation = (violation: {
  severityWeight: number;
  inspectionDate: string;
  oosIndicator: boolean;
}) => {
  const weight = violation.severityWeight + (violation.oosIndicator ? 2 : 0);
  const timeAgo = differenceInMonths(
    new Date(),
    parseISO(violation.inspectionDate),
  );
  let weightFactor;
  if (timeAgo < 6) {
    weightFactor = 3;
  } else if (timeAgo >= 6 && timeAgo < 12) {
    weightFactor = 2;
  } else if (timeAgo >= 12 && timeAgo < 24) {
    weightFactor = 1;
  } else {
    weightFactor = 0;
  }

  return weight * weightFactor;
};
