import * as React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Customized,
} from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import CustomizedLabel from './customized-label';
import CustomizedAxisTick from './custom-axis-tick';
import ThresholdLine from './threshold-line';
import RiskScoreChange from './risk-score-change';
import CustomTooltip from './custom-tooltip';

/** Column-chart properties */
type BasicScoresChartProps = {
  /** Provide source data for the chart. The data source is a collection of objects, such as
   * `const data = [{ time: '1991', value: 20 }, { time: '1992', value: 20 }]`
   */
  data: Array<any>;
  /** The name of the data field corresponding to the graph in the x-direction
   * For the above data, 'xField' will be 'time'
   */
  xField: string;
  /** The name of the data field corresponding to the graph in the y-direction
   * For the above data, 'yField' will be 'value'
   */
  yField: string;
  /** Callback function to be called when the bar chart is clicked */
  onBarChartClick?: (e?: CategoricalChartState) => void;
  /** Optional field for a min height in bar chart */
  minHeight?: number;
  /** Optional mapping for x-axis labels */
  customLabels?: { [key: string]: string };
};

export default function BasicScoresChart({
  data,
  xField,
  yField,
  onBarChartClick,
  minHeight = 0,
  customLabels = {},
}: BasicScoresChartProps) {
  const AdjustedRiskScoreChange = (props: any) => {
    const { customLabels, ...restProps } = props;
    const yOffset = customLabels ? 6 : 0;
    return <RiskScoreChange {...restProps} y={props.y + yOffset} />;
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={400}
        data={data}
        margin={{ top: 20, bottom: 50, left: 20 }}
        onClick={onBarChartClick}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 2" />
        <XAxis
          dataKey={xField}
          tick={<CustomizedAxisTick customLabels={customLabels} />}
          interval={0}
          tickLine={false}
          axisLine={false}
          stroke="#3350A1"
        />
        <YAxis
          ticks={[0, 20, 40, 60, 80, 100]}
          tickMargin={5}
          tickLine={false}
          axisLine={false}
          fontSize={12}
          tick={{ fill: '#878B95' }}
          stroke="#3350A1"
          label={{
            value: 'Score',
            angle: -90,
            fill: '#3350A1',
            offset: 5,
            position: 'insideLeft',
            dy: 15,
          }}
        />
        <Customized key="threshold-label" component={<CustomizedLabel />} />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey={yField}
          barSize={30}
          radius={3}
          name="Percentile"
          minPointSize={minHeight}
        >
          {data.map((entry) => (
            <Cell
              key={entry[xField]}
              fill={entry.color}
              cursor={entry.cursor}
            />
          ))}
          <LabelList dataKey="threshold" content={<ThresholdLine />} />
          <LabelList
            dataKey="changeFromLastMonth"
            content={<AdjustedRiskScoreChange customLabels={customLabels} />}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
