type GetISSLineChartProps = {
  period: number;
};

export const getISSLineChartProps = ({ period }: GetISSLineChartProps) => ({
  xField: 'name',
  yField: ['value'],
  xAxis: {
    axisLine: false,
    tick: { fontSize: 12, angle: -30, textAnchor: 'end', fill: '#878B95' },
    label: { value: `Last ${period} months`, offset: -25, dy: 10 },
  },
  yAxis: {
    axisLine: false,
    tick: { fontSize: 12, fill: '#878B95' },
    label: { value: 'Score' },
    ticks: [25, 50, 75, 100],
  },
  margin: { bottom: 50, top: 20, right: 30 },
});

type GetBasicScoreLineChartProps = {
  period: number;
  threshold: number;
};
export const getBasicScoreLineChartProps = ({
  period,
  threshold,
}: GetBasicScoreLineChartProps) => ({
  xField: 'month',
  yField: ['percentile'],
  xAxis: {
    axisLine: false,
    tick: { fontSize: 12, angle: -30, textAnchor: 'end', fill: '#878B95' },
    label: { value: `Last ${period} months`, offset: -25, dy: 10 },
  },
  yAxis: {
    axisLine: false,
    tick: { fontSize: 12, fill: '#878B95' },
    label: { value: 'Score' },
    ticks: [20, 40, 60, 80, 100],
  },
  margin: { bottom: 50, top: 20, right: 30 },
  lineConfig: [{ name: 'Value' }],
  referenceLines: [{ y: threshold, stroke: '#FA3252', strokeDasharray: '5 3' }],
});
