import { useState } from 'react';
import { Switch, storage } from '@nirvana/ui-kit';
import { Button, CircularProgress } from '@material-ui/core';
import { AUTH_TOKEN_STORAGE_KEY, SHARE_TOKEN_STORAGE_KEY } from 'src/constants';
import SignUpForm from 'src/components/sign-up-form';
import { useParams } from 'react-router-dom';
import { useActivateUserMutation } from 'src/types/graphql-types';
import { analytics } from 'src/helpers';
import log from 'src/utils/logger';

const SignUp = () => {
  const { shareId = '' } = useParams();
  const [error, setError] = useState<string | null>(null);

  const [activateUserInvite, { loading: activateLoading }] =
    useActivateUserMutation();

  const onSubmit = (formData: any) => {
    storage.set(SHARE_TOKEN_STORAGE_KEY, shareId);

    activateUserInvite({
      variables: {
        ...formData,
      },
      onError: (error) => {
        const graphqlError = error.graphQLErrors?.[0] as unknown as
          | string
          | undefined;
        if (graphqlError?.includes('already active')) {
          setError(
            "An account with this email already exists. Please click 'Sign in' to access your account",
          );
          return;
        }
        if (graphqlError?.includes('Failed to update user')) {
          setError(
            'This emails is already in use, please use a different email',
          );
          return;
        }
        log('Error activating user', 'error', error);
        setError(
          'There was an error while activating your account, please try again later',
        );
      },
      onCompleted: (data) => {
        if (!data.activateUser) {
          setError(
            'There was an error while activating your account, please try again later',
          );
          log('Activation failed with data', 'error', data);
          return;
        }
        storage.remove(SHARE_TOKEN_STORAGE_KEY);
        storage.set(AUTH_TOKEN_STORAGE_KEY, data.activateUser.sessionId);

        analytics.trackEvent({
          event: analytics.SegmentEventTrack.UserActivateSuccessful,
          properties: {
            email: formData.email,
          },
        });
        if (data.activateUser?.fleetSafetyReportId) {
          window.location.href = `/${data.activateUser.fleetSafetyReportId}/overview`;
          return;
        }
        window.location.href = '/';
      },
    });
  };

  const goToOverview = () => {
    storage.set(SHARE_TOKEN_STORAGE_KEY, shareId);
    window.location.href = '/';
  };

  return (
    <Switch>
      <Switch.Match when={!activateLoading}>
        <SignUpForm
          onSubmit={onSubmit}
          error={error}
          signInLink="/"
          title="Sign up"
        />
        <Button onClick={goToOverview} className="w-full">
          Explore Safety Insights
        </Button>
      </Switch.Match>
      <Switch.Match when={activateLoading}>
        <CircularProgress />
      </Switch.Match>
    </Switch>
  );
};

export default SignUp;
