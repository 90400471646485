import { Show } from '@nirvana/ui-kit';
import clsx from 'clsx';
import React from 'react';
import useLocalStorage from 'src/hooks/use-local-storage';
import log from 'src/utils/logger';

type BannerProps = {
  storageKey?: string;
  type?: 'info';
  title: string;
  children: React.ReactNode;
  cta?: React.ReactNode;
  allowDismiss?: boolean;
};

const typesClasses = {
  info: 'bg-info-light border-info-main',
};

export const Banner = ({
  storageKey,
  type = 'info',
  title,
  children,
  cta,
  allowDismiss = false,
}: BannerProps) => {
  if (!storageKey && allowDismiss) {
    log(
      `Warning: You should provide a storageKey when enabling banner dismissal, switching back to title as key (${title})`,
    );
  }

  const [isBannerVisible, setIsBannerVisible] = useLocalStorage(
    storageKey ?? window.btoa(title),
    true,
  );
  if (allowDismiss && !isBannerVisible) {
    return null;
  }
  return (
    <div
      className={clsx('px-6 py-4 mt-4 border rounded-lg', typesClasses[type])}
    >
      <h4 className="mb-2 font-semibold">{title}</h4>
      {children}
      <div
        className={clsx({
          flex: allowDismiss,
          'justify-end': allowDismiss && !cta,
          'justify-between': allowDismiss && cta,
        })}
      >
        <Show when={cta}>
          <div className="flex items-center justify-between font-medium text-primary-main">
            {cta}
          </div>
        </Show>
        <Show when={allowDismiss}>
          <button
            className="mt-2 text-primary-main"
            onClick={() => setIsBannerVisible(false)}
          >
            Dismiss
          </button>
        </Show>
      </div>
    </div>
  );
};
