import { AuthApi, Configuration } from '@nirvana/api/auth';

/**
 * Create instance of AuthApi
 */
const configOptions = new Configuration();
configOptions.isJsonMime = () => false;
const apiService = new AuthApi(configOptions);

export default apiService;
