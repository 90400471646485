import { Feature, FeatureCollection } from 'geojson';

type ChartGeoJsonData = {
  features: Feature[];
};

const geojsonColorPalette = {
  '0-20': '#6ad397',
  '20-40': '#b4e28b',
  '40-60': '#f8f07e',
  '60-80': '#ff9869',
  '80-100': '#fa3653',
};

const getGeojsonColor = (count: number) => {
  switch (true) {
    case count <= 20:
      return geojsonColorPalette['0-20'];
    case count <= 40:
      return geojsonColorPalette['20-40'];
    case count <= 60:
      return geojsonColorPalette['40-60'];
    case count <= 80:
      return geojsonColorPalette['60-80'];
    case count <= 100:
      return geojsonColorPalette['80-100'];
  }
};

const getGeojsonProperties = (properties: any) => {
  const count = properties?.violation_percentage;
  const fill = getGeojsonColor(count);
  return { ...properties, fill };
};

export const getGeojsonData = (data: ChartGeoJsonData): FeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: data.features.map((feature) => ({
      ...feature,
      properties: getGeojsonProperties(feature.properties),
    })),
  };
};
