import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, styled } from '@material-ui/core';
import { Show } from '@nirvana/ui-kit';

import ConnectTspModal from 'src/components/connect-tsp-modal';
import { useFleetDetailsQuery } from 'src/types/graphql-types';
import { ReactComponent as LocationInsight } from 'src/assets/icons/location-insight.svg';

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white !important',
  color: theme.palette.primary.dark,
  fontWeight: 600,
  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.12)',
  '&:hover': {
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.12)',
  },
}));

export default function PreTelematicsBanner() {
  const { reportId = '' } = useParams();
  const [showTelematicsModal, setShowTelematicsModal] = useState(false);
  const { data } = useFleetDetailsQuery({
    variables: { reportId },
  });

  return (
    <Show when={data?.fleetSafetyReport?.hasTelematicsConnection === false}>
      <div className="flex flex-wrap items-center pr-8 mb-6 space-x-4 border rounded-lg border-primary-extraLight bg-gradient-to-r from-primary-light/20 to-primary-main/20">
        <LocationInsight className="w-16" />

        <div className="flex-1 text-primary-main">
          <p className="text-xl font-bold">
            <span className="text-primary-light">Great start,</span> Save up to
            20%
          </p>
          <p>
            Fleets like yours can save up to 20% with Nirvana Insurance after
            connecting telematics
          </p>
        </div>

        <CustomButton
          variant="contained"
          className="mx-auto mt-4 md:mt-0"
          onClick={() => setShowTelematicsModal(true)}
        >
          Connect Telematics
        </CustomButton>
      </div>

      <ConnectTspModal
        open={showTelematicsModal}
        onClose={() => setShowTelematicsModal(false)}
      />
    </Show>
  );
}
