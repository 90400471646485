import qs from 'query-string';

export function firstParam(
  keys: string[],
  searchParams: qs.ParsedQuery<string>,
): (string | null)[] {
  return keys.map((key) => {
    const val = searchParams[key];
    if (!val) {
      return null;
    }
    if (Array.isArray(val)) {
      return val[0];
    }
    return val;
  });
}
