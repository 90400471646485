import * as React from 'react';
import {
  HiOutlineTruck,
  HiOutlineChat,
  HiOutlineChartBar,
  HiOutlineBadgeCheck,
} from 'react-icons/hi';

export const instructions = [
  {
    icon: <HiOutlineTruck className="w-6 h-6" />,
    content: 'An exclusive Nirvana Safety Score for your fleet and drivers',
  },
  {
    icon: <HiOutlineChat className="w-6 h-6" />,
    content: 'Personalized tips based on driving behavior',
  },
  {
    icon: <HiOutlineChartBar className="w-6 h-6" />,
    content:
      'Route and region-level analytics, including speeding and harsh events history',
  },
  {
    icon: <HiOutlineBadgeCheck className="w-6 h-6" />,
    content: 'See how you rank compared to similar fleets',
  },
];
