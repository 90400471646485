import * as React from 'react';
import clsx from 'clsx';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from '@material-ui/core';

import IconUnchecked from '../../assets/icons/unchecked.svg';
import IconChecked from '../../assets/icons/checked.svg';

type CheckboxProps = MuiCheckboxProps & {
  children?: React.ReactNode;
};

/**
 * Checkbox component with label and custom style.
 * Note: All props except children are passed down to MuiCheckbox.
 * Refer: https://next.material-ui.com/components/checkboxes
 * @component
 *
 * @param {Object} props - Extends MUI Checkbox
 * @param {string} props.label - Checkbox label to describe usage.
 * @param {string} props.className - Additional classes passed on to checkbox container.
 *
 * @example
 * return <Checkbox disabled>Check something here</Checkbox>
 */
const Checkbox = ({
  children,
  className,
  disabled,
  ...restProps
}: CheckboxProps) => {
  if (children) {
    return (
      <FormControlLabel
        className={className}
        control={<Checkbox disabled={disabled} {...restProps} />}
        label={children}
      />
    );
  }

  return (
    <MuiCheckbox
      className={clsx(className, {
        'opacity-50': disabled,
      })}
      color="default"
      checkedIcon={<img src={IconChecked} alt="Checked" />}
      icon={<img src={IconUnchecked} alt="Unchecked" />}
      disabled={disabled}
      {...restProps}
    />
  );
};

export default Checkbox;
