import { useMemo, useState } from 'react';
import type { FeatureCollection, Geometry } from 'geojson';
import { Button, MenuItem, Select } from '@material-ui/core';
import type { MapLayerMouseEvent } from 'react-map-gl';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { HiOutlineChevronRight, HiOutlineLocationMarker } from 'react-icons/hi';
import { getFormattedDate, range, Show, Switch } from '@nirvana/ui-kit';

import { Dates } from 'src/helpers';
import { BASICS } from 'src/constants';
import useMapRef from 'src/hooks/use-map-ref';
import MapChart from 'src/components/map-chart';
import useMapHover from 'src/hooks/use-map-hover';
import SelectPeriod from 'src/components/select-period';
import MapTooltip from 'src/pages/heatmap/components/map-tooltip';
import { useGetViolationMapDataQuery } from 'src/types/graphql-types';
import type { Category, Inspection } from 'src/types/graphql-types';
import { getGeojsonData } from 'src/pages/recommendations/constants/geojson-properties';

export default function Heatmap() {
  const navigate = useNavigate();
  const { reportId = '' } = useParams();
  const [period, setPeriod] = useState(24);
  const [selectedCategory, setSelectedCategory] = useState<
    Category | 'allViolations'
  >('allViolations');

  const { tooltipInfo, handleHover, onMouseLeave } = useMapHover();

  const fromTimestamp = useMemo(() => {
    // We fetch data for an extra month because
    // last month's data is not available sometimes
    const startMonth = startOfMonth(subMonths(new Date(), period + 1));
    return Dates.formatISOUTC(startMonth);
  }, [period]);

  const toTimestamp = useMemo(() => {
    const lastMonth = endOfMonth(subMonths(new Date(), 1));
    return Dates.formatISOUTC(lastMonth);
  }, []);

  const { data, loading } = useGetViolationMapDataQuery({
    variables: {
      reportId,
      violationType: 'ViolationsCount',
      fromTimestamp,
      toTimestamp,
    },
  });

  const geojson = useMemo(() => {
    if (data?.fleetSafetyReport?.LocationStats) {
      const locationData = getGeojsonData(
        data.fleetSafetyReport.LocationStats as FeatureCollection<Geometry>,
      );

      return {
        ...locationData,
        features: locationData.features.filter(({ properties }: any) =>
          selectedCategory !== 'allViolations'
            ? properties.inspectionRecords.find(({ violations }: Inspection) =>
                violations.find(
                  ({ category }) => category === selectedCategory,
                ),
              )
            : true,
        ),
      };
    }
  }, [data, selectedCategory]);

  const mapRef = useMapRef(geojson);

  const topCountiesByViolations = useMemo(
    () =>
      geojson?.features
        .sort(
          (f1, f2) =>
            f2?.properties?.violation_count - f1?.properties?.violation_count,
        )
        .slice(0, 3) ?? [],
    [geojson],
  );

  function handleMouseEnter(e: MapLayerMouseEvent) {
    const feature = e.features && e.features[0];
    if (feature && feature.properties?.violation_count) {
      return 'pointer';
    } else {
      return 'not-allowed';
    }
  }

  function handleClick(event: MapLayerMouseEvent) {
    const feature = event.features && event.features[0];
    if (feature && feature.properties?.violation_count) {
      navigate(
        `/${reportId}/insights/violations?location_countyCode=${feature.properties?.countyCode}`,
      );
    }
  }

  const basicCategories = useMemo(() => {
    const categories: { [key: string]: boolean } = {};

    data?.fleetSafetyReport?.LocationStats?.features.forEach((feature) =>
      feature.properties?.inspectionRecords.forEach((record) =>
        record.violations.forEach(
          ({ category }) => (categories[category] = true),
        ),
      ),
    );

    return categories;
  }, [data]);

  const scoreDate = useMemo(() => {
    const date = data?.fleetSafetyReport?.basicScores[0]?.scoreDate;
    return date ? getFormattedDate(date, 'MM/dd/yyyy') : '';
  }, [data]);

  return (
    <div className="mb-6 bg-white rounded-lg shadow">
      <div className="flex flex-wrap items-center p-4 border-b">
        <div className="p-1 mr-4 text-xl rounded bg-gold-light/30 text-gold-main">
          <HiOutlineLocationMarker />
        </div>
        <span className="text-xl font-semibold text-gray-800">Heatmap</span>
        <div className="flex-1" />
        <span className="mt-4 text-xs text-text-secondary">
          Most Recent FMCSA Data Release Date: {scoreDate}
        </span>
      </div>

      <div className="p-4">
        <div className="flex flex-wrap items-center justify-between mb-4">
          <Select
            variant="outlined"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <MenuItem value="allViolations">All Violations</MenuItem>
            {Object.keys(basicCategories).map((category) => (
              <MenuItem value={category} key={category}>
                {BASICS[category as Category]}
              </MenuItem>
            ))}
          </Select>
          <SelectPeriod value={period} onChange={setPeriod} />
        </div>
        <div className="flex flex-wrap items-start lg:space-x-4">
          <div className="w-full lg:w-2/3">
            <MapChart
              ref={mapRef}
              geojson={geojson}
              onHover={handleHover}
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Show when={tooltipInfo}>
                {({ x, y, feature }) => (
                  <MapTooltip x={x} y={y} properties={feature.properties} />
                )}
              </Show>
            </MapChart>
            <div className="flex items-center justify-center px-2 my-4 space-x-4 text-xs text-secondary-main">
              <p>Low number of events</p>
              <div className="flex-1 min-w-40 h-2 rounded-xl bg-gradient-to-r from-[#66D398] via-[#FFF27D] to-[#FA3252]" />
              <p>High number of events</p>
            </div>

            <div className="flex items-center justify-center">
              <Link to={`/${reportId}/insights/violations`}>
                <Button endIcon={<HiOutlineChevronRight />}>
                  View all violations
                </Button>
              </Link>
            </div>
          </div>

          <div className="flex-1 p-4 space-y-4 border rounded-md border-text-disabled">
            <p className="text-base font-bold text-text-primary">
              Locations impacting your scores
            </p>
            <div className="flex items-center justify-between px-4 py-2 rounded bg-primary-extraLight">
              <p className="text-xs font-normal text-gray-600">County</p>
              <p className="text-xs font-normal text-gray-600">Violations #</p>
            </div>
            <Switch>
              <Switch.Match when={loading}>
                {range(3).map((item) => (
                  <div
                    key={item}
                    className="flex items-center justify-between px-4 py-1"
                  >
                    <div className="w-24 h-6 bg-gray-100 rounded animate-pulse" />
                    <div className="w-12 h-6 bg-gray-100 rounded animate-pulse" />
                  </div>
                ))}
              </Switch.Match>
              <Switch.Match when={topCountiesByViolations.length > 0}>
                {topCountiesByViolations?.map((feature, index) => (
                  <div
                    className="flex items-center justify-between px-4 py-1"
                    key={index}
                  >
                    <Link
                      className="underline text-primary-main hover:text-primary-dark"
                      to={`/${reportId}/insights/violations?location_countyCode=${feature.properties?.countyCode}`}
                    >
                      {feature?.properties?.name} County
                    </Link>
                    <p>{feature?.properties?.violation_count}</p>
                  </div>
                ))}
              </Switch.Match>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
