import { VehicleRiskScoreBuckets } from 'src/helpers';
import percentRound from 'percent-round';
import { AggregatedInspection } from './mapInspectionsToDrivers';

export const generateRiskScoresPercentagesBuckets = (
  mappedDrivers: AggregatedInspection[],
) => {
  const riskScoresCount = mappedDrivers.reduce((acc, driver) => {
    const { score } = driver;
    VehicleRiskScoreBuckets.some((bucket) => {
      if (bucket.contains(score)) {
        acc[bucket.name] = (acc[bucket.name] ?? 0) + 1;
        return true;
      }
      return false;
    });
    return acc;
  }, {} as Record<string, number>);

  const totalDrivers = mappedDrivers.length;

  const riskScoreAsPercentages = {} as Record<string, number>;

  Object.entries(riskScoresCount).forEach(([key, element]) => {
    riskScoreAsPercentages[key] = (element * 100) / totalDrivers;
  });

  const percentages = percentRound(Object.values(riskScoreAsPercentages));
  let i = 0;

  Object.entries(riskScoreAsPercentages).forEach(([key]) => {
    riskScoreAsPercentages[key] = percentages[i];
    i++;
  });
  return riskScoreAsPercentages;
};
