import { constants } from '@nirvana/ui-kit';
import {
  LineOfBusiness,
  FnolNoticeType,
  PolicyCoverageEnums,
} from 'src/types/graphql-types';

export type Step =
  | 'reporter-information'
  | 'incident-details'
  | 'review-and-submit';

export const STEPS: { key: Step; label: string }[] = [
  { key: 'reporter-information', label: 'Reporter Information' },
  { key: 'incident-details', label: 'Incident Details' },
  { key: 'review-and-submit', label: 'Review & Submit' },
];

export const noticeTypes = {
  initiateClaim: FnolNoticeType.Claim,
  onlyReporting: FnolNoticeType.Report,
};

export const noticeTypeMapping = {
  initiateClaim: 'Initiate Claim',
  onlyReporting: 'Only Reporting',
};

export const noticeTypeOptions = Object.keys(noticeTypeMapping).map((key) => ({
  label: noticeTypeMapping[key as keyof typeof noticeTypeMapping],
  value: key,
}));

export const usStatesMapping: { [key: string]: string } =
  constants.usStates.reduce((acc: { [key: string]: string }, current) => {
    acc[current.code] = current.name;
    return acc;
  }, {});

export const policyCoveragesMapping: Record<PolicyCoverageEnums, string> = {
  [PolicyCoverageEnums.CoverageAutoLiability]: 'Auto Liability',
  [PolicyCoverageEnums.CoverageGeneralLiability]: 'General Liability',
  [PolicyCoverageEnums.CoverageMotorTruckCargo]: 'Motor Truck Cargo',
  [PolicyCoverageEnums.CoverageAutoPhysicalDamage]: 'Auto Physical Damage',
};

export const lineOfBusiness = {
  CoverageAutoLiability: LineOfBusiness.AutoLiability,
  CoverageAutoPhysicalDamage: LineOfBusiness.AutoLiability,
  CoverageGeneralLiability: LineOfBusiness.GeneralLiability,
  CoverageMotorTruckCargo: LineOfBusiness.MotorTruckCargo,
};
