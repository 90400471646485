import React, { useState, useEffect } from 'react';
import { Grid, Typography, Link, makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITheme, maskEmail } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';

import { forgotPassword } from '../../queries';
import PasswordHelper from './password-helper';

const RESEND_TIMEOUT = 30000;

const useStyles = makeStyles((theme: ITheme) => ({
  resend: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  pointerCursor: {
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
  },
  email: {
    color: theme.palette.text.primary,
  },
}));

const VerificationToken = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [waiting, setWaiting] = useState(false);
  const [timer, setTimer] = useState<number | null>(null);
  const email = location.state?.email;
  const localStorageKey = 'resendLinkTime';

  const startCountdown = (timeout: number) => {
    setWaiting(true);
    const newTimer = window.setInterval(() => {
      setWaiting(false);
    }, timeout);
    setTimer(newTimer);
  };

  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timer]);

  useEffect(() => {
    const storedTimeStamp = localStorage.getItem(localStorageKey);
    if (storedTimeStamp) {
      const currentTime = new Date().getTime();
      const timeDifference =
        parseInt(storedTimeStamp) - currentTime + RESEND_TIMEOUT;
      if (timeDifference < RESEND_TIMEOUT) {
        startCountdown(timeDifference);
      }
    }
  }, []);

  const { mutate } = useMutation(forgotPassword, {
    onSuccess: () => {
      const currentTime = new Date().getTime();
      localStorage.setItem(localStorageKey, currentTime.toString());
      startCountdown(RESEND_TIMEOUT);
    },
  });

  const handleResendClick = () => {
    mutate({ email });
  };

  useEffect(() => {
    if (!email) {
      navigate('/forgot-password', { replace: true });
    }
  });

  if (!email) {
    return null;
  }

  return (
    <Grid container direction="column">
      <Grid item mb={29} position="relative">
        <PasswordHelper
          heading="Verify Identity"
          text={
            <span>
              We’ve sent a verification link to{' '}
              <span className={classes.email}>{maskEmail(email)}</span>. Please
              click on the link in the email to continue resetting your
              password. The link will expire in 10 minutes.
            </span>
          }
        />
      </Grid>
      <Grid item className={classes.resend}>
        {waiting ? (
          <Typography variant="caption" color="#25B24A">
            Link has been re-sent
          </Typography>
        ) : (
          <Typography variant="caption" color="text.hint">
            Didn’t receive code?{' '}
            <Link className={classes.pointerCursor} onClick={handleResendClick}>
              Resend
            </Link>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default VerificationToken;
