import * as React from 'react';
// @ts-ignore
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/* react-click lacks types so this backfills available options for typescript
 * see https://react-slick.neostack.com/docs/api
 * */
interface SliderProps {
  accessibility?: boolean;
  adaptiveHeight?: boolean;
  afterChange?: (index: number) => void;
  appendDots?: (dots: React.ReactNode) => React.ReactNode;
  arrows?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  beforeChange?: (oldIndex: number, newIndex: number) => void;
  centerMode?: boolean;
  centerPadding?: string;
  className?: string;
  cssEase?: string;
  customPaging?: (i: number) => React.ReactNode;
  dots?: boolean;
  dotsClass?: string;
  draggable?: boolean;
  easing?: string;
  edgeFriction?: number;
  fade?: boolean;
  focusOnSelect?: boolean;
  infinite?: boolean;
  initialSlide?: number;
  lazyLoad?: string;
  nextArrow?: React.ReactNode;
  onEdge?: () => void;
  onInit?: () => void;
  onLazyLoadError?: () => void;
  onReInit?: () => void;
  pauseOnDotsHover?: boolean;
  pauseOnFocus?: boolean;
  pauseOnHover?: boolean;
  prevArrow?: React.ReactNode;
  responsive?: Array<{
    breakpoint: number;
    settings: Partial<SliderProps>;
  }>;
  rows?: number;
  rtl?: boolean;
  slide?: string;
  slidesPerRow?: number;
  slidesToScroll?: number;
  slidesToShow?: number;
  speed?: number;
  swipe?: boolean;
  swipeEvent?: (swipeDirection: string) => void;
  swipeToSlide?: boolean;
  touchMove?: boolean;
  touchThreshold?: number;
  useCSS?: boolean;
  useTransform?: boolean;
  variableWidth?: boolean;
  vertical?: boolean;
  waitForAnimate?: boolean;
  asNavFor?: Slider;
  unslick?: boolean;
}

interface CarouselProps {
  children: React.ReactNode[];
  sliderProps?: SliderProps;
}

const defaultSliderProps = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

export default function Carousel({ sliderProps, children }: CarouselProps) {
  const settings = {
    ...defaultSliderProps,
    ...sliderProps,
  };
  return <Slider {...settings}>{children}</Slider>;
}
