import clsx from 'clsx';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { BucketName, VehicleRiskScoreBuckets } from 'src/helpers';

export const ScoresDescription = () => {
  return (
    <div className="flex justify-center w-full mt-8">
      <div className="flex space-x-8">
        {VehicleRiskScoreBuckets.sort((a, b) => b.order - a.order).map(
          (bucket) => (
            <div key={bucket.name} className="flex items-center">
              <div
                className={clsx(
                  ' rounded-full bg-tr w-3 h-3 mr-2',
                  bucket.bgClass,
                )}
              />
              {bucket.tierName}
              {bucket.tierName === BucketName.Inconclusive ? (
                <HiOutlineInformationCircle className="ml-2" />
              ) : (
                <span className="ml-2 text-text-hint">({bucket.name})</span>
              )}
            </div>
          ),
        )}
      </div>
    </div>
  );
};
