import * as React from 'react';

type BlankStateProps = {
  icon: React.ReactNode;
  text?: string;
  subText?: string;
};

const BlankStateRegular = ({ icon, text, subText }: BlankStateProps) => {
  return (
    <div className="flex flex-col flex-grow items-center justify-center">
      <div className="mb-3">{icon}</div>

      {text && (
        <p className="text-xl font-semibold text-text-primary mb-1 text-center">
          {text}
        </p>
      )}
      {subText && (
        <p className="text-sm text-text-hint max-w-sm text-center">{subText}</p>
      )}
    </div>
  );
};

export default BlankStateRegular;
