import {
  ColumnFiltersState,
  ColumnSort,
  OnChangeFn,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

const convertSortByToQueryParams = (sortBy: ColumnSort[]) => {
  const paramsObj = sortBy.reduce(
    (acc, sortBy) =>
      acc + `${acc.length > 0 ? ',' : ''}${sortBy.desc ? '-' : ''}${sortBy.id}`,
    '',
  );
  return paramsObj;
};

const convertSortByFromQueryParams = (sortBy: string) => {
  const paramsObj = sortBy.split(',');
  return paramsObj.map((param) => ({
    id: param.replace(/^-/, ''),
    desc: param.startsWith('-'),
  }));
};

// This hook provides a set of functions to manage the state of the table
// using query parameters.
export function useTableQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const mapValue = (value: any) => {
    if (isNaN(Number(value))) {
      return value;
    }
    return Number(value);
  };

  const getFilters = (includeExternalFilters = false) => {
    const paramsObj = Array.from(searchParams.keys())
      .filter((key) =>
        includeExternalFilters
          ? true
          : !['search', 'period', 'sortBy'].includes(key),
      )
      .reduce(
        (acc, key) => [
          ...acc,
          {
            id: key,
            value: mapValue(searchParams.get(key)),
          },
        ],
        [] as ColumnFiltersState,
      );
    return paramsObj;
  };

  const setFilters = (cb: Updater<ColumnFiltersState>) => {
    const newParams = typeof cb === 'function' ? cb(getFilters(true)) : cb;

    const filteredParams = newParams.reduce(
      (acc, param) => ({
        ...acc,
        ...(param.value === undefined || param.value === ''
          ? {}
          : { [param.id]: param.value }),
      }),
      {},
    );

    setSearchParams(filteredParams as URLSearchParamsInit);
  };

  const setGlobalFilter = (filterValue: any) => {
    if (filterValue) {
      return setFilters((prev) => [
        ...prev,
        { id: 'search', value: filterValue },
      ]);
    }
    return setFilters((prev) =>
      prev.filter((filter) => filter.id !== 'search'),
    );
  };

  const globalFilter = getFilters(true)
    .find((filter) => filter.id === 'search')
    ?.value?.toString();

  const columnFilters = getFilters();

  const period = Number(
    getFilters(true).find((filter) => filter.id === 'period')?.value ?? 24,
  );

  const setPeriod = (period: number) => {
    setFilters((prev) => [...prev, { id: 'period', value: period }]);
  };

  const getSortBy = () => {
    const sortBy = getFilters(true).find(
      (filter) => filter.id === 'sortBy',
    )?.value;
    if (sortBy === undefined) {
      return [] as ColumnSort[];
    }
    const convertedSortBy = convertSortByFromQueryParams(sortBy as string);
    return convertedSortBy;
  };

  const setSortBy: OnChangeFn<SortingState> = (cb: Updater<SortingState>) => {
    const newSortBy = typeof cb === 'function' ? cb(getSortBy()) : cb;

    const convertedSortBy = convertSortByToQueryParams(newSortBy);

    if (convertedSortBy) {
      return setFilters((prev) => [
        ...prev,
        { id: 'sortBy', value: convertedSortBy },
      ]);
    }

    setFilters((prev) => prev.filter((filter) => filter.id !== 'sortBy'));
  };

  return {
    setFilters,
    columnFilters,
    globalFilter,
    setGlobalFilter,
    setPeriod,
    setSortBy,
    getSortBy,
    period,
  };
}
