import { Paper } from '@material-ui/core';
import { ReactComponent as Car } from 'src/assets/icons/car.svg';
import { ReactComponent as Document } from 'src/assets/icons/document.svg';
import { ReactComponent as Paperclip } from 'src/assets/icons/paperclip.svg';

export const Overview = () => {
  return (
    <div className="flex flex-col">
      <h3 className="text-base font-bold text-3xl">Report a Claim</h3>
      <p className="text-xl text-text-hint mt-2">
        Report an incident or start a claim. The following questions should take
        you about 5 minutes to complete.
      </p>

      <Paper className="p-8 mt-4" elevation={1}>
        <div className="flex flex-col gap-4">
          <p className="font-bold text-text-secondary text-base text-lg">
            Before you begin, please have the following information ready.
          </p>
          <p className="font-normal text-secondary-light text-base">
            If you leave before completing all questions, your information will
            be lost.
          </p>

          <div className="flex flex-row gap-4 my-3 items-center">
            <div className="text-primary-extraLight rounded w-9 h-9 p-2 flex justify-center items-center">
              <Car />
            </div>
            <div className="flex flex-col">
              <h6 className="font-bold text-text-secondary text-base">
                Vehicle and Driver Details
              </h6>
              <p className="font-normal text-text-primary text-sm">
                VINs, Registration Numbers, Driver Names, Licenses.
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-4 mb-3 items-center">
            <div className="text-primary-extraLight rounded w-9 h-9 p-2 flex justify-center items-center">
              <Document />
            </div>
            <div className="flex flex-col">
              <h6 className="font-bold text-text-secondary text-base">
                Incident information
              </h6>
              <p className="font-normal text-text-primary text-sm">
                Loss date/time, road conditions, police report number.
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-4 mb-3 items-center">
            <div className="text-primary-extraLight rounded w-9 h-9 p-2 flex justify-center items-center">
              <Paperclip />
            </div>
            <div className="flex flex-col">
              <h6 className="font-bold text-text-secondary text-base">
                Supporting Reports, Images and Videos
              </h6>
              <p className="font-normal text-text-primary text-sm">
                Reports, images and videos related to the incident.
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};
