import * as React from 'react';
import AccordionContext from './accordion-context';

type AccordionDetailsProps = {
  children: React.ReactNode;
};

export default function AccordionDetails({ children }: AccordionDetailsProps) {
  const { isOpen } = React.useContext(AccordionContext);

  return isOpen ? <div className="p-6 bg-white">{children}</div> : null;
}
