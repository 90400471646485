import { useParams } from 'react-router-dom';
import { useSafetyReportQuery } from 'src/types/graphql-types';

type Params = {
  withReportId?: boolean;
};
export const useGetDOTFromParams = ({ withReportId = false }: Params = {}) => {
  const { reportId: dotNumber = '' } = useParams<{ reportId: string }>();
  const { data: safetyReportData, loading } = useSafetyReportQuery({
    variables: { reportId: dotNumber },
    skip: !withReportId,
  });

  const reportId = safetyReportData?.fleetSafetyReport?.id || '';

  return {
    dotNumber,
    reportId,
    loading,
  };
};
