import React from 'react';

/** Custom tick component for XAxis */
export default function CustomizedAxisTick(props: any) {
  const { x, y, payload, customLabels } = props;
  if (!payload.value) {
    return null;
  }

  let text = payload.value;

  if (customLabels) {
    Object.keys(customLabels).forEach((key) => {
      text = text.replace(key, customLabels[key]);
    });
  }

  const words = text.split(' ');
  const lineHeight = 14;

  return (
    <g transform={`translate(${x}, ${y})`}>
      {words.map((word: string, index: number) => (
        <text
          key={index}
          x={0}
          y={8 + index * lineHeight}
          fontSize={12}
          fill="#363D4D"
          textAnchor="middle"
        >
          {word}
        </text>
      ))}
    </g>
  );
}
