import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import { DriversQuery } from 'src/types/graphql-types';
import {
  computeInspectionViolationsWeight,
  getLegacyInspectionsById,
} from './mapInspectionsToDrivers';

export type AggregatedUnassignedInspections = {
  VIN: string;
  name: string;
  inspectionId: string;
  violations: Number;
  totalSeverityWeight: Number;
  county: string;
  date: string;
};

type MappedVehiclesStatsListVinToName = Record<string, string>;

const mapVehiclesStatsListVinToName: (
  report: DriversQuery['fleetSafetyReport'],
) => MappedVehiclesStatsListVinToName = (report) => {
  if (!report?.VehiclesStatsList?.vehicles) return {};

  const vehiclesStatsList = report.VehiclesStatsList.vehicles.reduce(
    (acc, vehicle) => {
      if (!vehicle?.vIN) return acc;
      acc[vehicle.vIN] = vehicle.vehicleTSPName ?? vehicle.vehicleName ?? '';
      return acc;
    },
    {} as MappedVehiclesStatsListVinToName,
  );

  return vehiclesStatsList;
};

const mapVinToMileage = (report: DriversQuery['fleetSafetyReport']) => {
  if (!report?.telematicsVehicles) {
    return {};
  }
  const vinToMileage = report.telematicsVehicles.reduce((acc, vinMileage) => {
    if (!vinMileage.vin || !vinMileage.mileage) {
      return acc;
    }
    acc[vinMileage.vin] = Math.round(vinMileage.mileage.distanceMiles);
    return acc;
  }, {} as Record<string, number>);
  return vinToMileage;
};

export const mapInspectionsToUnassigned: (
  report: DriversQuery['fleetSafetyReport'],
) => AggregatedUnassignedInspections[] = (report) => {
  if (!report?.datagovInspections) {
    return [];
  }

  const namesForVins = mapVehiclesStatsListVinToName(report);

  const legacyInspectionsIds = report.inspections.map(
    (inspection) => inspection.inspectionID,
  );

  const legacyInspectionsById = getLegacyInspectionsById(report);

  const aggregatedInspections = report.datagovInspections
    .filter((inspection) => {
      if (!legacyInspectionsIds.includes(inspection.id)) {
        return false;
      }
      return !inspection.telematicsAssignments[0]?.driver;
    })
    .map((inspection) => {
      const { violations, telematicsAssignments } = inspection;
      const violationCount = violations.length;
      const severityWeight = computeInspectionViolationsWeight(
        legacyInspectionsById,
        inspection,
      );

      const VIN =
        telematicsAssignments[0]?.vehicle?.vin ?? inspection.vehicles[0].vin;

      const name =
        (telematicsAssignments[0]?.vehicle
          ? `${telematicsAssignments[0]?.vehicle?.make ?? ''} ${
              telematicsAssignments[0]?.vehicle?.model ?? ''
            } ${telematicsAssignments[0]?.vehicle?.year ?? ''}`
          : namesForVins[VIN as string] ?? inspection.vehicles[0].make) ?? '';

      return {
        VIN: VIN || 'N/A',
        name,
        inspectionId: inspection.id.toString(),
        violations: violationCount,
        totalSeverityWeight: severityWeight,
        county: inspection.countyName,
        date: inspection.inspectionDate,
      };
    });

  return aggregatedInspections;
};

export type AggregatedUnassignedSafetyScore = {
  vin: string;
  score: number;
  name: string;
  milesDriven?: number;
};

export const mapRiskVinToUnassignedSafetyScores: (
  report: DriversQuery['fleetSafetyReport'],
) => AggregatedUnassignedSafetyScore[] = (report) => {
  if (!report?.telematicsRiskVinPercentiles) {
    return [];
  }

  const datesOfScores = report.telematicsRiskVinPercentiles.map(
    (RiskVin) => RiskVin.scores[0].timestamp,
  );

  const countOfScoresPerDate = datesOfScores.reduce((acc, date) => {
    acc[date] = (acc[date] ?? 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const sortedCountOfScoresPerDate = Object.entries(countOfScoresPerDate).sort(
    (a, b) => Number(a[1] > b[1]),
  );
  const mostPopularDate = sortedCountOfScoresPerDate[0]?.[0] || '';

  const namesForVins = mapVehiclesStatsListVinToName(report);
  const milesDrivenPerVin = mapVinToMileage(report);

  const aggregatedRiskVinPerVIN = report.telematicsRiskVinPercentiles
    .filter((RiskVin) => {
      const hasScores = RiskVin.scores.length > 0;
      if (!hasScores) {
        return hasScores;
      }
      // Check if they are current (we assume that the most popular date is the current one)
      if (RiskVin.scores[0].timestamp !== mostPopularDate) {
        return false;
      }

      const isDateOlderThanTwoMonths =
        differenceInDays(new Date(), parseISO(RiskVin.scores[0].timestamp)) >
        60;

      if (isDateOlderThanTwoMonths) {
        return false;
      }

      const hasDriver = RiskVin.scores[0]?.telematicsAssignments?.[0]?.driver;
      if (hasDriver) {
        return !hasDriver;
      }
      return true;
    })
    .reduce((acc, RiskVin) => {
      const { vin, scores } = RiskVin;

      acc.push({
        vin,
        score: scores[0].score,
        name: namesForVins[vin] ?? '',
        milesDriven: milesDrivenPerVin[vin] ?? 0,
      });
      return acc;
    }, [] as Array<AggregatedUnassignedSafetyScore>);

  return aggregatedRiskVinPerVIN;
};
