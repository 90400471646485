import { useContext, useRef, useState, ReactNode } from 'react';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import { AppBar, Button, CircularProgress, Container } from '@material-ui/core';
import { HorizontalStepper, VerticalStepper, Show } from '@nirvana/ui-kit';

import { Attachment, NewClaimContext } from 'src/contexts/newClaim';
import { analytics } from 'src/helpers';
import useSnackbar from 'src/hooks/useSnackbar';
import { useCreateFnolMutation } from 'src/types/graphql-types';
import {
  lineOfBusiness,
  noticeTypes,
  Step,
  STEPS,
} from './new-claim-steps/constants';
import {
  IncidentDetails,
  ReporterInformation,
  ReviewAndSubmit,
  Overview,
} from './new-claim-steps';
import SubmittedSuccessfully from './SubmittedSuccessfully';

export const NewClaimForm = () => {
  const { handleSubmit, watch } = useContext(NewClaimContext);
  const componentRef = useRef<null | HTMLDivElement>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(-1);
  const [submittedSuccessfully, setSubmittedSuccessfully] =
    useState<boolean>(false);
  const currentStep = STEPS[currentStepIndex];
  const pushSnackbar = useSnackbar();

  const [createFNOL, { loading }] = useCreateFnolMutation({
    variables: {
      policyNumber: watch('policyNumber'),
      description: watch('description'),
      noticeType: noticeTypes[watch('noticeType')],
      lossLocation: watch('lossLocation'),
      lossState: watch('lossState'),
      lossDate: watch('lossDate').toISOString(),
      injuriesInvolved: watch('injureds') === 'yes',
      police: {
        agencyName: watch('police.agencyName'),
        reportNumber: watch('police.reportNumber'),
      },
      reporter: {
        firstName: watch('reporter.firstName'),
        lastName: watch('reporter.lastName'),
        phone: watch('reporter.phone'),
        email: watch('reporter.email'),
      },
      insuredVehicles: watch('insuredVehicleVins').map((vin: string) => ({
        vin: vin,
      })),
      otherVehicles: watch('otherVehicleVins').map((plate: string) => ({
        registrationNumber: plate,
      })),
      insuredName: watch('insuredName'),
      lineOfBusiness: lineOfBusiness[watch('lineOfBusiness')],
      attachmentKeys: watch('attachments').map(
        (att: Attachment) => att.key || '',
      ),
    },
    onCompleted: () => {
      setSubmittedSuccessfully(true);
    },
    onError: () => {
      pushSnackbar('Error submitting claim', 'Something went wrong', {
        variant: 'error',
      });
    },
  });

  const scrollToTop = () => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const screens: Record<Step, ReactNode> = {
    'incident-details': <IncidentDetails />,
    'reporter-information': <ReporterInformation />,
    'review-and-submit': (
      <ReviewAndSubmit
        onEdit={(step) => {
          const index = STEPS.findIndex((s) => s.key === step);
          setCurrentStepIndex(index);
          scrollToTop();
        }}
      />
    ),
  };

  return (
    <>
      <Show when={!submittedSuccessfully} fallback={<SubmittedSuccessfully />}>
        <div className="flex flex-col md:flex-row mt-8" ref={componentRef}>
          <div>
            <Show when={currentStepIndex >= 0}>
              <div className="flex-1 p-4 mb-4 overflow-auto bg-primary-extraLight rounded-lg border-none shadow-none pt-4 md:pl-8 md:pr-20 md:py-10">
                <div className="lg:hidden">
                  <HorizontalStepper
                    dataTestId="horizontal-stepper"
                    steps={STEPS}
                    activeStep={currentStepIndex}
                    onChange={(selectedStepIndex) => {
                      setCurrentStepIndex(selectedStepIndex);
                    }}
                  />
                </div>
                <div className="hidden lg:block">
                  <VerticalStepper
                    dataTestId="vertical-stepper"
                    steps={STEPS}
                    activeStep={currentStepIndex}
                    onChange={(selectedStepIndex) => {
                      setCurrentStepIndex(selectedStepIndex);
                    }}
                  />
                </div>
              </div>
            </Show>
          </div>

          <div className="p-8 pt-0 md:p-10 md:pt-0 w-full">
            <Show when={currentStepIndex < 0}>
              <Overview />
            </Show>

            <Show when={currentStepIndex >= 0}>
              <div className="w-full">
                <div className="flex flex-col">{screens[currentStep?.key]}</div>
              </div>
            </Show>
          </div>
        </div>

        <AppBar
          color="inherit"
          elevation={1}
          position="fixed"
          sx={{ top: 'auto', bottom: 0, py: 1 }}
        >
          <Container className="flex justify-end">
            <Show when={currentStepIndex > 0}>
              <Button
                className="mr-4"
                data-testid="previous-button"
                type="button"
                variant="outlined"
                onClick={() => {
                  setCurrentStepIndex(Math.max(currentStepIndex - 1, 0));
                  scrollToTop();
                }}
                startIcon={<HiArrowLeft />}
              >
                Previous
              </Button>
            </Show>

            <Show when={currentStepIndex !== STEPS.length - 1}>
              <Button
                className="mr-16 lg:mr-8"
                data-testid="next-button"
                type="button"
                variant="contained"
                onClick={() => {
                  setCurrentStepIndex(
                    Math.min(currentStepIndex + 1, STEPS.length - 1),
                  );
                  scrollToTop();
                }}
                endIcon={<HiArrowRight />}
              >
                {currentStepIndex < 0 ? 'Start' : 'Proceed'}
              </Button>
            </Show>

            <Show when={currentStepIndex === STEPS.length - 1}>
              <Button
                className="mr-16 lg:mr-8"
                data-testid="submit-button"
                disabled={loading}
                type="button"
                variant="contained"
                onClick={handleSubmit(
                  () => {
                    analytics.trackEvent({
                      event: analytics.SegmentEventTrack.FNOLFormSubmissions,
                    });
                    createFNOL();
                  },
                  () => {
                    pushSnackbar(
                      'Cannot submit form',
                      "You're missing some fields",
                      {
                        variant: 'error',
                      },
                    );
                  },
                )}
              >
                Submit
                <Show when={loading}>
                  <CircularProgress className="absolute" size={24} />
                </Show>
              </Button>
            </Show>
          </Container>
        </AppBar>
      </Show>
    </>
  );
};
