import { IChipProps } from '@nirvana/ui-kit';

export type RiskScoreBucket = {
  name: string;
  graphFill: string;
  chipClass: IChipProps['color'];
  order: number;
  contains: Function;
  tierName: string;
  bgClass: string;
};

export type RiskScoreChartRecord = {
  name: string;
  fill: string;
  count: number;
  percentage: number;
  order: number;
};

export enum BucketName {
  Inconclusive = 'No Score Available',
  Excellent = 'Excellent',
  Good = 'Good',
  Fair = 'Fair',
  Poor = 'Poor',
}

export const VehicleRiskScoreBuckets: Array<RiskScoreBucket> = [
  {
    name: 'Inconclusive',
    tierName: BucketName.Inconclusive,
    bgClass: 'bg-trs-inconclusive',
    graphFill: '#D7D8DB',
    chipClass: 'default',
    order: 0,
    contains: function (value: number | undefined | null): boolean {
      return value === undefined || value === null || Number.isNaN(value);
    },
  },
  {
    name: '0 - 25',
    tierName: BucketName.Excellent,
    bgClass: 'bg-trs-excellent',
    graphFill: '#25B24A',
    chipClass: 'success',
    order: 1,
    contains: function (value: number): boolean {
      return value >= 0 && value <= 25;
    },
  },
  {
    name: '25 - 50',
    tierName: BucketName.Good,
    bgClass: 'bg-trs-good',
    graphFill: '#82D01E',
    chipClass: 'gold',
    order: 2,
    contains: function (value: number): boolean {
      return value > 25 && value <= 50;
    },
  },
  {
    name: '50 - 75',
    tierName: BucketName.Fair,
    bgClass: 'bg-trs-fair',
    graphFill: '#FF9900',
    chipClass: 'warning',
    order: 3,
    contains: function (value: number): boolean {
      return value > 50 && value <= 75;
    },
  },
  {
    name: '75 - 100',
    tierName: BucketName.Poor,
    bgClass: 'bg-trs-poor',
    graphFill: '#FA3252',
    chipClass: 'error',
    order: 4,
    contains: function (value: number): boolean {
      return value > 75 && value <= 100;
    },
  },
];

export function getSafetyScoreChipProps(value: number): IChipProps {
  return getBucketChipProps(VehicleRiskScoreBuckets, value);
}

export function getBucketChipProps(
  buckets: Array<RiskScoreBucket>,
  value?: number | null,
): IChipProps {
  let chipClass: IChipProps['color'] = 'warning';
  buckets.some((bucket): boolean => {
    if (bucket.contains(value)) {
      chipClass = bucket.chipClass;
      return true;
    }
    return false;
  });
  return { color: chipClass, label: value };
}

function getPercentage(value: number, data: Array<{ riskScore?: any }>) {
  return Math.round((value * 100) / data.length);
}

export function getRiskScoreChartRecords(
  data: Array<{ riskScore?: any }>,
  buckets: Array<RiskScoreBucket>,
): Array<RiskScoreChartRecord> {
  const riskScores: Array<RiskScoreChartRecord> = buckets.map((record) => ({
    name: record.name,
    fill: record.graphFill,
    order: record.order,
    count: 0,
    percentage: 0,
  }));
  data?.forEach(({ riskScore }) => {
    buckets.some((bucket, index): boolean => {
      if (bucket.contains(riskScore)) {
        riskScores[index].count += 1;
        return true;
      }
      return false;
    });
  });

  return riskScores
    .map((record) => ({
      ...record,
      percentage: getPercentage(record.count, data),
    }))
    .sort((a, b) => {
      return a.order - b.order;
    });
}
