import { useState } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { GrDashboard } from 'react-icons/gr';
import { useSnackbar } from 'notistack';
import { Close as CloseIcon } from '@material-ui/icons';

import nirvanaSafety from 'src/assets/images/nirvana-safety.png';
import { tspProviders } from 'src/constants';
import {
  TelematicsRiskScoreDocument,
  Tsp,
  useInitiateTelematicsMutation,
} from 'src/types/graphql-types';
import { analytics } from 'src/helpers';
import { useGetDOTFromParams } from 'src/hooks/useGetDOTFromParams';
import { instructions } from './instructions';

type ConnectTspModalProps = {
  open: boolean;
  onClose: () => void;
};

export default function ConnectTspModal({
  open,
  onClose,
}: ConnectTspModalProps) {
  const { reportId } = useGetDOTFromParams({
    withReportId: true,
  });

  const { enqueueSnackbar } = useSnackbar();
  const [selectedTSP, setSelectedTSP] = useState(tspProviders[0].key);

  const [initiateTelematics, { loading: isLoading }] =
    useInitiateTelematicsMutation({
      onCompleted: ({ initiateTelematics }) => {
        const redirectLocation = initiateTelematics?.location;
        if (redirectLocation) {
          window.location.href = redirectLocation;
        }
        if (initiateTelematics?.tsp === Tsp.TspOther) {
          onClose();
        }
      },
      // TODO: we should create a helper function that returns the appropriate
      // Network error / client safe error / internal server error message.
      onError: () => {
        enqueueSnackbar(
          'Unexpected error while initiating the Telematics Connection. Please try again later.',
          { variant: 'error' },
        );
      },
      refetchQueries: [
        { query: TelematicsRiskScoreDocument, variables: { reportId } },
      ],
    });

  function handleInitiateTelematics() {
    if (selectedTSP === Tsp.TspOther) {
      analytics.trackEvent({
        event: analytics.SegmentEventTrack.OtherTelematicsConnect,
      });
      enqueueSnackbar(
        'Please contact support if you wish to connect with a non-Samsara/Motive TSP.',
        { variant: 'warning' },
      );
      return;
    }

    analytics.trackEvent({
      event: analytics.SegmentEventTrack.TelematicsConnect,
    });

    initiateTelematics({
      variables: { safetyReportId: reportId, tsp: selectedTSP },
    });
  }

  const Loader = <CircularProgress size={18} className="text-white" />;

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      onClose={onClose}
      classes={{ paper: 'border-none' }}
    >
      <div className="relative grid w-full max-w-4xl grid-cols-2 overflow-hidden">
        <IconButton
          size="small"
          className="absolute text-gray-500 top-4 right-4"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <div className="h-full px-6 py-12 text-white shrink-0 bg-primary-main">
          <p className="mb-2 text-base font-bold">
            Connect your ELD provider to unlock
          </p>
          <div className="w-20 h-1 mb-10 bg-gold-main" />
          <ul className="mb-20 space-y-6 text-xs">
            {instructions.map(({ icon, content }, index) => (
              <li key={index} className="flex items-start space-x-3">
                {icon}
                <p className="font-normal">{content}</p>
              </li>
            ))}
          </ul>
          <img src={nirvanaSafety} alt="Nirvana Safety Logo" className="h-10" />
        </div>

        <div className="flex flex-col px-6 py-12">
          <p className="mb-6 text-base font-bold">
            Select your ELD/Telematics provider
          </p>

          <div className="grid grid-cols-2 gap-4">
            {tspProviders.map(({ logo, key, alt }) => (
              <button
                key={key}
                onClick={() => setSelectedTSP(key)}
                className={clsx(
                  'flex items-center justify-center h-20 px-4 border rounded-lg shadow',
                  { 'ring-2 ring-primary-main': selectedTSP === key },
                )}
              >
                <img src={logo} alt={alt} />
              </button>
            ))}
            <button
              onClick={() => setSelectedTSP(Tsp.TspOther)}
              className={clsx(
                'flex items-center justify-center col-span-2 h-20 mb-20 px-4 border rounded-lg shadow space-x-4',
                { 'ring-2 ring-primary-main': selectedTSP === Tsp.TspOther },
              )}
            >
              <GrDashboard className="text-lg" />
              <span>Other Telematics Provider</span>
            </button>
          </div>

          <Button
            fullWidth
            className="mb-2"
            variant="contained"
            disabled={!selectedTSP}
            onClick={handleInitiateTelematics}
            startIcon={isLoading ? Loader : null}
          >
            Connect telematics
          </Button>

          <div className="space-y-2 text-xs">
            <p>
              We use industry-standard security practices to safeguard data.
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
