import * as React from 'react';
import clsx from 'clsx';
import { Chip as MuiChip, ChipProps } from '@material-ui/core';

import { useStyles } from './styles';

export type IChipColors =
  | 'primary'
  | 'secondary'
  | 'default'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | 'tint'
  | 'gold'
  | 'gold-dark'
  | 'grey';
export interface IChipProps extends Omit<ChipProps, 'color'> {
  color?: IChipColors;
  uppercase?: boolean;
  className?: string;
  layout?: 'default' | 'rounded';
}

/**
 * Chip/Badge UI component, which is an extension of Mui Chip
 * Refer: https://next.material-ui.com/components/chips for usage and props
 * @component
 *
 * @param {Object} props - Material UI chip props.
 * @param {string} [props.color] - Color options for the chip.
 *
 * @example
 * return <Chip color="success" label="Success" />
 */
const Chip = ({
  color = 'default',
  uppercase = false,
  className,
  layout = 'default',
  ...rest
}: IChipProps) => {
  const classes = useStyles();

  return (
    <MuiChip
      {...rest}
      color={color === 'primary' || color === 'secondary' ? color : 'default'}
      className={clsx(classes.root, className, {
        [classes.success]: color === 'success',
        [classes.error]: color === 'error',
        [classes.info]: color === 'info',
        [classes.warning]: color === 'warning',
        [classes.tint]: color === 'tint',
        [classes.gold]: color === 'gold',
        [classes['gold-dark']]: color === 'gold-dark',
        [classes.grey]: color === 'grey',
        [classes.uppercase]: uppercase,
        [classes.rounded]: layout === 'rounded',
      })}
      classes={{ label: classes.label }}
    />
  );
};

export default Chip;
