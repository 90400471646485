import { Button, FormHelperText } from '@material-ui/core';
import { InputPassword } from '@nirvana/ui-kit';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaSpinner } from 'react-icons/fa';
import { useCompleteDriverViolationFetchMutation } from 'src/types/graphql-types';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { analytics } from 'src/helpers';
import { useGetDOTFromParams } from 'src/hooks/useGetDOTFromParams';

type TwoFAProps = {
  browserWSEndpoint: string;
  twoFactorUrl: string;
  onNextClick: () => void;
};

const TwoFaSchema = z.object({
  twoFA: z.string().min(6).max(6),
});

type TwoFaType = z.infer<typeof TwoFaSchema>;

export const TwoFA = ({
  browserWSEndpoint,
  twoFactorUrl,
  onNextClick,
}: TwoFAProps) => {
  const { reportId } = useGetDOTFromParams({
    withReportId: true,
  });

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string>();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<TwoFaType>({
    mode: 'onChange',
    resolver: zodResolver(TwoFaSchema),
  });

  const [complete2FAQuery] = useCompleteDriverViolationFetchMutation({
    onCompleted: () => {
      setIsProcessing(false);
      onNextClick();
      analytics.trackEvent({
        event: analytics.SegmentEventTrack.CompleteFMCSALogin,
      });
    },
    onError: (error) => {
      setIsProcessing(false);
      if (error.graphQLErrors) {
        setError(error.graphQLErrors[0] as unknown as string);
      }
    },
  });

  const onSubmit = handleSubmit((data) => {
    setIsProcessing(true);
    setError(undefined);
    complete2FAQuery({
      variables: {
        reportID: reportId,
        browserWSEndpoint,
        twoFA: String(data.twoFA),
        twoFactorUrl,
      },
    });
  });
  return (
    <div>
      <form onSubmit={onSubmit}>
        <p>Write your 6 digit code from your authenticator app or phone.</p>
        <InputPassword
          {...register('twoFA')}
          formControlProps={{ fullWidth: true, className: 'my-4' }}
          placeholder="Two-Factor Authentication"
          fullWidth
          error={!!errors.twoFA}
          label="Two-Factor Authentication"
        />

        <FormHelperText className="mb-4" error>
          {errors.twoFA?.message}
        </FormHelperText>

        <FormHelperText error className="my-4">
          {error}
        </FormHelperText>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={!isValid || isProcessing}
        >
          {isProcessing ? (
            <>
              <FaSpinner className="mr-2 animate-spin" /> Signing in...
            </>
          ) : (
            'Complete Sign In'
          )}
        </Button>
      </form>
    </div>
  );
};
