import { useEffect, useMemo, useState } from 'react';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Chip, IChipProps, LineChart, Tooltip } from '@nirvana/ui-kit';

import {
  analytics,
  Dates,
  CustomTooltip,
  renderActiveDots,
  renderDots,
  computeScoreTextColor,
} from 'src/helpers';
import SelectPeriod from 'src/components/select-period';
import { useFleetRatingQuery } from 'src/types/graphql-types';

import clsx from 'clsx';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { getISSLineChartProps } from '../constants/line-chart-props';
import { issReferenceLines } from '../constants/iss-reference-lines';
import { ChartHeader } from './ChartHeader';

const getScoreChipProps = (score: number): IChipProps => {
  if (score <= 50) {
    return { label: 'PASS', color: 'success' };
  } else if (score > 50 && score < 75) {
    return { label: 'OPTIONAL', color: 'warning' };
  }
  return { label: 'INSPECT', color: 'error' };
};

export default function ISSScore() {
  const { reportId = '' } = useParams();
  const [period, setPeriod] = useState(12);

  useEffect(() => {
    analytics.trackEvent({
      event: analytics.SegmentEventTrack.DateRangeFilter,
      properties: {
        module: 'scores',
        chart: 'ISS Score',
        period: `Last ${period} months`,
      },
    });
  }, [period]);

  const fromTimestamp = useMemo(() => {
    // We fetch data for an extra month because
    // last month's data is not available sometimes
    const startMonth = startOfMonth(subMonths(new Date(), period + 1));
    return Dates.formatISOUTC(startMonth);
  }, [period]);

  const toTimestamp = useMemo(() => {
    const lastMonth = endOfMonth(subMonths(new Date(), 1));
    return Dates.formatISOUTC(lastMonth);
  }, []);

  const { data } = useFleetRatingQuery({
    variables: { reportId, fromTimestamp, toTimestamp },
  });

  // Final Data for ISS Score chart
  const issScore = useMemo(() => {
    if (data) {
      return (
        data.fleetSafetyReport?.issScores.slice(-period).map((score) => ({
          value: score.value,
          name: format(new Date(score.year, score.month - 1), 'MMM, yyyy'),
        })) ?? []
      );
    }
    return [];
  }, [data, period]);

  const currentScore = issScore?.[issScore.length - 1]?.value;

  return (
    <section id="iss-score" className="bg-white border rounded-lg ">
      <ChartHeader
        title={
          <>
            <p className="mr-2 font-bold">ISS Score</p>
            <Tooltip
              placement="right"
              title={
                <div className="max-w-md">
                  ISS stands for Inspection Selection System and was developed
                  by the FMCSA to help roadside commercial vehicle enforcement
                  officers make quick decisions on whether or not a truck should
                  be inspected.
                </div>
              }
            >
              <button className="text-primary-main">
                <HiOutlineInformationCircle />
              </button>
            </Tooltip>
          </>
        }
        score={
          <Tooltip
            title={
              <div className="max-w-md">
                There are three different ISS ratings categories:
                <ul className="ml-4 list-disc">
                  <li>Inspect: 75 - 100</li>
                  <li>Optional: 50 - 74</li>
                  <li>Pass: 1 - 49</li>
                </ul>
              </div>
            }
          >
            <div>
              <p className="text-xs font-normal text-text-primary lg:inline-block">
                Current Score:
              </p>
              <span
                className={clsx(
                  'mx-2 text-base font-bold',
                  computeScoreTextColor(currentScore || 55),
                )}
              >
                {currentScore || 'Inconclusive'}
              </span>{' '}
              {!!currentScore && <Chip {...getScoreChipProps(currentScore)} />}
            </div>
          </Tooltip>
        }
        periodPicker={<SelectPeriod value={period} onChange={setPeriod} />}
      />
      <div className="p-6 h-80">
        <LineChart
          data={issScore}
          referenceLines={issReferenceLines}
          tooltipProps={{ content: <CustomTooltip /> }}
          lineConfig={[{ dot: renderDots, activeDot: renderActiveDots }]}
          {...getISSLineChartProps({ period })}
        />
      </div>
    </section>
  );
}
