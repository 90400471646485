import * as React from 'react';
import {
  ResponsiveContainer,
  PieChart as RePieChart,
  Pie,
  Cell,
  Tooltip,
} from 'recharts';

/** PieChart properties */
type PieChartProps = {
  /** Provide data to render PieChart, usually in the form of:
   * const data = [{ name: "Group A", value: 400, fill: "#fff" },{ name: "Group B", value: 300, fill: "#ff0000" }]
   */
  data: Array<any>;
  /** Name of the key in data array which is supposed to provide value to pie-chart */
  dataKey: string;
  /** The start angle of first sector. */
  startAngle?: number;
  /** The end angle of last sector, which should be unequal to startAngle. */
  endAngle?: number;
  /** ResponsiveContainer width in percentage */
  width?: string;
  /** ResponsiveContainer height in percentage */
  height?: string;
  /** Boolean value to hide tooltip in the chart */
  hideTooltip?: boolean;
  /** The inner radius of all the sectors. If set a percentage, the final value is obtained by multiplying the percentage of maxRadius which is calculated by the width, height, cx, cy. */
  innerRadius?: string | number;
  /** The outer radius of all the sectors. If set a percentage, the final value is obtained by multiplying the percentage of maxRadius which is calculated by the width, height, cx, cy. */
  outerRadius?: string | number;
  /** To use Rounded edges for Pie Cells in the chart */
  cornerRadius?: string | number;
};

/** Custom PieChart component built using PieChart of recharts
 * Main purpose of this component is to customize using props rather than React composition
 * Refer https://recharts.org/en-US/api/PieChart for more details
 */
export default function PieChart({
  data,
  dataKey,
  startAngle = 180,
  endAngle = -180,
  width = '100%',
  height = '100%',
  hideTooltip,
  innerRadius = 0,
  outerRadius = '80%', // Using default value of library
  cornerRadius,
}: PieChartProps) {
  return (
    <ResponsiveContainer width={width} height={height}>
      <RePieChart>
        <Pie
          data={data}
          dataKey={dataKey}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          cornerRadius={cornerRadius}
        >
          {data.map((value, index) => (
            <Cell key={index} fill={value?.fill} />
          ))}
        </Pie>
        {!hideTooltip ? <Tooltip /> : null}
      </RePieChart>
    </ResponsiveContainer>
  );
}
