import { Link, IconButton } from '@material-ui/core';
import { Dialog } from '@nirvana/ui-kit';
import {
  HiCheck,
  HiChevronRight,
  HiOutlineShieldCheck,
  HiX,
} from 'react-icons/hi';

const content = [
  {
    title: 'Track progress',
    description:
      'See if your fleet is getting safer or needs coaching with monthly scoring updates and trend charts.',
  },
  {
    title: 'Get personalized recommendations',
    description:
      'Get valuable recommendations for improving driving behavior and road safety based on performance.',
  },
];

type NssInfoDialogProps = {
  open: boolean;
  onClose: () => void;
};

export default function NssInfoDialog({ open, onClose }: NssInfoDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="relative max-w-md p-2">
        <IconButton
          size="small"
          onClick={onClose}
          data-testid="close-nss-info-dialog"
          className="absolute right-0 -top-0"
        >
          <HiX />
        </IconButton>
        <div className="flex items-center mb-4 space-x-4">
          <div className="p-1 text-xl rounded text-primary-main bg-primary-extraLight">
            <HiOutlineShieldCheck />
          </div>

          <span className="text-xl font-semibold text-gray-800">
            Nirvana Safety Score
          </span>
        </div>
        <p className="mb-6" data-testid="nss-info-dialog-description">
          Based on your fleet&apos;s telematics data, your score indicates how
          likely your fleet is to experience an accident during your policy
          term. Monitor your score regularly to:
        </p>

        <ul className="pl-4 mb-6 space-y-4">
          {content.map(({ title, description }) => (
            <li key={title} className="flex items-start">
              <div className="flex items-center justify-center p-2 mr-4 text-lg rounded-full bg-success-extraLight text-success-main">
                <HiCheck />
              </div>
              <div>
                <p className="mb-1 font-semibold text-primary-main">{title}</p>
                <p>{description}</p>
              </div>
            </li>
          ))}
        </ul>

        <Link
          href="https://intercom.help/nirvana-insurance/en/articles/8864436-safety-scores"
          target="_blank"
          underline="none"
        >
          <button className="flex items-center mb-6 font-medium text-primary-main">
            <span>Learn More</span>
            <HiChevronRight className="ml-0.5" />
          </button>
        </Link>
      </div>
    </Dialog>
  );
}
