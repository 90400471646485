import { useMemo } from 'react';
import { Show } from '@nirvana/ui-kit';
import MapChart from 'src/components/map-chart';
import useMapRef from 'src/hooks/use-map-ref';
import useMapHover from 'src/hooks/use-map-hover';
import MapTooltip from './map-tooltip';
import { getGeojsonData } from '../constants/geojson-properties';

type RecommendationMapProps = {
  chart: any;
};

export default function RecommendationMap({ chart }: RecommendationMapProps) {
  const { tooltipInfo, handleHover, onMouseLeave } = useMapHover();

  const geojson = useMemo(() => {
    if (chart?.geoJSON) {
      return getGeojsonData(chart.geoJSON);
    }
  }, [chart]);

  const countyWithMaxViolation = useMemo(() => {
    if (geojson) {
      geojson.features.sort(
        (a, b) => b.properties?.violation_count - a.properties?.violation_count,
      );
      return {
        ...geojson,
        features: [geojson.features[0]],
      };
    }
  }, [geojson]);

  const mapRef = useMapRef(countyWithMaxViolation);

  return geojson ? (
    <div className="p-4 border rounded">
      <p className="mb-4 font-semibold text-secondary-main">{chart.title}</p>
      <MapChart
        ref={mapRef}
        geojson={geojson}
        onHover={handleHover}
        onMouseLeave={onMouseLeave}
      >
        <Show when={tooltipInfo}>
          {({ x, y, feature }) => (
            <MapTooltip x={x} y={y} properties={feature.properties} />
          )}
        </Show>
      </MapChart>
      <div className="flex items-center justify-center space-x-4 text-xs text-secondary-main">
        <p>Low Frequency</p>
        <div className="w-full h-2 max-w-sm rounded-xl bg-gradient-to-r from-[#66D398] via-[#FFF27D] to-[#FA3252]" />
        <p>High Frequency</p>
      </div>
    </div>
  ) : null;
}
