import * as React from 'react';
import NumberFormat from 'react-number-format';
import { OutlinedInput, OutlinedInputProps } from '@material-ui/core';

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value: number } }) => void;
  name: string;
  prefix?: string;
  suffix?: string;
  decimalScale?: number;
  allowNegative?: boolean;
  thousandSeparator?: boolean;
}

const NumberFormatCustom = React.forwardRef<
  NumberFormat,
  NumberFormatCustomProps
>(function NumberFormatCustom(props, ref) {
  const {
    onChange,
    prefix = '',
    suffix = '',
    allowNegative = false,
    decimalScale,
    thousandSeparator = true,
    ...rest
  } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: parseFloat(values.value),
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      prefix={prefix ? `${prefix} ` : ''}
      suffix={suffix ? ` ${suffix}` : ''}
    />
  );
});
export interface IInputNumericProps extends OutlinedInputProps {
  prefix?: string;
  suffix?: string;
  decimalScale?: number;
  allowNegative?: boolean;
  thousandSeparator?: boolean;
}

/**
 * Numeric input UI component, which is an extension of MUI's Outlined Input,
 * and uses [react-number-format](https://github.com/s-yadav/react-number-format)
 * to format the input value.
 * Refer: https://next.material-ui.com/api/outlined-input for usage and props
 * Note: This component makes sure that we always render an outlined text input.
 * @component
 *
 * @param {Object} props - Material UI Outlined Input props.
 *
 * @example
 * return <InputNumeric placeholder="Enter a numeric value" prefix="$" />
 */
const InputNumeric = React.forwardRef(
  (
    {
      prefix,
      suffix,
      decimalScale,
      allowNegative,
      thousandSeparator,
      inputProps,
      ...rest
    }: IInputNumericProps,
    ref: any,
  ) => {
    return (
      <>
        <OutlinedInput
          {...rest}
          inputComponent={NumberFormatCustom as any}
          inputProps={{
            prefix,
            suffix,
            allowNegative,
            decimalScale,
            thousandSeparator,
            ...inputProps,
          }}
          ref={ref}
        />
      </>
    );
  },
);

export default InputNumeric;
