import { useState } from 'react';
import type { MapboxGeoJSONFeature, MapLayerMouseEvent } from 'react-map-gl';

type ITooltipInfo = {
  x: number;
  y: number;
  feature: MapboxGeoJSONFeature;
};

export default function useMapHover() {
  const [tooltipInfo, setTooltipInfo] = useState<ITooltipInfo | null>(null);

  function handleHover(event: MapLayerMouseEvent) {
    const {
      features,
      point: { x, y },
    } = event;
    const feature = features && features[0];
    if (feature) {
      setTooltipInfo({ feature, x, y });
    }
  }

  function onMouseLeave() {
    setTooltipInfo(null);
  }

  return { tooltipInfo, handleHover, onMouseLeave };
}
