import { useClipboard } from 'use-clipboard-copy';
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Check as CheckIcon,
  FileCopyOutlined as FileCopyIcon,
} from '@material-ui/icons';
import { Dialog, Show } from '@nirvana/ui-kit';
import { SHAREID_QUERY_PARAM } from 'src/constants';

type ShareModalProps = {
  open: boolean;
  onClose: () => void;
  shareID: string;
};

export default function ShareModal({
  open,
  onClose,
  shareID,
}: ShareModalProps) {
  const { copy, copied, target } = useClipboard({ copiedTimeout: 3000 });

  const shareLinkURL = `${window.location.href}?${SHAREID_QUERY_PARAM}=${shareID}&utm_source=share&utm_medium=link&utm_campaign=share`;

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="relative flex flex-col items-center px-4 pt-2 pb-6 text-center">
        <IconButton
          aria-label="close"
          className="absolute top-0 right-0 text-gray-500"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <div className="mt-4 mb-8 space-y-2">
          <p className="text-xl font-semibold text-text-primary">
            Share Insights
          </p>
          <p className="text-xs text-text-hint">
            Please share the link below to share this report
          </p>
        </div>
        <OutlinedInput
          fullWidth
          readOnly
          className="mb-4"
          inputRef={target}
          value={shareLinkURL}
          endAdornment={
            <InputAdornment>
              <Tooltip title={copied ? 'Copied to clipboard!' : 'Copy'}>
                <IconButton size="small" onClick={copy}>
                  <Show when={copied} fallback={<FileCopyIcon />}>
                    <CheckIcon className="text-success-main" />
                  </Show>
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
        <Button variant="contained" className="w-48" onClick={copy}>
          Copy Link
        </Button>
      </div>
    </Dialog>
  );
}
