import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTable } from 'react-table';
import { TableSkeletonLoader } from '@nirvana/ui-kit';
import { useAccidentQuery } from 'src/types/graphql-types';
import { accidentColumns } from '../constants/accident';

export default function AccidentSummary() {
  const { reportId = '' } = useParams();

  const { data: accidentData, loading } = useAccidentQuery({
    variables: { reportId },
  });

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable({
      columns: accidentColumns,
      data: accidentData?.fleetSafetyReport?.OOSSummary ?? [],
    });

  const tableContent = useMemo(() => {
    if (loading) {
      return <TableSkeletonLoader columns={accidentColumns.length} />;
    }
    if (accidentData) {
      return rows.map((row, index) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            key={index}
            className="font-normal text-secondary-main"
          >
            {row.cells.map((cell, index) => (
              <td {...cell.getCellProps()} key={index} className="px-4 py-2">
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
        );
      });
    }
    return null;
  }, [accidentData, loading, prepareRow, rows]);

  return (
    <div className="p-4 bg-white rounded-lg shadow" data-testid="oos-summary">
      <div className="flex items-center mb-4 space-x-3">
        <span className="font-semibold text-secondary-main">
          Out-of-service (OOS) summary
        </span>
      </div>

      <table className="w-full" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  key={index}
                  className="px-4 py-2 text-xs font-normal text-left bg-primary-extraLight text-secondary-dark"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>{tableContent}</tbody>
      </table>
    </div>
  );
}
