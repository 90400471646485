import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';

import { ITheme } from '../../assets/themes';

import CheckboxWithLabel from './withLabel';

const useStyles = makeStyles((theme: ITheme) => ({
  checkboxContainer: {
    border: '1px solid #E6E7EF',
    borderRadius: theme.shape.borderRadius,
    padding: 2,

    '&:hover': {
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.extraLight,
    },
  },
  selected: {
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.extraLight,
    boxShadow: '0px 4px 6px rgba(0, 36, 138, 0.08)',
  },
}));

interface ICheckBoxRowProps extends CheckboxProps {
  label: string;
  className?: any;
}

/**
 * Checkbox component with label and a border.
 * Note: All props except label and className are passed down to MuiCheckbox.
 * @component
 *
 * @param {Object} props - Extends MUI Checkbox (Refer: https://next.material-ui.com/components/checkboxes)
 * @param {string} props.label - Checkbox label to describe usage.
 * @param {string} props.className - Additional classes passed on to checkbox container.
 *
 * @example
 * return <Checkbox label="Check something here" disabled />
 */
const CheckboxRow = ({ label, className, ...rest }: ICheckBoxRowProps) => {
  const classes = useStyles();

  return (
    <CheckboxWithLabel
      {...rest}
      label={label}
      className={clsx(classes.checkboxContainer, className, {
        [classes.selected]: rest.checked,
      })}
    />
  );
};

export default CheckboxRow;
