export const getCategoryLabel = (category: string) => {
  switch (category) {
    case 'Basics':
      return 'BASICs';

    case 'DotRating':
      return 'DOT Rating';

    case 'RelatedEntities':
      return 'Related Entities';

    default:
      return category;
  }
};
