import { createColumnHelper } from '@tanstack/react-table';
import {
  Chip,
  getFormattedDateInTimezone,
  Switch,
  Tooltip,
} from '@nirvana/ui-kit';
import { getSafetyScoreChipProps } from 'src/helpers';
import { ReactComponent as Warning } from 'src/assets/icons/warning.svg';
import { Link } from 'react-router-dom';
import { AggregatedInspection } from '../helpers/mapInspectionsToDrivers';
import {
  AggregatedUnassignedInspections,
  AggregatedUnassignedSafetyScore,
} from '../helpers/mapInspectionsToUnassigned';

export const driversColumns = () => {
  const columnHelper = createColumnHelper<AggregatedInspection>();
  return [
    columnHelper.accessor('name', {
      header: 'Driver',
      size: undefined,
      cell: ({ getValue, row }) => {
        return (
          <div>
            <div className="capitalize">{getValue()}</div>
            <div className="flex">
              <p className="truncate text-text-hint">{row.original.VINs[0]}</p>
              {row.original.VINs.length > 1 ? (
                <Tooltip
                  arrow
                  title={
                    <div className="p-1">
                      {row.original.VINs.slice(1).map((vin: string) => (
                        <li key={vin}>{vin}</li>
                      ))}
                    </div>
                  }
                >
                  <span className="ml-4 -mb-4">
                    <Chip
                      color="tint"
                      label={`+${row.original.VINs.length - 1}`}
                    />
                  </span>
                </Tooltip>
              ) : null}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('score', {
      header: 'Latest Safety Score',
      size: undefined,
      enableSorting: true,
      sortUndefined: 'last',
      meta: {
        tooltipText:
          'A lower score indicates less risky driving conditions and behavior. The score is calculated using telematics data from the last 3 months.',
      },
      cell: ({ getValue }) => {
        return (
          <Switch
            fallback={<Chip {...getSafetyScoreChipProps(getValue() ?? 0)} />}
          >
            <Switch.Match when={getValue() === undefined}>
              <Tooltip
                title="Three months of driving data is needed to calculate a driver's safety score."
                arrow
              >
                <div className="w-6 ml-2">
                  <Warning />
                </div>
              </Tooltip>
            </Switch.Match>
          </Switch>
        );
      },
    }),

    columnHelper.group({
      header: () => (
        <div className="flex justify-center w-full">Inspections</div>
      ),
      id: 'inspections',
      columns: [
        columnHelper.accessor('inspections.total', {
          header: 'Total',
          size: 100,
          cell: ({ getValue }) => {
            return <div>{getValue()}</div>;
          },
        }),
        columnHelper.accessor('inspections.clean', {
          header: 'Clean',
          size: 100,
          cell: ({ getValue }) => {
            return <div>{getValue()}</div>;
          },
        }),
        columnHelper.accessor('inspections.withViolations', {
          header: 'w/Violations',
          size: 100,
          cell: ({ getValue }) => {
            return <div>{getValue()}</div>;
          },
        }),
      ],
    }),
    columnHelper.accessor('weight', {
      header: 'Violation Sum',
      enableSorting: true,
      size: undefined,
      cell: ({ getValue, row }) => {
        return (
          <Link
            to={`../violations?${new URLSearchParams({
              vINs: row.original.VINs.join(','),
            })}`}
          >
            {getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor('totalMilesDriven', {
      header: () => (
        <div>
          Miles Driven <p className="text-text-hint">(Last 3 months)</p>
        </div>
      ),
      cell: ({ getValue }) => {
        return <div>{getValue()?.toLocaleString()} miles</div>;
      },
    }),
  ];
};

export const unassignedInspectionsColumns = () => {
  const columnHelper = createColumnHelper<AggregatedUnassignedInspections>();
  return [
    columnHelper.accessor('name', {
      header: 'VIN',
      cell: ({ getValue, row }) => {
        return (
          <div>
            <div className="capitalize">{row.original.VIN}</div>
            <div className="text-text-hint">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor('inspectionId', {
      header: 'Inspection ID',
    }),
    columnHelper.accessor('violations', {
      header: 'Number of Violations',
    }),
    columnHelper.accessor('totalSeverityWeight', {
      header: 'Severity Weight',
      enableSorting: true,
      cell: ({ getValue, row }) => {
        return (
          <Link
            to={`../violations?${new URLSearchParams({
              vINs: row.original.VIN,
            })}`}
          >
            {getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor('county', {
      header: 'County',
    }),
    columnHelper.accessor('date', {
      header: 'Date',
      cell: ({ getValue }) => {
        return <> {getFormattedDateInTimezone(getValue())}</>;
      },
    }),
  ];
};

export const unassignedSafetyScoresColumns = () => {
  const columnHelper = createColumnHelper<AggregatedUnassignedSafetyScore>();
  return [
    columnHelper.accessor('vin', {
      header: 'VIN',
      cell: ({ getValue, row }) => {
        return (
          <div>
            <div className="capitalize">{getValue()}</div>
            <div className="text-text-hint">{row.original.name}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor('score', {
      header: 'Safety Score',
      cell: ({ getValue }) => {
        return <Chip {...getSafetyScoreChipProps(getValue())} />;
      },
    }),
    columnHelper.accessor('milesDriven', {
      header: 'Total Miles Driven',
      cell: ({ getValue }) => {
        return <div>{getValue()?.toLocaleString()} miles</div>;
      },
    }),
  ];
};
