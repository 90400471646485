import { Chip, Tooltip } from '@nirvana/ui-kit';
import { UserProfileResponseUserTypeEnum } from '@nirvana/api/auth';
import { createColumnHelper } from '@tanstack/react-table';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import useAuth from 'src/hooks/useAuth';
import {
  ClaimsByDotNumberQuery,
  ClaimStatus,
  FnolStatus,
  ReportsQuery,
} from 'src/types/graphql-types';
import log from 'src/utils/logger';
import { ClaimRating } from '../components/ClaimRating';

const StatusChip = ({ status }: { status: ClaimStatus | FnolStatus }) => {
  switch (status) {
    case ClaimStatus.Open:
      return <Chip color="success" label="Open" />;

    case FnolStatus.Processed:
      return <Chip color="success" label="Claim Created" />;

    case FnolStatus.Sent:
      return <Chip color="success" label="Notice Sent" />;

    case ClaimStatus.Closed:
    case FnolStatus.Declined:
    case FnolStatus.Invalid:
      return <Chip color="grey" label="Closed" />;
    case ClaimStatus.Reopen:
      return <Chip color="info" label="Reopened" />;
    default:
      log(`Unknown status: ${status}`, 'info');
      return <Chip color="info" label={status} />;
  }
};

const formatDate = (dateString: string) => {
  const DAYS_BEFORE_SWITCH_TO_FULL_DATE = 7;
  const date = parseISO(dateString);
  const today = new Date();
  if (
    Math.abs(differenceInDays(date, today)) > DAYS_BEFORE_SWITCH_TO_FULL_DATE
  ) {
    return format(date, 'MMM dd yyyy');
  }
  return formatDistance(date, today, { addSuffix: true });
};

export const getClaimsColumns = () => {
  const columnHelper =
    createColumnHelper<ClaimsByDotNumberQuery['claimsByDOTNumber'][0]>();
  return [
    columnHelper.accessor('externalId', {
      header: 'Claim Number',
      enableSorting: false,

      cell: ({ row, getValue }) => {
        return (
          <div>
            <Tooltip
              title={
                <div className="w-36">
                  <div className="flex justify-between">
                    <p> Policy ID</p>
                    <p>{row.original.policyNumber}</p>
                  </div>
                </div>
              }
            >
              <div className="flex justify-between">
                <strong>{getValue()}</strong>
                <span className="ml-2 text-base text-primary-main">
                  <HiOutlineInformationCircle />
                </span>
              </div>
            </Tooltip>
          </div>
        );
      },
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      sortingFn: (rowA, rowB) => {
        // Define the priority of statuses
        const statusPriority = {
          [ClaimStatus.Open]: 1,
          [ClaimStatus.Reopen]: 2,
          [ClaimStatus.Closed]: 3,
        } as { [key in ClaimStatus]: number };

        const statusA = rowA.original.status;
        const statusB = rowB.original.status;

        // Compare statuses based on their priority
        if (statusPriority[statusA] < statusPriority[statusB]) {
          return -1;
        }
        if (statusPriority[statusA] > statusPriority[statusB]) {
          return 1;
        }
        // If all else fails, sort by ID
        return rowA.original.externalId.localeCompare(rowB.original.externalId);
      },
      cell: ({ getValue }) => <StatusChip status={getValue()} />,
    }),
    columnHelper.accessor('lineOfBusiness', {
      header: 'Claim Type',
      enableSorting: false,
      cell: ({ getValue }) => {
        return <Chip color="tint" label={getValue()} />;
      },
    }),
    columnHelper.accessor('reportedBy', {
      header: 'Reported By',
      cell: ({ getValue }) => {
        return (
          <div className="max-w-48">
            <Tooltip title={<>{getValue()?.toUpperCase()}</>}>
              <p className="truncate">{getValue()?.toUpperCase()}</p>
            </Tooltip>
          </div>
        );
      },
    }),
    columnHelper.accessor('reportedAt', {
      header: 'Reported On',
      cell: ({ getValue }) => {
        return formatDate(getValue());
      },
    }),
    columnHelper.accessor('modifiedAt', {
      header: 'Last Action Taken',
      cell: ({ getValue }) => {
        return formatDate(getValue());
      },
    }),
    columnHelper.display({
      id: 'feedbacks[0].id',
      header: 'Feedback',
      cell: ({ row }) => {
        // This is a hook, but it can't be renamed to useCell, so we need to disable the rule
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { user } = useAuth();

        return (
          <ClaimRating
            feedback={{
              ...row.original.feedbacks[0],
              claimId: row.original.id,
            }}
            canSubmitRating={
              user?.userType === UserProfileResponseUserTypeEnum.Fleet &&
              row.original.canSubmitFeedback
            }
            isClaimClosed={row.original.status === ClaimStatus.Closed}
          />
        );
      },
      meta: {
        tooltipText:
          "Adjusters won't see your rating. Your feedback is used to improve your claims experience with Nirvana.",
      },
    }),
  ];
};

export const getReportsColumns = () => {
  const columnHelper = createColumnHelper<ReportsQuery['fnols'][0]>();
  return [
    columnHelper.accessor('clientClaimNumber', {
      header: 'Reference Number',
    }),
    columnHelper.accessor('createdBy', {
      header: 'Reported By',
    }),
    columnHelper.accessor('createdAt', {
      header: 'Report Date',
      cell: ({ getValue }) => {
        return formatDate(getValue());
      },
    }),
    columnHelper.accessor('status', {
      header: 'Filling Status',
      sortingFn: (rowA, rowB) => {
        // Define the priority of statuses
        const statusPriority = {
          [FnolStatus.Declined]: 1,
          [FnolStatus.Invalid]: 1,
          [FnolStatus.Processed]: 0,
          [FnolStatus.Sent]: 0,
        } as { [key in FnolStatus]: number };

        const statusA = rowA.original.status;
        const statusB = rowB.original.status;

        // Compare statuses based on their priority
        if (statusPriority[statusA] < statusPriority[statusB]) {
          return -1;
        }
        if (statusPriority[statusA] > statusPriority[statusB]) {
          return 1;
        }
        // If all else fails, sort by ID
        return rowA.original.id.localeCompare(rowB.original.id);
      },
      cell: ({ getValue }) => <StatusChip status={getValue()} />,
    }),
  ];
};
