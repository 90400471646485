import { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import { analytics } from 'src/helpers';
import { SegmentEventTrack } from 'src/helpers/analytics';

type TrainingCardProps = {
  title: string;
  description: string;
  video: string;
};

export const TrainingCard = ({
  title,
  description,
  video,
}: TrainingCardProps) => {
  const playerRef = useRef<any>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [alreadyPlayed, setAlreadyPlayed] = useState(false);
  const [TrackedEventsPercentages, setTrackedEventsPercentages] = useState<
    number[]
  >([]);
  useEffect(() => {
    const interval = setInterval(async () => {
      const videoDuration =
        await playerRef.current.internalPlayer.getDuration();
      const currentTime =
        await playerRef.current.internalPlayer.getCurrentTime();
      const percentage = (currentTime / videoDuration) * 100;
      if (!TrackedEventsPercentages.includes(percentage)) {
        setTrackedEventsPercentages([...TrackedEventsPercentages, percentage]);
        analytics.trackEvent({
          event: SegmentEventTrack.TrainingVideoPercentagePlayed,
          properties: {
            video,
            percentage,
            title,
            description,
            currentTime,
          },
        });
      }
    }, 10_000);

    if (!isPlaying) clearInterval(interval);

    return () => clearInterval(interval);
  }, [TrackedEventsPercentages, description, isPlaying, title, video]);

  const trackAndUpdate = () => {
    if (!alreadyPlayed) {
      analytics.trackEvent({
        event: SegmentEventTrack.TrainingVideoPlayed,
        properties: {
          video,
          title,
          description,
        },
      });
      setAlreadyPlayed(true);
    }
    setIsPlaying(true);
  };
  return (
    <article className="p-4 lg:w-1/2 xl:w-1/3">
      <div className="aspect-video">
        <YouTube
          videoId={video}
          id={`player-${video}`}
          ref={playerRef}
          opts={{
            height: '100%',
            width: '100%',
          }}
          onPlay={trackAndUpdate}
          onPause={() => setIsPlaying(false)}
          className="w-full h-full"
        />
      </div>
      <h4 className="mt-2 font-bold text-secondary-dark">{title}</h4>
      <p className="mt-2 text-text-hint">{description}</p>
    </article>
  );
};
