import * as React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as ReLineChart,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceLineProps,
  ReferenceAreaProps,
  ReferenceArea,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import type { ReactElement } from 'react';
import { AxisConfig, TooltipConfig } from '../../types/charts';

/** Configuration for an individual Line of LineChart */
type LineConfig = {
  /** Provde name for an individual Line of the LineChart. Same name will be reflected in Legend and Tooltip */
  name?: string;
  /** stroke color of an individaul Line of the LineChart */
  stroke?: string;
  /** Provide string like '4 1' for dashed style Line. 4 is dash length and 1 is gap length */
  strokeDasharray?: string;
  /** Provide stroke width of each individual line in Line Chart */
  strokeWidth?: number;
  /**
   * If false set, dots will not be drawn. If true set, dots will be drawn which have the props calculated internally.
   * If object set, dots will be drawn which have the props mergered by the internal calculated props and the option.
   * If ReactElement set, the option can be the custom dot element.
   * If set a function, the function will be called to render customized dot.
   */
  dot?: boolean | object | ReactElement | ((props: any) => ReactElement);
  /**
   * The active dot is shown when a user enters a line chart and this chart has tooltip.
   * If set to false, no active dot will be drawn.
   * If set to true, active dot will be drawn with the props calculated internally.
   * If passed an object, active dot will be drawn, and the internally calculated props will be merged with the key value pairs of the passed object.
   * If passed a ReactElement, the option can be the custom active dot element.
   * If passed a function, the function will be called to render a customized active dot.
   */
  activeDot?: boolean | object | ReactElement | ((props: any) => ReactElement);
  /** If set true, the animation will be active in the line chart,
   * it has a reported bug when displaying only dots
   * https://github.com/recharts/recharts/issues/1426#issuecomment-501221315
   */
  isAnimationActive?: boolean;
};

/** Line Chart properties */
export type LineChartProps = {
  /** Provide source data for the chart. The data source is a collection of objects, such as
   * `const data = [{ time: '1991', value: 20 }, { time: '1992', value: 20 }]`
   */
  data: Array<any>;
  /** The name of the data field corresponding to the graph in the x-direction
   * For the above data, 'xField' will be 'time'
   */
  xField: string;
  /** The name of the data field corresponding to the graph in the y-direction
   * For the above data, 'yField' will be ['value']
   * You can add multiple values in array for multi-line chart
   */
  yField: Array<string>;
  /** The sizes of whitespace around the container. */
  margin?: { top?: number; right?: number; bottom?: number; left?: number };
  /** Array of LineConfig used to customize individual lines in the chart */
  lineConfig?: Array<LineConfig>;
  /** Configuration for the x-axis */
  xAxis?: AxisConfig;
  /** Configuration for the y-axis */
  yAxis?: AxisConfig;
  /** Whether to show legend in the chart or not */
  legend?: boolean;
  /** Array of reference line props to add multiple reference lines in the chart */
  referenceLines?: Array<ReferenceLineProps>;
  /** Array of reference area props to add multiple reference areas in the chart */
  referenceAreas?: Array<ReferenceAreaProps>;
  /** props for recharts Tooltip component */
  tooltipProps?: TooltipConfig;
};

export default function LineChart({
  data,
  xField,
  yField,
  margin,
  lineConfig,
  xAxis,
  yAxis,
  legend,
  referenceLines = [],
  referenceAreas = [],
  tooltipProps,
}: LineChartProps) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ReLineChart
        width={500}
        height={400}
        data={data}
        margin={{ bottom: 20, right: 20, ...margin }}
      >
        <CartesianGrid vertical={false} stroke="#E5E7EB" />
        <XAxis
          dataKey={xField}
          tickLine={false}
          stroke="#3350A1"
          {...xAxis}
          label={{
            position: 'insideBottom',
            fill: '#3350A1',
            offset: -10,
            ...xAxis?.label,
          }}
        />
        <YAxis
          tickLine={false}
          stroke="#3350A1"
          {...yAxis}
          label={{
            angle: -90,
            position: 'insideLeft',
            offset: 10,
            fill: '#3350A1',
            dy: 25,
            ...yAxis?.label,
          }}
        />
        <Tooltip {...tooltipProps} />
        {legend ? <Legend offset={10} wrapperStyle={{ bottom: 0 }} /> : null}
        {referenceLines.map((_, index) => (
          <ReferenceLine key={`line-${index}`} {...referenceLines[index]} />
        ))}
        {referenceAreas.map((_, index) => (
          <ReferenceArea key={`area-${index}`} {...referenceAreas[index]} />
        ))}
        {yField.map((key, index) => (
          <Line
            key={key}
            dataKey={key}
            strokeWidth={2}
            dot={{
              stroke: lineConfig?.[index].stroke ?? '#00A0FF',
              fill: '#ffffff',
              r: 4,
              strokeDasharray: '0',
            }}
            activeDot={{
              stroke: '#ffffff',
              r: 6,
              fill: lineConfig?.[index].stroke ?? '#00A0FF',
            }}
            {...lineConfig?.[index]}
          />
        ))}
      </ReLineChart>
    </ResponsiveContainer>
  );
}
