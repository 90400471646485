import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthLayout, BrandingTypes, Page } from '@nirvana/ui-kit';

import useAuth from 'src/hooks/useAuth';

const queryClient = new QueryClient();

const Auth: FC = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, user } = useAuth();

  const from =
    location.state?.from?.pathname ??
    (user?.userType === 'fleet' ? `/${user?.reportId}/overview` : '/search');

  if (isAuthenticated) {
    return <Navigate to={from} />;
  }

  return (
    <AuthLayout
      title={
        <>
          Modern Fleet <br /> Risk Management
        </>
      }
      brandingType={BrandingTypes.Safety}
    >
      <Page title="Nirvana Safety">
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </Page>
    </AuthLayout>
  );
};

export default Auth;
