import { useMemo } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import { SortableHeader, TableSkeletonLoader } from '@nirvana/ui-kit';
import { ReactComponent as ShoppingCart } from 'src/assets/icons/shopping-cart.svg';
import { useShippersQuery } from 'src/types/graphql-types';
import { shipperColumns } from '../constants/shippers';

export default function Shippers() {
  const { reportId = '' } = useParams();

  const { data: shippersData, loading } = useShippersQuery({
    variables: { reportId },
  });

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable(
      {
        columns: shipperColumns,
        data: shippersData?.fleetSafetyReport?.ShipperList ?? [],
        defaultColumn: { Header: SortableHeader },
        autoResetSortBy: false,
        initialState: { sortBy: [{ id: 'inspectionsCount', desc: true }] },
      },
      useSortBy,
    );

  const tableContent = useMemo(() => {
    if (loading) {
      return <TableSkeletonLoader columns={shipperColumns.length} />;
    }
    if (shippersData) {
      return rows.map((row, index) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            key={index}
            className="text-xs font-normal text-text-primary"
          >
            {row.cells.map((cell, index) => (
              <td {...cell.getCellProps()} key={index} className="px-4 py-3">
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
        );
      });
    }
    return null;
  }, [shippersData, loading, prepareRow, rows]);

  return (
    <div className="p-4 overflow-hidden bg-white rounded-lg shadow lg:col-span-5">
      <div className="flex items-center mb-4 space-x-3">
        <ShoppingCart />
        <span className="font-semibold text-secondary-main">Top Shippers</span>
      </div>

      <div className="overflow-y-auto h-72">
        <table className="w-full" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps)}
                    key={index}
                    className="sticky top-0 px-4 py-2 text-xs font-normal text-left bg-primary-extraLight text-secondary-dark"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className={clsx({ 'divide-y divide-gray-100': !loading })}
          >
            {tableContent}
          </tbody>
        </table>
      </div>
    </div>
  );
}
