import * as React from 'react';
import { Box, Grid, Typography, Link, Alert } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Show, InputWithLabel } from '@nirvana/ui-kit';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

let password = '';

const SignUpSchema = z.object({
  firstName: z.string().min(1, 'Please enter your first name'),
  lastName: z.string().min(1, 'Please enter your last name'),
  email: z.string().email('Please enter a valid email address'),
  password: z
    .string()
    .min(8, 'The password must have at least 8 characters')
    .refine((value) => {
      password = value;
      return true;
    }),
  confirmPassword: z
    .string()
    .min(8, 'The password must have at least 8 characters')
    .refine((value) => value === password, 'Passwords do not match'),
});

type SignUpType = z.infer<typeof SignUpSchema>;

export type InitialFormData = Exclude<SignUpType, 'password'>;
export interface SignUpFormProps {
  error: string | null;
  signInLink: string;
  initialValues?: InitialFormData;
  onSubmit: (data: SignUpType) => void;
  title?: string;
}

const SignUpForm: React.FC<SignUpFormProps> = ({
  error,
  onSubmit,
  signInLink,
  initialValues = {},
  title = 'Complete sign up',
}) => {
  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SignUpType>({
    mode: 'onBlur',
    resolver: zodResolver(SignUpSchema),
    defaultValues: initialValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-5">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Show when={!!error}>
            <Grid item>
              <Alert severity="error">{error}</Alert>
            </Grid>
          </Show>

          <Grid item>
            <Box component="form">
              <Grid container direction="column" alignItems="stretch">
                <Grid
                  item
                  container
                  spacing={2}
                  direction="row"
                  alignItems="stretch"
                >
                  <Grid item xs>
                    <InputWithLabel
                      {...register('firstName')}
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                      }}
                      id="firstName"
                      label="First Name"
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  </Grid>
                  <Grid item xs>
                    <InputWithLabel
                      {...register('lastName')}
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                      }}
                      id="lastName"
                      label="Last Name"
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <InputWithLabel
                    {...register('email')}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    id="email"
                    type="email"
                    label="Email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>

                <Grid item>
                  <InputWithLabel
                    {...register('password')}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    id="password"
                    label="Password"
                    type="password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                </Grid>
                <Grid item>
                  <InputWithLabel
                    {...register('confirmPassword')}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    id="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type={isValid ? 'submit' : 'button'}
              onClick={
                isValid
                  ? undefined
                  : () => {
                      trigger();
                    }
              }
            >
              Create Account
            </Button>
          </Grid>
          <Grid item container alignItems="center" direction="column">
            <Link href={signInLink}>Sign in</Link>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default SignUpForm;
