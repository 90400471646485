import * as React from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  makeStyles,
} from '@material-ui/core';
import { HiOutlineInformationCircle } from 'react-icons/hi';

import { ITheme } from '../../assets/themes';

const useStyles = makeStyles((theme: ITheme) => ({
  arrow: {
    color: theme.palette.text.primary,
  },
  tooltip: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: 10,
    maxWidth: 'none',
    width: 'auto',
  },
}));

interface ITooltipProps extends TooltipProps {
  includeIcon?: boolean;
}

export default function Tooltip({
  children,
  includeIcon = false,
  ...rest
}: ITooltipProps) {
  const classes = useStyles();
  return (
    <MuiTooltip
      {...rest}
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
    >
      <div className="inline-block">
        {children}
        {includeIcon && (
          <span className="text-lg text-primary-main">
            <HiOutlineInformationCircle />
          </span>
        )}
      </div>
    </MuiTooltip>
  );
}
