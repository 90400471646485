import * as React from 'react';
import {
  HiOutlineArrowSmDown,
  HiOutlineArrowSmUp,
  HiOutlineMinus,
} from 'react-icons/hi';
import { Props as LabelProps } from 'recharts/types/component/Label';
import Switch from '../switch-match';

export default function RiskScoreChange(props: LabelProps) {
  const { x: xStr, y: yStr, height: heightStr, value, width: widthStr } = props;

  const x = Number(xStr);
  const y = Number(yStr);
  const height = Number(heightStr);
  const width = Number(widthStr);
  if (typeof value === 'undefined' || typeof value === 'string') {
    return null;
  }

  const TOTAL_HEIGHT = y + height;

  return (
    <g>
      <rect
        x={x - 3}
        y={TOTAL_HEIGHT + 46}
        width={width + 6}
        height="25"
        rx="4"
        fill="#F3F4FA"
      />
      <Switch>
        <Switch.Match when={value < 0}>
          <HiOutlineArrowSmUp
            x={x + 2}
            y={TOTAL_HEIGHT + 50}
            className="text-error-main"
          />
        </Switch.Match>
        <Switch.Match when={value > 0}>
          <HiOutlineArrowSmDown
            x={x + 2}
            y={TOTAL_HEIGHT + 50}
            className="text-success-main"
          />
        </Switch.Match>
        <Switch.Match when={value === 0}>
          <HiOutlineMinus
            x={x}
            y={TOTAL_HEIGHT + 51}
            className="text-text-blue"
          />
        </Switch.Match>
      </Switch>
      <text x={x + 15} y={TOTAL_HEIGHT + 62} fontSize={12} fill="#3350A1">
        {Math.abs(value)}
      </text>
    </g>
  );
}
