/* eslint-disable no-console */
type LogLevel = 'info' | 'error' | 'debug' | 'warn';

const log = (message: string, level: LogLevel = 'info', data?: any) => {
  switch (level) {
    case 'info':
      console.info(message, data);
      break;
    case 'error':
      console.error(message, data);
      break;
    case 'debug':
      console.debug(message, data);
      break;
    case 'warn':
      console.warn(message, data);
      break;
    default:
      console.log(message, data);
  }
};

export default log;
