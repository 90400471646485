import { Feature, useFeatureValue } from 'src/helpers/feature-flags';
import log from 'src/utils/logger';
import { useGetDOTFromParams } from './useGetDOTFromParams';

export const useDOTSpecificFeature = (
  feature: Feature,
  { allowUserOverride = false } = {},
) => {
  const { dotNumber } = useGetDOTFromParams();

  const flagValue = useFeatureValue(feature);

  let enabled = false;

  const isString = typeof flagValue === 'string';
  if (!isString) {
    log('Feature value is not a string', 'warn');
  }

  if (allowUserOverride && flagValue === 'all') {
    enabled = true;
  }

  if (dotNumber && isString && flagValue.includes(dotNumber)) {
    enabled = true;
  }

  return {
    enabled,
    loading: false,
  };
};
