import { alpha, makeStyles } from '@material-ui/core/styles';
import { ITheme } from '../../assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    height: 'auto',
    padding: theme.spacing(0.25, 1.25),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),

    '& .MuiChip-icon': {
      marginLeft: 0,
      marginRight: theme.spacing(0.75),
      width: '12px',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    padding: 0,
  },
  success: {
    backgroundColor: theme.palette.success.extraLight,
    color: theme.palette.success.dark,
  },
  warning: {
    backgroundColor: theme.palette.warning.extraLight,
    color: theme.palette.warning.dark,
  },
  info: {
    backgroundColor: theme.palette.info.extraLight,
    color: theme.palette.info.dark,
  },
  error: {
    backgroundColor: theme.palette.error.extraLight,
    color: theme.palette.error.dark,
  },
  tint: {
    backgroundColor: alpha(theme.palette.tint.extraLight, 0.15),
    color: theme.palette.tint.dark,
  },
  gold: {
    backgroundColor: alpha(theme.palette.gold.light, 0.15),
    color: theme.palette.gold.main,
  },
  'gold-dark': {
    backgroundColor: alpha(theme.palette.gold.main, 0.15),
    color: theme.palette.common.black,
  },
  grey: {
    backgroundColor: alpha(theme.palette.grey[200], 0.15),
    color: theme.palette.grey[600],
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  rounded: {
    borderRadius: theme.spacing(4),
  },
}));
