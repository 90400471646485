import { makeStyles } from '@material-ui/core';

import { ITheme } from '../../assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  appBar: {
    padding: theme.spacing(0.5, 0),
    position: 'relative',
    top: 0,
    zIndex: 11,
  },
  elevation: {
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.06)',
  },
  toolbar: {
    minHeight: theme.spacing(5),
  },
  infoIcon: {
    color: theme.palette.info.main,
  },
  declinedIcon: {
    color: '#FF823C',
  },
  colorLight: {
    color: theme.palette.grey[400],
  },
}));
