import * as React from 'react';
import { forwardRef } from 'react';
import { SnackbarContent } from 'notistack';

import CustomContent, { ISnackbarContentProps } from './content';

const Snackbar = forwardRef<HTMLDivElement, ISnackbarContentProps>(
  (props, ref) => {
    return (
      <SnackbarContent ref={ref}>
        <CustomContent {...props} />
      </SnackbarContent>
    );
  },
);

export default Snackbar;
