import { useCallback } from 'react';
import { useClaimsPresignedUploadLinksLazyQuery } from 'src/types/graphql-types';

export function useUploadLinkGenerator() {
  const [getPresignedUploadLinks] = useClaimsPresignedUploadLinksLazyQuery();

  const handler = useCallback(
    async (policyNumber: string, files: File[]) => {
      const { data, error } = await getPresignedUploadLinks({
        variables: {
          policyNumber,
          fileNames: files.map(({ name }) => name),
        },
      });
      if (error) {
        throw error;
      }
      return (data?.claimsPresignedUploadLinks ?? []).map(({ url, key }) => ({
        url,
        key,
      }));
    },
    [getPresignedUploadLinks],
  );

  return { handler };
}
