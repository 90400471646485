/* tslint:disable */
/* eslint-disable */
/**
 * Nirvana Auth API
 * Nirvana Auth APIs
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface APIDesc
 */
export interface APIDesc {
  /**
   *
   * @type {string}
   * @memberof APIDesc
   */
  api: string;
  /**
   *
   * @type {Array<string>}
   * @memberof APIDesc
   */
  methods: Array<APIDescMethodsEnum>;
  /**
   *
   * @type {Array<APIRateLimit>}
   * @memberof APIDesc
   */
  rate_limit?: Array<APIRateLimit>;
}

/**
 * @export
 * @enum {string}
 */
export enum APIDescMethodsEnum {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH',
}

/**
 *
 * @export
 * @interface APIKeyScope
 */
export interface APIKeyScope {
  /**
   *
   * @type {Array<APIDesc>}
   * @memberof APIKeyScope
   */
  apis: Array<APIDesc>;
  /**
   *
   * @type {Array<APIRateLimit>}
   * @memberof APIKeyScope
   */
  global_rate_limit: Array<APIRateLimit>;
}
/**
 *
 * @export
 * @interface APIKeysWithAgency
 */
export interface APIKeysWithAgency {
  /**
   *
   * @type {string}
   * @memberof APIKeysWithAgency
   */
  keyId: string;
  /**
   *
   * @type {string}
   * @memberof APIKeysWithAgency
   */
  userId: string;
  /**
   *
   * @type {KeyStatus}
   * @memberof APIKeysWithAgency
   */
  status: KeyStatus;
  /**
   *
   * @type {APIKeyScope}
   * @memberof APIKeysWithAgency
   */
  scope: APIKeyScope;
  /**
   *
   * @type {string}
   * @memberof APIKeysWithAgency
   */
  expiresAt: string;
  /**
   *
   * @type {string}
   * @memberof APIKeysWithAgency
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof APIKeysWithAgency
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof APIKeysWithAgency
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof APIKeysWithAgency
   */
  agencyName: string;
}
/**
 *
 * @export
 * @interface APIRateLimit
 */
export interface APIRateLimit {
  /**
   *
   * @type {string}
   * @memberof APIRateLimit
   */
  duration: string;
  /**
   *
   * @type {number}
   * @memberof APIRateLimit
   */
  limit: number;
}
/**
 *
 * @export
 * @interface Agency
 */
export interface Agency {
  /**
   *
   * @type {string}
   * @memberof Agency
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Agency
   */
  name: string;
}
/**
 *
 * @export
 * @interface AgencyRole
 */
export interface AgencyRole {
  /**
   *
   * @type {string}
   * @memberof AgencyRole
   */
  role: string;
  /**
   *
   * @type {Agency}
   * @memberof AgencyRole
   */
  agency: Agency;
}
/**
 *
 * @export
 * @interface AgentDetails
 */
export interface AgentDetails {
  /**
   *
   * @type {boolean}
   * @memberof AgentDetails
   */
  isDetailsComplete: boolean;
}
/**
 *
 * @export
 * @interface CreateAPIKeyRequest
 */
export interface CreateAPIKeyRequest {
  /**
   * Agency ID of the API user
   * @type {string}
   * @memberof CreateAPIKeyRequest
   */
  agencyID: string;
  /**
   *
   * @type {APIKeyScope}
   * @memberof CreateAPIKeyRequest
   */
  scope: APIKeyScope;
  /**
   *
   * @type {string}
   * @memberof CreateAPIKeyRequest
   */
  expiresAt?: string;
}
/**
 *
 * @export
 * @interface CreateAPIKeyResponse
 */
export interface CreateAPIKeyResponse {
  /**
   *
   * @type {string}
   * @memberof CreateAPIKeyResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CreateAPIKeyResponse
   */
  secret: string;
}
/**
 *
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof ErrorMessage
   */
  code: number;
}
/**
 *
 * @export
 * @interface Fleet
 */
export interface Fleet {
  /**
   *
   * @type {string}
   * @memberof Fleet
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Fleet
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Fleet
   */
  dotNumber: number;
}
/**
 *
 * @export
 * @interface FleetRole
 */
export interface FleetRole {
  /**
   *
   * @type {string}
   * @memberof FleetRole
   */
  role: string;
  /**
   *
   * @type {Fleet}
   * @memberof FleetRole
   */
  fleet: Fleet;
}
/**
 *
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordRequest
   */
  email: string;
}
/**
 *
 * @export
 * @interface GetAPIKeyResponse
 */
export interface GetAPIKeyResponse {
  /**
   *
   * @type {string}
   * @memberof GetAPIKeyResponse
   */
  keyId: string;
  /**
   *
   * @type {string}
   * @memberof GetAPIKeyResponse
   */
  userId: string;
  /**
   *
   * @type {KeyStatus}
   * @memberof GetAPIKeyResponse
   */
  status: KeyStatus;
  /**
   *
   * @type {APIKeyScope}
   * @memberof GetAPIKeyResponse
   */
  scope: APIKeyScope;
  /**
   *
   * @type {string}
   * @memberof GetAPIKeyResponse
   */
  expiresAt: string;
  /**
   *
   * @type {string}
   * @memberof GetAPIKeyResponse
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof GetAPIKeyResponse
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof GetAPIKeyResponse
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface GoogleAuthResponse
 */
export interface GoogleAuthResponse {
  /**
   *
   * @type {string}
   * @memberof GoogleAuthResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof GoogleAuthResponse
   */
  handleID?: string;
}
/**
 *
 * @export
 * @interface HubspotLoginRequest
 */
export interface HubspotLoginRequest {
  /**
   * The redirect_url query parameter that is provided by hubspot. We echo this query param back to hubspot in our client redirect URI.
   * @type {string}
   * @memberof HubspotLoginRequest
   */
  redirectURL?: string;
}
/**
 *
 * @export
 * @interface HubspotLoginResponse
 */
export interface HubspotLoginResponse {
  /**
   * The URL that will authenticate this user with hubspot upon redirecting to it.
   * @type {string}
   * @memberof HubspotLoginResponse
   */
  absoluteLoginUrl: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum KeyStatus {
  Active = 'active',
  Expired = 'expired',
  Blocked = 'blocked',
}

/**
 *
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  password: string;
  /**
   *
   * @type {UTMTags}
   * @memberof LoginRequest
   */
  utmTags?: UTMTags;
  /**
   * The source of the login request.
   * @type {string}
   * @memberof LoginRequest
   */
  source?: LoginRequestSourceEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum LoginRequestSourceEnum {
  Agent = 'Agent',
  Underwriter = 'Underwriter',
  Safety = 'Safety',
  Unknown = 'Unknown',
}

/**
 *
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  expiration: string;
}
/**
 *
 * @export
 * @interface NirvanaRole
 */
export interface NirvanaRole {
  /**
   *
   * @type {string}
   * @memberof NirvanaRole
   */
  role: string;
}
/**
 *
 * @export
 * @interface OAuthConnectionData
 */
export interface OAuthConnectionData {
  /**
   *
   * @type {string}
   * @memberof OAuthConnectionData
   */
  authCode?: string;
  /**
   *
   * @type {string}
   * @memberof OAuthConnectionData
   */
  scope?: string;
  /**
   *
   * @type {string}
   * @memberof OAuthConnectionData
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof OAuthConnectionData
   */
  error?: string;
}
/**
 *
 * @export
 * @interface PatchAPIKeyRequest
 */
export interface PatchAPIKeyRequest {
  /**
   *
   * @type {string}
   * @memberof PatchAPIKeyRequest
   */
  keyId: string;
  /**
   *
   * @type {APIKeyScope}
   * @memberof PatchAPIKeyRequest
   */
  scope?: APIKeyScope;
  /**
   *
   * @type {KeyStatus}
   * @memberof PatchAPIKeyRequest
   */
  status?: KeyStatus;
}
/**
 *
 * @export
 * @interface Roles
 */
export interface Roles {
  /**
   * List of authz internal `Nirvana` roles for this user.
   * @type {Array<NirvanaRole>}
   * @memberof Roles
   */
  nirvanaRoles?: Array<NirvanaRole>;
  /**
   * List of authz `Agency` roles for this user. If a user has an `agency` role, then they are typically either an `Agent` or an internal `Nirvana` user.
   * @type {Array<AgencyRole>}
   * @memberof Roles
   */
  agencyRoles?: Array<AgencyRole>;
  /**
   * List of authz `Fleet` roles for this user. If a user has `Fleet` roles but no `Agency` or `Nirvana` roles, then this user can only view their own DOT(s) in the Safety App.
   * @type {Array<FleetRole>}
   * @memberof Roles
   */
  fleetRoles?: Array<FleetRole>;
}
/**
 *
 * @export
 * @interface UTMTags
 */
export interface UTMTags {
  /**
   *
   * @type {string}
   * @memberof UTMTags
   */
  utm_source?: string;
  /**
   *
   * @type {string}
   * @memberof UTMTags
   */
  utm_medium?: string;
  /**
   *
   * @type {string}
   * @memberof UTMTags
   */
  utm_campaign?: string;
  /**
   *
   * @type {string}
   * @memberof UTMTags
   */
  utm_adgroup?: string;
  /**
   *
   * @type {string}
   * @memberof UTMTags
   */
  utm_keyword?: string;
}
/**
 *
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
  /**
   *
   * @type {string}
   * @memberof UserProfileResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileResponse
   */
  reportId: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileResponse
   */
  email: string;
  /**
   * Type of the user\'s roles:  * `nirvana` - Internal Nirvana user, such as `superuser` or `support` users.  * `agent` - User belongs to one or more Agency roles.  * `fleet` - User belongs to one or more Fleet roles, and no Agency or Nirvana roles. This user should only have access to the safety app.  * `shared_link` - Shared link user that is anonymously viewing a Safety App shared URL.  * `unprivileged` - User has login credentials but does not belong to any agencies or fleets.
   * @type {string}
   * @memberof UserProfileResponse
   */
  userType: UserProfileResponseUserTypeEnum;
  /**
   *
   * @type {Roles}
   * @memberof UserProfileResponse
   */
  roles?: Roles;
  /**
   *
   * @type {string}
   * @memberof UserProfileResponse
   */
  defaultAgencyId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UserProfileResponseUserTypeEnum {
  Nirvana = 'nirvana',
  Agent = 'agent',
  Fleet = 'fleet',
  SharedLink = 'shared_link',
  Unprivileged = 'unprivileged',
}

/**
 *
 * @export
 * @interface VerifyTokenRequest
 */
export interface VerifyTokenRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyTokenRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof VerifyTokenRequest
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof VerifyTokenRequest
   */
  newPassword: string;
}
/**
 *
 * @export
 * @interface WorkrampLoginRequest
 */
export interface WorkrampLoginRequest {
  /**
   * The relative path where the user should be redirected to upon authenticating with absoluteLoginUrl.
   * @type {string}
   * @memberof WorkrampLoginRequest
   */
  destPath?: string;
}
/**
 *
 * @export
 * @interface WorkrampLoginResponse
 */
export interface WorkrampLoginResponse {
  /**
   *
   * @type {string}
   * @memberof WorkrampLoginResponse
   */
  absoluteLoginUrl: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Retrieve agent details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    agentDetailsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/agent_details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sends a verification token to the user
     * @param {ForgotPasswordRequest} forgotPasswordRequest A JSON object containing the user Email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authForgotPasswordPost: async (
      forgotPasswordRequest: ForgotPasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'forgotPasswordRequest' is not null or undefined
      assertParamExists(
        'authForgotPasswordPost',
        'forgotPasswordRequest',
        forgotPasswordRequest,
      );
      const localVarPath = `/auth/forgotPassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forgotPasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a signed JWT SSO login URL for Hubspot, which authenticates this user into Hubspot. See https://knowledge.hubspot.com/website-pages/set-up-single-sign-on-sso-to-access-private-content#set-up-sso-for-a-jwt-based-application for more details.
     * @param {HubspotLoginRequest} [hubspotLoginRequest] A JSON object containing the redirect_url passed in by hubspot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginHubspotPost: async (
      hubspotLoginRequest?: HubspotLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/login/hubspot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        hubspotLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logs the user in and returns the sessionId
     * @param {LoginRequest} loginRequest A JSON object containing the login and password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginPost: async (
      loginRequest: LoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginRequest' is not null or undefined
      assertParamExists('authLoginPost', 'loginRequest', loginRequest);
      const localVarPath = `/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Invokes the Workramp Instant Auth endpoint to generate a login URL for this user. See https://help.workramp.com/en/articles/6993556-setting-up-instant-auth-academies for more details.
     * @param {WorkrampLoginRequest} [workrampLoginRequest] A JSON object containing the optional destination path where the user should be redirected.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginWorkrampPost: async (
      workrampLoginRequest?: WorkrampLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/login/workramp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workrampLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logs the user out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogoutPost: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Authenticates the token and updates the password of the user
     * @param {VerifyTokenRequest} verifyTokenRequest A JSON object containing the user email, token and new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authVerifyTokenPost: async (
      verifyTokenRequest: VerifyTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyTokenRequest' is not null or undefined
      assertParamExists(
        'authVerifyTokenPost',
        'verifyTokenRequest',
        verifyTokenRequest,
      );
      const localVarPath = `/auth/verifyToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Checks if we have the google token for the user from the context
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleAuthGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/google/auth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logs the user in using Google and returns the sessionId
     * @param {OAuthConnectionData} oAuthConnectionData A JSON object containing the googleIdToken.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleAuthPost: async (
      oAuthConnectionData: OAuthConnectionData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'oAuthConnectionData' is not null or undefined
      assertParamExists(
        'googleAuthPost',
        'oAuthConnectionData',
        oAuthConnectionData,
      );
      const localVarPath = `/google/auth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        oAuthConnectionData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Begins the Google login flow for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleAuthStartPost: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/google/auth/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user profile for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Retrieve agent details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async agentDetailsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentDetails>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.agentDetailsGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Sends a verification token to the user
     * @param {ForgotPasswordRequest} forgotPasswordRequest A JSON object containing the user Email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authForgotPasswordPost(
      forgotPasswordRequest: ForgotPasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authForgotPasswordPost(
          forgotPasswordRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a signed JWT SSO login URL for Hubspot, which authenticates this user into Hubspot. See https://knowledge.hubspot.com/website-pages/set-up-single-sign-on-sso-to-access-private-content#set-up-sso-for-a-jwt-based-application for more details.
     * @param {HubspotLoginRequest} [hubspotLoginRequest] A JSON object containing the redirect_url passed in by hubspot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLoginHubspotPost(
      hubspotLoginRequest?: HubspotLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HubspotLoginResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authLoginHubspotPost(
          hubspotLoginRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Logs the user in and returns the sessionId
     * @param {LoginRequest} loginRequest A JSON object containing the login and password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLoginPost(
      loginRequest: LoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginPost(
        loginRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Invokes the Workramp Instant Auth endpoint to generate a login URL for this user. See https://help.workramp.com/en/articles/6993556-setting-up-instant-auth-academies for more details.
     * @param {WorkrampLoginRequest} [workrampLoginRequest] A JSON object containing the optional destination path where the user should be redirected.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLoginWorkrampPost(
      workrampLoginRequest?: WorkrampLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkrampLoginResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authLoginWorkrampPost(
          workrampLoginRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Logs the user out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLogoutPost(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLogoutPost(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Authenticates the token and updates the password of the user
     * @param {VerifyTokenRequest} verifyTokenRequest A JSON object containing the user email, token and new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authVerifyTokenPost(
      verifyTokenRequest: VerifyTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authVerifyTokenPost(
          verifyTokenRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Checks if we have the google token for the user from the context
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async googleAuthGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.googleAuthGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Logs the user in using Google and returns the sessionId
     * @param {OAuthConnectionData} oAuthConnectionData A JSON object containing the googleIdToken.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async googleAuthPost(
      oAuthConnectionData: OAuthConnectionData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.googleAuthPost(
        oAuthConnectionData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Begins the Google login flow for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async googleAuthStartPost(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GoogleAuthResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.googleAuthStartPost(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get user profile for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @summary Retrieve agent details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    agentDetailsGet(options?: any): AxiosPromise<AgentDetails> {
      return localVarFp
        .agentDetailsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Sends a verification token to the user
     * @param {ForgotPasswordRequest} forgotPasswordRequest A JSON object containing the user Email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authForgotPasswordPost(
      forgotPasswordRequest: ForgotPasswordRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .authForgotPasswordPost(forgotPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a signed JWT SSO login URL for Hubspot, which authenticates this user into Hubspot. See https://knowledge.hubspot.com/website-pages/set-up-single-sign-on-sso-to-access-private-content#set-up-sso-for-a-jwt-based-application for more details.
     * @param {HubspotLoginRequest} [hubspotLoginRequest] A JSON object containing the redirect_url passed in by hubspot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginHubspotPost(
      hubspotLoginRequest?: HubspotLoginRequest,
      options?: any,
    ): AxiosPromise<HubspotLoginResponse> {
      return localVarFp
        .authLoginHubspotPost(hubspotLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Logs the user in and returns the sessionId
     * @param {LoginRequest} loginRequest A JSON object containing the login and password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginPost(
      loginRequest: LoginRequest,
      options?: any,
    ): AxiosPromise<LoginResponse> {
      return localVarFp
        .authLoginPost(loginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Invokes the Workramp Instant Auth endpoint to generate a login URL for this user. See https://help.workramp.com/en/articles/6993556-setting-up-instant-auth-academies for more details.
     * @param {WorkrampLoginRequest} [workrampLoginRequest] A JSON object containing the optional destination path where the user should be redirected.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginWorkrampPost(
      workrampLoginRequest?: WorkrampLoginRequest,
      options?: any,
    ): AxiosPromise<WorkrampLoginResponse> {
      return localVarFp
        .authLoginWorkrampPost(workrampLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Logs the user out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogoutPost(options?: any): AxiosPromise<void> {
      return localVarFp
        .authLogoutPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Authenticates the token and updates the password of the user
     * @param {VerifyTokenRequest} verifyTokenRequest A JSON object containing the user email, token and new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authVerifyTokenPost(
      verifyTokenRequest: VerifyTokenRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .authVerifyTokenPost(verifyTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Checks if we have the google token for the user from the context
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleAuthGet(options?: any): AxiosPromise<void> {
      return localVarFp
        .googleAuthGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Logs the user in using Google and returns the sessionId
     * @param {OAuthConnectionData} oAuthConnectionData A JSON object containing the googleIdToken.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleAuthPost(
      oAuthConnectionData: OAuthConnectionData,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .googleAuthPost(oAuthConnectionData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Begins the Google login flow for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleAuthStartPost(options?: any): AxiosPromise<GoogleAuthResponse> {
      return localVarFp
        .googleAuthStartPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user profile for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meGet(options?: any): AxiosPromise<UserProfileResponse> {
      return localVarFp
        .meGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @summary Retrieve agent details
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public agentDetailsGet(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .agentDetailsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Sends a verification token to the user
   * @param {ForgotPasswordRequest} forgotPasswordRequest A JSON object containing the user Email.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authForgotPasswordPost(
    forgotPasswordRequest: ForgotPasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authForgotPasswordPost(forgotPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a signed JWT SSO login URL for Hubspot, which authenticates this user into Hubspot. See https://knowledge.hubspot.com/website-pages/set-up-single-sign-on-sso-to-access-private-content#set-up-sso-for-a-jwt-based-application for more details.
   * @param {HubspotLoginRequest} [hubspotLoginRequest] A JSON object containing the redirect_url passed in by hubspot.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLoginHubspotPost(
    hubspotLoginRequest?: HubspotLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authLoginHubspotPost(hubspotLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Logs the user in and returns the sessionId
   * @param {LoginRequest} loginRequest A JSON object containing the login and password.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLoginPost(
    loginRequest: LoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authLoginPost(loginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Invokes the Workramp Instant Auth endpoint to generate a login URL for this user. See https://help.workramp.com/en/articles/6993556-setting-up-instant-auth-academies for more details.
   * @param {WorkrampLoginRequest} [workrampLoginRequest] A JSON object containing the optional destination path where the user should be redirected.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLoginWorkrampPost(
    workrampLoginRequest?: WorkrampLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authLoginWorkrampPost(workrampLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Logs the user out
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLogoutPost(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authLogoutPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Authenticates the token and updates the password of the user
   * @param {VerifyTokenRequest} verifyTokenRequest A JSON object containing the user email, token and new password.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authVerifyTokenPost(
    verifyTokenRequest: VerifyTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authVerifyTokenPost(verifyTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Checks if we have the google token for the user from the context
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public googleAuthGet(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .googleAuthGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Logs the user in using Google and returns the sessionId
   * @param {OAuthConnectionData} oAuthConnectionData A JSON object containing the googleIdToken.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public googleAuthPost(
    oAuthConnectionData: OAuthConnectionData,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .googleAuthPost(oAuthConnectionData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Begins the Google login flow for the user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public googleAuthStartPost(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .googleAuthStartPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user profile for the authenticated user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public meGet(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .meGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates an API Key for given user and scope, returns the key id and secret
     * @param {CreateAPIKeyRequest} createAPIKeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAPIKey: async (
      createAPIKeyRequest: CreateAPIKeyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAPIKeyRequest' is not null or undefined
      assertParamExists(
        'createAPIKey',
        'createAPIKeyRequest',
        createAPIKeyRequest,
      );
      const localVarPath = `/nirvana/v0/api_key`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAPIKeyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetches API key details via given key ID
     * @param {string} keyID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAPIKeyDetails: async (
      keyID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'keyID' is not null or undefined
      assertParamExists('getAPIKeyDetails', 'keyID', keyID);
      const localVarPath = `/nirvana/v0/api_key/{keyID}`.replace(
        `{${'keyID'}}`,
        encodeURIComponent(String(keyID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetches details of all API keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAPIKeys: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/nirvana/v0/api_keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates API key details like scope and status
     * @param {PatchAPIKeyRequest} [patchAPIKeyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAPIKey: async (
      patchAPIKeyRequest?: PatchAPIKeyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/nirvana/v0/api_key`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchAPIKeyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates an API Key for given user and scope, returns the key id and secret
     * @param {CreateAPIKeyRequest} createAPIKeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAPIKey(
      createAPIKeyRequest: CreateAPIKeyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateAPIKeyResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAPIKey(
        createAPIKeyRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetches API key details via given key ID
     * @param {string} keyID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAPIKeyDetails(
      keyID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAPIKeyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAPIKeyDetails(keyID, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetches details of all API keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAPIKeys(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<APIKeysWithAgency>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAPIKeys(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Updates API key details like scope and status
     * @param {PatchAPIKeyRequest} [patchAPIKeyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAPIKey(
      patchAPIKeyRequest?: PatchAPIKeyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAPIKey(
        patchAPIKeyRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     * Creates an API Key for given user and scope, returns the key id and secret
     * @param {CreateAPIKeyRequest} createAPIKeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAPIKey(
      createAPIKeyRequest: CreateAPIKeyRequest,
      options?: any,
    ): AxiosPromise<CreateAPIKeyResponse> {
      return localVarFp
        .createAPIKey(createAPIKeyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetches API key details via given key ID
     * @param {string} keyID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAPIKeyDetails(
      keyID: string,
      options?: any,
    ): AxiosPromise<GetAPIKeyResponse> {
      return localVarFp
        .getAPIKeyDetails(keyID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetches details of all API keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAPIKeys(options?: any): AxiosPromise<Array<APIKeysWithAgency>> {
      return localVarFp
        .getAllAPIKeys(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates API key details like scope and status
     * @param {PatchAPIKeyRequest} [patchAPIKeyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAPIKey(
      patchAPIKeyRequest?: PatchAPIKeyRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateAPIKey(patchAPIKeyRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * Creates an API Key for given user and scope, returns the key id and secret
   * @param {CreateAPIKeyRequest} createAPIKeyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createAPIKey(
    createAPIKeyRequest: CreateAPIKeyRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .createAPIKey(createAPIKeyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetches API key details via given key ID
   * @param {string} keyID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAPIKeyDetails(keyID: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getAPIKeyDetails(keyID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetches details of all API keys
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAllAPIKeys(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getAllAPIKeys(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates API key details like scope and status
   * @param {PatchAPIKeyRequest} [patchAPIKeyRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateAPIKey(
    patchAPIKeyRequest?: PatchAPIKeyRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .updateAPIKey(patchAPIKeyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
