import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { ITheme } from '../../assets/themes';

const useStyles = makeStyles((theme: ITheme) => ({
  rowDivider: {
    '&> td, &> th': {
      borderColor: theme.palette.divider,
    },
  },
  tableRow: {
    '&> th': {
      ...theme.typography.body2,
      backgroundColor: theme.palette.primary.extraLight,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1, 2),

      '&:first-child': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
      '&:last-child': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
    },
    '&> td': {
      ...theme.typography.body2,
      color: theme.palette.text.primary,
      padding: theme.spacing(2.25, 2),
      verticalAlign: 'top',
    },
  },
}));

export interface TableViewColumn {
  key?: string;
  align?: 'left' | 'right' | 'center';
  content: React.ReactNode;
  styles?: React.CSSProperties;
}

export interface TableViewRow {
  key?: string;
  columns: Array<TableViewColumn>;
  subTable?: ITableViewProps;
}

export interface ITableViewProps {
  headers?: Array<TableViewColumn>;
  rows: Array<TableViewRow>;
  styles?: React.CSSProperties;
}

const renderCell = (cell: TableViewColumn, cellIndex: number) => {
  const cellKey = cell.key || `row-cell-${cellIndex}`;
  return (
    <TableCell key={cellKey} align={cell.align} style={cell.styles}>
      {cell.content}
    </TableCell>
  );
};

const renderHeaderCell = (cell: TableViewColumn, cellIndex: number) => {
  const cellKey = cell.key || `header-cell-${cellIndex}`;
  return (
    <TableCell key={cellKey} align={cell.align} style={cell.styles}>
      {cell.content}
    </TableCell>
  );
};

/**
 * Table component that renders a table using Material UI components.
 * Refer: https://next.material-ui.com/components/tables for usage and props
 * @component
 *
 * @param {Object} props
 * @param {Object[]} props.headers - List of header columns (Refer: TableViewColumn for details).
 * @param {Object[]} props.rows - List of rows to be renderd (Refer: TableViewRow for details).
 *
 * @example
 * const headers = [{
 *   key: 'radius',
 *   content: 'Radius'
 * }, {
 *   key: 'percentage',
 *   content: 'Percentage'
 * }];
 * const rows = [{
 *    content: '100mi'
 * }, {
 *    content: '50%'
 * }];
 * return <TableView headers={headers} rows={rows} />
 */
const TableSpaced = ({ headers, rows, styles }: ITableViewProps) => {
  const classes = useStyles();

  return (
    <Table size="small" style={styles}>
      <TableHead>
        <TableRow className={clsx(classes.rowDivider, classes.tableRow)}>
          {headers?.map(renderHeaderCell)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row: TableViewRow, index) => (
          <>
            <TableRow
              key={index}
              className={clsx(classes.rowDivider, classes.tableRow)}
            >
              {row.columns.map(renderCell)}
            </TableRow>
          </>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableSpaced;
