import MuiSwitch, { SwitchProps } from '@material-ui/core/Switch';
import { styled } from '@material-ui/core/styles';
import { ITheme } from '../../assets/themes';

type StyledToggle = {
  theme: ITheme;
  size?: 'small' | 'medium';
  backgroundTrackColor?: string; // default is error.main to make backward compatible
};

/**
 * Custom Toggle component, which is an extension of Material UI Switch
 * Refer: https://mui.com/components/switches/ for usage and props
 */
const Toggle = styled((props: SwitchProps) => <MuiSwitch {...props} />)(
  ({ theme, size = 'medium', backgroundTrackColor }: StyledToggle) => ({
    width: size === 'small' ? 28 : 48,
    height: size === 'small' ? 16 : 24,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: size === 'small' ? 15 : 24,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: `translateX(${size === 'small' ? '9' : '20'}px)`,
      },
    },
    '& .MuiSwitch-input': {
      '&:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,

      '&.Mui-checked': {
        transform: `translateX(${size === 'small' ? '12' : '24'}px)`,
        color: '#fff',
        '& +.MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.success.main,
        },
      },
      '&.Mui-disabled': {
        '& +.MuiSwitch-track': {
          opacity: 0.8,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: size === 'small' ? 12 : 20,
      height: size === 'small' ? 12 : 20,
      borderRadius: size === 'small' ? 6 : 10,
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      transition: theme.transitions.create(['width'], { duration: 200 }),
    },
    '& .MuiSwitch-track': {
      borderRadius: size === 'small' ? 16 / 2 : 24 / 2,
      opacity: 1,
      backgroundColor: backgroundTrackColor || theme.palette.error.main,
      boxSizing: 'border-box',
    },
  }),
);

export default Toggle;
