import * as React from 'react';
import clsx from 'clsx';
import {
  Step as MuiStep,
  StepIconProps,
  StepLabel,
  makeStyles,
  withStyles,
  alpha,
  Box,
  Typography,
} from '@material-ui/core';
import MuiStepper, { StepperProps } from '@material-ui/core/Stepper';
import StepConnector, {
  stepConnectorClasses,
} from '@material-ui/core/StepConnector';
import DoneIcon from '@material-ui/icons/Done';

import { ITheme } from '../../assets/themes';

const useIconStyles = makeStyles((theme: ITheme) => ({
  icon: {
    height: theme.typography.pxToRem(10),
    width: theme.typography.pxToRem(10),
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '50%',
    backgroundColor: '#F7F8FC',
    overflow: 'hidden',
    zIndex: 1,
  },
  iconActive: {
    height: theme.typography.pxToRem(20),
    width: theme.typography.pxToRem(20),
    backgroundColor: theme.palette.gold.main,
    border: `4px solid ${theme.palette.gold.light}`,
    overflow: 'hidden',
  },
  iconCompleted: {
    height: theme.typography.pxToRem(14),
    width: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.success.main,
    border: `2px solid ${theme.palette.success.main}`,
    color: theme.palette.common.white,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconError: {
    height: theme.typography.pxToRem(20),
    width: theme.typography.pxToRem(20),
    backgroundColor: theme.palette.error.main,
    border: '4px solid #FCD4DC',
    overflow: 'hidden',
  },
  doneIcon: {
    fontSize: theme.typography.pxToRem(10),
  },
}));

function MuiStepIcon(props: StepIconProps) {
  const classes = useIconStyles();
  const { active, completed, error } = props;

  return (
    <div
      className={clsx(classes.icon, {
        [classes.iconActive]: active,
        [classes.iconCompleted]: completed,
        [classes.iconError]: error && active,
      })}
    >
      {completed ? <DoneIcon className={classes.doneIcon} /> : null}
    </div>
  );
}

const MuiStepperConnector = withStyles(({ palette, typography }) => ({
  root: {
    top: typography.pxToRem(9),
    left: 'calc(-50% + 5px)',
    right: 'calc(50% + 5px)',

    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: palette.success.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: palette.success.main,
      },
    },
  },
  stepLabel: {
    padding: 0,
  },
  line: {
    borderTopStyle: 'dotted',
    borderTopWidth: 2,
    borderTopColor: alpha(palette.primary.light, 0.3),
    width: 'auto',
  },
}))(StepConnector);

const Step = withStyles(() => ({
  root: {},
}))(MuiStep);

const MuiStepLabel = withStyles(({ palette, typography }) => ({
  root: {
    padding: 0,
    '&.Mui-disabled': {},
  },
  label: {
    color: palette.grey[400],
    display: 'block',
    fontWeight: typography.fontWeightRegular,
    lineHeight: typography.pxToRem(24),

    '& button': {
      display: 'none',
    },

    '&.MuiStepLabel-completed': {
      color: palette.grey[400],
      fontWeight: typography.fontWeightRegular,
    },

    '&.Mui-active': {
      color: palette.secondary.main,
      fontWeight: typography.fontWeightMedium,

      '& .time': {
        color: palette.text.secondary,
      },

      '& button': {
        display: 'inline-flex',
      },
    },
  },
  iconContainer: {
    height: typography.pxToRem(20),
    width: typography.pxToRem(20),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))(StepLabel);

interface IStep {
  key: string | number;
  label: React.ReactNode;
  extra?: () => React.ReactNode;
  action?: React.ReactNode;
}

export interface IStepperProps
  extends Omit<StepperProps, 'backButton' | 'nextButton' | 'onChange'> {
  steps: Array<IStep>;
  activeStep: number;
  onChange?: (index: number) => void;
  error?: boolean;
  dataTestId?: string;
}

/**
 * The horizontal stepper UI component, which is an extension of Mui Stepper, styled to our theme.
 * This stepper supports a label on both sides of the step icon, as compared to vertical stepper
 * that supports a label only on one side.
 * Refer: https://next.material-ui.com/components/steppers for usage and props
 * @component
 *
 * @param {Object} props
 * @param {string[]} props.steps - List of steps to be displayed.
 * @param {Object} props.activeStep - Zero based index of currently active step.
 * @param {Object} [props.onChange] - Callback invoked on step change.
 *
 * @example
 * return <Stepper steps={['Start', 'End']} activeStep={0} />
 */
const HorizontalStepper = ({
  steps = [],
  activeStep = 0,
  onChange = () => null,
  error = false,
  dataTestId,
}: IStepperProps) => {
  return (
    <MuiStepper
      data-testid={dataTestId}
      activeStep={activeStep}
      alternativeLabel
      connector={<MuiStepperConnector />}
    >
      {steps.map((step: IStep, index: number) => {
        return (
          <Step key={step.key} onClick={() => onChange(index)}>
            <MuiStepLabel
              StepIconComponent={MuiStepIcon}
              StepIconProps={{ error }}
            >
              <Box position="relative">
                <Typography
                  variant="caption"
                  fontWeight="fontWeightRegular"
                  className="time"
                  sx={{
                    position: 'absolute',
                    top: '-55px',
                    left: '50%',
                    transform: 'translate(-50%, -100%)',
                    width: '90%',
                    textAlign: 'center',
                  }}
                >
                  {!!step.extra && step.extra()}
                </Typography>
                <Box mb="12px">{step.label}</Box>
                {step.action}
              </Box>
            </MuiStepLabel>
          </Step>
        );
      })}
    </MuiStepper>
  );
};

export default HorizontalStepper;
