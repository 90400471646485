import type { ReactNode } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import clsx from 'clsx';

type SortableHeaderProps = {
  column: {
    label: ReactNode;
    disableSortBy?: boolean;
    isSorted?: boolean;
    isSortedDesc?: boolean;
  };
};

export default function SortableHeader({
  column: { label, disableSortBy, isSorted, isSortedDesc },
}: SortableHeaderProps) {
  return (
    <div className="flex items-center">
      <span>{label}</span>
      {!disableSortBy ? (
        <span
          className={clsx('inline-flex flex-col justify-center ml-2 gap-y-1', {
            'text-primary-tint3': isSorted,
          })}
        >
          <HiChevronUp
            className={clsx({ 'text-primary-dark': !isSortedDesc })}
          />
          <HiChevronDown
            className={clsx('-mt-2', { 'text-primary-dark': isSortedDesc })}
          />
        </span>
      ) : null}
    </div>
  );
}
