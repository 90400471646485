export function getPieChartData(score: number) {
  if (score <= 25) {
    return [
      { score, fill: '#25B24A' },
      { score: 25 - score, fill: '#d1d5db' },
      { score: 25, fill: '#d1d5db' },
      { score: 25, fill: '#d1d5db' },
      { score: 25, fill: '#d1d5db' },
    ];
  } else if (score > 25 && score <= 50) {
    return [
      { score: 25, fill: '#25B24A' },
      { score: score - 25, fill: '#82D01E' },
      { score: 50 - score, fill: '#d1d5db' },
      { score: 25, fill: '#d1d5db' },
      { score: 25, fill: '#d1d5db' },
    ];
  } else if (score > 50 && score <= 75) {
    return [
      { score: 25, fill: '#25B24A' },
      { score: 25, fill: '#82D01E' },
      { score: score - 50, fill: '#FF9900' },
      { score: 75 - score, fill: '#d1d5db' },
      { score: 25, fill: '#d1d5db' },
    ];
  } else if (score > 75) {
    return [
      { score: 25, fill: '#25B24A' },
      { score: 25, fill: '#82D01E' },
      { score: 25, fill: '#FF9900' },
      { score: score - 75, fill: '#FA3252' },
      { score: 100 - score, fill: '#d1d5db' },
    ];
  }
}
