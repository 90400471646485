export type ViolationScoreRecord = {
  range: string;
  value: number;
  percentage: number;
  color: string;
};

export function getViolationScore(
  data: Array<{ violationSeverityWeight?: any }>,
) {
  const violationsScore = [
    { range: '0 - 10', value: 0, percentage: 0, color: '#25B24A' },
    { range: '11 - 20', value: 0, percentage: 0, color: '#FFB900' },
    { range: '21 - 30', value: 0, percentage: 0, color: '#FF823C' },
    { range: '30+', value: 0, percentage: 0, color: '#FA3252' },
    { range: 'N/A', value: 0, percentage: 0, color: '#6B7280' },
  ];

  data?.forEach(({ violationSeverityWeight }) => {
    if (violationSeverityWeight == null) {
      violationsScore[4].value += 1;
    } else if (violationSeverityWeight <= 10) {
      violationsScore[0].value += 1;
    } else if (violationSeverityWeight <= 20) {
      violationsScore[1].value += 1;
    } else if (violationSeverityWeight <= 30) {
      violationsScore[2].value += 1;
    } else {
      violationsScore[3].value += 1;
    }
  });

  function getPercentage(value: number) {
    return Math.round((value * 100) / data.length);
  }

  return violationsScore.map((record) => ({
    ...record,
    percentage: getPercentage(record.value),
  }));
}
