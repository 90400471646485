import * as React from 'react';
import { Show } from '@nirvana/ui-kit';

export default function CustomTooltip(props: any) {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <div className="p-3 space-y-1 text-xs rounded-md bg-text-primary text-primary-tint1">
        <Show when={payload[0].value !== 0}>
          <p>Current Score: {payload[0].value}</p>
        </Show>
        <p>Threshold: {payload[0].payload.threshold}</p>
        <Show
          when={
            payload?.[0]?.payload?.measure && !isNaN(payload[0].payload.measure)
          }
        >
          <p>Measure: {payload?.[0]?.payload?.measure}</p>
        </Show>
      </div>
    );
  }

  return null;
}
