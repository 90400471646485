import { Factory } from 'fishery';
import { faker } from '@faker-js/faker';
import { subDays, subMonths, subYears } from 'date-fns';
import {
  Category,
  Driver,
  Inspection,
  Vehicle,
  Violation,
} from '../../../src/types/graphql-types';

const violationData: number[] = Array.from({ length: 75 }, () =>
  faker.datatype.number({ min: 0, max: 5 }),
);

type ViolationTypes = {
  category: Category;
  code: string;
  description: string;
  humanReadable: string;
  name: string;
  humanReadableCode: string;
  severityWeight: number;
};

const violationTypes: ViolationTypes[] = [
  {
    category: Category.VehicleMaintenance,
    code: '39345dluv',
    description: 'Brake Connections with Leaks Under Vehicle',
    humanReadable: 'Brakes, All Others',
    name: 'BrakesAllOthers',
    humanReadableCode: '393.45DLUV',
    severityWeight: 4,
  },
  {
    category: Category.VehicleMaintenance,
    code: '39355e',
    description:
      'No or Defective ABS Malfunction Indicator Lamp for trailer manufactured after 03/01/1998',
    humanReadable: 'Brakes, All Others',
    name: 'BrakesAllOthers',
    humanReadableCode: '393.55(e)',
    severityWeight: 4,
  },
  {
    category: Category.UnsafeDriving,
    code: '3922lv',
    description: 'Lane Restriction Violation',
    humanReadable: 'Misc Violations',
    name: 'MiscViolations',
    humanReadableCode: '392.2LV',
    severityWeight: 3,
  },
  {
    category: Category.HosCompliance,
    code: '39530b1',
    description:
      'Driver failed to certify the accuracy of the information gathered by the ELD',
    humanReadable: 'Other Log/From \u0026 Manner',
    name: 'OtherLogFormManner',
    humanReadableCode: '395.30B1',
    severityWeight: 1,
  },
  {
    category: Category.HosCompliance,
    code: '3958e',
    description: 'False report of drivers record of duty status',
    humanReadable: 'False Log',
    name: 'FalseLog',
    humanReadableCode: '395.8(e)',
    severityWeight: 7,
  },
  {
    category: Category.VehicleMaintenance,
    code: '39530b1',
    description: 'Tire-flat and/or audible air leak',
    humanReadable: 'Tires',
    name: 'Tires',
    humanReadableCode: '393.75(a)(3)',
    severityWeight: 8,
  },
];

export const violationsFactory = Factory.define<Violation[]>(() => {
  return violationData.map((data, index) => {
    return {
      __typename: 'violation',
      category: violationTypes[data].category,
      code: violationTypes[data].code,
      description: violationTypes[data].description,
      group: {
        __typename: 'violationGroup',
        category: violationTypes[data].category,
        humanReadable: violationTypes[data].humanReadable,
        name: violationTypes[data].name,
        severity: violationTypes[data].severityWeight,
      },
      humanReadableCode: violationTypes[data].humanReadableCode,
      isDSMS: true,
      oosIndicator: false,
      oosWeight: 0,
      publishedDate: '2024-09-27T00:00:00Z',
      rowID: index.toString(),
      severityWeight: violationTypes[data].severityWeight,
      timeWeight: 1,
      totalSeverityWeight: 0,
      violationID: index,
    };
  });
});

const violations = violationsFactory.build();

const counties = [
  ['WI081', 'Monroe county, WI'],
  ['CT009', 'New Haven county, CT'],
  ['NV007', 'Elko county, NV'],
  ['CO077', 'Mes county, CO'],
  ['IL167', 'Sangamon county, IL'],
  ['NE049', 'Deuel county, NE'],
  ['FL005', 'Bay county, FL'],
  ['CO073', 'Lincoln county, CO'],
  ['MT093', 'Silver Bow county, MT'],
  ['IN097', 'Marion county, IN'],
  ['UT043', 'Summit county, UT'],
  ['UT003', 'Box Elder county, UT'],
  ['OK071', 'Kay county, OK'],
  ['CA071', 'San Bernardino county, CA'],
  ['TN093', 'Knox county, TN'],
  ['MT051', 'Liberty county, MT'],
  ['WA063', 'Spokane county, WA'],
  ['MT041', 'Hill county, MT'],
  ['SD109', 'Roberts county, SD'],
  ['MI115', 'Monroe county, MI'],
  ['NM045', 'San Juan county, NM'],
  ['AZ015', 'Mohave county, AZ'],
  ['TN075', 'Haywood county, TN'],
  ['MT111', 'Yellowstone county, MT'],
  ['TN077', 'Henderson county, TN'],
];

type InspectionData = {
  county: number;
  subDate: [number, number, number];
  violations: Violation[];
};

const inspectionData: InspectionData[] = [
  {
    county: 0,
    subDate: [0, 0, 1],
    violations: [violations[0], violations[1]],
  },
  {
    county: 1,
    subDate: [0, 1, 3],
    violations: [violations[2], violations[3]],
  },
  {
    county: 2,
    subDate: [0, 2, 12],
    violations: [violations[4]],
  },
  {
    county: 3,
    subDate: [0, 3, 18],
    violations: [violations[5], violations[6]],
  },
  {
    county: 4,
    subDate: [0, 4, 2],
    violations: [violations[7], violations[8], violations[9]],
  },
  {
    county: 5,
    subDate: [0, 5, 27],
    violations: [
      violations[10],
      violations[11],
      violations[12],
      violations[13],
    ],
  },
  {
    county: 6,
    subDate: [0, 6, 25],
    violations: [
      violations[14],
      violations[15],
      violations[16],
      violations[17],
      violations[18],
      violations[19],
    ],
  },
  {
    county: 7,
    subDate: [0, 7, 2],
    violations: [violations[20]],
  },
  {
    county: 8,
    subDate: [0, 7, 12],
    violations: [violations[21]],
  },
  {
    county: 9,
    subDate: [0, 7, 30],
    violations: [violations[22], violations[23]],
  },
  {
    county: 10,
    subDate: [0, 8, 29],
    violations: [
      violations[24],
      violations[25],
      violations[26],
      violations[27],
      violations[28],
      violations[29],
      violations[30],
      violations[31],
      violations[32],
      violations[33],
      violations[34],
      violations[35],
    ],
  },
  {
    county: 11,
    subDate: [0, 9, 6],
    violations: [
      violations[36],
      violations[37],
      violations[38],
      violations[39],
      violations[40],
    ],
  },
  {
    county: 12,
    subDate: [0, 9, 18],
    violations: [
      violations[40],
      violations[41],
      violations[42],
      violations[43],
      violations[44],
      violations[45],
    ],
  },
  {
    county: 13,
    subDate: [0, 9, 30],
    violations: [violations[46], violations[47], violations[48]],
  },
  {
    county: 14,
    subDate: [0, 11, 5],
    violations: [
      violations[49],
      violations[50],
      violations[51],
      violations[52],
      violations[53],
      violations[54],
    ],
  },
  {
    county: 15,
    subDate: [1, 0, 19],
    violations: [violations[55], violations[56], violations[57]],
  },
  {
    county: 16,
    subDate: [1, 1, 22],
    violations: [violations[58]],
  },
  {
    county: 13,
    subDate: [1, 1, 29],
    violations: [violations[59]],
  },
  {
    county: 13,
    subDate: [1, 3, 25],
    violations: [violations[60]],
  },
  {
    county: 17,
    subDate: [1, 3, 29],
    violations: [violations[61]],
  },
  {
    county: 18,
    subDate: [1, 4, 3],
    violations: [violations[62]],
  },
  {
    county: 19,
    subDate: [1, 5, 12],
    violations: [violations[63], violations[64]],
  },
  {
    county: 20,
    subDate: [1, 6, 18],
    violations: [violations[65]],
  },
  {
    county: 21,
    subDate: [1, 7, 2],
    violations: [violations[66], violations[67], violations[68]],
  },
  {
    county: 22,
    subDate: [1, 8, 30],
    violations: [violations[69], violations[70]],
  },
  {
    county: 23,
    subDate: [1, 9, 23],
    violations: [violations[71]],
  },
  {
    county: 24,
    subDate: [1, 11, 12],
    violations: [violations[72], violations[73], violations[74]],
  },
];

type InspectionTransientParams = {
  addDrivers: boolean;
};

type DriverAndVehicles = {
  driver: Driver | null;
  vehicles: Vehicle[];
};

const driversAndVehicles: DriverAndVehicles[] = [
  {
    driver: {
      id: '1',
      name: 'John Doe',
      licenseNumber: '1234',
      licenseState: 'CA',
    },
    vehicles: [
      {
        __typename: 'vehicle',
        vin: 'xx4244523063673xx',
        company: 'Nirvana Trucking',
        make: 'Honda',
        model: 'CRV',
      },
      {
        __typename: 'vehicle',
        vin: 'xx5244523063673xx',
        company: 'Nirvana Trucking',
        make: 'Honda',
        model: 'CRV',
      },
    ],
  },
  {
    driver: {
      id: '2',
      name: 'Jane Smith',
      licenseNumber: '1234',
      licenseState: 'WI',
    },
    vehicles: [
      {
        __typename: 'vehicle',
        vin: 'xx6244523063673xx',
        company: 'Nirvana Trucking',
        make: 'Honda',
        model: 'CRV',
      },
      {
        __typename: 'vehicle',
        vin: 'xx7244523063673xx',
        company: 'Nirvana Trucking',
        make: 'Honda',
        model: 'CRV',
      },
    ],
  },
  {
    driver: {
      id: '3',
      name: 'Alice Johnson',
      licenseNumber: '1234',
      licenseState: 'TX',
    },
    vehicles: [
      {
        __typename: 'vehicle',
        vin: 'xx8244523063673xx',
        company: 'Nirvana Trucking',
        make: 'Honda',
        model: 'CRV',
      },
      {
        __typename: 'vehicle',
        vin: 'xx9244523063673xx',
        company: 'Nirvana Trucking',
        make: 'Honda',
        model: 'CRV',
      },
    ],
  },
  {
    driver: {
      id: '4',
      name: 'Bob Brown',
      licenseNumber: '1234',
      licenseState: 'FL',
    },
    vehicles: [
      {
        __typename: 'vehicle',
        vin: 'xx1244523063673xx',
        company: 'Nirvana Trucking',
        make: 'Honda',
        model: 'CRV',
      },
      {
        __typename: 'vehicle',
        vin: 'xx2244523063673xx',
        company: 'Nirvana Trucking',
        make: 'Honda',
        model: 'CRV',
      },
    ],
  },
];

export const inspectionsFactory = Factory.define<
  Partial<Inspection>[],
  InspectionTransientParams
>(({ transientParams }) => {
  const currentDate = new Date();
  return inspectionData.map((data, index) => {
    let inspectionDate = currentDate;
    inspectionDate = subYears(inspectionDate, data.subDate[0]);
    inspectionDate = subMonths(inspectionDate, data.subDate[1]);
    inspectionDate = subDays(inspectionDate, data.subDate[2]);
    if (inspectionDate > subMonths(currentDate, 6)) {
      data.violations.forEach((violation) => {
        violation.timeWeight = 3;
      });
    } else if (inspectionDate > subMonths(currentDate, 12)) {
      data.violations.forEach((violation) => {
        violation.timeWeight = 2;
      });
    }

    const driver = Math.floor(Math.random() * 4);
    return {
      __typename: 'inspection',
      dotNumber: 123456,
      driver: transientParams.addDrivers
        ? driversAndVehicles[driver].driver
        : null,
      rowID: index.toString(),
      countyCode: counties[data.county][0],
      countyName: counties[data.county][1],
      location: '9804',
      publicVINs: driversAndVehicles[driver].vehicles.map(
        (vehicle) => vehicle.vin,
      ),
      inspectionDate: inspectionDate.toISOString(),
      inspectionID: index,
      vehicles: driversAndVehicles[driver].vehicles,
      violations: data.violations,
    };
  });
});
