import { useCallback } from 'react';
import bbox from '@turf/bbox';
import { FeatureCollection } from 'geojson';
import type { MapRef } from 'react-map-gl';

// We are using callback ref rather than directly using useRef hook
// Reason is to excute the logic when a ref changes, which is not possible with useRef + useEffect
// Refer this example from official React Document: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
export default function useMapRef(geojson?: FeatureCollection) {
  return useCallback(
    (node: MapRef | null) => {
      if (node && geojson?.features.length) {
        const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
        node.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 40, duration: 1000 },
        );
      }
    },
    [geojson],
  );
}
