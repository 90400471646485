import { Column } from 'react-table';

type ShipperData = {
  shipperName?: string | null;
  inspectionsCount?: number | null;
};

export const shipperColumns: Array<Column<ShipperData> & { label: string }> = [
  { label: 'Shipper', accessor: 'shipperName', disableSortBy: true },
  { label: '# of Inspections', accessor: 'inspectionsCount' },
];
