import { Tooltip } from '@nirvana/ui-kit';
import { ReactNode } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { formatNumber } from 'src/helpers';

export type TabKeys = 'pU' | 'mileage' | 'utilization';

export const tabs: Array<{ label: string; value: TabKeys; icon?: ReactNode }> =
  [
    { label: 'Power unit', value: 'pU' },
    { label: 'Mileage', value: 'mileage' },
    {
      label: 'Utilization',
      value: 'utilization',
      icon: (
        <Tooltip title="Average mileage per unit">
          <span className="ml-2">
            <HiOutlineInformationCircle />
          </span>
        </Tooltip>
      ),
    },
  ];

const lineChartNames = {
  pU: 'Power Unit',
  mileage: 'Mileage',
  utilization: 'Utilization',
};

export const getLineChartProps = (selectedTab: TabKeys) => ({
  xField: 'date',
  yField: [selectedTab],
  margin: { top: 15, right: 20, left: 20 },
  yAxis: { fontSize: '12px', tickFormatter: (v: number) => formatNumber(v) },
  xAxis: { fontSize: '12px', label: { value: 'Months' } },
  lineConfig: [{ name: lineChartNames[selectedTab] }],
  tooltipProps: { formatter: (v: number) => formatNumber(v) },
});
