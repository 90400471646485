import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import {
  LoginRequest,
  LoginRequestSourceEnum,
  UTMTags,
} from '@nirvana/api/auth';
import { EMAIL_REGEX, InputPassword, InputWithLabel } from '@nirvana/ui-kit';

import { analytics } from 'src/helpers';
import useAuth from 'src/hooks/useAuth';

import { login } from '../../queries';

const useStyles = makeStyles((theme: Theme) => ({
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  formControlButton: {
    marginTop: theme.spacing(6),
  },
  forgotPasswordGrid: {
    paddingTop: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
}));

/**
 * Login form that accepts username and password and submits to the API.
 * Corresponds to Login (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A12200&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginRequest>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { login: setLoginContext } = useAuth();
  const cookies = document.cookie?.split(';');
  const utmCookies = cookies
    ?.filter((cookie) => {
      return cookie.includes('utm_');
    })
    .map((cookie) => cookie.trim());

  const { mutate } = useMutation(login, {
    onMutate: () => {
      setError(false);
      setLoading(true);
    },
    onSuccess: (data) => {
      setLoginContext(data.sessionId).then(() => {
        // Clear cookies from utmParams
        utmCookies.forEach((cookie) => {
          const [key] = cookie?.split('=');
          document.cookie = `${key}=; Domain=nirvanatech.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        });

        const from = location.state?.from ?? '/search';
        navigate(from, { replace: true });
      });
      setLoading(false);
    },
    onError: () => {
      setError(true);
      setLoading(false);
    },
  });

  const onSubmit = (data: LoginRequest) => {
    analytics.trackEvent({
      event: analytics.SegmentEventTrack.Login,
      properties: {
        email: data.email,
      },
    });

    const apiData = { ...data };

    // Get cookies from utmParams
    if (document.cookie) {
      // Add utmParams to data
      if (utmCookies?.length) {
        apiData.utmTags = {};
        apiData.source = LoginRequestSourceEnum.Safety;

        utmCookies?.forEach((cookie) => {
          const [key, value] = cookie?.split('=');
          if (key && apiData.utmTags) {
            apiData.utmTags[key as keyof UTMTags] = value;
          }
        });
      }
    }

    mutate(apiData);
  };

  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.LoginPageView,
    });
  }, []);

  return (
    <Grid container direction="column" spacing={5}>
      <Grid item>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h4"
          fontWeight="fontWeightBold"
        >
          Login
        </Typography>
        <FormHelperText error>
          {error ? 'Invalid credentials, please check and try again' : ' '}
        </FormHelperText>
      </Grid>
      <Grid item>
        <>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.loginForm}>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                pattern: EMAIL_REGEX,
              }}
              render={({ field: { onChange, value } }) => (
                <InputWithLabel
                  label="Email ID"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputLabelProps={{ color: 'primary' }}
                  placeholder="Email"
                  type="email"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  error={!!errors.email}
                />
              )}
            />

            <Controller
              name="password"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <InputPassword
                  label="Password"
                  formControlProps={{ fullWidth: true, className: 'mb-6' }}
                  placeholder="Password"
                  fullWidth
                  value={value}
                  onChange={({ target: { value } }) => onChange(value)}
                  error={!!errors.password}
                />
              )}
            />
            <FormControl className={classes.formControlButton} fullWidth>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={!isValid || loading}
                startIcon={
                  loading && (
                    <CircularProgress className="text-white" size={18} />
                  )
                }
              >
                {loading ? 'Signing in...' : 'Login'}
              </Button>
            </FormControl>
          </form>
        </>
        <Grid item className={classes.forgotPasswordGrid}>
          <NavLink to="/forgot-password">
            <Typography variant="caption">Forgot password?</Typography>
          </NavLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
