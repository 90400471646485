import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useResponsiveDrawer = () => {
  const location = useLocation();

  const [isClosing, setIsClosing] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  return {
    handleDrawerToggle,
    handleDrawerClose,
    handleDrawerTransitionEnd,
    mobileOpen,
  };
};
