import { getFormattedDate } from '@nirvana/ui-kit';
import { Column } from 'react-table';

export type InsuranceData = {
  carrier?: string | null;
  policyNumber?: string | null;
  types?: string | null;
  effectiveDateTo?: string | null;
  effectiveDateFrom?: string | null;
};

export const insuranceColumns: Array<
  Column<InsuranceData> & { label: string }
> = [
  { label: 'Carrier', accessor: 'carrier' },
  { label: 'Policy Number', accessor: 'policyNumber' },
  { label: 'Type', accessor: 'types' },
  {
    label: 'Effective date period',
    accessor: 'effectiveDateFrom',
    Cell: ({ row }) => {
      const { effectiveDateTo = '', effectiveDateFrom = '' } = row.original;

      return `${getFormattedDate(effectiveDateFrom ?? '', 'MM/dd/yyyy')} - ${
        effectiveDateTo
          ? getFormattedDate(effectiveDateTo, 'MM/dd/yyyy')
          : 'Present'
      }`;
    },
  },
];
