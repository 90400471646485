import * as React from 'react';
import {
  Bar,
  BarChart as ReBarChart,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import type { LabelListProps } from 'recharts';
import { AxisConfig, LegendConfig, TooltipConfig } from '../../types/charts';

/** Configuration for an individual Bar of BarChart */
type BarConfig = {
  /** Provide width of the bar */
  barSize?: number;
  /** Corner radius of the bar */
  radius?: number;
  /** fill color for the bar */
  fill?: string;
  /** Add labels on top of bars */
  label?: Object;
};

/** Bar Chart Properties */
export type BarChartProps = {
  /** Provide source data for the chart. The data source is a collection of objects, such as
   * `const data = [ { time: '1991', value: 20 }, { time: '1992', value: 10 } ]`
   */
  data: Array<any>;
  /** The name of the data field corresponding to the graph in the x-direction
   * For the above data, 'xField' will be 'time'
   */
  xField: string;
  /** The name of the data field corresponding to the graph in the y-direction
   * For the above data, 'yField' will be ['value']
   * You can add multiple values in array for multi-bar chart
   */
  yField: Array<string>;
  /** The layout of bars in the chart */
  layout?: 'vertical' | 'horizontal';
  /** The sizes of whitespace around the container */
  margin?: { top?: number; right?: number; bottom?: number; left?: number };
  /** Array of BarConfig to customize the individual bar in the chart */
  barConfig?: Array<BarConfig>;
  /** Method to get bar content based on bar data in the chart */
  getCellConfig?: (record: any) => BarConfig;
  /** Configuration for the x-axis */
  xAxis?: AxisConfig & { padding?: { left?: number; right?: number } };
  /** Configuration for the y-axis */
  yAxis?: AxisConfig & { padding?: { top?: number; bottom?: number } };
  tooltip?: TooltipConfig;
  /** Whether to show legend in the chart or not */
  legend?: LegendConfig;
  /** Props for rendering LabelLists along with Bars */
  labelList?: LabelListProps<any>[];
};

export default function BarChart({
  data,
  xField,
  yField,
  layout = 'horizontal',
  margin,
  barConfig,
  getCellConfig = () => ({}),
  xAxis,
  yAxis,
  tooltip,
  legend,
  labelList,
}: BarChartProps) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ReBarChart data={data} margin={{ ...margin }} layout={layout}>
        <XAxis
          dataKey={xField}
          tickLine={false}
          fill="#878B95"
          {...xAxis}
          label={{
            position: 'bottom',
            fill: '#3350A1',
            fontSize: '12px',
            ...xAxis?.label,
          }}
        />
        <YAxis
          fill="#878B95"
          tickLine={false}
          {...yAxis}
          label={{
            angle: -90,
            position: 'insideLeft',
            fill: '#3350A1',
            offset: 10,
            fontSize: '12px',
            ...yAxis?.label,
          }}
        />
        {tooltip ? <Tooltip {...tooltip} /> : null}
        {legend ? <Legend wrapperStyle={{ bottom: 0 }} {...legend} /> : null}
        {yField.map((field, index) => (
          <Bar
            key={field}
            dataKey={field}
            barSize={30}
            radius={2}
            {...barConfig?.[index]}
          >
            {data.map((record) => (
              <Cell key={`cell-${field}`} {...getCellConfig(record)} />
            ))}
            {labelList?.map((props, idx) => (
              <LabelList key={idx} {...props} />
            ))}
          </Bar>
        ))}
      </ReBarChart>
    </ResponsiveContainer>
  );
}
