import * as React from 'react';
import { Helmet } from 'react-helmet';

export interface IPageProps {
  title: string;
}

/**
 * HOC for authenticated pages. Includes changing document title using react-helmet.
 * Refer: https://github.com/nfl/react-helmet for more details about react-helmet
 * @component
 *
 * @param {Object} props
 * @param {string} props.title - Title of document, forwarded to react-helmet.
 *
 * @example
 * return <PrivateLayout>Add some private content here.</PrivateLayout>
 */
const Page: React.FC<IPageProps> = ({ children, title = '' }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

export default Page;
