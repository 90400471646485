import { Show } from '@nirvana/ui-kit';
import clsx from 'clsx';

type AggregatedLineChartProps = {
  data: {
    value: number;
    color: string;
  }[];
  isLoading?: boolean;
};
/**
 * Displays a line chart where each value is displayed next to the other on the same line.
 **/
export const AggregatedLineChart = ({
  data,
  isLoading,
}: AggregatedLineChartProps) => {
  return (
    <div className="flex w-full mb-8 space-x-1">
      <Show
        when={!isLoading}
        fallback={
          <div className="w-full h-8 bg-gray-100 rounded animate-pulse" />
        }
      >
        {data.map((line) => (
          <div
            key={`${line.value}-${line.color}`}
            className={clsx(line.color, 'h-4 rounded relative')}
            style={{
              width: `${line.value ? `${line.value}%` : '4px'}`,
            }}
          >
            <Show when={line.value !== 0}>
              <div className="mt-4 min-w-12">
                <div className="flex flex-col items-center">
                  <div className="w-0.5 h-2 border" />
                  <div>{line.value} %</div>
                </div>
              </div>
            </Show>
          </div>
        ))}
      </Show>
    </div>
  );
};
