type NumberBoxProps = {
  value: number;
  label: string;
  loading?: boolean;
};
export const NumberBox = ({ value, label, loading }: NumberBoxProps) => {
  return (
    <div className="flex flex-col justify-between w-full p-4 text-center bg-white rounded-md shadow md:text-left">
      <span className="mb-2 text-text-hint">{label}</span>
      <div className="text-2xl font-bold">
        {loading ? (
          <div className="w-1/4 h-8 bg-gray-100 rounded animate-pulse" />
        ) : (
          value
        )}
      </div>
    </div>
  );
};
