import { GeoJsonProperties } from 'geojson';

type MapTooltipProps = {
  x: number;
  y: number;
  properties: GeoJsonProperties;
};

export default function MapTooltip({ x, y, properties }: MapTooltipProps) {
  return (
    <div
      className="absolute z-10 p-4 text-white translate-x-4 -translate-y-1/2 bg-gray-800 rounded-md"
      style={{ left: x, top: y }}
    >
      <div className="flex items-center mb-2">
        <div
          className="w-2 h-2 mr-2 rounded-full"
          style={{
            backgroundColor: properties?.fill,
          }}
        />
        <p className="font-semibold">{properties?.name} County</p>
      </div>
      <p className="mb-2">
        <span className="text-xs">Inspections Count: </span>
        <span className="font-medium">{properties?.inspection_count}</span>
      </p>
      <p>
        <span className="text-xs">Violations Count: </span>
        <span className="font-medium">{properties?.violation_count}</span>
      </p>

      <div className="absolute left-0 w-2 h-2 rotate-45 -translate-x-1/2 -translate-y-1/2 bg-gray-800 top-1/2" />
    </div>
  );
}
