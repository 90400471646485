import '@material-ui/lab/themeAugmentation';
import createMuiTheme, {
  Theme,
  ThemeOptions,
} from '@material-ui/core/styles/createMuiTheme';
import {
  Palette,
  PaletteColor,
  TypeText,
} from '@material-ui/core/styles/createPalette';
import { Typography } from '@material-ui/core/styles/createTypography';
import { light as lightTheme } from './light';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xxl: true;
  }
}

interface IPaletteColor extends PaletteColor {
  extraLight: string;
  tint1: string;
  tint2: string;
  tint3: string;
  track: string;
}

interface ITypeText extends TypeText {
  hint: string;
  lightGrey: string;
}
interface IPalette extends Palette {
  primary: IPaletteColor;
  secondary: IPaletteColor;
  success: IPaletteColor;
  error: IPaletteColor;
  warning: IPaletteColor;
  info: IPaletteColor;
  gold: IPaletteColor;
  tint: IPaletteColor;
  text: ITypeText;
  border: IPaletteColor;
  overlay: string;
}

interface ITypography extends Typography {
  fontWeightSemiBold: number;
}

export interface ITheme extends Theme {
  palette: IPalette;
  typography: ITypography;
}

interface IThemeOptions extends ThemeOptions {}

const ThemeLight: Theme = createMuiTheme({
  ...lightTheme,
} as IThemeOptions);

export const useLightTheme = () => ThemeLight;
export { lightTheme };
