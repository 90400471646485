/* tslint:disable */
/* eslint-disable */
/**
 * Nirvana Application API
 * Nirvana Application APIs
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface AboutResponse
 */
export interface AboutResponse {
  /**
   *
   * @type {string}
   * @memberof AboutResponse
   */
  version: string;
  /**
   *
   * @type {string}
   * @memberof AboutResponse
   */
  time: string;
}
/**
 *
 * @export
 * @interface AdditionalCommoditiyRecord
 */
export interface AdditionalCommoditiyRecord {
  /**
   *
   * @type {string}
   * @memberof AdditionalCommoditiyRecord
   */
  commodities: string;
  /**
   *
   * @type {number}
   * @memberof AdditionalCommoditiyRecord
   */
  percentageOfHauls: number;
}
/**
 *
 * @export
 * @interface AdditionalIncumbentInfo
 */
export interface AdditionalIncumbentInfo {
  /**
   *
   * @type {boolean}
   * @memberof AdditionalIncumbentInfo
   */
  isALIncumbent: boolean;
}
/**
 *
 * @export
 * @interface AdditionalInfoCoverageMetadata
 */
export interface AdditionalInfoCoverageMetadata {
  /**
   *
   * @type {AdditionalIncumbentInfo}
   * @memberof AdditionalInfoCoverageMetadata
   */
  additionalIncumbentInfo: AdditionalIncumbentInfo;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum AdditionalInformationCommodity {
  AddlInfoHazardousMaterialsInclClass9 = 'AddlInfoHazardousMaterialsInclClass9',
  AddlInfoLiftGateOrWhiteGloveService = 'AddlInfoLiftGateOrWhiteGloveService',
  AddlInfoResidentialDelivery = 'AddlInfoResidentialDelivery',
  AddlInfoDoubleOrTripleTrailers = 'AddlInfoDoubleOrTripleTrailers',
  AddlInfoMeatOnHook = 'AddlInfoMeatOnHook',
}

/**
 *
 * @export
 * @interface AdditionalInformationForm
 */
export interface AdditionalInformationForm {
  /**
   *
   * @type {number}
   * @memberof AdditionalInformationForm
   */
  numOwnerOperatorUnits?: number;
  /**
   *
   * @type {number}
   * @memberof AdditionalInformationForm
   */
  percentageOfSubhaul?: number;
  /**
   *
   * @type {Array<AdditionalInformationCommodity>}
   * @memberof AdditionalInformationForm
   */
  commodities?: Array<AdditionalInformationCommodity>;
  /**
   *
   * @type {string}
   * @memberof AdditionalInformationForm
   */
  commoditiesComment?: string;
  /**
   *
   * @type {DriverList}
   * @memberof AdditionalInformationForm
   */
  driverList?: DriverList;
  /**
   *
   * @type {Array<TargetPrice>}
   * @memberof AdditionalInformationForm
   */
  targetPrices?: Array<TargetPrice>;
  /**
   *
   * @type {Array<FileMetadata>}
   * @memberof AdditionalInformationForm
   */
  lossRunFiles?: Array<FileMetadata>;
  /**
   *
   * @type {string}
   * @memberof AdditionalInformationForm
   */
  largeLossComment?: string;
  /**
   *
   * @type {string}
   * @memberof AdditionalInformationForm
   */
  overallComment?: string;
  /**
   *
   * @type {AdditionalInfoCoverageMetadata}
   * @memberof AdditionalInformationForm
   */
  coverageMetadata?: AdditionalInfoCoverageMetadata;
}
/**
 *
 * @export
 * @interface Agency
 */
export interface Agency {
  /**
   *
   * @type {string}
   * @memberof Agency
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Agency
   */
  name: string;
}
/**
 *
 * @export
 * @interface AgencyBDMapping
 */
export interface AgencyBDMapping {
  /**
   *
   * @type {string}
   * @memberof AgencyBDMapping
   */
  id: string;
  /**
   *
   * @type {User}
   * @memberof AgencyBDMapping
   */
  user: User;
  /**
   *
   * @type {Agency}
   * @memberof AgencyBDMapping
   */
  agency: Agency;
  /**
   *
   * @type {ProgramType}
   * @memberof AgencyBDMapping
   */
  programType: ProgramType;
  /**
   *
   * @type {Array<User>}
   * @memberof AgencyBDMapping
   */
  userOptions: Array<User>;
}
/**
 *
 * @export
 * @interface AgencyBDMappingRequest
 */
export interface AgencyBDMappingRequest {
  /**
   *
   * @type {string}
   * @memberof AgencyBDMappingRequest
   */
  agencyId: string;
  /**
   *
   * @type {string}
   * @memberof AgencyBDMappingRequest
   */
  userId: string;
  /**
   *
   * @type {ProgramType}
   * @memberof AgencyBDMappingRequest
   */
  programType: ProgramType;
}
/**
 *
 * @export
 * @interface AgencyBDMappingsResponse
 */
export interface AgencyBDMappingsResponse {
  /**
   *
   * @type {Array<AgencyBDMapping>}
   * @memberof AgencyBDMappingsResponse
   */
  agencyBDMappings: Array<AgencyBDMapping>;
}
/**
 *
 * @export
 * @interface ApiKeyAuthConnData
 */
export interface ApiKeyAuthConnData {
  /**
   *
   * @type {string}
   * @memberof ApiKeyAuthConnData
   */
  apiKey: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum AppetiteCheckRule {
  AppetiteCheckRuleYearsInOperation = 'AppetiteCheckRuleYearsInOperation',
  AppetiteCheckRuleLapsesInOperation = 'AppetiteCheckRuleLapsesInOperation',
  AppetiteCheckRuleCarrierLoyalty = 'AppetiteCheckRuleCarrierLoyalty',
  AppetiteCheckRuleIsValidOrActive = 'AppetiteCheckRuleIsValidOrActive',
  AppetiteCheckRuleUnitMeasure = 'AppetiteCheckRuleUnitMeasure',
  AppetiteCheckRuleCompanyAddress = 'AppetiteCheckRuleCompanyAddress',
  AppetiteCheckRuleDotRating = 'AppetiteCheckRuleDotRating',
  AppetiteCheckRuleHasUndesiredOperations = 'AppetiteCheckRuleHasUndesiredOperations',
}

/**
 *
 * @export
 * @interface AppetiteCheckRuleResponse
 */
export interface AppetiteCheckRuleResponse {
  /**
   *
   * @type {AppetiteCheckRule}
   * @memberof AppetiteCheckRuleResponse
   */
  rule: AppetiteCheckRule;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof AppetiteCheckRuleResponse
   */
  details: { [key: string]: any };
}
/**
 *
 * @export
 * @interface AppetiteForm
 */
export interface AppetiteForm {
  /**
   *
   * @type {number}
   * @memberof AppetiteForm
   */
  dotNumber: number;
  /**
   *
   * @type {string}
   * @memberof AppetiteForm
   */
  companyName: string;
  /**
   *
   * @type {boolean}
   * @memberof AppetiteForm
   */
  hasUndesiredOperations: boolean;
  /**
   *
   * @type {string}
   * @memberof AppetiteForm
   */
  effectiveDate: string;
  /**
   *
   * @type {string}
   * @memberof AppetiteForm
   */
  producerId?: string;
  /**
   *
   * @type {number}
   * @memberof AppetiteForm
   */
  numPowerUnits: number;
  /**
   *
   * @type {string}
   * @memberof AppetiteForm
   */
  marketerId?: string;
}
/**
 *
 * @export
 * @interface ApplicationBasicInfo
 */
export interface ApplicationBasicInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfo
   */
  CompanyName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfo
   */
  EffectiveDateOfCoverage: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfo
   */
  ProducerID?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfo
   */
  CreatedBy: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfo
   */
  AgencyID: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfo
   */
  InsuredName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfo
   */
  InsuredEmail: string;
}
/**
 *
 * @export
 * @interface ApplicationBasicInfoForm
 */
export interface ApplicationBasicInfoForm {
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfoForm
   */
  CompanyName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfoForm
   */
  EffectiveDateOfCoverage?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfoForm
   */
  ProducerID?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfoForm
   */
  CreatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfoForm
   */
  AgencyID?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfoForm
   */
  InsuredName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasicInfoForm
   */
  InsuredEmail?: string;
}
/**
 *
 * @export
 * @interface ApplicationDetail
 */
export interface ApplicationDetail {
  /**
   *
   * @type {ApplicationSummary}
   * @memberof ApplicationDetail
   */
  summary: ApplicationSummary;
  /**
   *
   * @type {IndicationForm}
   * @memberof ApplicationDetail
   */
  indicationForm?: IndicationForm;
  /**
   *
   * @type {AdditionalInformationForm}
   * @memberof ApplicationDetail
   */
  additionalInfoForm?: AdditionalInformationForm;
  /**
   *
   * @type {IndicationOption}
   * @memberof ApplicationDetail
   */
  selectedIndication?: IndicationOption;
  /**
   *
   * @type {TelematicsInfo}
   * @memberof ApplicationDetail
   */
  telematicsInfo?: TelematicsInfo;
}
/**
 *
 * @export
 * @interface ApplicationListResponse
 */
export interface ApplicationListResponse {
  /**
   *
   * @type {number}
   * @memberof ApplicationListResponse
   */
  totalCount: number;
  /**
   *
   * @type {Array<ApplicationSummary>}
   * @memberof ApplicationListResponse
   */
  apps: Array<ApplicationSummary>;
  /**
   *
   * @type {string}
   * @memberof ApplicationListResponse
   */
  cursor?: string;
}
/**
 *
 * @export
 * @interface ApplicationPublicInfo
 */
export interface ApplicationPublicInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationPublicInfo
   */
  insuredName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPublicInfo
   */
  insuredEmail?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationPublicInfo
   */
  dotNumber: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationPublicInfo
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPublicInfo
   */
  agencyName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPublicInfo
   */
  producerName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPublicInfo
   */
  producerEmail: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ApplicationState {
  ApplicationStateCreated = 'ApplicationStateCreated',
  ApplicationStateInProgress = 'ApplicationStateInProgress',
  ApplicationStateUnderReviewForIndication = 'ApplicationStateUnderReviewForIndication',
  ApplicationStatePanic = 'ApplicationStatePanic',
  ApplicationStateIndicationDelay = 'ApplicationStateIndicationDelay',
  ApplicationStateIndicationGenerated = 'ApplicationStateIndicationGenerated',
  ApplicationStateIndicationSelected = 'ApplicationStateIndicationSelected',
  ApplicationStatePendingEldTelematics = 'ApplicationStatePendingELDTelematics',
  ApplicationStateProcessingEldTelematics = 'ApplicationStateProcessingELDTelematics',
  ApplicationStateUnderReviewForQuote = 'ApplicationStateUnderReviewForQuote',
  ApplicationStateQuoteGenerated = 'ApplicationStateQuoteGenerated',
  ApplicationStateDeclined = 'ApplicationStateDeclined',
  ApplicationStateBound = 'ApplicationStateBound',
  ApplicationStateApproved = 'ApplicationStateApproved',
  ApplicationStateClosed = 'ApplicationStateClosed',
}

/**
 *
 * @export
 * @interface ApplicationSummary
 */
export interface ApplicationSummary {
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  applicationID: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  shortID: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationSummary
   */
  dotNumber: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  companyName: string;
  /**
   *
   * @type {ApplicationState}
   * @memberof ApplicationSummary
   */
  state: ApplicationState;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  underwriterName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  underwriterEmail: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  bdName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  bdEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  producerName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  effectiveDate: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  agencyID: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  producerID?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationSummary
   */
  createdBy: string;
  /**
   *
   * @type {RenewalMetadata}
   * @memberof ApplicationSummary
   */
  renewalMetadata?: RenewalMetadata;
  /**
   *
   * @type {IndicationOptionTag}
   * @memberof ApplicationSummary
   */
  packageType?: IndicationOptionTag;
  /**
   *
   * @type {ApplicationSummaryTelematicsDataStatus}
   * @memberof ApplicationSummary
   */
  telematicsDataStatus?: ApplicationSummaryTelematicsDataStatus;
  /**
   *
   * @type {PotentialClearanceStatus}
   * @memberof ApplicationSummary
   */
  clearanceStatus?: PotentialClearanceStatus;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ApplicationSummaryTelematicsDataStatus {
  TelematicsDataStatusSuccess = 'TelematicsDataStatusSuccess',
  TelematicsDataStatusFailed = 'TelematicsDataStatusFailed',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ApplicationTab {
  ApplicationTabAll = 'ApplicationTabAll',
  ApplicationTabInProgress = 'ApplicationTabInProgress',
  ApplicationTabPendingEldTelematics = 'ApplicationTabPendingELDTelematics',
  ApplicationTabUnderUwReview = 'ApplicationTabUnderUWReview',
  ApplicationTabReadyToQuote = 'ApplicationTabReadyToQuote',
  ApplicationTabReadyToBind = 'ApplicationTabReadyToBind',
  ApplicationTabDeclined = 'ApplicationTabDeclined',
  ApplicationTabBound = 'ApplicationTabBound',
  ApplicationTabClosed = 'ApplicationTabClosed',
}

/**
 *
 * @export
 * @interface BasicAuthConnData
 */
export interface BasicAuthConnData {
  /**
   *
   * @type {string}
   * @memberof BasicAuthConnData
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof BasicAuthConnData
   */
  password: string;
}
/**
 *
 * @export
 * @interface BasicAuthWithLoginIdConnData
 */
export interface BasicAuthWithLoginIdConnData {
  /**
   *
   * @type {string}
   * @memberof BasicAuthWithLoginIdConnData
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof BasicAuthWithLoginIdConnData
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof BasicAuthWithLoginIdConnData
   */
  loginId: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BillingContactRole {
  Owner = 'Owner',
  BillingManager = 'BillingManager',
  Agent = 'Agent',
  Producer = 'Producer',
  Other = 'Other',
}

/**
 *
 * @export
 * @interface ClassesAndCommoditiesForm
 */
export interface ClassesAndCommoditiesForm {
  /**
   *
   * @type {Array<OperatingClassDistributionRecord>}
   * @memberof ClassesAndCommoditiesForm
   */
  operatingClassDistribution: Array<OperatingClassDistributionRecord>;
  /**
   *
   * @type {OperatingClass}
   * @memberof ClassesAndCommoditiesForm
   */
  primaryOperatingClass: OperatingClass;
  /**
   *
   * @type {PrimaryCommodityHauled}
   * @memberof ClassesAndCommoditiesForm
   */
  primaryCommodity?: PrimaryCommodityHauled;
  /**
   *
   * @type {CommodityCategory}
   * @memberof ClassesAndCommoditiesForm
   */
  primaryCategory?: CommodityCategory;
  /**
   *
   * @type {CommodityDistribution}
   * @memberof ClassesAndCommoditiesForm
   */
  commodityDistribution?: CommodityDistribution;
}
/**
 *
 * @export
 * @interface CommodityCategory
 */
export interface CommodityCategory {
  /**
   *
   * @type {CommodityCategoryEnum}
   * @memberof CommodityCategory
   */
  type: CommodityCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof CommodityCategory
   */
  label?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CommodityCategoryEnum {
  CommodityCategoryBeveragesAlcoholic = 'CommodityCategoryBeveragesAlcoholic',
  CommodityCategoryBeverageNonAlcoholic = 'CommodityCategoryBeverageNonAlcoholic',
  CommodityCategoryFoodsBakedDryPackagedCanned = 'CommodityCategoryFoodsBakedDryPackagedCanned',
  CommodityCategoryFoodsFreshProduceNondry = 'CommodityCategoryFoodsFreshProduceNondry',
  CommodityCategoryReeferMeatAndSeafood = 'CommodityCategoryReeferMeatAndSeafood',
  CommodityCategoryReeferNonMeat = 'CommodityCategoryReeferNonMeat',
  CommodityCategoryGeneralFreight = 'CommodityCategoryGeneralFreight',
  CommodityCategoryHouseholdGoods = 'CommodityCategoryHouseholdGoods',
  CommodityCategoryHouseholdHardware = 'CommodityCategoryHouseholdHardware',
  CommodityCategoryIntermodalContainerizedFreight = 'CommodityCategoryIntermodalContainerizedFreight',
  CommodityCategoryMailAndParcel = 'CommodityCategoryMailAndParcel',
  CommodityCategoryPaperAndPlasticProducts = 'CommodityCategoryPaperAndPlasticProducts',
  CommodityCategoryBuildingMaterialsAndLumber = 'CommodityCategoryBuildingMaterialsAndLumber',
  CommodityCategoryElectronicsFinished = 'CommodityCategoryElectronicsFinished',
  CommodityCategoryElectronicParts = 'CommodityCategoryElectronicParts',
  CommodityCategoryAutoPartsAndAccessories = 'CommodityCategoryAutoPartsAndAccessories',
  CommodityCategoryMetalsPrecious = 'CommodityCategoryMetalsPrecious',
  CommodityCategoryMetalsNonPrecious = 'CommodityCategoryMetalsNonPrecious',
  CommodityCategoryTextiles = 'CommodityCategoryTextiles',
  CommodityCategoryOther = 'CommodityCategoryOther',
}

/**
 *
 * @export
 * @interface CommodityDistribution
 */
export interface CommodityDistribution {
  /**
   *
   * @type {Array<WeightedCommodityRecord>}
   * @memberof CommodityDistribution
   */
  commodities: Array<WeightedCommodityRecord>;
  /**
   *
   * @type {AdditionalCommoditiyRecord}
   * @memberof CommodityDistribution
   */
  additionalCommodities?: AdditionalCommoditiyRecord;
}
/**
 * Only label is set as required field, because we accept free form text from agent
 * @export
 * @interface CommodityHauled
 */
export interface CommodityHauled {
  /**
   *
   * @type {CommodityHauledEnum}
   * @memberof CommodityHauled
   */
  type?: CommodityHauledEnum;
  /**
   *
   * @type {string}
   * @memberof CommodityHauled
   */
  label: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CommodityHauledEnum {
  CommodityHauledAsbestos = 'CommodityHauledAsbestos',
  CommodityHauledChipsOrCandyAndOtherSnackFoods = 'CommodityHauledChipsOrCandyAndOtherSnackFoods',
  CommodityHauledLogsLoggersWoodHarvestingPulp = 'CommodityHauledLogs_Loggers_WoodHarvesting_Pulp',
  CommodityHauledMobileModularHomes = 'CommodityHauledMobile_ModularHomes',
  CommodityHauledAllTerrainVehicles = 'CommodityHauledAllTerrainVehicles',
  CommodityHauledBoats25feetandOverInLengthNewOrUsed = 'CommodityHauledBoats25feetandOverInLength_NewOrUsed',
  CommodityHauledCoalDustPowderTitaniumDioxide = 'CommodityHauledCoalDust_Powder_TitaniumDioxide',
  CommodityHauledFreshFoodsProduceVegetablesFruit = 'CommodityHauledFreshFoods_Produce_Vegetables_Fruit',
  CommodityHauledHouseholdHardwareFastenersKeysLocksHingesChains = 'CommodityHauledHouseholdHardware_Fasteners_Keys_Locks_Hinges_Chains',
  CommodityHauledMilk = 'CommodityHauledMilk',
  CommodityHauledShellfishMollusksCrustaceansFreshorFrozen = 'CommodityHauledShellfish_Mollusks_Crustaceans_FreshorFrozen',
  CommodityHauledCeilingOrFlooringAndWallCovering = 'CommodityHauledCeilingOrFlooringAndWallCovering',
  CommodityHauledClass62InfectiousSubstancesPackagingGroupIii = 'CommodityHauledClass6_2_InfectiousSubstances_PackagingGroupIII',
  CommodityHauledConstructionDebrisIncludesDemolitionDebris = 'CommodityHauledConstructionDebris_IncludesDemolitionDebris',
  CommodityHauledMethylBromide = 'CommodityHauledMethylBromide',
  CommodityHauledBulldozer = 'CommodityHauledBulldozer',
  CommodityHauledFishLive = 'CommodityHauledFish_Live',
  CommodityHauledHousewaresCookingUtensilsDishesEtc = 'CommodityHauledHousewares_CookingUtensils_Dishes_etc_',
  CommodityHauledShingles = 'CommodityHauledShingles',
  CommodityHauledSugar = 'CommodityHauledSugar',
  CommodityHauledSandOrSilicate = 'CommodityHauledSandOrSilicate',
  CommodityHauledClass62InfectiousSubstancesPackagingGroupI = 'CommodityHauledClass6_2_InfectiousSubstances_PackagingGroupI',
  CommodityHauledInkWaterSoluble = 'CommodityHauledInk_WaterSoluble',
  CommodityHauledPrintedMaterial = 'CommodityHauledPrintedMaterial',
  CommodityHauledStorageAndMedia = 'CommodityHauledStorageAndMedia',
  CommodityHauledTvAndVideo = 'CommodityHauledTVAndVideo',
  CommodityHauledUnknownCommodityA = 'CommodityHauledUnknownCommodityA',
  CommodityHauledUnknownCommodityB = 'CommodityHauledUnknownCommodityB',
  CommodityHauledUnknownCommodityC = 'CommodityHauledUnknownCommodityC',
  CommodityHauledUnknownCommodityD = 'CommodityHauledUnknownCommodityD',
  CommodityHauledUnknownCommodityE = 'CommodityHauledUnknownCommodityE',
  CommodityHauledUnknownCommodityF = 'CommodityHauledUnknownCommodityF',
  CommodityHauledConstructionEquipment = 'CommodityHauledConstructionEquipment',
  CommodityHauledPaperAndPaperProductsIncludingPackagingMaterials = 'CommodityHauledPaperAndPaperProducts_IncludingPackagingMaterials',
  CommodityHauledPoultryFrozenFreshPackagedBoxedProcessed = 'CommodityHauledPoultry_Frozen_Fresh_Packaged_Boxed_Processed',
  CommodityHauledSoftware = 'CommodityHauledSoftware',
  CommodityHauledSportingGoods = 'CommodityHauledSportingGoods',
  CommodityHauledLiquidsMilk = 'CommodityHauledLiquidsMilk',
  CommodityHauledAluminumChloride = 'CommodityHauledAluminumChloride',
  CommodityHauledHomeAudio = 'CommodityHauledHomeAudio',
  CommodityHauledOilLubricatingInTankers = 'CommodityHauledOil_Lubricating_InTankers',
  CommodityHauledOilinBarrelsorSmallCans = 'CommodityHauledOilinBarrelsorSmallCans',
  CommodityHauledBeveragesNonAlcoholic = 'CommodityHauledBeveragesNonAlcoholic',
  CommodityHauledClass8CorrosivesPackagingGroupIi = 'CommodityHauledClass8_Corrosives_PackagingGroupII',
  CommodityHauledComputerComponents = 'CommodityHauledComputerComponents',
  CommodityHauledFrozenFoodsVegetablesFruitExcludingFishAndSeafood = 'CommodityHauledFrozenFoods_Vegetables_Fruit_ExcludingFishAndSeafood',
  CommodityHauledPetroleumProductsBulkorinTankers = 'CommodityHauledPetroleumProducts_BulkorinTankers',
  CommodityHauledBatteriesDryCarOrMarineOrCampersOretc = 'CommodityHauledBatteries_Dry_CarOrMarineOrCampersOretc',
  CommodityHauledIntegratedCircuitComputerChipMicroprocessorsSemiconductorHardDrive = 'CommodityHauledIntegratedCircuit_ComputerChip_Microprocessors_Semiconductor_HardDrive',
  CommodityHauledPersonalCare = 'CommodityHauledPersonalCare',
  CommodityHauledAutomobilesUsedOrClassicOrAntiqueOrAuctionandSnowbirdTypeOperations = 'CommodityHauledAutomobiles_UsedOrClassicOrAntiqueOrAuctionandSnowbirdTypeOperations',
  CommodityHauledHazardouswaste = 'CommodityHauledHazardouswaste',
  CommodityHauledHealthAids = 'CommodityHauledHealthAids',
  CommodityHauledIceCreamIce = 'CommodityHauledIceCream_Ice',
  CommodityHauledMobileHomes = 'CommodityHauledMobileHomes',
  CommodityHauledBuildingMaterialsFinished = 'CommodityHauledBuildingMaterialsFinished',
  CommodityHauledMetalsBarsOrBeams = 'CommodityHauledMetalsBarsOrBeams',
  CommodityHauledClass61PoisonousMaterialsOtherthanInhalationHazard = 'CommodityHauledClass6_1_PoisonousMaterials_OtherthanInhalationHazard',
  CommodityHauledEdibleOils = 'CommodityHauledEdibleOils',
  CommodityHauledJunk = 'CommodityHauledJunk',
  CommodityHauledLeadPowderDust = 'CommodityHauledLead_Powder_Dust',
  CommodityHauledMultimediaProjectors = 'CommodityHauledMultimediaProjectors',
  CommodityHauledPipeExceptOilFieldPipe = 'CommodityHauledPipe_ExceptOilFieldPipe',
  CommodityHauledPrinters = 'CommodityHauledPrinters',
  CommodityHauledWasteWaterLandfill = 'CommodityHauledWasteWater_Landfill',
  CommodityHauledBuildingMaterialsRaw = 'CommodityHauledBuildingMaterialsRaw',
  CommodityHauledClass3FlammableOrCombustibleLiquids = 'CommodityHauledClass3FlammableOrCombustibleLiquids',
  CommodityHauledBees = 'CommodityHauledBees',
  CommodityHauledCereals = 'CommodityHauledCereals',
  CommodityHauledRefrigerationMeat = 'CommodityHauledRefrigerationMeat',
  CommodityHauledSugarLiquid = 'CommodityHauledSugar_Liquid',
  CommodityHauledVansCustomized = 'CommodityHauledVans_Customized',
  CommodityHauledClass8CorrosivesPackagingGroupI = 'CommodityHauledClass8_Corrosives_PackagingGroupI',
  CommodityHauledClothing = 'CommodityHauledClothing',
  CommodityHauledFlyAsh = 'CommodityHauledFlyAsh',
  CommodityHauledGasoline = 'CommodityHauledGasoline',
  CommodityHauledPaperShredded = 'CommodityHauledPaperShredded',
  CommodityHauledPlasticProducts = 'CommodityHauledPlasticProducts',
  CommodityHauledPoultryLiveDucksChickensGeese = 'CommodityHauledPoultry_LiveDucks_Chickens_Geese',
  CommodityHauledAutomobilesCrushedOrJunk = 'CommodityHauledAutomobiles_CrushedOrJunk',
  CommodityHauledClass9MiscellaneousHazardousMaterials = 'CommodityHauledClass9_MiscellaneousHazardousMaterials',
  CommodityHauledComputerHardware = 'CommodityHauledComputerHardware',
  CommodityHauledMetalsRolledOrCoiledSteels = 'CommodityHauledMetalsRolledOrCoiledSteels',
  CommodityHauledGameorWildBirdsLivePheasantsQuailDuckGeese = 'CommodityHauledGameorWildBirds_LivePheasants_Quail_Duck_Geese',
  CommodityHauledTrailersasCargoonFlatbed = 'CommodityHauledTrailersasCargoonFlatbed',
  CommodityHauledUraniumOxide = 'CommodityHauledUraniumOxide',
  CommodityHauledCannedGoods = 'CommodityHauledCannedGoods',
  CommodityHauledClass42SpontaneouslyCombustibleMaterial = 'CommodityHauledClass4_2_SpontaneouslyCombustibleMaterial',
  CommodityHauledDriveTowAwayTrailersTractors = 'CommodityHauledDrive_TowAwayTrailers_Tractors',
  CommodityHauledOtherBuildingMaterialsNotOtherwiseClassified = 'CommodityHauledOtherBuildingMaterialsNotOtherwiseClassified',
  CommodityHauledSalt = 'CommodityHauledSalt',
  CommodityHauledHazardousMaterialsOrBatteries = 'CommodityHauledHazardousMaterialsOrBatteries',
  CommodityHauledClass7RadioactiveMaterials = 'CommodityHauledClass7RadioactiveMaterials',
  CommodityHauledClass3FlammablePackagingGroupI = 'CommodityHauledClass3_Flammable_PackagingGroupI',
  CommodityHauledClay = 'CommodityHauledClay',
  CommodityHauledClass3FlammablePackagingGroupIi = 'CommodityHauledClass3_Flammable_PackagingGroupII',
  CommodityHauledDimensionStoneStoneSlabsGraniteMarbleLimestoneSlate = 'CommodityHauledDimensionStone_StoneSlabs_Granite_Marble_Limestone_Slate',
  CommodityHauledFilmCelluloidScrap = 'CommodityHauledFilm_CelluloidScrap',
  CommodityHauledFrozenReadytoBakeCookFoods = 'CommodityHauledFrozen_ReadytoBake_CookFoods',
  CommodityHauledPackingMaterialAndSupplies = 'CommodityHauledPackingMaterialAndSupplies',
  CommodityHauledBakeryGoodsBreadOrPieOrPastriesOrCookieOrCakes = 'CommodityHauledBakeryGoods_BreadOrPieOrPastriesOrCookieOrCakes',
  CommodityHauledChickenSludgeOrGutsHotOffalOrChickenFat = 'CommodityHauledChickenSludgeOrGuts_HotOffalOrChickenFat',
  CommodityHauledClass51OxidizerPackagingGroupIii = 'CommodityHauledClass5_1_Oxidizer_PackagingGroupIII',
  CommodityHauledDairyProducts = 'CommodityHauledDairyProducts',
  CommodityHauledOversizedOrOverweight = 'CommodityHauledOversizedOrOverweight',
  CommodityHauledBottledWater = 'CommodityHauledBottledWater',
  CommodityHauledCalciumCarbide = 'CommodityHauledCalciumCarbide',
  CommodityHauledDrugsPrescriptionsAndPharmaceuticals = 'CommodityHauledDrugs_PrescriptionsAndPharmaceuticals',
  CommodityHauledInputDevices = 'CommodityHauledInputDevices',
  CommodityHauledManureAndFertilizerBulk = 'CommodityHauledManureAndFertilizer_Bulk',
  CommodityHauledSatin = 'CommodityHauledSatin',
  CommodityHauledCementAndConcreteBulk = 'CommodityHauledCementAndConcrete_Bulk',
  CommodityHauledFertilizerDryBagged = 'CommodityHauledFertilizer_Dry_Bagged',
  CommodityHauledFertilizerLiquid = 'CommodityHauledFertilizer_Liquid',
  CommodityHauledgarbageRefuseTrash = 'CommodityHauledgarbage_Refuse_Trash',
  CommodityHauledMotorcycles = 'CommodityHauledMotorcycles',
  CommodityHauledCosmetics = 'CommodityHauledCosmetics',
  CommodityHauledEnginesOrMachinery = 'CommodityHauledEnginesOrMachinery',
  CommodityHauledClass41FlammableSolidPackagingGroupIi = 'CommodityHauledClass4_1_FlammableSolid_PackagingGroupII',
  CommodityHauledCottonGinned = 'CommodityHauledCotton_Ginned',
  CommodityHauledGolfCarts = 'CommodityHauledGolfCarts',
  CommodityHauledMedicalInstruments = 'CommodityHauledMedicalInstruments',
  CommodityHauledSwingingmeat = 'CommodityHauledSwingingmeat',
  CommodityHauledDietFoods = 'CommodityHauledDietFoods',
  CommodityHauledGlassProducts = 'CommodityHauledGlassProducts',
  CommodityHauledHay = 'CommodityHauledHay',
  CommodityHauledShavers = 'CommodityHauledShavers',
  CommodityHauledClass5OxidizingSubstancesOrOrganicPeroxides = 'CommodityHauledClass5OxidizingSubstancesOrOrganicPeroxides',
  CommodityHauledBeansSoybeansOrKidneyOrPeasOrLentilsOrChickpeas = 'CommodityHauledBeans_SoybeansOrKidneyOrPeasOrLentilsOrChickpeas',
  CommodityHauledFirewood = 'CommodityHauledFirewood',
  CommodityHauledJewelry = 'CommodityHauledJewelry',
  CommodityHauledNutsAndSeeds = 'CommodityHauledNutsAndSeeds',
  CommodityHauledTiresNewOnly = 'CommodityHauledTires_NewOnly',
  CommodityHauledClass1Explosives = 'CommodityHauledClass1Explosives',
  CommodityHauledClass6ToxinsOrInfectiousSubstances = 'CommodityHauledClass6ToxinsOrInfectiousSubstances',
  CommodityHauledAutomobilesNew = 'CommodityHauledAutomobiles_New',
  CommodityHauledLiquidLatex = 'CommodityHauledLiquidLatex',
  CommodityHauledLivestockLivePigsCattleSheepHorses = 'CommodityHauledLivestock_LivePigs_Cattle_Sheep_Horses',
  CommodityHauledCamerasAndPhotography = 'CommodityHauledCamerasAndPhotography',
  CommodityHauledAsphaltLiquid = 'CommodityHauledAsphaltLiquid',
  CommodityHauledGrainOrSeedOrFeed = 'CommodityHauledGrainOrSeedOrFeed',
  CommodityHauledClass8Corrosives = 'CommodityHauledClass8Corrosives',
  CommodityHauledCarnivalOrCircusEquipmentOrRides = 'CommodityHauledCarnivalOrCircusEquipmentOrRides',
  CommodityHauledLimestone = 'CommodityHauledLimestone',
  CommodityHauledPetSuppliesFoodEtc = 'CommodityHauledPetSupplies_Food_etc_',
  CommodityHauledRiggingCranes = 'CommodityHauledRigging_Cranes',
  CommodityHauledFlowersOrPlants = 'CommodityHauledFlowersOrPlants',
  CommodityHauledGrainsCornWheatBarleyRiceOatsRyePeanutsSorghum = 'CommodityHauledGrains_Corn_Wheat_Barley_Rice_Oats_Rye_Peanuts_Sorghum',
  CommodityHauledHandToolsPowerTools = 'CommodityHauledHandTools_PowerTools',
  CommodityHauledWaterWell = 'CommodityHauledWaterWell',
  CommodityHauledBatteriesWetCarOrMarineOrCamperOretc = 'CommodityHauledBatteries_Wet_CarOrMarineOrCamperOretc',
  CommodityHauledClass51OxidizerPackagingGroupI = 'CommodityHauledClass5_1_Oxidizer_PackagingGroupI',
  CommodityHauledCranesBooms = 'CommodityHauledCranes_Booms',
  CommodityHauledMusicalInstruments = 'CommodityHauledMusicalInstruments',
  CommodityHauledOxidizers = 'CommodityHauledOxidizers',
  CommodityHauledPotash = 'CommodityHauledPotash',
  CommodityHauledSawdust = 'CommodityHauledSawdust',
  CommodityHauledSodiumSulfate = 'CommodityHauledSodiumSulfate',
  CommodityHauledTobaccoFinishedProducts = 'CommodityHauledTobacco_FinishedProducts',
  CommodityHauledAutoPartsAccessories = 'CommodityHauledAutoPartsAccessories',
  CommodityHauledLiquidsOtherNonFlammable = 'CommodityHauledLiquidsOtherNonFlammable',
  CommodityHauledMetalsCopper = 'CommodityHauledMetalsCopper',
  CommodityHauledChinaAndCeramics = 'CommodityHauledChinaAndCeramics',
  CommodityHauledClass62InfectiousSubstancesPackagingGroupIi = 'CommodityHauledClass6_2_InfectiousSubstances_PackagingGroupII',
  CommodityHauledClass8CorrosivesPackagingGroupIii = 'CommodityHauledClass8_Corrosives_PackagingGroupIII',
  CommodityHauledHandheldDevicesGpsPdasIPodsHeadsetsHeadphonesMp3 = 'CommodityHauledHandheldDevices_GPS_PDAs_I_PODs_Headsets_Headphones_MP3',
  CommodityHauledMethylMethanolAlcohol = 'CommodityHauledMethyl_MethanolAlcohol',
  CommodityHauledPrepackagedFoods = 'CommodityHauledPrepackagedFoods',
  CommodityHauledBeveragesAlcoholic = 'CommodityHauledBeveragesAlcoholic',
  CommodityHauledGeneralDryFreight = 'CommodityHauledGeneralDryFreight',
  CommodityHauledAircraftOrJetEngines = 'CommodityHauledAircraftOrJetEngines',
  CommodityHauledBricks = 'CommodityHauledBricks',
  CommodityHauledDryIce = 'CommodityHauledDryIce',
  CommodityHauledIntermodalContainerContainerizedFreight = 'CommodityHauledIntermodalContainer_ContainerizedFreight',
  CommodityHauledMetalProducts = 'CommodityHauledMetalProducts',
  CommodityHauledSoapLiquid = 'CommodityHauledSoap_Liquid',
  CommodityHauledSuede = 'CommodityHauledSuede',
  CommodityHauledAsh = 'CommodityHauledAsh',
  CommodityHauledAppliances = 'CommodityHauledAppliances',
  CommodityHauledCowhidesRawHides = 'CommodityHauledCowhides_RawHides',
  CommodityHauledExoticCircusZooorWildAnimalsLive = 'CommodityHauledExotic_Circus_ZooorWildAnimals_Live',
  CommodityHauledPlumbingFixturesAndEquipment = 'CommodityHauledPlumbingFixturesAndEquipment',
  CommodityHauledBatteriesHousehold = 'CommodityHauledBatteries_Household',
  CommodityHauledBoatsUnder25feetLengthNewOrUsed = 'CommodityHauledBoatsUnder25feetLength_NewOrUsed',
  CommodityHauledClass52OrganicPeroxide = 'CommodityHauledClass5_2_OrganicPeroxide',
  CommodityHauledGlassDustPowder = 'CommodityHauledGlassDust_Powder',
  CommodityHauledSandAndGravelCrushedStoneSlagAggregateSilica = 'CommodityHauledSandAndGravel_CrushedStone_Slag_Aggregate_Silica',
  CommodityHauledSodiumChloride = 'CommodityHauledSodiumChloride',
  CommodityHauledClass9MiscellaneousOrNotOtherwiseSpecified = 'CommodityHauledClass9MiscellaneousOrNotOtherwiseSpecified',
  CommodityHauledAmmoniumNitrateOrFertilizer = 'CommodityHauledAmmoniumNitrateOrFertilizer',
  CommodityHauledHomeFurnishingsAndAccessoriesDrapes = 'CommodityHauledHomeFurnishingsAndAccessories_Drapes',
  CommodityHauledPaperOrPlasticProducts = 'CommodityHauledPaperOrPlasticProducts',
  CommodityHauledMachineryAndHeavyEquipment = 'CommodityHauledMachineryAndHeavyEquipment',
  CommodityHauledTextiles = 'CommodityHauledTextiles',
  CommodityHauledTobaccoRaw = 'CommodityHauledTobacco_Raw',
  CommodityHauledDryFoods = 'CommodityHauledDryFoods',
  CommodityHauledBeerOrWineOrBrandy = 'CommodityHauledBeerOrWineOrBrandy',
  CommodityHauledBottledAndCannedSoftdrinks = 'CommodityHauledBottledAndCannedSoftdrinks',
  CommodityHauledComputerAccessories = 'CommodityHauledComputerAccessories',
  CommodityHauledVegetableOil = 'CommodityHauledVegetableOil',
  CommodityHauledWoodChips = 'CommodityHauledWoodChips',
  CommodityHauledCeramicTileOrQuarryTileOrPaversOrProsaic = 'CommodityHauledCeramicTileOrQuarryTileOrPaversOrProsaic',
  CommodityHauledOres = 'CommodityHauledOres',
  CommodityHauledOtherPaperPlasticGlass = 'CommodityHauledOtherPaper_Plastic_Glass',
  CommodityHauledUraniumOre = 'CommodityHauledUraniumOre',
  CommodityHauledVelvet = 'CommodityHauledVelvet',
  CommodityHauledClass61PoisonousMaterialsInhalationHazard = 'CommodityHauledClass6_1_PoisonousMaterials_InhalationHazard',
  CommodityHauledConfectionaryProducts = 'CommodityHauledConfectionaryProducts',
  CommodityHauledElectricalPartsSuppliesorFixturesSupplies = 'CommodityHauledElectricalParts_SuppliesorFixtures_Supplies',
  CommodityHauledFishAndSeafoodFreshorFrozen = 'CommodityHauledFishAndSeafoodFreshorFrozen',
  CommodityHauledContaminatedDirtorSoil = 'CommodityHauledContaminatedDirtorSoil',
  CommodityHauledElectricalEquipment = 'CommodityHauledElectricalEquipment',
  CommodityHauledGlassFlat = 'CommodityHauledGlass_Flat',
  CommodityHauledRadioactiveMaterial = 'CommodityHauledRadioactiveMaterial',
  CommodityHauledLiquidsFuel = 'CommodityHauledLiquidsFuel',
  CommodityHauledCarbonBlack = 'CommodityHauledCarbonBlack',
  CommodityHauledElectricalSystemsAndEquipment = 'CommodityHauledElectricalSystemsAndEquipment',
  CommodityHauledIndustrialEquipment = 'CommodityHauledIndustrialEquipment',
  CommodityHauledSolarPanelsCells = 'CommodityHauledSolarPanels_Cells',
  CommodityHauledRefrigeratedFoodsNonmeat = 'CommodityHauledRefrigeratedFoodsNonmeat',
  CommodityHauledLumberOrLogs = 'CommodityHauledLumberOrLogs',
  CommodityHauledSilk = 'CommodityHauledSilk',
  CommodityHauledBoats = 'CommodityHauledBoats',
  CommodityHauledClass4FlammableSolids = 'CommodityHauledClass4FlammableSolids',
  CommodityHauledCharcoal = 'CommodityHauledCharcoal',
  CommodityHauledClass7Radioactive = 'CommodityHauledClass7_Radioactive',
  CommodityHauledElectronicsAndComputersnototherwiseclassified = 'CommodityHauledElectronicsAndComputersnototherwiseclassified',
  CommodityHauledLawnandGarden = 'CommodityHauledLawnandGarden',
  CommodityHauledRock = 'CommodityHauledRock',
  CommodityHauledSteelAndConstructionMetals = 'CommodityHauledSteelAndConstructionMetals',
  CommodityHauledAgriculturalEquipment = 'CommodityHauledAgriculturalEquipment',
  CommodityHauledCommunicationEquipment = 'CommodityHauledCommunicationEquipment',
  CommodityHauledHouseAndBuildingMovers = 'CommodityHauledHouseAndBuildingMovers',
  CommodityHauledPotassiumChloride = 'CommodityHauledPotassiumChloride',
  CommodityHauledVideoGames = 'CommodityHauledVideoGames',
  CommodityHauledAutosRaceCars = 'CommodityHauledAutos_RaceCars',
  CommodityHauledChlorine = 'CommodityHauledChlorine',
  CommodityHauledCoal = 'CommodityHauledCoal',
  CommodityHauledCadmium = 'CommodityHauledCadmium',
  CommodityHauledClass22NonFlammableCompressedGas = 'CommodityHauledClass2_2_NonFlammableCompressedGas',
  CommodityHauledRendering = 'CommodityHauledRendering',
  CommodityHauledCopperAndCopperProducts = 'CommodityHauledCopperAndCopperProducts',
  CommodityHauledFurniture = 'CommodityHauledFurniture',
  CommodityHauledHouseholdCleaningProducts = 'CommodityHauledHouseholdCleaningProducts',
  CommodityHauledMetalSheetsCoilsRolls = 'CommodityHauledMetalSheets_Coils_Rolls',
  CommodityHauledMulchTopSoilorFill = 'CommodityHauledMulch_TopSoilorFill',
  CommodityHauledAutomobilePartsAndAccessories = 'CommodityHauledAutomobilePartsAndAccessories',
  CommodityHauledCarpeting = 'CommodityHauledCarpeting',
  CommodityHauledClass51OxidizerPackagingGroupIi = 'CommodityHauledClass5_1_Oxidizer_PackagingGroupII',
  CommodityHauledExplosivesDetonators = 'CommodityHauledExplosives_Detonators',
  CommodityHauledFireworks = 'CommodityHauledFireworks',
  CommodityHauledFurFurSkinPelts = 'CommodityHauledFur_FurSkin_Pelts',
  CommodityHauledHeatingVentilationAndAirConditioning = 'CommodityHauledHeating_VentilationAndAirConditioning',
  CommodityHauledHouseholdHardwarePlumbingSuppliesPaintCabinetBath = 'CommodityHauledHouseholdHardware_PlumbingSupplies_Paint_Cabinet_Bath',
  CommodityHauledTiresScrap = 'CommodityHauledTires_Scrap',
  CommodityHauledTrailersasCargoPulledbyPowerUnit = 'CommodityHauledTrailersasCargoPulledbyPowerUnit',
  CommodityHauledEmptyContainers = 'CommodityHauledEmptyContainers',
  CommodityHauledBananas = 'CommodityHauledBananas',
  CommodityHauledConcreteProducts = 'CommodityHauledConcreteProducts',
  CommodityHauledOilFieldEquipment = 'CommodityHauledOilFieldEquipment',
  CommodityHauledSod = 'CommodityHauledSod',
  CommodityHauledPharmaceuticals = 'CommodityHauledPharmaceuticals',
  CommodityHauledCampersandRecreationalVehicles = 'CommodityHauledCampersandRecreationalVehicles',
  CommodityHauledLiquorExcludingBeerAndWine = 'CommodityHauledLiquor_ExcludingBeerAndWine',
  CommodityHauledClass21FlammableGas = 'CommodityHauledClass2_1_FlammableGas',
  CommodityHauledOfficeEquipmentMachinesAndSupplies = 'CommodityHauledOfficeEquipment_MachinesAndSupplies',
  CommodityHauledSludgeClassAorClassB = 'CommodityHauledSludge_ClassAorClassB',
  CommodityHauledRetailProducts = 'CommodityHauledRetailProducts',
  CommodityHauledChemicalsBulkLiquid = 'CommodityHauledChemicalsBulkLiquid',
  CommodityHauledFertilizers = 'CommodityHauledFertilizers',
  CommodityHauledAdipicPellets = 'CommodityHauledAdipicPellets',
  CommodityHauledDrugsOverTheCounterDrugsMedications = 'CommodityHauledDrugs_Over_the_CounterDrugs_Medications',
  CommodityHauledNutrition = 'CommodityHauledNutrition',
  CommodityHauledPlantsAndNurseryStock = 'CommodityHauledPlantsAndNurseryStock',
  CommodityHauledScrapMetal = 'CommodityHauledScrapMetal',
  CommodityHauledEggs = 'CommodityHauledEggs',
  CommodityHauledLeather = 'CommodityHauledLeather',
  CommodityHauledMailUsps = 'CommodityHauledMailUSPS',
  CommodityHauledRefrigeratedFoodsmeat = 'CommodityHauledRefrigeratedFoodsmeat',
  CommodityHauledClass23PoisonousGas = 'CommodityHauledClass2_3_PoisonousGas',
  CommodityHauledDryChemicals = 'CommodityHauledDryChemicals',
  CommodityHauledScientificInstrumentsAndEquipment = 'CommodityHauledScientificInstrumentsAndEquipment',
  CommodityHauledScrapMetalIronorSalvageOperations = 'CommodityHauledScrapMetal_IronorSalvageOperations',
  CommodityHauledFeedSeeds = 'CommodityHauledFeed_Seeds',
  CommodityHauledMail = 'CommodityHauledMail',
  CommodityHauledRubberProducts = 'CommodityHauledRubberProducts',
  CommodityHauledElectronics = 'CommodityHauledElectronics',
  CommodityHauledAutosPersonalOrPrivatePassengeOrMotorHomes = 'CommodityHauledAutos_PersonalOrPrivatePassengeOrMotorHomes',
  CommodityHauledCalciumChlorideSolution = 'CommodityHauledCalciumChlorideSolution',
  CommodityHauledCommunicationsCellPhones = 'CommodityHauledCommunications_CellPhones',
  CommodityHauledCompressedGasesandHeatingOil = 'CommodityHauledCompressedGasesandHeatingOil',
  CommodityHauledFlour = 'CommodityHauledFlour',
  CommodityHauledGypsum = 'CommodityHauledGypsum',
  CommodityHauledAppliancesOrHardware = 'CommodityHauledAppliancesOrHardware',
  CommodityHauledFlammables = 'CommodityHauledFlammables',
  CommodityHauledMolasses = 'CommodityHauledMolasses',
  CommodityHauledNetworking = 'CommodityHauledNetworking',
  CommodityHauledPlasterDrywallGypsumboard = 'CommodityHauledPlaster_Drywall_Gypsumboard',
  CommodityHauledLargeMachinery = 'CommodityHauledLargeMachinery',
  CommodityHauledAggregatesRockOrSandOrGravelOrStoneOrDirt = 'CommodityHauledAggregatesRockOrSandOrGravelOrStoneOrDirt',
  CommodityHauledAsphaltAndBlacktop = 'CommodityHauledAsphaltAndBlacktop',
  CommodityHauledClass41FlammableSolidPackagingGroupI = 'CommodityHauledClass4_1_FlammableSolid_PackagingGroupI',
  CommodityHauledClass43DangerousWhenWetMaterial = 'CommodityHauledClass4_3_DangerousWhenWetMaterial',
  CommodityHauledPaintAndPaintThinnersCannedorBulk = 'CommodityHauledPaintAndPaintThinners_CannedorBulk',
  CommodityHauledTelephoneandOrUtilityPoles = 'CommodityHauledTelephoneand_orUtilityPoles',
  CommodityHauledRefrigeratedGoods = 'CommodityHauledRefrigeratedGoods',
  CommodityHauledRawSilk = 'CommodityHauledRawSilk',
  CommodityHauledRefrigerationNonMeat = 'CommodityHauledRefrigerationNonMeat',
  CommodityHauledSalineSolution = 'CommodityHauledSalineSolution',
  CommodityHauledWool = 'CommodityHauledWool',
  CommodityHauledClass2Gases = 'CommodityHauledClass2Gases',
  CommodityHauledFirearmsAndSuppliesAmmunitionBlackPowderEtc = 'CommodityHauledFirearmsAndSupplies_Ammunition_BlackPowder_etc',
  CommodityHauledAmusementDevices = 'CommodityHauledAmusementDevices',
  CommodityHauledClass41FlammableSolidPackagingGroupIii = 'CommodityHauledClass4_1_FlammableSolid_PackagingGroupIII',
  CommodityHauledLimeSlackedAndUnslacked = 'CommodityHauledLime_SlackedAndUnslacked',
  CommodityHauledLumber = 'CommodityHauledLumber',
  CommodityHauledAnhydrousAmmoniaUn1005Class203 = 'CommodityHauledAnhydrousAmmonia_UN1005_Class203',
  CommodityHauledClass3FlammablePackagingGroupIii = 'CommodityHauledClass3_Flammable_PackagingGroupIII',
  CommodityHauledCorrosiveSolidsSalt = 'CommodityHauledCorrosiveSolids_Salt',
  CommodityHauledmeatFrozenFreshPackagedBoxedExcludingSwingingmeat = 'CommodityHauledmeat_Frozen_Fresh_Packaged_BoxedExcludingSwingingmeat',
  CommodityHauledMuslin = 'CommodityHauledMuslin',
  CommodityHauledPerfumesAndColognes = 'CommodityHauledPerfumesAndColognes',
  CommodityHauledPlasticPellets = 'CommodityHauledPlasticPellets',
  CommodityHauledMailParcelsOrAmazon = 'CommodityHauledMailParcelsOrAmazon',
}

/**
 *
 * @export
 * @interface Contact
 */
export interface Contact {
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  phoneNumber?: string;
  /**
   *
   * @type {POCType}
   * @memberof Contact
   */
  pocType: POCType;
  /**
   *
   * @type {boolean}
   * @memberof Contact
   */
  originalSignatory?: boolean;
  /**
   *
   * @type {SafetyContactRole}
   * @memberof Contact
   */
  safetyContactRole?: SafetyContactRole;
  /**
   *
   * @type {BillingContactRole}
   * @memberof Contact
   */
  billingContactRole?: BillingContactRole;
}
/**
 *
 * @export
 * @interface ContactRecordsUpdate
 */
export interface ContactRecordsUpdate {
  /**
   *
   * @type {ContactType}
   * @memberof ContactRecordsUpdate
   */
  type: ContactType;
  /**
   * List of Contacts and their details
   * @type {Array<Contact>}
   * @memberof ContactRecordsUpdate
   */
  contacts: Array<Contact>;
}
/**
 *
 * @export
 * @interface ContactRecordsUpdateResponse
 */
export interface ContactRecordsUpdateResponse {
  /**
   *
   * @type {boolean}
   * @memberof ContactRecordsUpdateResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ContactRecordsUpdateResponse
   */
  message: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ContactType {
  ContactTypeSafety = 'ContactTypeSafety',
  ContactTypeBilling = 'ContactTypeBilling',
}

/**
 *
 * @export
 * @interface CoverageDetails
 */
export interface CoverageDetails {
  /**
   *
   * @type {Array<CoverageRecord>}
   * @memberof CoverageDetails
   */
  approvedCoverages?: Array<CoverageRecord>;
  /**
   *
   * @type {Array<RejectedCoverage>}
   * @memberof CoverageDetails
   */
  rejectedCoverages?: Array<RejectedCoverage>;
  /**
   *
   * @type {Array<Array<CoverageType>>}
   * @memberof CoverageDetails
   */
  coveragesWithCombinedDeductibles?: Array<Array<CoverageType>>;
}
/**
 *
 * @export
 * @interface CoverageRecord
 */
export interface CoverageRecord {
  /**
   *
   * @type {CoverageType}
   * @memberof CoverageRecord
   */
  coverageType: CoverageType;
  /**
   *
   * @type {number}
   * @memberof CoverageRecord
   */
  premium?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageRecord
   */
  premiumPerUnit?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageRecord
   */
  premiumPerHundredMiles?: number;
  /**
   * What percentage of the total value of the equipment is insured - mostly relates to Physical Damage. For example if a fleet is valuated at 100k and the total premium is 30k, TIV% = 30%.
   * @type {number}
   * @memberof CoverageRecord
   */
  TIVPercentage?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageRecord
   */
  deductible?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageRecord
   */
  limit?: number;
  /**
   *
   * @type {Array<SymbolAndDefinition>}
   * @memberof CoverageRecord
   */
  symbolsAndDefinitions?: Array<SymbolAndDefinition>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CoverageType {
  CoverageAutoLiability = 'CoverageAutoLiability',
  CoverageAutoPhysicalDamage = 'CoverageAutoPhysicalDamage',
  CoverageGeneralLiability = 'CoverageGeneralLiability',
  CoverageMotorTruckCargo = 'CoverageMotorTruckCargo',
  CoverageTrailerInterchange = 'CoverageTrailerInterchange',
  CoverageUninsuredMotoristBodilyInjury = 'CoverageUninsuredMotoristBodilyInjury',
  CoverageUnderinsuredMotoristBodilyInjury = 'CoverageUnderinsuredMotoristBodilyInjury',
  CoverageUninsuredMotoristPropertyDamage = 'CoverageUninsuredMotoristPropertyDamage',
  CoverageUnderinsuredMotoristPropertyDamage = 'CoverageUnderinsuredMotoristPropertyDamage',
  CoveragePersonalInjuryProtection = 'CoveragePersonalInjuryProtection',
  CoverageMedicalPayments = 'CoverageMedicalPayments',
  CoverageUmuimBodilyInjury = 'CoverageUMUIMBodilyInjury',
  CoverageUmuimPropertyDamage = 'CoverageUMUIMPropertyDamage',
  CoveragePropertyProtectionInsurance = 'CoveragePropertyProtectionInsurance',
  CoveragePersonalInjuryProtectionBasic = 'CoveragePersonalInjuryProtectionBasic',
  CoveragePersonalInjuryProtectionIncreased = 'CoveragePersonalInjuryProtectionIncreased',
  CoveragePipExcessAttendantCare = 'CoveragePIPExcessAttendantCare',
  CoverageReefer = 'CoverageReefer',
  CoverageReeferWithHumanError = 'CoverageReeferWithHumanError',
  CoverageEnhancedPackageTowingLimit = 'CoverageEnhancedPackageTowingLimit',
  CoverageGuestPersonalInjuryProtection = 'CoverageGuestPersonalInjuryProtection',
  CoverageReeferWithoutHumanError = 'CoverageReeferWithoutHumanError',
  CoverageStopGap = 'CoverageStopGap',
  CoverageBroadenedPollution = 'CoverageBroadenedPollution',
  CoverageBlanketAdditional = 'CoverageBlanketAdditional',
  CoverageUiia = 'CoverageUIIA',
  CoverageBlanketWaiverOfSubrogation = 'CoverageBlanketWaiverOfSubrogation',
  CoverageGlBlanketWaiverOfSubrogation = 'CoverageGLBlanketWaiverOfSubrogation',
  CoverageGlBlanketAdditional = 'CoverageGLBlanketAdditional',
  CoverageMtcBlanketWaiverOfSubrogation = 'CoverageMTCBlanketWaiverOfSubrogation',
  CoverageMtcBlanketAdditional = 'CoverageMTCBlanketAdditional',
  CoverageUmuim = 'CoverageUMUIM',
  CoverageUmbiuimbi = 'CoverageUMBIUIMBI',
  CoverageUm = 'CoverageUM',
  CoverageUim = 'CoverageUIM',
  CoverageTerrorism = 'CoverageTerrorism',
  CoverageDebrisRemoval = 'CoverageDebrisRemoval',
  CoverageNonOwnedTrailer = 'CoverageNonOwnedTrailer',
  CoverageApduiia = 'CoverageAPDUIIA',
  CoverageMtcuiia = 'CoverageMTCUIIA',
  CoverageApdTrailerInterchange = 'CoverageAPDTrailerInterchange',
  CoverageMtcTrailerInterchange = 'CoverageMTCTrailerInterchange',
  CoverageApdNonOwnedTrailer = 'CoverageAPDNonOwnedTrailer',
  CoverageMtcNonOwnedTrailer = 'CoverageMTCNonOwnedTrailer',
  CoverageUnattendedTruck = 'CoverageUnattendedTruck',
  CoverageEarnedFreight = 'CoverageEarnedFreight',
  CoverageRentalReimbursement = 'CoverageRentalReimbursement',
  CoverageTowingLaborAndStorage = 'CoverageTowingLaborAndStorage',
}

/**
 *
 * @export
 * @interface CoverageVariablesOptionNumeric
 */
export interface CoverageVariablesOptionNumeric {
  /**
   *
   * @type {number}
   * @memberof CoverageVariablesOptionNumeric
   */
  current: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CoverageVariablesOptionNumeric
   */
  options: Array<number>;
}
/**
 *
 * @export
 * @interface CoverageVariablesOptions
 */
export interface CoverageVariablesOptions {
  /**
   *
   * @type {CoverageType}
   * @memberof CoverageVariablesOptions
   */
  coverage: CoverageType;
  /**
   *
   * @type {CoverageVariablesOptionNumeric}
   * @memberof CoverageVariablesOptions
   */
  deductibles?: CoverageVariablesOptionNumeric;
  /**
   *
   * @type {CoverageVariablesOptionNumeric}
   * @memberof CoverageVariablesOptions
   */
  limits?: CoverageVariablesOptionNumeric;
}
/**
 *
 * @export
 * @interface CreatedApplicationResponse
 */
export interface CreatedApplicationResponse {
  /**
   *
   * @type {string}
   * @memberof CreatedApplicationResponse
   */
  applicationID: string;
  /**
   *
   * @type {Flags}
   * @memberof CreatedApplicationResponse
   */
  flags?: Flags;
}
/**
 *
 * @export
 * @interface CurrentDeductibleRecord
 */
export interface CurrentDeductibleRecord {
  /**
   *
   * @type {CoverageType}
   * @memberof CurrentDeductibleRecord
   */
  coverage: CoverageType;
  /**
   *
   * @type {number}
   * @memberof CurrentDeductibleRecord
   */
  amount: number;
}
/**
 *
 * @export
 * @interface DeclineAppForm
 */
export interface DeclineAppForm {
  /**
   *
   * @type {string}
   * @memberof DeclineAppForm
   */
  description: string;
}
/**
 *
 * @export
 * @interface DeductibleRecord
 */
export interface DeductibleRecord {
  /**
   *
   * @type {CoverageType}
   * @memberof DeductibleRecord
   */
  coverage: CoverageType;
  /**
   *
   * @type {Array<number>}
   * @memberof DeductibleRecord
   */
  amounts: Array<number>;
}
/**
 *
 * @export
 * @interface DocumentSummary
 */
export interface DocumentSummary {
  /**
   *
   * @type {string}
   * @memberof DocumentSummary
   */
  signaturePacketHandleId: string;
  /**
   *
   * @type {string}
   * @memberof DocumentSummary
   */
  signaturePacketZipHandleId?: string;
}
/**
 *
 * @export
 * @interface DotPrefillResponse
 */
export interface DotPrefillResponse {
  /**
   *
   * @type {string}
   * @memberof DotPrefillResponse
   */
  effectiveDate: string;
  /**
   *
   * @type {USState}
   * @memberof DotPrefillResponse
   */
  usState: USState;
  /**
   *
   * @type {string}
   * @memberof DotPrefillResponse
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof DotPrefillResponse
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof DotPrefillResponse
   */
  last_reported_mileage?: number;
  /**
   *
   * @type {number}
   * @memberof DotPrefillResponse
   */
  last_reported_power_unit_count: number;
  /**
   * Whether nirvana is operating in the state or not (based on the state of the address associated to the DOT) - used for appetite check
   * @type {boolean}
   * @memberof DotPrefillResponse
   */
  isStateActive?: boolean;
}
/**
 *
 * @export
 * @interface DownloadFileLinkResponse
 */
export interface DownloadFileLinkResponse {
  /**
   *
   * @type {string}
   * @memberof DownloadFileLinkResponse
   */
  link: string;
}
/**
 *
 * @export
 * @interface DownloadFileResponse
 */
export interface DownloadFileResponse {
  /**
   *
   * @type {any}
   * @memberof DownloadFileResponse
   */
  file: any;
}
/**
 *
 * @export
 * @interface DriverList
 */
export interface DriverList {
  /**
   *
   * @type {FlatfileMetadata}
   * @memberof DriverList
   */
  flatfileMetadata?: FlatfileMetadata;
  /**
   *
   * @type {ImplerMetadata}
   * @memberof DriverList
   */
  implerMetadata?: ImplerMetadata;
  /**
   *
   * @type {Array<DriverListRecord>}
   * @memberof DriverList
   */
  drivers: Array<DriverListRecord>;
}
/**
 *
 * @export
 * @interface DriverListRecord
 */
export interface DriverListRecord {
  /**
   *
   * @type {string}
   * @memberof DriverListRecord
   */
  dlNumber: string;
  /**
   *
   * @type {USState}
   * @memberof DriverListRecord
   */
  usState: USState;
  /**
   *
   * @type {string}
   * @memberof DriverListRecord
   */
  dateHired: string;
  /**
   *
   * @type {string}
   * @memberof DriverListRecord
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof DriverListRecord
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof DriverListRecord
   */
  lastName?: string;
  /**
   *
   * @type {number}
   * @memberof DriverListRecord
   */
  yearsOfExperience?: number;
}
/**
 *
 * @export
 * @interface DuplicateApplicationDetails
 */
export interface DuplicateApplicationDetails {
  /**
   *
   * @type {string}
   * @memberof DuplicateApplicationDetails
   */
  existingApplicationId: string;
  /**
   *
   * @type {string}
   * @memberof DuplicateApplicationDetails
   */
  existingApplicationStatus: string;
  /**
   *
   * @type {boolean}
   * @memberof DuplicateApplicationDetails
   */
  isRenewal: boolean;
}
/**
 *
 * @export
 * @interface Email
 */
export interface Email {
  /**
   *
   * @type {string}
   * @memberof Email
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Email
   */
  email: string;
}
/**
 *
 * @export
 * @interface EquipmentList
 */
export interface EquipmentList {
  /**
   *
   * @type {FlatfileMetadata}
   * @memberof EquipmentList
   */
  flatfileMetadata?: FlatfileMetadata;
  /**
   *
   * @type {ImplerMetadata}
   * @memberof EquipmentList
   */
  implerMetadata?: ImplerMetadata;
  /**
   *
   * @type {string}
   * @memberof EquipmentList
   */
  fileHandle?: string;
  /**
   *
   * @type {Array<EquipmentListRecord>}
   * @memberof EquipmentList
   */
  info: Array<EquipmentListRecord>;
}
/**
 *
 * @export
 * @interface EquipmentListRecord
 */
export interface EquipmentListRecord {
  /**
   *
   * @type {string}
   * @memberof EquipmentListRecord
   */
  vin: string;
  /**
   *
   * @type {number}
   * @memberof EquipmentListRecord
   */
  statedValue?: number;
}
/**
 *
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof ErrorMessage
   */
  code: number;
}
/**
 *
 * @export
 * @interface FetchTelematicsVehicleStatsResponse
 */
export interface FetchTelematicsVehicleStatsResponse {
  /**
   *
   * @type {TimeInterval}
   * @memberof FetchTelematicsVehicleStatsResponse
   */
  interval: TimeInterval;
  /**
   *
   * @type {Array<TelematicsVehicleStatsItem>}
   * @memberof FetchTelematicsVehicleStatsResponse
   */
  vehicles: Array<TelematicsVehicleStatsItem>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum FileDestinationGroup {
  FileDestinationGroupQuoting = 'FileDestinationGroupQuoting',
  FileDestinationGroupUnderwriting = 'FileDestinationGroupUnderwriting',
  FileDestinationGroupForms = 'FileDestinationGroupForms',
  FileDestinationGroupClaims = 'FileDestinationGroupClaims',
}

/**
 *
 * @export
 * @interface FileHandle
 */
export interface FileHandle {
  /**
   *
   * @type {string}
   * @memberof FileHandle
   */
  handle: string;
}
/**
 *
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
  /**
   *
   * @type {FileType}
   * @memberof FileInfo
   */
  type: FileType;
  /**
   *
   * @type {FileDestinationGroup}
   * @memberof FileInfo
   */
  destinationGroup: FileDestinationGroup;
}
/**
 *
 * @export
 * @interface FileMetadata
 */
export interface FileMetadata {
  /**
   *
   * @type {string}
   * @memberof FileMetadata
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FileMetadata
   */
  handle?: string;
  /**
   *
   * @type {FileType}
   * @memberof FileMetadata
   */
  FileType?: FileType;
  /**
   *
   * @type {FileDestinationGroup}
   * @memberof FileMetadata
   */
  FileDestinationGroup?: FileDestinationGroup;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum FileType {
  FileTypeEquipmentList = 'FileTypeEquipmentList',
  FileTypeDriversList = 'FileTypeDriversList',
  FileTypeLossRun = 'FileTypeLossRun',
  FileTypeUwDocument = 'FileTypeUwDocument',
  FileTypePdfForms = 'FileTypePDFForms',
  FileTypeIftaFile = 'FileTypeIFTAFile',
  FileTypeClaimDocument = 'FileTypeClaimDocument',
}

/**
 *
 * @export
 * @interface FileUploadRequest
 */
export interface FileUploadRequest {
  /**
   *
   * @type {FileType}
   * @memberof FileUploadRequest
   */
  fileType: FileType;
  /**
   *
   * @type {FileDestinationGroup}
   * @memberof FileUploadRequest
   */
  fileDestinationGroup: FileDestinationGroup;
  /**
   *
   * @type {FlatfileMetadata}
   * @memberof FileUploadRequest
   */
  flatfileMetadata?: FlatfileMetadata;
  /**
   *
   * @type {ImplerMetadata}
   * @memberof FileUploadRequest
   */
  implerMetadata?: ImplerMetadata;
}
/**
 *
 * @export
 * @interface Flags
 */
export interface Flags {
  /**
   *
   * @type {boolean}
   * @memberof Flags
   */
  clearanceConflict: boolean;
}
/**
 *
 * @export
 * @interface FlatfileMetadata
 */
export interface FlatfileMetadata {
  /**
   *
   * @type {string}
   * @memberof FlatfileMetadata
   */
  flatfileHandle: string;
  /**
   *
   * @type {FileMetadata}
   * @memberof FlatfileMetadata
   */
  fileMetadata: FileMetadata;
}
/**
 *
 * @export
 * @interface FleetInfo
 */
export interface FleetInfo {
  /**
   *
   * @type {number}
   * @memberof FleetInfo
   */
  dotNumber?: number;
  /**
   *
   * @type {string}
   * @memberof FleetInfo
   */
  fleetName?: string;
  /**
   *
   * @type {Array<FleetInfoVehicle>}
   * @memberof FleetInfo
   */
  vehicles?: Array<FleetInfoVehicle>;
}
/**
 *
 * @export
 * @interface FleetInfoVehicle
 */
export interface FleetInfoVehicle {
  /**
   *
   * @type {string}
   * @memberof FleetInfoVehicle
   */
  vin?: string;
  /**
   *
   * @type {string}
   * @memberof FleetInfoVehicle
   */
  name?: string;
}
/**
 *
 * @export
 * @interface FormRecord
 */
export interface FormRecord {
  /**
   *
   * @type {string}
   * @memberof FormRecord
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof FormRecord
   */
  name: string;
  /**
   * template url
   * @type {string}
   * @memberof FormRecord
   */
  url: string;
  /**
   * filled PDF file handle
   * @type {string}
   * @memberof FormRecord
   */
  handle?: string;
  /**
   *
   * @type {OrderCategory}
   * @memberof FormRecord
   */
  orderCategory: OrderCategory;
  /**
   * frozen PDF file handle
   * @type {string}
   * @memberof FormRecord
   */
  frozenHandleId?: string;
  /**
   *
   * @type {boolean}
   * @memberof FormRecord
   */
  isDynamic: boolean;
  /**
   *
   * @type {CoverageType}
   * @memberof FormRecord
   */
  coverage: CoverageType;
  /**
   *
   * @type {string}
   * @memberof FormRecord
   */
  UserVisibleCode: string;
}
/**
 *
 * @export
 * @interface GenerateSafetyReportURIResponse
 */
export interface GenerateSafetyReportURIResponse {
  /**
   *
   * @type {string}
   * @memberof GenerateSafetyReportURIResponse
   */
  url: string;
}
/**
 *
 * @export
 * @interface GeoTabConnData
 */
export interface GeoTabConnData {
  /**
   *
   * @type {string}
   * @memberof GeoTabConnData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GeoTabConnData
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof GeoTabConnData
   */
  dbName: string;
}
/**
 *
 * @export
 * @interface GetContactsResponse
 */
export interface GetContactsResponse {
  /**
   * List of Contacts and their details
   * @type {Array<Contact>}
   * @memberof GetContactsResponse
   */
  safety: Array<Contact>;
  /**
   * List of Contacts and their details
   * @type {Array<Contact>}
   * @memberof GetContactsResponse
   */
  billing: Array<Contact>;
}
/**
 *
 * @export
 * @interface ImplerMetadata
 */
export interface ImplerMetadata {
  /**
   *
   * @type {string}
   * @memberof ImplerMetadata
   */
  implerHandle: string;
  /**
   *
   * @type {FileMetadata}
   * @memberof ImplerMetadata
   */
  fileMetadata: FileMetadata;
}
/**
 *
 * @export
 * @interface IndicationForm
 */
export interface IndicationForm {
  /**
   *
   * @type {OperationsForm}
   * @memberof IndicationForm
   */
  operationsForm?: OperationsForm;
  /**
   *
   * @type {ClassesAndCommoditiesForm}
   * @memberof IndicationForm
   */
  classesAndCommoditiesForm?: ClassesAndCommoditiesForm;
  /**
   *
   * @type {Array<LossRunSummaryPerCoverage>}
   * @memberof IndicationForm
   */
  lossRunSummaryForm?: Array<LossRunSummaryPerCoverage>;
}
/**
 *
 * @export
 * @interface IndicationOption
 */
export interface IndicationOption {
  /**
   *
   * @type {string}
   * @memberof IndicationOption
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof IndicationOption
   */
  totalPremium: number;
  /**
   *
   * @type {Array<CoverageRecord>}
   * @memberof IndicationOption
   */
  coverages: Array<CoverageRecord>;
  /**
   *
   * @type {IndicationOptionTag}
   * @memberof IndicationOption
   */
  optionTag: IndicationOptionTag;
  /**
   *
   * @type {boolean}
   * @memberof IndicationOption
   */
  isRecommended: boolean;
  /**
   *
   * @type {number}
   * @memberof IndicationOption
   */
  totalPowerUnits: number;
  /**
   *
   * @type {number}
   * @memberof IndicationOption
   */
  TIV: number;
  /**
   *
   * @type {number}
   * @memberof IndicationOption
   */
  totalMiles: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum IndicationOptionTag {
  IndicationOptionTagBasic = 'IndicationOptionTagBasic',
  IndicationOptionTagStandard = 'IndicationOptionTagStandard',
  IndicationOptionTagComplete = 'IndicationOptionTagComplete',
  IndicationOptionTagCustom = 'IndicationOptionTagCustom',
}

/**
 *
 * @export
 * @interface IndicationOptions
 */
export interface IndicationOptions {
  /**
   *
   * @type {Array<IndicationOption>}
   * @memberof IndicationOptions
   */
  options: Array<IndicationOption>;
  /**
   *
   * @type {Array<CurrentDeductibleRecord>}
   * @memberof IndicationOptions
   */
  currentDeductibles: Array<CurrentDeductibleRecord>;
  /**
   *
   * @type {Array<DeductibleRecord>}
   * @memberof IndicationOptions
   */
  deductiblesOptions: Array<DeductibleRecord>;
  /**
   *
   * @type {Array<CoverageVariablesOptions>}
   * @memberof IndicationOptions
   */
  coverageVariablesOptions: Array<CoverageVariablesOptions>;
  /**
   *
   * @type {Array<Array<CoverageType>>}
   * @memberof IndicationOptions
   */
  coveragesWithCombinedDeductibles: Array<Array<CoverageType>>;
  /**
   *
   * @type {Array<CoverageVariablesOptionNumeric>}
   * @memberof IndicationOptions
   */
  limitsOptions?: Array<CoverageVariablesOptionNumeric>;
}
/**
 *
 * @export
 * @interface LossRunSummaryPerCoverage
 */
export interface LossRunSummaryPerCoverage {
  /**
   *
   * @type {CoverageType}
   * @memberof LossRunSummaryPerCoverage
   */
  coverageType: CoverageType;
  /**
   *
   * @type {Array<LossRunSummaryRecord>}
   * @memberof LossRunSummaryPerCoverage
   */
  lossRunSummary: Array<LossRunSummaryRecord>;
}
/**
 *
 * @export
 * @interface LossRunSummaryRecord
 */
export interface LossRunSummaryRecord {
  /**
   *
   * @type {string}
   * @memberof LossRunSummaryRecord
   */
  policyPeriodStartDate: string;
  /**
   *
   * @type {string}
   * @memberof LossRunSummaryRecord
   */
  policyPeriodEndDate: string;
  /**
   *
   * @type {number}
   * @memberof LossRunSummaryRecord
   */
  numberOfPowerUnits: number;
  /**
   *
   * @type {number}
   * @memberof LossRunSummaryRecord
   */
  lossIncurred: number;
  /**
   *
   * @type {number}
   * @memberof LossRunSummaryRecord
   */
  numberOfClaims: number;
  /**
   *
   * @type {boolean}
   * @memberof LossRunSummaryRecord
   */
  isNirvanaPeriod?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum MileageRadiusBucket {
  MileageRadiusBucketZeroToFifty = 'MileageRadiusBucketZeroToFifty',
  MileageRadiusBucketFiftyToTwoHundred = 'MileageRadiusBucketFiftyToTwoHundred',
  MileageRadiusBucketTwoHundredToFiveHundred = 'MileageRadiusBucketTwoHundredToFiveHundred',
  MileageRadiusBucketFiveHundredPlus = 'MileageRadiusBucketFiveHundredPlus',
}

/**
 *
 * @export
 * @interface MileageRadiusRecord
 */
export interface MileageRadiusRecord {
  /**
   *
   * @type {number}
   * @memberof MileageRadiusRecord
   */
  percentageOfFleet: number;
  /**
   *
   * @type {MileageRadiusBucket}
   * @memberof MileageRadiusRecord
   */
  mileageRadiusBucket: MileageRadiusBucket;
}
/**
 *
 * @export
 * @interface OAuthConnectionData
 */
export interface OAuthConnectionData {
  /**
   *
   * @type {string}
   * @memberof OAuthConnectionData
   */
  authCode?: string;
  /**
   *
   * @type {string}
   * @memberof OAuthConnectionData
   */
  scope?: string;
  /**
   *
   * @type {string}
   * @memberof OAuthConnectionData
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof OAuthConnectionData
   */
  error?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum OperatingClass {
  OperatingClassDump = 'OperatingClassDump',
  OperatingClassDryVan = 'OperatingClassDryVan',
  OperatingClassIntermodal = 'OperatingClassIntermodal',
  OperatingClassTanker = 'OperatingClassTanker',
  OperatingClassRefrigerated = 'OperatingClassRefrigerated',
  OperatingClassHeavyHaul = 'OperatingClassHeavyHaul',
  OperatingClassFlatbed = 'OperatingClassFlatbed',
  OperatingClassHazmat = 'OperatingClassHazmat',
}

/**
 *
 * @export
 * @interface OperatingClassDistributionRecord
 */
export interface OperatingClassDistributionRecord {
  /**
   *
   * @type {OperatingClass}
   * @memberof OperatingClassDistributionRecord
   */
  operatingClass: OperatingClass;
  /**
   *
   * @type {number}
   * @memberof OperatingClassDistributionRecord
   */
  percentageOfFleet: number;
}
/**
 *
 * @export
 * @interface OperationsForm
 */
export interface OperationsForm {
  /**
   *
   * @type {number}
   * @memberof OperationsForm
   */
  numberOfPowerUnits: number;
  /**
   *
   * @type {number}
   * @memberof OperationsForm
   */
  projectedMileage: number;
  /**
   *
   * @type {Array<MileageRadiusRecord>}
   * @memberof OperationsForm
   */
  radiusOfOperation: Array<MileageRadiusRecord>;
  /**
   *
   * @type {EquipmentList}
   * @memberof OperationsForm
   */
  equipmentList: EquipmentList;
  /**
   *
   * @type {Array<CoverageRecord>}
   * @memberof OperationsForm
   */
  coveragesRequired?: Array<CoverageRecord>;
  /**
   *
   * @type {string}
   * @memberof OperationsForm
   */
  producerId: string;
  /**
   *
   * @type {Array<Array<CoverageType>>}
   * @memberof OperationsForm
   */
  coveragesWithCombinedDeductibles?: Array<Array<CoverageType>>;
  /**
   *
   * @type {Array<TerminalLocation>}
   * @memberof OperationsForm
   */
  terminalLocations?: Array<TerminalLocation>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum OrderCategory {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  Za = 'ZA',
  Zb = 'ZB',
  Zc = 'ZC',
  Zd = 'ZD',
  Ze = 'ZE',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum POCType {
  PocTypePrimary = 'POCTypePrimary',
  PocTypeRegular = 'POCTypeRegular',
}

/**
 *
 * @export
 * @interface PatchModelVersionInLatestPendingAppReviewForm
 */
export interface PatchModelVersionInLatestPendingAppReviewForm {
  /**
   *
   * @type {string}
   * @memberof PatchModelVersionInLatestPendingAppReviewForm
   */
  version: string;
}
/**
 *
 * @export
 * @interface PatchModelVersionInLatestPendingAppReviewResponse
 */
export interface PatchModelVersionInLatestPendingAppReviewResponse {
  /**
   *
   * @type {boolean}
   * @memberof PatchModelVersionInLatestPendingAppReviewResponse
   */
  updated: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchModelVersionInLatestPendingAppReviewResponse
   */
  message: string;
}
/**
 *
 * @export
 * @interface PatchRenewalApplicationForm
 */
export interface PatchRenewalApplicationForm {
  /**
   *
   * @type {RenewalCoverageForm}
   * @memberof PatchRenewalApplicationForm
   */
  renewalCoverageForm?: RenewalCoverageForm;
  /**
   *
   * @type {RenewalOperationsForm}
   * @memberof PatchRenewalApplicationForm
   */
  renewalOperationsForm?: RenewalOperationsForm;
  /**
   *
   * @type {RenewalClassesAndCommoditiesForm}
   * @memberof PatchRenewalApplicationForm
   */
  renewalClassesAndCommoditiesForm?: RenewalClassesAndCommoditiesForm;
  /**
   *
   * @type {RenewalLossForm}
   * @memberof PatchRenewalApplicationForm
   */
  renewalLossForm?: RenewalLossForm;
  /**
   *
   * @type {RenewalAdditionalForm}
   * @memberof PatchRenewalApplicationForm
   */
  renewalAdditionalForm?: RenewalAdditionalForm;
  /**
   *
   * @type {SectionCompletionMap}
   * @memberof PatchRenewalApplicationForm
   */
  sectionCompletionMap?: SectionCompletionMap;
  /**
   *
   * @type {RenewalFilesForm}
   * @memberof PatchRenewalApplicationForm
   */
  renewalFilesForm?: RenewalFilesForm;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PaymentOption {
  PaymentOptionPaidInFull = 'PaymentOptionPaidInFull',
  PaymentOptionMonthlyReporter = 'PaymentOptionMonthlyReporter',
}

/**
 *
 * @export
 * @interface PaymentOptionRecord
 */
export interface PaymentOptionRecord {
  /**
   *
   * @type {PaymentOption}
   * @memberof PaymentOptionRecord
   */
  name: PaymentOption;
  /**
   *
   * @type {string}
   * @memberof PaymentOptionRecord
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof PaymentOptionRecord
   */
  toBind: number;
  /**
   *
   * @type {PaymentOptionType}
   * @memberof PaymentOptionRecord
   */
  type: PaymentOptionType;
  /**
   *
   * @type {number}
   * @memberof PaymentOptionRecord
   */
  estimatedMonthlyPayment?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentOptionRecord
   */
  paymentDueDate: string;
  /**
   *
   * @type {number}
   * @memberof PaymentOptionRecord
   */
  collateral?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PaymentOptionType {
  Monthly = 'monthly',
  Annual = 'annual',
}

/**
 *
 * @export
 * @interface PolicyRecord
 */
export interface PolicyRecord {
  /**
   *
   * @type {string}
   * @memberof PolicyRecord
   */
  policyNumber: string;
  /**
   *
   * @type {string}
   * @memberof PolicyRecord
   */
  policyIdentifier: string;
  /**
   *
   * @type {string}
   * @memberof PolicyRecord
   */
  policyID: string;
  /**
   *
   * @type {string}
   * @memberof PolicyRecord
   */
  applicationID: string;
  /**
   *
   * @type {string}
   * @memberof PolicyRecord
   */
  insuredName: string;
  /**
   *
   * @type {string}
   * @memberof PolicyRecord
   */
  producerName: string;
  /**
   *
   * @type {string}
   * @memberof PolicyRecord
   */
  agencyName: string;
  /**
   *
   * @type {number}
   * @memberof PolicyRecord
   */
  dotNumber: number;
  /**
   *
   * @type {string}
   * @memberof PolicyRecord
   */
  policyIssuanceDate: string;
  /**
   *
   * @type {string}
   * @memberof PolicyRecord
   */
  policyExpirationDate: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PolicyRecord
   */
  coverageList: Array<PolicyRecordCoverageListEnum>;
  /**
   *
   * @type {PolicyStatus}
   * @memberof PolicyRecord
   */
  status: PolicyStatus;
}

/**
 * @export
 * @enum {string}
 */
export enum PolicyRecordCoverageListEnum {
  CoverageAutoPhysicalDamage = 'CoverageAutoPhysicalDamage',
  CoverageGeneralLiability = 'CoverageGeneralLiability',
}

/**
 *
 * @export
 * @interface PolicySetRecord
 */
export interface PolicySetRecord {
  /**
   *
   * @type {string}
   * @memberof PolicySetRecord
   */
  policySetID: string;
  /**
   *
   * @type {string}
   * @memberof PolicySetRecord
   */
  policySetNumber: string;
  /**
   *
   * @type {string}
   * @memberof PolicySetRecord
   */
  applicationID: string;
  /**
   *
   * @type {string}
   * @memberof PolicySetRecord
   */
  insuredName: string;
  /**
   *
   * @type {number}
   * @memberof PolicySetRecord
   */
  dotNumber: number;
  /**
   *
   * @type {string}
   * @memberof PolicySetRecord
   */
  policySetIssuanceDate: string;
  /**
   *
   * @type {string}
   * @memberof PolicySetRecord
   */
  policySetExpirationDate: string;
  /**
   *
   * @type {Array<CoverageType>}
   * @memberof PolicySetRecord
   */
  coverageList: Array<CoverageType>;
  /**
   *
   * @type {PolicySetStatus}
   * @memberof PolicySetRecord
   */
  status: PolicySetStatus;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PolicySetStatus {
  PolicySetStateActive = 'PolicySetStateActive',
  PolicySetStateRenewed = 'PolicySetStateRenewed',
  PolicySetStateExpired = 'PolicySetStateExpired',
  PolicySetStateCancelled = 'PolicySetStateCancelled',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PolicySetTab {
  PolicySetTabAll = 'PolicySetTabAll',
  PolicySetTabActive = 'PolicySetTabActive',
  PolicySetTabCancelled = 'PolicySetTabCancelled',
  PolicySetTabExpired = 'PolicySetTabExpired',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PolicyStatus {
  Active = 'Active',
  ExpiringSoon = 'Expiring Soon',
  Expired = 'Expired',
  Cancelled = 'Cancelled',
  Upcoming = 'Upcoming',
  CancellationPending = 'Cancellation Pending',
}

/**
 *
 * @export
 * @interface PotentialClearanceDetails
 */
export interface PotentialClearanceDetails {
  /**
   *
   * @type {PotentialClearanceStatus}
   * @memberof PotentialClearanceDetails
   */
  status: PotentialClearanceStatus;
  /**
   *
   * @type {boolean}
   * @memberof PotentialClearanceDetails
   */
  isSameAgency: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PotentialClearanceStatus {
  ClearedApplicationExists = 'ClearedApplicationExists',
  ClearedApplicationExistsSameAgency = 'ClearedApplicationExistsSameAgency',
  DeclinedClearedApplicationExists = 'DeclinedClearedApplicationExists',
}

/**
 *
 * @export
 * @interface PotentialRenewalDetails
 */
export interface PotentialRenewalDetails {
  /**
   *
   * @type {string}
   * @memberof PotentialRenewalDetails
   */
  applicationExpiryDate: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PreApplicationCheckRule {
  PreApplicationRuleDuplicateApplication = 'PreApplicationRuleDuplicateApplication',
  PreApplicationRulePotentialClearance = 'PreApplicationRulePotentialClearance',
  PreApplicationRulePossibleRenewal = 'PreApplicationRulePossibleRenewal',
  PreApplicationRulePossibleMidTermMove = 'PreApplicationRulePossibleMidTermMove',
  PreApplicationRuleSameAgencyRenewal = 'PreApplicationRuleSameAgencyRenewal',
}

/**
 *
 * @export
 * @interface PreApplicationChecksRuleRequest
 */
export interface PreApplicationChecksRuleRequest {
  /**
   *
   * @type {number}
   * @memberof PreApplicationChecksRuleRequest
   */
  dotNumber: number;
  /**
   *
   * @type {string}
   * @memberof PreApplicationChecksRuleRequest
   */
  producerId: string;
  /**
   *
   * @type {string}
   * @memberof PreApplicationChecksRuleRequest
   */
  effectiveDate: string;
}
/**
 *
 * @export
 * @interface PreApplicationChecksRuleResponse
 */
export interface PreApplicationChecksRuleResponse {
  /**
   *
   * @type {PreApplicationCheckRule}
   * @memberof PreApplicationChecksRuleResponse
   */
  rule: PreApplicationCheckRule;
  /**
   *
   * @type {DuplicateApplicationDetails | PotentialClearanceDetails | PotentialRenewalDetails}
   * @memberof PreApplicationChecksRuleResponse
   */
  details:
    | DuplicateApplicationDetails
    | PotentialClearanceDetails
    | PotentialRenewalDetails;
}
/**
 *
 * @export
 * @interface PremiumDetails
 */
export interface PremiumDetails {
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  totalPowerUnits: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  tiv: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  totalMiles: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  subtotalPremium: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  preDiscountTotalPremium: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  flatCharges: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  safetyDiscountPremium: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  safetyDiscountPercentage: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  totalPremium: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  totalSurchargePremium?: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  totalSurplusLinesTax?: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDetails
   */
  totalStampingFee?: number;
}
/**
 *
 * @export
 * @interface PrimaryCommodityHauled
 */
export interface PrimaryCommodityHauled {
  /**
   *
   * @type {PrimaryCommodityHauledEnum}
   * @memberof PrimaryCommodityHauled
   */
  type: PrimaryCommodityHauledEnum;
  /**
   *
   * @type {string}
   * @memberof PrimaryCommodityHauled
   */
  label?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PrimaryCommodityHauledEnum {
  CommodityHauledAggregatesRockOrSandOrGravelOrStoneOrDirt = 'CommodityHauledAggregatesRockOrSandOrGravelOrStoneOrDirt',
  CommodityHauledRetailProducts = 'CommodityHauledRetailProducts',
  CommodityHauledAsphaltLiquid = 'CommodityHauledAsphaltLiquid',
  CommodityHauledFlowersOrPlants = 'CommodityHauledFlowersOrPlants',
  CommodityHauledRefrigeratedFoodsNonmeat = 'CommodityHauledRefrigeratedFoodsNonmeat',
  CommodityHauledMetalsBarsOrBeams = 'CommodityHauledMetalsBarsOrBeams',
  CommodityHauledAutoPartsAccessories = 'CommodityHauledAutoPartsAccessories',
  CommodityHauledRefrigeratedGoods = 'CommodityHauledRefrigeratedGoods',
  CommodityHauledCosmetics = 'CommodityHauledCosmetics',
  CommodityHauledBoats = 'CommodityHauledBoats',
  CommodityHauledLumberOrLogs = 'CommodityHauledLumberOrLogs',
  CommodityHauledClass4FlammableSolids = 'CommodityHauledClass4FlammableSolids',
  CommodityHauledWoodChips = 'CommodityHauledWoodChips',
  CommodityHauledBeveragesNonAlcoholic = 'CommodityHauledBeveragesNonAlcoholic',
  CommodityHauledElectronics = 'CommodityHauledElectronics',
  CommodityHauledHazardousMaterialsOrBatteries = 'CommodityHauledHazardousMaterialsOrBatteries',
  CommodityHauledLargeMachinery = 'CommodityHauledLargeMachinery',
  CommodityHauledClass1Explosives = 'CommodityHauledClass1Explosives',
  CommodityHauledAppliancesOrHardware = 'CommodityHauledAppliancesOrHardware',
  CommodityHauledGeneralDryFreight = 'CommodityHauledGeneralDryFreight',
  CommodityHauledLiquidsOtherNonFlammable = 'CommodityHauledLiquidsOtherNonFlammable',
  CommodityHauledSandOrSilicate = 'CommodityHauledSandOrSilicate',
  CommodityHauledClass3FlammableOrCombustibleLiquids = 'CommodityHauledClass3FlammableOrCombustibleLiquids',
  CommodityHauledMetalsCopper = 'CommodityHauledMetalsCopper',
  CommodityHauledOversizedOrOverweight = 'CommodityHauledOversizedOrOverweight',
  CommodityHauledAsh = 'CommodityHauledAsh',
  CommodityHauledDryFoods = 'CommodityHauledDryFoods',
  CommodityHauledMailParcelsOrAmazon = 'CommodityHauledMailParcelsOrAmazon',
  CommodityHauledPaperOrPlasticProducts = 'CommodityHauledPaperOrPlasticProducts',
  CommodityHauledChemicalsBulkLiquid = 'CommodityHauledChemicalsBulkLiquid',
  CommodityHauledRefrigeratedFoodsmeat = 'CommodityHauledRefrigeratedFoodsmeat',
  CommodityHauledClass2Gases = 'CommodityHauledClass2Gases',
  CommodityHauledClass5OxidizingSubstancesOrOrganicPeroxides = 'CommodityHauledClass5OxidizingSubstancesOrOrganicPeroxides',
  CommodityHauledClass9MiscellaneousOrNotOtherwiseSpecified = 'CommodityHauledClass9MiscellaneousOrNotOtherwiseSpecified',
  CommodityHauledPharmaceuticals = 'CommodityHauledPharmaceuticals',
  CommodityHauledBuildingMaterialsFinished = 'CommodityHauledBuildingMaterialsFinished',
  CommodityHauledClass6ToxinsOrInfectiousSubstances = 'CommodityHauledClass6ToxinsOrInfectiousSubstances',
  CommodityHauledClass7RadioactiveMaterials = 'CommodityHauledClass7RadioactiveMaterials',
  CommodityHauledMetalsRolledOrCoiledSteels = 'CommodityHauledMetalsRolledOrCoiledSteels',
  CommodityHauledScrapMetal = 'CommodityHauledScrapMetal',
  CommodityHauledMailUsps = 'CommodityHauledMailUSPS',
  CommodityHauledFertilizers = 'CommodityHauledFertilizers',
  CommodityHauledLiquidsMilk = 'CommodityHauledLiquidsMilk',
  CommodityHauledConstructionEquipment = 'CommodityHauledConstructionEquipment',
  CommodityHauledBuildingMaterialsRaw = 'CommodityHauledBuildingMaterialsRaw',
  CommodityHauledBeveragesAlcoholic = 'CommodityHauledBeveragesAlcoholic',
  CommodityHauledEmptyContainers = 'CommodityHauledEmptyContainers',
  CommodityHauledGrainOrSeedOrFeed = 'CommodityHauledGrainOrSeedOrFeed',
  CommodityHauledLiquidsFuel = 'CommodityHauledLiquidsFuel',
  CommodityHauledEnginesOrMachinery = 'CommodityHauledEnginesOrMachinery',
  CommodityHauledClass8Corrosives = 'CommodityHauledClass8Corrosives',
}

/**
 *
 * @export
 * @interface Producer
 */
export interface Producer {
  /**
   *
   * @type {string}
   * @memberof Producer
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Producer
   */
  name: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ProgramType {
  ProgramTypeFleet = 'ProgramTypeFleet',
  ProgramTypeNonFleetCanopiusNrb = 'ProgramTypeNonFleetCanopiusNRB',
  ProgramTypeNonFleetAdmitted = 'ProgramTypeNonFleetAdmitted',
  ProgramTypeInvalid = 'ProgramTypeInvalid',
}

/**
 *
 * @export
 * @interface ProgramTypeRequest
 */
export interface ProgramTypeRequest {
  /**
   *
   * @type {ProgramType}
   * @memberof ProgramTypeRequest
   */
  programType?: ProgramType;
}
/**
 *
 * @export
 * @interface QuoteData
 */
export interface QuoteData {
  /**
   *
   * @type {ApplicationDetail}
   * @memberof QuoteData
   */
  userAppDetails: ApplicationDetail;
  /**
   *
   * @type {ApplicationDetail}
   * @memberof QuoteData
   */
  finalAppDetails: ApplicationDetail;
  /**
   *
   * @type {Array<string>}
   * @memberof QuoteData
   */
  fieldsChangedByUW: Array<string>;
}
/**
 *
 * @export
 * @interface QuoteDetails
 */
export interface QuoteDetails {
  /**
   *
   * @type {ApplicationSummary}
   * @memberof QuoteDetails
   */
  appSummary: ApplicationSummary;
  /**
   *
   * @type {QuoteSummary}
   * @memberof QuoteDetails
   */
  quoteSummary: QuoteSummary;
  /**
   *
   * @type {PremiumDetails}
   * @memberof QuoteDetails
   */
  premiumDetails: PremiumDetails;
  /**
   *
   * @type {CoverageDetails}
   * @memberof QuoteDetails
   */
  coverageDetails: CoverageDetails;
  /**
   *
   * @type {Array<FormRecord>}
   * @memberof QuoteDetails
   */
  formsDetails: Array<FormRecord>;
  /**
   *
   * @type {Array<PaymentOptionRecord>}
   * @memberof QuoteDetails
   */
  paymentOptions: Array<PaymentOptionRecord>;
  /**
   *
   * @type {DocumentSummary}
   * @memberof QuoteDetails
   */
  documentSummary: DocumentSummary;
}
/**
 *
 * @export
 * @interface QuoteSummary
 */
export interface QuoteSummary {
  /**
   *
   * @type {IndicationOptionTag}
   * @memberof QuoteSummary
   */
  optionTag: IndicationOptionTag;
  /**
   *
   * @type {string}
   * @memberof QuoteSummary
   */
  effectiveDate: string;
  /**
   *
   * @type {number}
   * @memberof QuoteSummary
   */
  totalPremium: number;
  /**
   *
   * @type {string}
   * @memberof QuoteSummary
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface RejectedApplicationResponse
 */
export interface RejectedApplicationResponse {
  /**
   *
   * @type {Array<AppetiteCheckRuleResponse>}
   * @memberof RejectedApplicationResponse
   */
  declinedRules: Array<AppetiteCheckRuleResponse>;
}
/**
 *
 * @export
 * @interface RejectedCoverage
 */
export interface RejectedCoverage {
  /**
   *
   * @type {CoverageType}
   * @memberof RejectedCoverage
   */
  coverageType: CoverageType;
  /**
   *
   * @type {string}
   * @memberof RejectedCoverage
   */
  reason: string;
}
/**
 *
 * @export
 * @interface RenewalAdditionalForm
 */
export interface RenewalAdditionalForm {
  /**
   *
   * @type {DriverList}
   * @memberof RenewalAdditionalForm
   */
  driverList: DriverList;
  /**
   *
   * @type {number}
   * @memberof RenewalAdditionalForm
   */
  numOwnerOperatorUnits: number;
  /**
   *
   * @type {Array<FileMetadata>}
   * @memberof RenewalAdditionalForm
   */
  lossRunFiles: Array<FileMetadata>;
  /**
   *
   * @type {string}
   * @memberof RenewalAdditionalForm
   */
  largeLossComment: string;
  /**
   *
   * @type {string}
   * @memberof RenewalAdditionalForm
   */
  overallComment: string;
  /**
   *
   * @type {Array<FileMetadata>}
   * @memberof RenewalAdditionalForm
   */
  iftaFiles: Array<FileMetadata>;
}
/**
 *
 * @export
 * @interface RenewalClassesAndCommoditiesForm
 */
export interface RenewalClassesAndCommoditiesForm {
  /**
   *
   * @type {Array<OperatingClassDistributionRecord>}
   * @memberof RenewalClassesAndCommoditiesForm
   */
  operatingClassDistribution: Array<OperatingClassDistributionRecord>;
  /**
   *
   * @type {OperatingClass}
   * @memberof RenewalClassesAndCommoditiesForm
   */
  primaryOperatingClass: OperatingClass;
  /**
   *
   * @type {PrimaryCommodityHauled}
   * @memberof RenewalClassesAndCommoditiesForm
   */
  primaryCommodity?: PrimaryCommodityHauled;
  /**
   *
   * @type {CommodityCategory}
   * @memberof RenewalClassesAndCommoditiesForm
   */
  primaryCategory?: CommodityCategory;
  /**
   *
   * @type {CommodityDistribution}
   * @memberof RenewalClassesAndCommoditiesForm
   */
  commodityDistribution?: CommodityDistribution;
  /**
   *
   * @type {Array<AdditionalInformationCommodity>}
   * @memberof RenewalClassesAndCommoditiesForm
   */
  additionalCommodities?: Array<AdditionalInformationCommodity>;
  /**
   *
   * @type {string}
   * @memberof RenewalClassesAndCommoditiesForm
   */
  commoditiesComment?: string;
}
/**
 *
 * @export
 * @interface RenewalCoverageForm
 */
export interface RenewalCoverageForm {
  /**
   *
   * @type {Array<CoverageRecord>}
   * @memberof RenewalCoverageForm
   */
  coveragesRequired: Array<CoverageRecord>;
  /**
   *
   * @type {Array<Array<CoverageType>>}
   * @memberof RenewalCoverageForm
   */
  coveragesWithCombinedDeductibles?: Array<Array<CoverageType>>;
  /**
   *
   * @type {string}
   * @memberof RenewalCoverageForm
   */
  producerId: string;
  /**
   *
   * @type {IndicationOptionTag}
   * @memberof RenewalCoverageForm
   */
  packageType: IndicationOptionTag;
}
/**
 *
 * @export
 * @interface RenewalFilesForm
 */
export interface RenewalFilesForm {
  /**
   *
   * @type {Array<FileMetadata>}
   * @memberof RenewalFilesForm
   */
  lossRunFiles: Array<FileMetadata>;
  /**
   *
   * @type {EquipmentList}
   * @memberof RenewalFilesForm
   */
  equipmentList: EquipmentList;
  /**
   *
   * @type {DriverList}
   * @memberof RenewalFilesForm
   */
  driverList: DriverList;
  /**
   *
   * @type {Array<FileMetadata>}
   * @memberof RenewalFilesForm
   */
  iftaFiles: Array<FileMetadata>;
}
/**
 *
 * @export
 * @interface RenewalLossForm
 */
export interface RenewalLossForm {
  /**
   *
   * @type {Array<LossRunSummaryPerCoverage>}
   * @memberof RenewalLossForm
   */
  lossRunSummaryForm: Array<LossRunSummaryPerCoverage>;
}
/**
 *
 * @export
 * @interface RenewalMetadata
 */
export interface RenewalMetadata {
  /**
   *
   * @type {string}
   * @memberof RenewalMetadata
   */
  originalApplicationId: string;
  /**
   *
   * @type {SectionCompletionMap}
   * @memberof RenewalMetadata
   */
  SectionCompletionMap?: SectionCompletionMap;
  /**
   *
   * @type {Array<FileMetadata>}
   * @memberof RenewalMetadata
   */
  iftaFiles?: Array<FileMetadata>;
  /**
   *
   * @type {boolean}
   * @memberof RenewalMetadata
   */
  belongsToRedList?: boolean;
}
/**
 *
 * @export
 * @interface RenewalOperationsForm
 */
export interface RenewalOperationsForm {
  /**
   *
   * @type {number}
   * @memberof RenewalOperationsForm
   */
  numberOfPowerUnits: number;
  /**
   *
   * @type {number}
   * @memberof RenewalOperationsForm
   */
  projectedMileage: number;
  /**
   *
   * @type {EquipmentList}
   * @memberof RenewalOperationsForm
   */
  equipmentList: EquipmentList;
  /**
   *
   * @type {Array<TerminalLocation>}
   * @memberof RenewalOperationsForm
   */
  terminalLocations?: Array<TerminalLocation>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum SafetyContactRole {
  Owner = 'Owner',
  SafetyManager = 'SafetyManager',
  FleetManager = 'FleetManager',
  Other = 'Other',
}

/**
 *
 * @export
 * @interface SectionCompletionMap
 */
export interface SectionCompletionMap {
  /**
   *
   * @type {boolean}
   * @memberof SectionCompletionMap
   */
  coverages: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SectionCompletionMap
   */
  operations: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SectionCompletionMap
   */
  classesAndCommodities: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SectionCompletionMap
   */
  lossHistory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SectionCompletionMap
   */
  additionalInformation: boolean;
}
/**
 *
 * @export
 * @interface SelectedIndication
 */
export interface SelectedIndication {
  /**
   *
   * @type {string}
   * @memberof SelectedIndication
   */
  id: string;
}
/**
 *
 * @export
 * @interface SpeedgaugeBaseConnData
 */
export interface SpeedgaugeBaseConnData {
  /**
   *
   * @type {string}
   * @memberof SpeedgaugeBaseConnData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SpeedgaugeBaseConnData
   */
  email: string;
}
/**
 *
 * @export
 * @interface StatusTracker
 */
export interface StatusTracker {
  /**
   *
   * @type {StatusTrackerRecord}
   * @memberof StatusTracker
   */
  statusSubmitted: StatusTrackerRecord;
  /**
   *
   * @type {StatusTrackerRecord}
   * @memberof StatusTracker
   */
  statusWaitingELDTelematicsConsent: StatusTrackerRecord;
  /**
   *
   * @type {StatusTrackerRecord}
   * @memberof StatusTracker
   */
  statusWaitingELDTelematicsData: StatusTrackerRecord;
  /**
   *
   * @type {StatusTrackerRecord}
   * @memberof StatusTracker
   */
  statusUnderReviewForQuote: StatusTrackerRecord;
}
/**
 *
 * @export
 * @interface StatusTrackerRecord
 */
export interface StatusTrackerRecord {
  /**
   *
   * @type {string}
   * @memberof StatusTrackerRecord
   */
  finalizedDate?: string;
  /**
   *
   * @type {number}
   * @memberof StatusTrackerRecord
   */
  minEstimatedDays?: number;
  /**
   *
   * @type {number}
   * @memberof StatusTrackerRecord
   */
  maxEstimatedDays?: number;
}
/**
 *
 * @export
 * @interface SupportedOperationsRecord
 */
export interface SupportedOperationsRecord {
  /**
   *
   * @type {OperatingClass}
   * @memberof SupportedOperationsRecord
   */
  operatingClass: OperatingClass;
  /**
   *
   * @type {Array<PrimaryCommodityHauled>}
   * @memberof SupportedOperationsRecord
   */
  primaryOperatingCommodities: Array<PrimaryCommodityHauled>;
}
/**
 *
 * @export
 * @interface SupportedOperationsRecordV2
 */
export interface SupportedOperationsRecordV2 {
  /**
   *
   * @type {Array<SupportedOperationsRecord>}
   * @memberof SupportedOperationsRecordV2
   */
  data: Array<SupportedOperationsRecord>;
  /**
   *
   * @type {Array<CommodityCategory>}
   * @memberof SupportedOperationsRecordV2
   */
  categories: Array<CommodityCategory>;
  /**
   *
   * @type {Array<OperatingClass>}
   * @memberof SupportedOperationsRecordV2
   */
  classes: Array<OperatingClass>;
  /**
   *
   * @type {Array<CommodityHauled>}
   * @memberof SupportedOperationsRecordV2
   */
  commodities: Array<CommodityHauled>;
}
/**
 *
 * @export
 * @interface SymbolAndDefinition
 */
export interface SymbolAndDefinition {
  /**
   *
   * @type {string}
   * @memberof SymbolAndDefinition
   */
  symbol: string;
  /**
   *
   * @type {string}
   * @memberof SymbolAndDefinition
   */
  definition: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TSP {
  TspSamsara = 'TSPSamsara',
  TspKeepTruckin = 'TSPKeepTruckin',
  TspGeotab = 'TSPGeotab',
  TspAdvantageAssetTracking = 'TSPAdvantageAssetTracking',
  TspAssuredTelematics = 'TSPAssuredTelematics',
  TspEagleWireless = 'TSPEagleWireless',
  TspgoFleet = 'TSPGoFleet',
  TspGridline = 'TSPGridline',
  TsponTrakSolutions = 'TSPOnTrakSolutions',
  TspRushEnterpises = 'TSPRushEnterpises',
  TspTraxxisGps = 'TSPTraxxisGPS',
  TspOmnitracsXrs = 'TSPOmnitracsXRS',
  TspRandMcNally = 'TSPRandMcNally',
  TspAzuga = 'TSPAzuga',
  TspjjKeller = 'TSPJJKeller',
  TspBigRoad = 'TSPBigRoad',
  TspTeletracNavman = 'TSPTeletracNavman',
  TspTrimble = 'TSPTrimble',
  TspVerizonConnect = 'TSPVerizonConnect',
  TspVerizonConnectReveal = 'TSPVerizonConnectReveal',
  TspMountainEld = 'TspMountainEld',
  TspVerizonConnectFleet = 'TSPVerizonConnectFleet',
  TspWebfleet = 'TSPWebfleet',
  Tsperoad = 'TSPEROAD',
  TspgpsInsight = 'TSPGPSInsight',
  TspZonar = 'TSPZonar',
  TspAgilisLinxup = 'TSPAgilisLinxup',
  TspOther = 'TSPOther',
  TspTransflo = 'TSPTransflo',
  TspActsoftEncore = 'TSPActsoftEncore',
  TspBlueInkTechnology = 'TSPBlueInkTechnology',
  TspGeoforce = 'TSPGeoforce',
  TspSimpleTruckEld = 'TSPSimpleTruckELD',
  TspGorillaSafety = 'TSPGorillaSafety',
  TspinTouchGps = 'TSPInTouchGPS',
  TspezFleet = 'TSPEZFleet',
  TspOrbcomm = 'TSPOrbcomm',
  TspNextraq = 'TSPNextraq',
  TspArgosConnectedSolutions = 'TSPArgosConnectedSolutions',
  TspariFleet = 'TSPARIFleet',
  TspatAndTFleet = 'TSPATAndTFleet',
  TspatAndTFleetComplete = 'TSPATAndTFleetComplete',
  TspAttriX = 'TSPAttriX',
  TspAwareGps = 'TSPAwareGPS',
  TspBadgerFleetSolutions = 'TSPBadgerFleetSolutions',
  TspBlueArrow = 'TSPBlueArrow',
  TspCarrierHq = 'TSPCarrierHQ',
  TspClutchEld = 'TSPClutchELD',
  TspCommandGps = 'TSPCommandGPS',
  TspCyntrXeldPlus = 'TSPCyntrXELDPlus',
  TspDriveEdr = 'TSPDriveEDR',
  TspeldFleet = 'TSPELDFleet',
  TspeldMandatePlus = 'TSPELDMandatePlus',
  TspeldMandatePro = 'TSPELDMandatePro',
  TspeldOne = 'TSPELDOne',
  TspeldRider = 'TSPELDRider',
  TspenVueTelematics = 'TSPEnVueTelematics',
  TspFleetComplete = 'TSPFleetComplete',
  TspFleetNavSystems = 'TSPFleetNavSystems',
  TspFleetProfitCenter = 'TSPFleetProfitCenter',
  TspFleetBossGps = 'TSPFleetBossGPS',
  TspFleetistics = 'TSPFleetistics',
  TspFleetLocate21 = 'TSPFleetLocate21',
  TspFleetLocateAdvancedAndCompliance = 'TSPFleetLocateAdvancedAndCompliance',
  TspFleetLocateEld = 'TSPFleetLocateELD',
  TspFleetmaster = 'TSPFleetmaster',
  TspFleetSharp = 'TSPFleetSharp',
  TspFlexport = 'TSPFlexport',
  TspForceByMojio = 'TSPForceByMojio',
  TspGlobalEld = 'TSPGlobalELD',
  TspGlostone = 'TSPGlostone',
  TspgoGps = 'TSPGoGPS',
  TspgpsCommander = 'TSPGPSCommander',
  TspgpsFleetFinder = 'TSPGPSFleetFinder',
  TspgpsSolutions = 'TSPGPSSolutions',
  TspgpsTab = 'TSPGPSTab',
  TspgpsTrackingCanada = 'TSPGPSTrackingCanada',
  TspgpsTrackit = 'TSPGPSTrackit',
  TspGrayboxSolutions = 'TSPGrayboxSolutions',
  TspHighPointGps = 'TSPHighPointGPS',
  TspInsightMobileData = 'TSPInsightMobileData',
  TspStreetEagle = 'TSPStreetEagle',
  TspIntellishift = 'TSPIntellishift',
  Tspvts = 'TSPVTS',
  TspIntouchEld = 'TSPIntouchELD',
  TspioTab = 'TSPIoTab',
  TspLynx = 'TSPLynx',
  TspMasterEld = 'TSPMasterELD',
  TspMonarchGps = 'TSPMonarchGPS',
  TspOmnitracs = 'TSPOmnitracs',
  TspOneStepGps = 'TSPOneStepGPS',
  TspOrion = 'TSPOrion',
  TspPositrace = 'TSPPositrace',
  TspPowerFleet = 'TSPPowerFleet',
  TspPrePassEld = 'TSPPrePassELD',
  TspQualityGps = 'TSPQualityGPS',
  TspRealEld = 'TSPRealELD',
  TspRightTruckingEld = 'TSPRightTruckingELD',
  TsprmjTechnologies = 'TSPRMJTechnologies',
  TspRoadStarEld = 'TSPRoadStarELD',
  TspSafetyVision = 'TSPSafetyVision',
  TspSimpleElog = 'TSPSimpleELOG',
  TspSmartDrive = 'TSPSmartDrive',
  TspSmartWitness = 'TSPSmartWitness',
  TsptMobile = 'TSPTMobile',
  TspTangerine = 'TSPTangerine',
  Tsptfmeld = 'TSPTFMELD',
  TspTrackOnHos = 'TSPTrackOnHOS',
  TspTruckerPathEldPro = 'TSPTruckerPathELDPro',
  TspTruPathSystems = 'TSPTruPathSystems',
  TspVertrax = 'TSPVertrax',
  Tspzeld = 'TSPZELD',
  TspZenduit = 'TSPZenduit',
  Tsp3Md = 'TSP3MD',
  Tsp888Eld = 'TSP888ELD',
  TspApolloEld = 'TSPApolloELD',
  TspBlueStarEld = 'TSPBlueStarELD',
  Tspcneld = 'TSPCNELD',
  TspcteLogEld = 'TSPCTELogELD',
  TspDreamEld = 'TSPDreamELD',
  TspeldTab = 'TSPELDTab',
  TspExpressWayEld = 'TSPExpressWayELD',
  TspezeldSolutions = 'TSPEZELDSolutions',
  TspezLogz = 'TSPEZLogz',
  TspFleetmatics = 'TSPFleetmatics',
  TspForwardThinkingEld = 'TSPForwardThinkingELD',
  TspGoodDealGps = 'TSPGoodDealGPS',
  TspHorizonPathEld = 'TSPHorizonPathELD',
  Tspkskeld = 'TSPKSKELD',
  TspLogPlusEld = 'TSPLogPlusELD',
  TspLookTruckEld = 'TSPLookTruckELD',
  TspLytXDriveCam = 'TSPLytXDriveCam',
  Tspmy20Eld = 'TSPMy20ELD',
  TspNetradyneInc = 'TSPNetradyneInc',
  TspOaneEld = 'TSPOaneELD',
  TspOnePlusEld = 'TSPOnePlusELD',
  TspOptimaEld = 'TSPOptimaELD',
  TspPhoenixEld = 'TSPPhoenixELD',
  TspProRideEld = 'TspProRideEld',
  TspReliableEld = 'TSPReliableELD',
  TspSwiftEld = 'TSPSwiftELD',
  Tsptmeld = 'TSPTMELD',
  Tsptteld = 'TSPTTELD',
  TspTrackEnsureInc = 'TSPTrackEnsureInc',
  TspUnityEld = 'TSPUnityELD',
  TspVistaEld = 'TSPVistaELD',
  TspvLogEld = 'TSPVLogELD',
  TspWorldTruckingEld = 'TSPWorldTruckingELD',
  Tspxeld = 'TSPXELD',
  TspContiGo = 'TspContiGO',
  TspRigbot = 'TSPRigbot',
  TspColumbusEld = 'TSPColumbusELD',
  TspBlackBearEld = 'TSPBlackBearELD',
  Tspelog42 = 'TSPELOG42',
  TspPeopleNet = 'TSPPeopleNet',
  TspTrendyEld = 'TSPTrendyELD',
  TspCoretex = 'TSPCoretex',
  TsPeasiTrack = 'TSPeasiTrack',
  TspDigitalEld = 'TSPDigitalELD',
  TspAlfaEld = 'TSPAlfaELD',
  TspDailyEld = 'TSPDailyELD',
  TspRoadReadySolutions = 'TSPRoadReadySolutions',
  Tspmteld = 'TSPMTELD',
  Tspsfeld = 'TSPSFELD',
  TspPlatformScience = 'TSPPlatformScience',
  Tspsreld = 'TSPSRELD',
  TspProLogs = 'TSPProLogs',
  Tspbellfameld = 'TSPBELLFAMELD',
  Tspdsgelogs = 'TSPDSGELOGS',
  TspTruckXeld = 'TSPTruckXELD',
  TspGarmin = 'TSPGarmin',
  Tspfactoreld = 'TSPFACTORELD',
  Tspsmartchoicelogseld = 'TSPSMARTCHOICELOGSELD',
  Tspstateelogs = 'TSPSTATEELOGS',
  TspisaacInstruments = 'TSPISAACInstruments',
  TspKonexial = 'TSPKonexial',
  Tsptrusteld = 'TSPTRUSTELD',
  TsplbTechnology = 'TSPLBTechnology',
  TspPowerEld = 'TSPPowerELD',
  Tsplegacyeld = 'TSPLEGACYELD',
  TspMondo = 'TSPMondo',
  Tspmoonlighteld = 'TSPMOONLIGHTELD',
  TspZippyEld = 'TSPZippyELD',
  Tspevoeld = 'TSPEVOELD',
}

/**
 *
 * @export
 * @interface TSPConnection
 */
export interface TSPConnection {
  /**
   *
   * @type {ProgramType}
   * @memberof TSPConnection
   */
  programType?: ProgramType;
  /**
   *
   * @type {TSP}
   * @memberof TSPConnection
   */
  tsp: TSP;
  /**
   *
   * @type {TelematicsConsentKind}
   * @memberof TSPConnection
   */
  consentKind: TelematicsConsentKind;
  /**
   *
   * @type {SpeedgaugeBaseConnData}
   * @memberof TSPConnection
   */
  speedgauge?: SpeedgaugeBaseConnData;
  /**
   *
   * @type {GeoTabConnData}
   * @memberof TSPConnection
   */
  speedgaugeGeotab?: GeoTabConnData;
  /**
   *
   * @type {BasicAuthConnData}
   * @memberof TSPConnection
   */
  basicAuth?: BasicAuthConnData;
  /**
   *
   * @type {ApiKeyAuthConnData}
   * @memberof TSPConnection
   */
  apiKeyAuth?: ApiKeyAuthConnData;
  /**
   *
   * @type {BasicAuthWithLoginIdConnData}
   * @memberof TSPConnection
   */
  basicAuthWithLoginId?: BasicAuthWithLoginIdConnData;
  /**
   *
   * @type {TSPConnectionAdditionalInfo}
   * @memberof TSPConnection
   */
  additionalInfo?: TSPConnectionAdditionalInfo;
}
/**
 *
 * @export
 * @interface TSPConnectionAdditionalInfo
 */
export interface TSPConnectionAdditionalInfo {
  /**
   *
   * @type {string}
   * @memberof TSPConnectionAdditionalInfo
   */
  freeTextTSPName: string;
}
/**
 *
 * @export
 * @interface TSPConnectionCompleteRequest
 */
export interface TSPConnectionCompleteRequest {
  /**
   *
   * @type {ProgramType}
   * @memberof TSPConnectionCompleteRequest
   */
  programType?: ProgramType;
  /**
   *
   * @type {OAuthConnectionData}
   * @memberof TSPConnectionCompleteRequest
   */
  TSPSamsara?: OAuthConnectionData;
  /**
   *
   * @type {OAuthConnectionData}
   * @memberof TSPConnectionCompleteRequest
   */
  TSPKeepTruckin?: OAuthConnectionData;
}
/**
 *
 * @export
 * @interface TSPConnectionCompleteResponse
 */
export interface TSPConnectionCompleteResponse {
  /**
   *
   * @type {string}
   * @memberof TSPConnectionCompleteResponse
   */
  applicationID: string;
  /**
   *
   * @type {string}
   * @memberof TSPConnectionCompleteResponse
   */
  handleID?: string;
  /**
   *
   * @type {string}
   * @memberof TSPConnectionCompleteResponse
   */
  safetyReportURI: string;
  /**
   * When present, contains a link to present the sign-up page for this user, and includes the shareID as part of the sign-up URI.
   * @type {string}
   * @memberof TSPConnectionCompleteResponse
   */
  signUpURI?: string;
}
/**
 *
 * @export
 * @interface TSPConnectionResponse
 */
export interface TSPConnectionResponse {
  /**
   *
   * @type {string}
   * @memberof TSPConnectionResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof TSPConnectionResponse
   */
  handleID?: string;
}
/**
 *
 * @export
 * @interface TSPRecord
 */
export interface TSPRecord {
  /**
   *
   * @type {string}
   * @memberof TSPRecord
   */
  name: string;
  /**
   *
   * @type {TSP}
   * @memberof TSPRecord
   */
  tsp: TSP;
  /**
   *
   * @type {TelematicsConsentKind}
   * @memberof TSPRecord
   */
  consentKind: TelematicsConsentKind;
  /**
   *
   * @type {string}
   * @memberof TSPRecord
   */
  logoUrl?: string;
}
/**
 *
 * @export
 * @interface TargetPrice
 */
export interface TargetPrice {
  /**
   *
   * @type {CoverageType}
   * @memberof TargetPrice
   */
  coverageType: CoverageType;
  /**
   *
   * @type {number}
   * @memberof TargetPrice
   */
  totalPremium?: number;
}
/**
 *
 * @export
 * @interface TelematicsApplicationConsentLinkRequest
 */
export interface TelematicsApplicationConsentLinkRequest {
  /**
   *
   * @type {string}
   * @memberof TelematicsApplicationConsentLinkRequest
   */
  applicationId: string;
  /**
   *
   * @type {ProgramType}
   * @memberof TelematicsApplicationConsentLinkRequest
   */
  programType: ProgramType;
  /**
   *
   * @type {TelematicsApplicationConsentLinkRequestInsuredInformation}
   * @memberof TelematicsApplicationConsentLinkRequest
   */
  insuredInformation?: TelematicsApplicationConsentLinkRequestInsuredInformation;
}
/**
 *
 * @export
 * @interface TelematicsApplicationConsentLinkRequestInsuredInformation
 */
export interface TelematicsApplicationConsentLinkRequestInsuredInformation {
  /**
   *
   * @type {string}
   * @memberof TelematicsApplicationConsentLinkRequestInsuredInformation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsApplicationConsentLinkRequestInsuredInformation
   */
  email: string;
}
/**
 *
 * @export
 * @interface TelematicsApplicationConsentLinkResponse
 */
export interface TelematicsApplicationConsentLinkResponse {
  /**
   * link that redirects you to the consent flow
   * @type {string}
   * @memberof TelematicsApplicationConsentLinkResponse
   */
  link: string;
}
/**
 *
 * @export
 * @interface TelematicsConnectionInfo
 */
export interface TelematicsConnectionInfo {
  /**
   *
   * @type {string}
   * @memberof TelematicsConnectionInfo
   */
  handleID: string;
  /**
   *
   * @type {TSP}
   * @memberof TelematicsConnectionInfo
   */
  tsp: TSP;
  /**
   *
   * @type {TelematicsConnectionStatus}
   * @memberof TelematicsConnectionInfo
   */
  status: TelematicsConnectionStatus;
  /**
   *
   * @type {string}
   * @memberof TelematicsConnectionInfo
   */
  error?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TelematicsConnectionInfo
   * @deprecated
   */
  validationErrors?: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TelematicsConnectionStatus {
  TelematicsConnectionStatusUnknown = 'TelematicsConnectionStatusUnknown',
  TelematicsConnectionStatusInitiated = 'TelematicsConnectionStatusInitiated',
  TelematicsConnectionStatusAuthorized = 'TelematicsConnectionStatusAuthorized',
  TelematicsConnectionStatusRejected = 'TelematicsConnectionStatusRejected',
  TelematicsConnectionStatusConnected = 'TelematicsConnectionStatusConnected',
  TelematicsConnectionStatusDisconnected = 'TelematicsConnectionStatusDisconnected',
  TelematicsConnectionStatusInternallyDisabled = 'TelematicsConnectionStatusInternallyDisabled',
  TelematicsConnectionStatusPermanentlyLost = 'TelematicsConnectionStatusPermanentlyLost',
  TelematicsConnectionStatusPermanentlyDeleted = 'TelematicsConnectionStatusPermanentlyDeleted',
}

/**
 *
 * @export
 * @interface TelematicsConsentInfo
 */
export interface TelematicsConsentInfo {
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentInfo
   */
  insuredName?: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentInfo
   */
  insuredEmail?: string;
  /**
   *
   * @type {number}
   * @memberof TelematicsConsentInfo
   */
  dotNumber: number;
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentInfo
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentInfo
   */
  agencyId?: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentInfo
   */
  agencyName?: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentInfo
   */
  creatorId?: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentInfo
   */
  producerName?: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentInfo
   */
  producerEmail?: string;
}
/**
 *
 * @export
 * @interface TelematicsConsentInfoResponse
 */
export interface TelematicsConsentInfoResponse {
  /**
   *
   * @type {boolean}
   * @memberof TelematicsConsentInfoResponse
   */
  isExpired: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TelematicsConsentInfoResponse
   */
  isRevoked: boolean;
  /**
   *
   * @type {TelematicsConsentInfo}
   * @memberof TelematicsConsentInfoResponse
   */
  telematicsConsentInfo?: TelematicsConsentInfo;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TelematicsConsentKind {
  TelematicsConsentKindOAuth = 'TelematicsConsentKindOAuth',
  TelematicsConsentKindSpeedgauge = 'TelematicsConsentKindSpeedgauge',
  TelematicsConsentKindSpeedgaugeGeotab = 'TelematicsConsentKindSpeedgaugeGeotab',
  TelematicsConsentKindBasicAuth = 'TelematicsConsentKindBasicAuth',
  TelematicsConsentKindApiKey = 'TelematicsConsentKindApiKey',
  TelematicsConsentKindBasicAuthWithLoginId = 'TelematicsConsentKindBasicAuthWithLoginId',
}

/**
 *
 * @export
 * @interface TelematicsConsentRequestEmail
 */
export interface TelematicsConsentRequestEmail {
  /**
   * List of email addresses to send the consent link email to.
   * @type {Array<Email>}
   * @memberof TelematicsConsentRequestEmail
   */
  to: Array<Email>;
  /**
   * List of email addresses to CC the consent link email to.
   * @type {Array<Email>}
   * @memberof TelematicsConsentRequestEmail
   */
  cc?: Array<Email>;
}
/**
 * Mark the consent request email task as completed, and optionally send a request email to the insured.
 * @export
 * @interface TelematicsConsentRequestEmailData
 */
export interface TelematicsConsentRequestEmailData {
  /**
   *
   * @type {boolean}
   * @memberof TelematicsConsentRequestEmailData
   */
  completed: boolean;
  /**
   *
   * @type {TelematicsConsentRequestEmail}
   * @memberof TelematicsConsentRequestEmailData
   */
  email?: TelematicsConsentRequestEmail;
}
/**
 *
 * @export
 * @interface TelematicsConsentRequestEmailResponse
 */
export interface TelematicsConsentRequestEmailResponse {
  /**
   *
   * @type {boolean}
   * @memberof TelematicsConsentRequestEmailResponse
   */
  completed: boolean;
  /**
   *
   * @type {string}
   * @memberof TelematicsConsentRequestEmailResponse
   */
  completedAt?: string;
}
/**
 *
 * @export
 * @interface TelematicsFleetApplicationInfo
 */
export interface TelematicsFleetApplicationInfo {
  /**
   *
   * @type {number}
   * @memberof TelematicsFleetApplicationInfo
   */
  dotNumber?: number;
  /**
   *
   * @type {string}
   * @memberof TelematicsFleetApplicationInfo
   */
  fleetName?: string;
  /**
   *
   * @type {Array<FleetInfoVehicle>}
   * @memberof TelematicsFleetApplicationInfo
   */
  vehicles?: Array<FleetInfoVehicle>;
  /**
   *
   * @type {number}
   * @memberof TelematicsFleetApplicationInfo
   */
  numPowerUnits: number;
}
/**
 *
 * @export
 * @interface TelematicsFleetApplicationInfoAllOf
 */
export interface TelematicsFleetApplicationInfoAllOf {
  /**
   *
   * @type {number}
   * @memberof TelematicsFleetApplicationInfoAllOf
   */
  numPowerUnits: number;
}
/**
 *
 * @export
 * @interface TelematicsFleetInfoResponse
 */
export interface TelematicsFleetInfoResponse {
  /**
   *
   * @type {TelematicsFleetApplicationInfo}
   * @memberof TelematicsFleetInfoResponse
   */
  applicationInfo: TelematicsFleetApplicationInfo;
  /**
   *
   * @type {TelematicsFleetTelematicsInfo}
   * @memberof TelematicsFleetInfoResponse
   */
  telematicsInfo: TelematicsFleetTelematicsInfo;
  /**
   *
   * @type {string}
   * @memberof TelematicsFleetInfoResponse
   */
  handleID: string;
  /**
   *
   * @type {TSP}
   * @memberof TelematicsFleetInfoResponse
   */
  tsp: TSP;
  /**
   *
   * @type {Array<string>}
   * @memberof TelematicsFleetInfoResponse
   */
  validationErrors?: Array<TelematicsFleetInfoResponseValidationErrorsEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum TelematicsFleetInfoResponseValidationErrorsEnum {
  MissingTelematicsVehicles = 'MissingTelematicsVehicles',
  InvalidDotNumber = 'InvalidDOTNumber',
  InvalidFleetName = 'InvalidFleetName',
}

/**
 *
 * @export
 * @interface TelematicsFleetTelematicsInfo
 */
export interface TelematicsFleetTelematicsInfo {
  /**
   *
   * @type {number}
   * @memberof TelematicsFleetTelematicsInfo
   */
  dotNumber?: number;
  /**
   *
   * @type {string}
   * @memberof TelematicsFleetTelematicsInfo
   */
  fleetName?: string;
  /**
   *
   * @type {Array<FleetInfoVehicle>}
   * @memberof TelematicsFleetTelematicsInfo
   */
  vehicles?: Array<FleetInfoVehicle>;
  /**
   *
   * @type {number}
   * @memberof TelematicsFleetTelematicsInfo
   */
  matchingPowerUnits: number;
}
/**
 *
 * @export
 * @interface TelematicsFleetTelematicsInfoAllOf
 */
export interface TelematicsFleetTelematicsInfoAllOf {
  /**
   *
   * @type {number}
   * @memberof TelematicsFleetTelematicsInfoAllOf
   */
  matchingPowerUnits: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TelematicsIdentifierType {
  Dot = 'DOT',
  Vin = 'VIN',
  ConnectionHandleId = 'ConnectionHandleId',
}

/**
 *
 * @export
 * @interface TelematicsInfo
 */
export interface TelematicsInfo {
  /**
   *
   * @type {string}
   * @memberof TelematicsInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsInfo
   */
  linkEmailedAt?: string;
  /**
   *
   * @type {string}
   * @memberof TelematicsInfo
   */
  link: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TelematicsVehicleStatKind {
  GpsLog = 'GPSLog',
  EngineStateLog = 'EngineStateLog',
  OdometerLog = 'OdometerLog',
}

/**
 *
 * @export
 * @interface TelematicsVehicleStatsItem
 */
export interface TelematicsVehicleStatsItem {
  /**
   *
   * @type {string}
   * @memberof TelematicsVehicleStatsItem
   */
  vin: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TelematicsVehicleStatsItem
   */
  paths: Array<string>;
}
/**
 *
 * @export
 * @interface TerminalLocation
 */
export interface TerminalLocation {
  /**
   *
   * @type {string}
   * @memberof TerminalLocation
   */
  addressLineOne: string;
  /**
   *
   * @type {string}
   * @memberof TerminalLocation
   */
  addressLineTwo?: string;
  /**
   *
   * @type {string}
   * @memberof TerminalLocation
   */
  zipCode: string;
  /**
   *
   * @type {USState}
   * @memberof TerminalLocation
   */
  usState: USState;
  /**
   *
   * @type {TypeOfTerminal}
   * @memberof TerminalLocation
   */
  typeOfTerminal: TypeOfTerminal;
  /**
   *
   * @type {boolean}
   * @memberof TerminalLocation
   */
  isGuarded: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TerminalLocation
   */
  isGated: boolean;
}
/**
 *
 * @export
 * @interface TimeInterval
 */
export interface TimeInterval {
  /**
   *
   * @type {string}
   * @memberof TimeInterval
   */
  start: string;
  /**
   *
   * @type {string}
   * @memberof TimeInterval
   */
  end: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TypeOfTerminal {
  Terminal = 'Terminal',
  Dock = 'Dock',
  Office = 'Office',
  DropLot = 'DropLot',
}

/**
 * Two character short code for the US state the driver is licensed in.
 * @export
 * @enum {string}
 */

export enum USState {
  Al = 'AL',
  Ak = 'AK',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum UTMParamMedium {
  QuotingApp = 'quoting_app',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum UTMParamSource {
  TelematicsConsent = 'telematics_consent',
}

/**
 *
 * @export
 * @interface UpdateApplicationRedListForm
 */
export interface UpdateApplicationRedListForm {
  /**
   *
   * @type {boolean}
   * @memberof UpdateApplicationRedListForm
   */
  belongsToRedList: boolean;
}
/**
 *
 * @export
 * @interface UploadFileRequest
 */
export interface UploadFileRequest {
  /**
   *
   * @type {any}
   * @memberof UploadFileRequest
   */
  file: any;
  /**
   *
   * @type {string}
   * @memberof UploadFileRequest
   */
  fileType: string;
  /**
   *
   * @type {string}
   * @memberof UploadFileRequest
   */
  fileDestinationGroup: string;
}
/**
 *
 * @export
 * @interface UploadFilesRequest
 */
export interface UploadFilesRequest {
  /**
   *
   * @type {Array<any>}
   * @memberof UploadFilesRequest
   */
  files: Array<any>;
  /**
   *
   * @type {Array<FileInfo>}
   * @memberof UploadFilesRequest
   */
  filesInfo: Array<FileInfo>;
}
/**
 *
 * @export
 * @interface UploadFlatFileRequest
 */
export interface UploadFlatFileRequest {
  /**
   *
   * @type {FlatfileMetadata}
   * @memberof UploadFlatFileRequest
   */
  flatfileMetadata: FlatfileMetadata;
  /**
   *
   * @type {FileType}
   * @memberof UploadFlatFileRequest
   */
  fileType: FileType;
  /**
   *
   * @type {FileDestinationGroup}
   * @memberof UploadFlatFileRequest
   */
  fileDestinationGroup: FileDestinationGroup;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name: string;
}
/**
 *
 * @export
 * @interface WeightedCommodityRecord
 */
export interface WeightedCommodityRecord {
  /**
   *
   * @type {CommodityHauled}
   * @memberof WeightedCommodityRecord
   */
  commodity: CommodityHauled;
  /**
   *
   * @type {CommodityCategory}
   * @memberof WeightedCommodityRecord
   */
  category: CommodityCategory;
  /**
   *
   * @type {number}
   * @memberof WeightedCommodityRecord
   */
  avgDollarValueHauled: number;
  /**
   *
   * @type {number}
   * @memberof WeightedCommodityRecord
   */
  maxDollarValueHauled: number;
  /**
   *
   * @type {number}
   * @memberof WeightedCommodityRecord
   */
  percentageOfHauls: number;
}
/**
 *
 * @export
 * @interface ZipcodeDecodeResponse
 */
export interface ZipcodeDecodeResponse {
  /**
   *
   * @type {string}
   * @memberof ZipcodeDecodeResponse
   */
  cityName: string;
  /**
   *
   * @type {string}
   * @memberof ZipcodeDecodeResponse
   */
  stateCode: string;
  /**
   *
   * @type {string}
   * @memberof ZipcodeDecodeResponse
   */
  stateName: string;
  /**
   *
   * @type {string}
   * @memberof ZipcodeDecodeResponse
   */
  countyName: string;
}

/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Update BD for agency and program type
     * @param {AgencyBDMappingRequest} agencyBDMappingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    agencyBdMappingPut: async (
      agencyBDMappingRequest: AgencyBDMappingRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'agencyBDMappingRequest' is not null or undefined
      assertParamExists(
        'agencyBdMappingPut',
        'agencyBDMappingRequest',
        agencyBDMappingRequest,
      );
      const localVarPath = `/agency_bd_mapping`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        agencyBDMappingRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get All BD Mappings for Agencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    agencyBdMappingsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/agency_bd_mappings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the current information of the additional information form
     * @param {string} applicationID
     * @param {AdditionalInformationForm} additionalInformationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDAdditionalInformationPut: async (
      applicationID: string,
      additionalInformationForm: AdditionalInformationForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDAdditionalInformationPut',
        'applicationID',
        applicationID,
      );
      // verify required parameter 'additionalInformationForm' is not null or undefined
      assertParamExists(
        'applicationApplicationIDAdditionalInformationPut',
        'additionalInformationForm',
        additionalInformationForm,
      );
      const localVarPath =
        `/application/{applicationID}/additional_information`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        additionalInformationForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns app pdf link
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDAppPdfLinkGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDAppPdfLinkGet',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/app_pdf_link`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDBasicInfoGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDBasicInfoGet',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/basic_info`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the current basic information
     * @param {string} applicationID
     * @param {ApplicationBasicInfoForm} applicationBasicInfoForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDBasicInfoPatch: async (
      applicationID: string,
      applicationBasicInfoForm: ApplicationBasicInfoForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDBasicInfoPatch',
        'applicationID',
        applicationID,
      );
      // verify required parameter 'applicationBasicInfoForm' is not null or undefined
      assertParamExists(
        'applicationApplicationIDBasicInfoPatch',
        'applicationBasicInfoForm',
        applicationBasicInfoForm,
      );
      const localVarPath = `/application/{applicationID}/basic_info`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationBasicInfoForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Decline an application
     * @param {string} applicationID
     * @param {DeclineAppForm} declineAppForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDDeclinePost: async (
      applicationID: string,
      declineAppForm: DeclineAppForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDDeclinePost',
        'applicationID',
        applicationID,
      );
      // verify required parameter 'declineAppForm' is not null or undefined
      assertParamExists(
        'applicationApplicationIDDeclinePost',
        'declineAppForm',
        declineAppForm,
      );
      const localVarPath = `/application/{applicationID}/decline`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        declineAppForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return application\'s information
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDGet',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return the indication options generated
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDIndicationOptionsGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDIndicationOptionsGet',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/indication/options`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Select one indication option
     * @param {string} applicationID
     * @param {SelectedIndication} selectedIndication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDIndicationOptionsSelectPost: async (
      applicationID: string,
      selectedIndication: SelectedIndication,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDIndicationOptionsSelectPost',
        'applicationID',
        applicationID,
      );
      // verify required parameter 'selectedIndication' is not null or undefined
      assertParamExists(
        'applicationApplicationIDIndicationOptionsSelectPost',
        'selectedIndication',
        selectedIndication,
      );
      const localVarPath =
        `/application/{applicationID}/indication/options/select`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        selectedIndication,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the current information of the indication from
     * @param {string} applicationID
     * @param {IndicationForm} indicationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDIndicationPut: async (
      applicationID: string,
      indicationForm: IndicationForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDIndicationPut',
        'applicationID',
        applicationID,
      );
      // verify required parameter 'indicationForm' is not null or undefined
      assertParamExists(
        'applicationApplicationIDIndicationPut',
        'indicationForm',
        indicationForm,
      );
      const localVarPath = `/application/{applicationID}/indication`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        indicationForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Submit the indication form to generate indication options
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDIndicationSubmitPost: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDIndicationSubmitPost',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/indication/submit`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Post a Policy and transition to PolicyGenerated state
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDPolicyPost: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDPolicyPost',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/policy`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return application\'s public info
     * @param {string} applicationID
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDPublicInfoGet: async (
      applicationID: string,
      programType?: ProgramType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDPublicInfoGet',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/public_info`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (programType !== undefined) {
        localVarQueryParameter['programType'] = programType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the final data used for the quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDQuoteDataGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDQuoteDataGet',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/quote_data`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns quote details
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDQuoteGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDQuoteGet',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/quote`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns quote pdf link
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDQuotePdfLinkGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDQuotePdfLinkGet',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/quote_pdf_link`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a renewal application from an existing application
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDRenewPost: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDRenewPost',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/renew`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return application\'s state
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDStateGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDStateGet',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/state`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the status tracker
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDStatusTrackerGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDStatusTrackerGet',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/status_tracker`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Submit the application for underwriter review
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDSubmitPost: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDSubmitPost',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/submit`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to initiate the telematics connection flow with a TSP. It is called by the frontend when the insured selects a TSP in the consent flow and enters (if needed), the details required to make a connection to the TSP using the provided `consentKind`. NOTE: This endpoint is public because it is currently being consumed by a public screen in the frontend
     * @param {string} applicationID
     * @param {TSPConnection} [tSPConnection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDTelematicsConnectionPost: async (
      applicationID: string,
      tSPConnection?: TSPConnection,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDTelematicsConnectionPost',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/telematics_connection`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tSPConnection,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDTelematicsConsentRequestEmailPost: async (
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDTelematicsConsentRequestEmailPost',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/telematics_consent_request_email`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        telematicsConsentRequestEmailData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Patches a renewal application
     * @param {string} applicationID
     * @param {PatchRenewalApplicationForm} patchRenewalApplicationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDV2RenewPatch: async (
      applicationID: string,
      patchRenewalApplicationForm: PatchRenewalApplicationForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDV2RenewPatch',
        'applicationID',
        applicationID,
      );
      // verify required parameter 'patchRenewalApplicationForm' is not null or undefined
      assertParamExists(
        'applicationApplicationIDV2RenewPatch',
        'patchRenewalApplicationForm',
        patchRenewalApplicationForm,
      );
      const localVarPath = `/application/{applicationID}/v2/renew`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchRenewalApplicationForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a renewal application from an existing application v2
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDV2RenewPost: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDV2RenewPost',
        'applicationID',
        applicationID,
      );
      const localVarPath = `/application/{applicationID}/v2/renew`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the red list status for a renewal application
     * @param {string} applicationID
     * @param {UpdateApplicationRedListForm} updateApplicationRedListForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDV2RenewRedListPatch: async (
      applicationID: string,
      updateApplicationRedListForm: UpdateApplicationRedListForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDV2RenewRedListPatch',
        'applicationID',
        applicationID,
      );
      // verify required parameter 'updateApplicationRedListForm' is not null or undefined
      assertParamExists(
        'applicationApplicationIDV2RenewRedListPatch',
        'updateApplicationRedListForm',
        updateApplicationRedListForm,
      );
      const localVarPath =
        `/application/{applicationID}/v2/renew/red_list`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateApplicationRedListForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Submit the renewal application for underwriter review
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDV2RenewSubmitPost: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDV2RenewSubmitPost',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/v2/renew/submit`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Downloads a file for an application
     * @param {string} fileHandle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFileGet: async (
      fileHandle: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileHandle' is not null or undefined
      assertParamExists('applicationFileGet', 'fileHandle', fileHandle);
      const localVarPath = `/application/file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (fileHandle !== undefined) {
        localVarQueryParameter['fileHandle'] = fileHandle;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a temporary public URL to download an application file.
     * @param {string} fileHandle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFileLinkGet: async (
      fileHandle: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileHandle' is not null or undefined
      assertParamExists('applicationFileLinkGet', 'fileHandle', fileHandle);
      const localVarPath = `/application/file_link`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (fileHandle !== undefined) {
        localVarQueryParameter['fileHandle'] = fileHandle;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upload one file for an application
     * @param {any} file
     * @param {string} fileType
     * @param {string} fileDestinationGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFilePost: async (
      file: any,
      fileType: string,
      fileDestinationGroup: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('applicationFilePost', 'file', file);
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists('applicationFilePost', 'fileType', fileType);
      // verify required parameter 'fileDestinationGroup' is not null or undefined
      assertParamExists(
        'applicationFilePost',
        'fileDestinationGroup',
        fileDestinationGroup,
      );
      const localVarPath = `/application/file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (fileType !== undefined) {
        localVarFormParams.append('fileType', fileType as any);
      }

      if (fileDestinationGroup !== undefined) {
        localVarFormParams.append(
          'fileDestinationGroup',
          fileDestinationGroup as any,
        );
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Uploads a file for an application
     * @param {FileUploadRequest} [fileUploadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFileUploadPost: async (
      fileUploadRequest?: FileUploadRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/application/file_upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fileUploadRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Uploads a file for an application
     * @param {Array<any>} files
     * @param {Array<FileInfo>} filesInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFilesPost: async (
      files: Array<any>,
      filesInfo: Array<FileInfo>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'files' is not null or undefined
      assertParamExists('applicationFilesPost', 'files', files);
      // verify required parameter 'filesInfo' is not null or undefined
      assertParamExists('applicationFilesPost', 'filesInfo', filesInfo);
      const localVarPath = `/application/files`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (files) {
        files.forEach((element) => {
          localVarFormParams.append('files', element as any);
        });
      }

      if (filesInfo) {
        localVarFormParams.append(
          'filesInfo',
          filesInfo.join(COLLECTION_FORMATS.csv),
        );
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Uploads a flatfile for an application
     * @param {UploadFlatFileRequest} [uploadFlatFileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFlatfilePost: async (
      uploadFlatFileRequest?: UploadFlatFileRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/application/flatfile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uploadFlatFileRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all applications for an agency with pagination
     * @param {number} [size]
     * @param {string} [cursor]
     * @param {string} [q]
     * @param {ApplicationTab} [tab]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationListGet: async (
      size?: number,
      cursor?: string,
      q?: string,
      tab?: ApplicationTab,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/application/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      if (tab !== undefined) {
        localVarQueryParameter['tab'] = tab;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new application
     * @param {AppetiteForm} appetiteForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationPost: async (
      appetiteForm: AppetiteForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appetiteForm' is not null or undefined
      assertParamExists('applicationPost', 'appetiteForm', appetiteForm);
      const localVarPath = `/application`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appetiteForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Runs all pre application checks (existing application, potential clearance)
     * @param {PreApplicationChecksRuleRequest} [preApplicationChecksRuleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationPreCreateValidatePost: async (
      preApplicationChecksRuleRequest?: PreApplicationChecksRuleRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/application/pre_create_validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        preApplicationChecksRuleRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get prefill information for a DOT number
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationPrefillDotNumberDotNumberGet: async (
      dotNumber: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dotNumber' is not null or undefined
      assertParamExists(
        'applicationPrefillDotNumberDotNumberGet',
        'dotNumber',
        dotNumber,
      );
      const localVarPath =
        `/application/prefill/dot_number/{dotNumber}`.replace(
          `{${'dotNumber'}}`,
          encodeURIComponent(String(dotNumber)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all applications for an agency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationsGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/applications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the list of available producers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    availableProducersGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/available_producers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Generate a share-able safety report URI for the application with the given UTM params
     * @param {string} applicationID
     * @param {UTMParamMedium} utmMedium
     * @param {UTMParamSource} utmSource
     * @param {ProgramTypeRequest} [programTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateSafetyReportURI: async (
      applicationID: string,
      utmMedium: UTMParamMedium,
      utmSource: UTMParamSource,
      programTypeRequest?: ProgramTypeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'generateSafetyReportURI',
        'applicationID',
        applicationID,
      );
      // verify required parameter 'utmMedium' is not null or undefined
      assertParamExists('generateSafetyReportURI', 'utmMedium', utmMedium);
      // verify required parameter 'utmSource' is not null or undefined
      assertParamExists('generateSafetyReportURI', 'utmSource', utmSource);
      const localVarPath = `/application/{applicationID}/safety-report`.replace(
        `{${'applicationID'}}`,
        encodeURIComponent(String(applicationID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (utmMedium !== undefined) {
        localVarQueryParameter['utm_medium'] = utmMedium;
      }

      if (utmSource !== undefined) {
        localVarQueryParameter['utm_source'] = utmSource;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        programTypeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint creates a new non-expired consent link for an application. This link is used so an application can consent to a provider that Nirvana is able to retrieve data from the provider (e.g. Samsara)
     * @param {TelematicsApplicationConsentLinkRequest} [telematicsApplicationConsentLinkRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTelematicsApplicationConsentLink: async (
      telematicsApplicationConsentLinkRequest?: TelematicsApplicationConsentLinkRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/telematics/application/consent-link`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        telematicsApplicationConsentLinkRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to complete the OAuth telematics connection flow with a TSP (Samsara & Motive only, for now). This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the insured completes the OAuth flow with the TSP, the TSP redirects the insured to a frontend screen which calls this endpoint to complete the connection flow.
     * @param {TSPConnectionCompleteRequest} [tSPConnectionCompleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsConnectionCompletePost: async (
      tSPConnectionCompleteRequest?: TSPConnectionCompleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/telematics_connection_complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tSPConnectionCompleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to retrieve the data that is needed in the consent flow while using a telematics link. It has backward compatability with older links which assumed an application id as their token. This is a public endpoint because it is consumed when someone opens the telematics link which does not need authorization. Upon opening the link, the frontend should call this endpoint to retrieve all the links information which includes two flags (isExpired and isRevoked). Both flags correlate to the link\'s functionality. In case, the link is not expired it will include the available public information of the telematics link.
     * @param {string} token
     * @param {ProgramType} programType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsConsentInfoGet: async (
      token: string,
      programType: ProgramType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('telematicsConsentInfoGet', 'token', token);
      // verify required parameter 'programType' is not null or undefined
      assertParamExists('telematicsConsentInfoGet', 'programType', programType);
      const localVarPath = `/telematics/consent-info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      if (programType !== undefined) {
        localVarQueryParameter['programType'] = programType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to get the fleet info for an application and the telematics handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the user successfully links a new telematics connection, the frontend calls this endpoint to get Fleet information, such as DOT number, name and registered VINs.
     * @param {string} handleID
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsHandleIDFleetInfoGet: async (
      handleID: string,
      programType?: ProgramType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'handleID' is not null or undefined
      assertParamExists('telematicsHandleIDFleetInfoGet', 'handleID', handleID);
      const localVarPath = `/telematics/{handleID}/fleet_info`.replace(
        `{${'handleID'}}`,
        encodeURIComponent(String(handleID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (programType !== undefined) {
        localVarQueryParameter['programType'] = programType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Decodes a US zipcode to city, state and county
     * @param {string} zipCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    zipcodeZipCodeDecodeGet: async (
      zipCode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'zipCode' is not null or undefined
      assertParamExists('zipcodeZipCodeDecodeGet', 'zipCode', zipCode);
      const localVarPath = `/zipcode/{zipCode}/decode`.replace(
        `{${'zipCode'}}`,
        encodeURIComponent(String(zipCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ApplicationApiAxiosParamCreator(configuration);
  return {
    /**
     * Update BD for agency and program type
     * @param {AgencyBDMappingRequest} agencyBDMappingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async agencyBdMappingPut(
      agencyBDMappingRequest: AgencyBDMappingRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.agencyBdMappingPut(
          agencyBDMappingRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get All BD Mappings for Agencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async agencyBdMappingsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AgencyBDMappingsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.agencyBdMappingsGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update the current information of the additional information form
     * @param {string} applicationID
     * @param {AdditionalInformationForm} additionalInformationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDAdditionalInformationPut(
      applicationID: string,
      additionalInformationForm: AdditionalInformationForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDAdditionalInformationPut(
          applicationID,
          additionalInformationForm,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns app pdf link
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDAppPdfLinkGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DownloadFileLinkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDAppPdfLinkGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDBasicInfoGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationBasicInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDBasicInfoGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update the current basic information
     * @param {string} applicationID
     * @param {ApplicationBasicInfoForm} applicationBasicInfoForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDBasicInfoPatch(
      applicationID: string,
      applicationBasicInfoForm: ApplicationBasicInfoForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDBasicInfoPatch(
          applicationID,
          applicationBasicInfoForm,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Decline an application
     * @param {string} applicationID
     * @param {DeclineAppForm} declineAppForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDDeclinePost(
      applicationID: string,
      declineAppForm: DeclineAppForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDDeclinePost(
          applicationID,
          declineAppForm,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return application\'s information
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return the indication options generated
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDIndicationOptionsGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IndicationOptions>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDIndicationOptionsGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Select one indication option
     * @param {string} applicationID
     * @param {SelectedIndication} selectedIndication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDIndicationOptionsSelectPost(
      applicationID: string,
      selectedIndication: SelectedIndication,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDIndicationOptionsSelectPost(
          applicationID,
          selectedIndication,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update the current information of the indication from
     * @param {string} applicationID
     * @param {IndicationForm} indicationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDIndicationPut(
      applicationID: string,
      indicationForm: IndicationForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDIndicationPut(
          applicationID,
          indicationForm,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Submit the indication form to generate indication options
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDIndicationSubmitPost(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDIndicationSubmitPost(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Post a Policy and transition to PolicyGenerated state
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDPolicyPost(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDPolicyPost(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return application\'s public info
     * @param {string} applicationID
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDPublicInfoGet(
      applicationID: string,
      programType?: ProgramType,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationPublicInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDPublicInfoGet(
          applicationID,
          programType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns the final data used for the quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDQuoteDataGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteData>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDQuoteDataGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns quote details
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDQuoteGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteDetails>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDQuoteGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns quote pdf link
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDQuotePdfLinkGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DownloadFileLinkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDQuotePdfLinkGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Create a renewal application from an existing application
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDRenewPost(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreatedApplicationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDRenewPost(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return application\'s state
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDStateGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationState>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDStateGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns the status tracker
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDStatusTrackerGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusTracker>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDStatusTrackerGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Submit the application for underwriter review
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDSubmitPost(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDSubmitPost(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint is used to initiate the telematics connection flow with a TSP. It is called by the frontend when the insured selects a TSP in the consent flow and enters (if needed), the details required to make a connection to the TSP using the provided `consentKind`. NOTE: This endpoint is public because it is currently being consumed by a public screen in the frontend
     * @param {string} applicationID
     * @param {TSPConnection} [tSPConnection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDTelematicsConnectionPost(
      applicationID: string,
      tSPConnection?: TSPConnection,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TSPConnectionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDTelematicsConnectionPost(
          applicationID,
          tSPConnection,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDTelematicsConsentRequestEmailPost(
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsConsentRequestEmailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDTelematicsConsentRequestEmailPost(
          applicationID,
          telematicsConsentRequestEmailData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Patches a renewal application
     * @param {string} applicationID
     * @param {PatchRenewalApplicationForm} patchRenewalApplicationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDV2RenewPatch(
      applicationID: string,
      patchRenewalApplicationForm: PatchRenewalApplicationForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDV2RenewPatch(
          applicationID,
          patchRenewalApplicationForm,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Create a renewal application from an existing application v2
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDV2RenewPost(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreatedApplicationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDV2RenewPost(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Updates the red list status for a renewal application
     * @param {string} applicationID
     * @param {UpdateApplicationRedListForm} updateApplicationRedListForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDV2RenewRedListPatch(
      applicationID: string,
      updateApplicationRedListForm: UpdateApplicationRedListForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDV2RenewRedListPatch(
          applicationID,
          updateApplicationRedListForm,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Submit the renewal application for underwriter review
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDV2RenewSubmitPost(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDV2RenewSubmitPost(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Downloads a file for an application
     * @param {string} fileHandle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationFileGet(
      fileHandle: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DownloadFileResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationFileGet(fileHandle, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Create a temporary public URL to download an application file.
     * @param {string} fileHandle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationFileLinkGet(
      fileHandle: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DownloadFileLinkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationFileLinkGet(
          fileHandle,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Upload one file for an application
     * @param {any} file
     * @param {string} fileType
     * @param {string} fileDestinationGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationFilePost(
      file: any,
      fileType: string,
      fileDestinationGroup: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileHandle>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationFilePost(
          file,
          fileType,
          fileDestinationGroup,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Uploads a file for an application
     * @param {FileUploadRequest} [fileUploadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationFileUploadPost(
      fileUploadRequest?: FileUploadRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileHandle>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationFileUploadPost(
          fileUploadRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Uploads a file for an application
     * @param {Array<any>} files
     * @param {Array<FileInfo>} filesInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationFilesPost(
      files: Array<any>,
      filesInfo: Array<FileInfo>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<FileHandle>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationFilesPost(
          files,
          filesInfo,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Uploads a flatfile for an application
     * @param {UploadFlatFileRequest} [uploadFlatFileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationFlatfilePost(
      uploadFlatFileRequest?: UploadFlatFileRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileHandle>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationFlatfilePost(
          uploadFlatFileRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get all applications for an agency with pagination
     * @param {number} [size]
     * @param {string} [cursor]
     * @param {string} [q]
     * @param {ApplicationTab} [tab]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationListGet(
      size?: number,
      cursor?: string,
      q?: string,
      tab?: ApplicationTab,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationListGet(
          size,
          cursor,
          q,
          tab,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Create a new application
     * @param {AppetiteForm} appetiteForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationPost(
      appetiteForm: AppetiteForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreatedApplicationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.applicationPost(
        appetiteForm,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Runs all pre application checks (existing application, potential clearance)
     * @param {PreApplicationChecksRuleRequest} [preApplicationChecksRuleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationPreCreateValidatePost(
      preApplicationChecksRuleRequest?: PreApplicationChecksRuleRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationPreCreateValidatePost(
          preApplicationChecksRuleRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get prefill information for a DOT number
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationPrefillDotNumberDotNumberGet(
      dotNumber: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DotPrefillResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationPrefillDotNumberDotNumberGet(
          dotNumber,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get all applications for an agency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ApplicationSummary>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.applicationsGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the list of available producers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async availableProducersGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<Producer>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.availableProducersGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Generate a share-able safety report URI for the application with the given UTM params
     * @param {string} applicationID
     * @param {UTMParamMedium} utmMedium
     * @param {UTMParamSource} utmSource
     * @param {ProgramTypeRequest} [programTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateSafetyReportURI(
      applicationID: string,
      utmMedium: UTMParamMedium,
      utmSource: UTMParamSource,
      programTypeRequest?: ProgramTypeRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GenerateSafetyReportURIResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateSafetyReportURI(
          applicationID,
          utmMedium,
          utmSource,
          programTypeRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint creates a new non-expired consent link for an application. This link is used so an application can consent to a provider that Nirvana is able to retrieve data from the provider (e.g. Samsara)
     * @param {TelematicsApplicationConsentLinkRequest} [telematicsApplicationConsentLinkRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTelematicsApplicationConsentLink(
      telematicsApplicationConsentLinkRequest?: TelematicsApplicationConsentLinkRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsApplicationConsentLinkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTelematicsApplicationConsentLink(
          telematicsApplicationConsentLinkRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint is used to complete the OAuth telematics connection flow with a TSP (Samsara & Motive only, for now). This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the insured completes the OAuth flow with the TSP, the TSP redirects the insured to a frontend screen which calls this endpoint to complete the connection flow.
     * @param {TSPConnectionCompleteRequest} [tSPConnectionCompleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async telematicsConnectionCompletePost(
      tSPConnectionCompleteRequest?: TSPConnectionCompleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TSPConnectionCompleteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.telematicsConnectionCompletePost(
          tSPConnectionCompleteRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint is used to retrieve the data that is needed in the consent flow while using a telematics link. It has backward compatability with older links which assumed an application id as their token. This is a public endpoint because it is consumed when someone opens the telematics link which does not need authorization. Upon opening the link, the frontend should call this endpoint to retrieve all the links information which includes two flags (isExpired and isRevoked). Both flags correlate to the link\'s functionality. In case, the link is not expired it will include the available public information of the telematics link.
     * @param {string} token
     * @param {ProgramType} programType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async telematicsConsentInfoGet(
      token: string,
      programType: ProgramType,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsConsentInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.telematicsConsentInfoGet(
          token,
          programType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint is used to get the fleet info for an application and the telematics handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the user successfully links a new telematics connection, the frontend calls this endpoint to get Fleet information, such as DOT number, name and registered VINs.
     * @param {string} handleID
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async telematicsHandleIDFleetInfoGet(
      handleID: string,
      programType?: ProgramType,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsFleetInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.telematicsHandleIDFleetInfoGet(
          handleID,
          programType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Decodes a US zipcode to city, state and county
     * @param {string} zipCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async zipcodeZipCodeDecodeGet(
      zipCode: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ZipcodeDecodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.zipcodeZipCodeDecodeGet(
          zipCode,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ApplicationApiFp(configuration);
  return {
    /**
     * Update BD for agency and program type
     * @param {AgencyBDMappingRequest} agencyBDMappingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    agencyBdMappingPut(
      agencyBDMappingRequest: AgencyBDMappingRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .agencyBdMappingPut(agencyBDMappingRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get All BD Mappings for Agencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    agencyBdMappingsGet(options?: any): AxiosPromise<AgencyBDMappingsResponse> {
      return localVarFp
        .agencyBdMappingsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the current information of the additional information form
     * @param {string} applicationID
     * @param {AdditionalInformationForm} additionalInformationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDAdditionalInformationPut(
      applicationID: string,
      additionalInformationForm: AdditionalInformationForm,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDAdditionalInformationPut(
          applicationID,
          additionalInformationForm,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns app pdf link
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDAppPdfLinkGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<DownloadFileLinkResponse> {
      return localVarFp
        .applicationApplicationIDAppPdfLinkGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDBasicInfoGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<ApplicationBasicInfo> {
      return localVarFp
        .applicationApplicationIDBasicInfoGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the current basic information
     * @param {string} applicationID
     * @param {ApplicationBasicInfoForm} applicationBasicInfoForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDBasicInfoPatch(
      applicationID: string,
      applicationBasicInfoForm: ApplicationBasicInfoForm,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDBasicInfoPatch(
          applicationID,
          applicationBasicInfoForm,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Decline an application
     * @param {string} applicationID
     * @param {DeclineAppForm} declineAppForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDDeclinePost(
      applicationID: string,
      declineAppForm: DeclineAppForm,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDDeclinePost(
          applicationID,
          declineAppForm,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Return application\'s information
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<ApplicationDetail> {
      return localVarFp
        .applicationApplicationIDGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return the indication options generated
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDIndicationOptionsGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<IndicationOptions> {
      return localVarFp
        .applicationApplicationIDIndicationOptionsGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Select one indication option
     * @param {string} applicationID
     * @param {SelectedIndication} selectedIndication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDIndicationOptionsSelectPost(
      applicationID: string,
      selectedIndication: SelectedIndication,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDIndicationOptionsSelectPost(
          applicationID,
          selectedIndication,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the current information of the indication from
     * @param {string} applicationID
     * @param {IndicationForm} indicationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDIndicationPut(
      applicationID: string,
      indicationForm: IndicationForm,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDIndicationPut(
          applicationID,
          indicationForm,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Submit the indication form to generate indication options
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDIndicationSubmitPost(
      applicationID: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDIndicationSubmitPost(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Post a Policy and transition to PolicyGenerated state
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDPolicyPost(
      applicationID: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDPolicyPost(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return application\'s public info
     * @param {string} applicationID
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDPublicInfoGet(
      applicationID: string,
      programType?: ProgramType,
      options?: any,
    ): AxiosPromise<ApplicationPublicInfo> {
      return localVarFp
        .applicationApplicationIDPublicInfoGet(
          applicationID,
          programType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the final data used for the quote
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDQuoteDataGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<QuoteData> {
      return localVarFp
        .applicationApplicationIDQuoteDataGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns quote details
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDQuoteGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<QuoteDetails> {
      return localVarFp
        .applicationApplicationIDQuoteGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns quote pdf link
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDQuotePdfLinkGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<DownloadFileLinkResponse> {
      return localVarFp
        .applicationApplicationIDQuotePdfLinkGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a renewal application from an existing application
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDRenewPost(
      applicationID: string,
      options?: any,
    ): AxiosPromise<CreatedApplicationResponse> {
      return localVarFp
        .applicationApplicationIDRenewPost(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return application\'s state
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDStateGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<ApplicationState> {
      return localVarFp
        .applicationApplicationIDStateGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the status tracker
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDStatusTrackerGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<StatusTracker> {
      return localVarFp
        .applicationApplicationIDStatusTrackerGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Submit the application for underwriter review
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDSubmitPost(
      applicationID: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDSubmitPost(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to initiate the telematics connection flow with a TSP. It is called by the frontend when the insured selects a TSP in the consent flow and enters (if needed), the details required to make a connection to the TSP using the provided `consentKind`. NOTE: This endpoint is public because it is currently being consumed by a public screen in the frontend
     * @param {string} applicationID
     * @param {TSPConnection} [tSPConnection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDTelematicsConnectionPost(
      applicationID: string,
      tSPConnection?: TSPConnection,
      options?: any,
    ): AxiosPromise<TSPConnectionResponse> {
      return localVarFp
        .applicationApplicationIDTelematicsConnectionPost(
          applicationID,
          tSPConnection,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDTelematicsConsentRequestEmailPost(
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options?: any,
    ): AxiosPromise<TelematicsConsentRequestEmailResponse> {
      return localVarFp
        .applicationApplicationIDTelematicsConsentRequestEmailPost(
          applicationID,
          telematicsConsentRequestEmailData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Patches a renewal application
     * @param {string} applicationID
     * @param {PatchRenewalApplicationForm} patchRenewalApplicationForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDV2RenewPatch(
      applicationID: string,
      patchRenewalApplicationForm: PatchRenewalApplicationForm,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDV2RenewPatch(
          applicationID,
          patchRenewalApplicationForm,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a renewal application from an existing application v2
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDV2RenewPost(
      applicationID: string,
      options?: any,
    ): AxiosPromise<CreatedApplicationResponse> {
      return localVarFp
        .applicationApplicationIDV2RenewPost(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the red list status for a renewal application
     * @param {string} applicationID
     * @param {UpdateApplicationRedListForm} updateApplicationRedListForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDV2RenewRedListPatch(
      applicationID: string,
      updateApplicationRedListForm: UpdateApplicationRedListForm,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDV2RenewRedListPatch(
          applicationID,
          updateApplicationRedListForm,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Submit the renewal application for underwriter review
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDV2RenewSubmitPost(
      applicationID: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationApplicationIDV2RenewSubmitPost(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Downloads a file for an application
     * @param {string} fileHandle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFileGet(
      fileHandle: string,
      options?: any,
    ): AxiosPromise<DownloadFileResponse> {
      return localVarFp
        .applicationFileGet(fileHandle, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a temporary public URL to download an application file.
     * @param {string} fileHandle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFileLinkGet(
      fileHandle: string,
      options?: any,
    ): AxiosPromise<DownloadFileLinkResponse> {
      return localVarFp
        .applicationFileLinkGet(fileHandle, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Upload one file for an application
     * @param {any} file
     * @param {string} fileType
     * @param {string} fileDestinationGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFilePost(
      file: any,
      fileType: string,
      fileDestinationGroup: string,
      options?: any,
    ): AxiosPromise<FileHandle> {
      return localVarFp
        .applicationFilePost(file, fileType, fileDestinationGroup, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Uploads a file for an application
     * @param {FileUploadRequest} [fileUploadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFileUploadPost(
      fileUploadRequest?: FileUploadRequest,
      options?: any,
    ): AxiosPromise<FileHandle> {
      return localVarFp
        .applicationFileUploadPost(fileUploadRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Uploads a file for an application
     * @param {Array<any>} files
     * @param {Array<FileInfo>} filesInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFilesPost(
      files: Array<any>,
      filesInfo: Array<FileInfo>,
      options?: any,
    ): AxiosPromise<Array<FileHandle>> {
      return localVarFp
        .applicationFilesPost(files, filesInfo, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Uploads a flatfile for an application
     * @param {UploadFlatFileRequest} [uploadFlatFileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationFlatfilePost(
      uploadFlatFileRequest?: UploadFlatFileRequest,
      options?: any,
    ): AxiosPromise<FileHandle> {
      return localVarFp
        .applicationFlatfilePost(uploadFlatFileRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all applications for an agency with pagination
     * @param {number} [size]
     * @param {string} [cursor]
     * @param {string} [q]
     * @param {ApplicationTab} [tab]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationListGet(
      size?: number,
      cursor?: string,
      q?: string,
      tab?: ApplicationTab,
      options?: any,
    ): AxiosPromise<ApplicationListResponse> {
      return localVarFp
        .applicationListGet(size, cursor, q, tab, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new application
     * @param {AppetiteForm} appetiteForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationPost(
      appetiteForm: AppetiteForm,
      options?: any,
    ): AxiosPromise<CreatedApplicationResponse> {
      return localVarFp
        .applicationPost(appetiteForm, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Runs all pre application checks (existing application, potential clearance)
     * @param {PreApplicationChecksRuleRequest} [preApplicationChecksRuleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationPreCreateValidatePost(
      preApplicationChecksRuleRequest?: PreApplicationChecksRuleRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applicationPreCreateValidatePost(
          preApplicationChecksRuleRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get prefill information for a DOT number
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationPrefillDotNumberDotNumberGet(
      dotNumber: number,
      options?: any,
    ): AxiosPromise<DotPrefillResponse> {
      return localVarFp
        .applicationPrefillDotNumberDotNumberGet(dotNumber, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all applications for an agency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationsGet(options?: any): AxiosPromise<Array<ApplicationSummary>> {
      return localVarFp
        .applicationsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the list of available producers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    availableProducersGet(options?: any): AxiosPromise<Array<Producer>> {
      return localVarFp
        .availableProducersGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Generate a share-able safety report URI for the application with the given UTM params
     * @param {string} applicationID
     * @param {UTMParamMedium} utmMedium
     * @param {UTMParamSource} utmSource
     * @param {ProgramTypeRequest} [programTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateSafetyReportURI(
      applicationID: string,
      utmMedium: UTMParamMedium,
      utmSource: UTMParamSource,
      programTypeRequest?: ProgramTypeRequest,
      options?: any,
    ): AxiosPromise<GenerateSafetyReportURIResponse> {
      return localVarFp
        .generateSafetyReportURI(
          applicationID,
          utmMedium,
          utmSource,
          programTypeRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint creates a new non-expired consent link for an application. This link is used so an application can consent to a provider that Nirvana is able to retrieve data from the provider (e.g. Samsara)
     * @param {TelematicsApplicationConsentLinkRequest} [telematicsApplicationConsentLinkRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTelematicsApplicationConsentLink(
      telematicsApplicationConsentLinkRequest?: TelematicsApplicationConsentLinkRequest,
      options?: any,
    ): AxiosPromise<TelematicsApplicationConsentLinkResponse> {
      return localVarFp
        .postTelematicsApplicationConsentLink(
          telematicsApplicationConsentLinkRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to complete the OAuth telematics connection flow with a TSP (Samsara & Motive only, for now). This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the insured completes the OAuth flow with the TSP, the TSP redirects the insured to a frontend screen which calls this endpoint to complete the connection flow.
     * @param {TSPConnectionCompleteRequest} [tSPConnectionCompleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsConnectionCompletePost(
      tSPConnectionCompleteRequest?: TSPConnectionCompleteRequest,
      options?: any,
    ): AxiosPromise<TSPConnectionCompleteResponse> {
      return localVarFp
        .telematicsConnectionCompletePost(tSPConnectionCompleteRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to retrieve the data that is needed in the consent flow while using a telematics link. It has backward compatability with older links which assumed an application id as their token. This is a public endpoint because it is consumed when someone opens the telematics link which does not need authorization. Upon opening the link, the frontend should call this endpoint to retrieve all the links information which includes two flags (isExpired and isRevoked). Both flags correlate to the link\'s functionality. In case, the link is not expired it will include the available public information of the telematics link.
     * @param {string} token
     * @param {ProgramType} programType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsConsentInfoGet(
      token: string,
      programType: ProgramType,
      options?: any,
    ): AxiosPromise<TelematicsConsentInfoResponse> {
      return localVarFp
        .telematicsConsentInfoGet(token, programType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to get the fleet info for an application and the telematics handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the user successfully links a new telematics connection, the frontend calls this endpoint to get Fleet information, such as DOT number, name and registered VINs.
     * @param {string} handleID
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsHandleIDFleetInfoGet(
      handleID: string,
      programType?: ProgramType,
      options?: any,
    ): AxiosPromise<TelematicsFleetInfoResponse> {
      return localVarFp
        .telematicsHandleIDFleetInfoGet(handleID, programType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Decodes a US zipcode to city, state and county
     * @param {string} zipCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    zipcodeZipCodeDecodeGet(
      zipCode: string,
      options?: any,
    ): AxiosPromise<ZipcodeDecodeResponse> {
      return localVarFp
        .zipcodeZipCodeDecodeGet(zipCode, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
  /**
   * Update BD for agency and program type
   * @param {AgencyBDMappingRequest} agencyBDMappingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public agencyBdMappingPut(
    agencyBDMappingRequest: AgencyBDMappingRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .agencyBdMappingPut(agencyBDMappingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get All BD Mappings for Agencies
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public agencyBdMappingsGet(options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .agencyBdMappingsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the current information of the additional information form
   * @param {string} applicationID
   * @param {AdditionalInformationForm} additionalInformationForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDAdditionalInformationPut(
    applicationID: string,
    additionalInformationForm: AdditionalInformationForm,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDAdditionalInformationPut(
        applicationID,
        additionalInformationForm,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns app pdf link
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDAppPdfLinkGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDAppPdfLinkGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDBasicInfoGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDBasicInfoGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the current basic information
   * @param {string} applicationID
   * @param {ApplicationBasicInfoForm} applicationBasicInfoForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDBasicInfoPatch(
    applicationID: string,
    applicationBasicInfoForm: ApplicationBasicInfoForm,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDBasicInfoPatch(
        applicationID,
        applicationBasicInfoForm,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Decline an application
   * @param {string} applicationID
   * @param {DeclineAppForm} declineAppForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDDeclinePost(
    applicationID: string,
    declineAppForm: DeclineAppForm,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDDeclinePost(
        applicationID,
        declineAppForm,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return application\'s information
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return the indication options generated
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDIndicationOptionsGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDIndicationOptionsGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Select one indication option
   * @param {string} applicationID
   * @param {SelectedIndication} selectedIndication
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDIndicationOptionsSelectPost(
    applicationID: string,
    selectedIndication: SelectedIndication,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDIndicationOptionsSelectPost(
        applicationID,
        selectedIndication,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the current information of the indication from
   * @param {string} applicationID
   * @param {IndicationForm} indicationForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDIndicationPut(
    applicationID: string,
    indicationForm: IndicationForm,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDIndicationPut(
        applicationID,
        indicationForm,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Submit the indication form to generate indication options
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDIndicationSubmitPost(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDIndicationSubmitPost(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Post a Policy and transition to PolicyGenerated state
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDPolicyPost(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDPolicyPost(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return application\'s public info
   * @param {string} applicationID
   * @param {ProgramType} [programType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDPublicInfoGet(
    applicationID: string,
    programType?: ProgramType,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDPublicInfoGet(
        applicationID,
        programType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the final data used for the quote
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDQuoteDataGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDQuoteDataGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns quote details
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDQuoteGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDQuoteGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns quote pdf link
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDQuotePdfLinkGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDQuotePdfLinkGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a renewal application from an existing application
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDRenewPost(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDRenewPost(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return application\'s state
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDStateGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDStateGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the status tracker
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDStatusTrackerGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDStatusTrackerGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Submit the application for underwriter review
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDSubmitPost(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDSubmitPost(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to initiate the telematics connection flow with a TSP. It is called by the frontend when the insured selects a TSP in the consent flow and enters (if needed), the details required to make a connection to the TSP using the provided `consentKind`. NOTE: This endpoint is public because it is currently being consumed by a public screen in the frontend
   * @param {string} applicationID
   * @param {TSPConnection} [tSPConnection]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDTelematicsConnectionPost(
    applicationID: string,
    tSPConnection?: TSPConnection,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDTelematicsConnectionPost(
        applicationID,
        tSPConnection,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
   * @param {string} applicationID
   * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDTelematicsConsentRequestEmailPost(
    applicationID: string,
    telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDTelematicsConsentRequestEmailPost(
        applicationID,
        telematicsConsentRequestEmailData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Patches a renewal application
   * @param {string} applicationID
   * @param {PatchRenewalApplicationForm} patchRenewalApplicationForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDV2RenewPatch(
    applicationID: string,
    patchRenewalApplicationForm: PatchRenewalApplicationForm,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDV2RenewPatch(
        applicationID,
        patchRenewalApplicationForm,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a renewal application from an existing application v2
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDV2RenewPost(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDV2RenewPost(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the red list status for a renewal application
   * @param {string} applicationID
   * @param {UpdateApplicationRedListForm} updateApplicationRedListForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDV2RenewRedListPatch(
    applicationID: string,
    updateApplicationRedListForm: UpdateApplicationRedListForm,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDV2RenewRedListPatch(
        applicationID,
        updateApplicationRedListForm,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Submit the renewal application for underwriter review
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationApplicationIDV2RenewSubmitPost(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationApplicationIDV2RenewSubmitPost(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Downloads a file for an application
   * @param {string} fileHandle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationFileGet(fileHandle: string, options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .applicationFileGet(fileHandle, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a temporary public URL to download an application file.
   * @param {string} fileHandle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationFileLinkGet(
    fileHandle: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationFileLinkGet(fileHandle, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Upload one file for an application
   * @param {any} file
   * @param {string} fileType
   * @param {string} fileDestinationGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationFilePost(
    file: any,
    fileType: string,
    fileDestinationGroup: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationFilePost(file, fileType, fileDestinationGroup, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Uploads a file for an application
   * @param {FileUploadRequest} [fileUploadRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationFileUploadPost(
    fileUploadRequest?: FileUploadRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationFileUploadPost(fileUploadRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Uploads a file for an application
   * @param {Array<any>} files
   * @param {Array<FileInfo>} filesInfo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationFilesPost(
    files: Array<any>,
    filesInfo: Array<FileInfo>,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationFilesPost(files, filesInfo, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Uploads a flatfile for an application
   * @param {UploadFlatFileRequest} [uploadFlatFileRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationFlatfilePost(
    uploadFlatFileRequest?: UploadFlatFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationFlatfilePost(uploadFlatFileRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all applications for an agency with pagination
   * @param {number} [size]
   * @param {string} [cursor]
   * @param {string} [q]
   * @param {ApplicationTab} [tab]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationListGet(
    size?: number,
    cursor?: string,
    q?: string,
    tab?: ApplicationTab,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationListGet(size, cursor, q, tab, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new application
   * @param {AppetiteForm} appetiteForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationPost(
    appetiteForm: AppetiteForm,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationPost(appetiteForm, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Runs all pre application checks (existing application, potential clearance)
   * @param {PreApplicationChecksRuleRequest} [preApplicationChecksRuleRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationPreCreateValidatePost(
    preApplicationChecksRuleRequest?: PreApplicationChecksRuleRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationPreCreateValidatePost(
        preApplicationChecksRuleRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get prefill information for a DOT number
   * @param {number} dotNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationPrefillDotNumberDotNumberGet(
    dotNumber: number,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .applicationPrefillDotNumberDotNumberGet(dotNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all applications for an agency
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public applicationsGet(options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .applicationsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the list of available producers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public availableProducersGet(options?: AxiosRequestConfig) {
    return ApplicationApiFp(this.configuration)
      .availableProducersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Generate a share-able safety report URI for the application with the given UTM params
   * @param {string} applicationID
   * @param {UTMParamMedium} utmMedium
   * @param {UTMParamSource} utmSource
   * @param {ProgramTypeRequest} [programTypeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public generateSafetyReportURI(
    applicationID: string,
    utmMedium: UTMParamMedium,
    utmSource: UTMParamSource,
    programTypeRequest?: ProgramTypeRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .generateSafetyReportURI(
        applicationID,
        utmMedium,
        utmSource,
        programTypeRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint creates a new non-expired consent link for an application. This link is used so an application can consent to a provider that Nirvana is able to retrieve data from the provider (e.g. Samsara)
   * @param {TelematicsApplicationConsentLinkRequest} [telematicsApplicationConsentLinkRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public postTelematicsApplicationConsentLink(
    telematicsApplicationConsentLinkRequest?: TelematicsApplicationConsentLinkRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .postTelematicsApplicationConsentLink(
        telematicsApplicationConsentLinkRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to complete the OAuth telematics connection flow with a TSP (Samsara & Motive only, for now). This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the insured completes the OAuth flow with the TSP, the TSP redirects the insured to a frontend screen which calls this endpoint to complete the connection flow.
   * @param {TSPConnectionCompleteRequest} [tSPConnectionCompleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public telematicsConnectionCompletePost(
    tSPConnectionCompleteRequest?: TSPConnectionCompleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .telematicsConnectionCompletePost(tSPConnectionCompleteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to retrieve the data that is needed in the consent flow while using a telematics link. It has backward compatability with older links which assumed an application id as their token. This is a public endpoint because it is consumed when someone opens the telematics link which does not need authorization. Upon opening the link, the frontend should call this endpoint to retrieve all the links information which includes two flags (isExpired and isRevoked). Both flags correlate to the link\'s functionality. In case, the link is not expired it will include the available public information of the telematics link.
   * @param {string} token
   * @param {ProgramType} programType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public telematicsConsentInfoGet(
    token: string,
    programType: ProgramType,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .telematicsConsentInfoGet(token, programType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to get the fleet info for an application and the telematics handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the user successfully links a new telematics connection, the frontend calls this endpoint to get Fleet information, such as DOT number, name and registered VINs.
   * @param {string} handleID
   * @param {ProgramType} [programType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public telematicsHandleIDFleetInfoGet(
    handleID: string,
    programType?: ProgramType,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .telematicsHandleIDFleetInfoGet(handleID, programType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Decodes a US zipcode to city, state and county
   * @param {string} zipCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public zipcodeZipCodeDecodeGet(
    zipCode: string,
    options?: AxiosRequestConfig,
  ) {
    return ApplicationApiFp(this.configuration)
      .zipcodeZipCodeDecodeGet(zipCode, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Fetch all contacts for dot
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContactsForDot: async (
      dotNumber: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dotNumber' is not null or undefined
      assertParamExists('getContactsForDot', 'dotNumber', dotNumber);
      const localVarPath = `/contact/{dotNumber}`.replace(
        `{${'dotNumber'}}`,
        encodeURIComponent(String(dotNumber)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update list of contacts
     * @param {number} dotNumber
     * @param {ContactRecordsUpdate} contactRecordsUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContacts: async (
      dotNumber: number,
      contactRecordsUpdate: ContactRecordsUpdate,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dotNumber' is not null or undefined
      assertParamExists('updateContacts', 'dotNumber', dotNumber);
      // verify required parameter 'contactRecordsUpdate' is not null or undefined
      assertParamExists(
        'updateContacts',
        'contactRecordsUpdate',
        contactRecordsUpdate,
      );
      const localVarPath = `/contact/{dotNumber}`.replace(
        `{${'dotNumber'}}`,
        encodeURIComponent(String(dotNumber)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contactRecordsUpdate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration);
  return {
    /**
     * Fetch all contacts for dot
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContactsForDot(
      dotNumber: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetContactsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContactsForDot(dotNumber, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update list of contacts
     * @param {number} dotNumber
     * @param {ContactRecordsUpdate} contactRecordsUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateContacts(
      dotNumber: number,
      contactRecordsUpdate: ContactRecordsUpdate,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ContactRecordsUpdateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateContacts(
        dotNumber,
        contactRecordsUpdate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContactApiFp(configuration);
  return {
    /**
     * Fetch all contacts for dot
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContactsForDot(
      dotNumber: number,
      options?: any,
    ): AxiosPromise<GetContactsResponse> {
      return localVarFp
        .getContactsForDot(dotNumber, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update list of contacts
     * @param {number} dotNumber
     * @param {ContactRecordsUpdate} contactRecordsUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContacts(
      dotNumber: number,
      contactRecordsUpdate: ContactRecordsUpdate,
      options?: any,
    ): AxiosPromise<ContactRecordsUpdateResponse> {
      return localVarFp
        .updateContacts(dotNumber, contactRecordsUpdate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
  /**
   * Fetch all contacts for dot
   * @param {number} dotNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactApi
   */
  public getContactsForDot(dotNumber: number, options?: AxiosRequestConfig) {
    return ContactApiFp(this.configuration)
      .getContactsForDot(dotNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update list of contacts
   * @param {number} dotNumber
   * @param {ContactRecordsUpdate} contactRecordsUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactApi
   */
  public updateContacts(
    dotNumber: number,
    contactRecordsUpdate: ContactRecordsUpdate,
    options?: AxiosRequestConfig,
  ) {
    return ContactApiFp(this.configuration)
      .updateContacts(dotNumber, contactRecordsUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get all the supported operating classes and their respective commodities hauled
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDSupportedOperationsGet: async (
      applicationID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDSupportedOperationsGet',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/supported_operations`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return information about the server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return OpenAPI spec JSON
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openapiSpecGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/openapi-spec`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all the supported operating classes and their respective commodities hauled
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDSupportedOperationsGet(
      applicationID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupportedOperationsRecordV2>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDSupportedOperationsGet(
          applicationID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return information about the server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Return OpenAPI spec JSON
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async openapiSpecGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.openapiSpecGet(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     * Get all the supported operating classes and their respective commodities hauled
     * @param {string} applicationID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDSupportedOperationsGet(
      applicationID: string,
      options?: any,
    ): AxiosPromise<SupportedOperationsRecordV2> {
      return localVarFp
        .applicationApplicationIDSupportedOperationsGet(applicationID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return information about the server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthGet(options?: any): AxiosPromise<AboutResponse> {
      return localVarFp
        .healthGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return OpenAPI spec JSON
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openapiSpecGet(options?: any): AxiosPromise<any> {
      return localVarFp
        .openapiSpecGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * Get all the supported operating classes and their respective commodities hauled
   * @param {string} applicationID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public applicationApplicationIDSupportedOperationsGet(
    applicationID: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .applicationApplicationIDSupportedOperationsGet(applicationID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return information about the server.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public healthGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .healthGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return OpenAPI spec JSON
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public openapiSpecGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .openapiSpecGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DemoApi - axios parameter creator
 * @export
 */
export const DemoApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Resets the agency by archiving all the applications and app reviews other than the default ones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    demoAgencyResetPost: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/demo/agency/reset`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DemoApi - functional programming interface
 * @export
 */
export const DemoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DemoApiAxiosParamCreator(configuration);
  return {
    /**
     * Resets the agency by archiving all the applications and app reviews other than the default ones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async demoAgencyResetPost(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.demoAgencyResetPost(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DemoApi - factory interface
 * @export
 */
export const DemoApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DemoApiFp(configuration);
  return {
    /**
     * Resets the agency by archiving all the applications and app reviews other than the default ones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    demoAgencyResetPost(options?: any): AxiosPromise<void> {
      return localVarFp
        .demoAgencyResetPost(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DemoApi - object-oriented interface
 * @export
 * @class DemoApi
 * @extends {BaseAPI}
 */
export class DemoApi extends BaseAPI {
  /**
   * Resets the agency by archiving all the applications and app reviews other than the default ones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoApi
   */
  public demoAgencyResetPost(options?: AxiosRequestConfig) {
    return DemoApiFp(this.configuration)
      .demoAgencyResetPost(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PolicyApi - axios parameter creator
 * @export
 */
export const PolicyApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get a list of all the policies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPolicies: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/policy/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch a policy by Id
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPoliciesForDot: async (
      dotNumber: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dotNumber' is not null or undefined
      assertParamExists('getPoliciesForDot', 'dotNumber', dotNumber);
      const localVarPath = `/policies/{dotNumber}`.replace(
        `{${'dotNumber'}}`,
        encodeURIComponent(String(dotNumber)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch a policy by Id
     * @param {string} policyID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPolicyById: async (
      policyID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'policyID' is not null or undefined
      assertParamExists('getPolicyById', 'policyID', policyID);
      const localVarPath = `/policy/{policyID}`.replace(
        `{${'policyID'}}`,
        encodeURIComponent(String(policyID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PolicyApi - functional programming interface
 * @export
 */
export const PolicyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PolicyApiAxiosParamCreator(configuration);
  return {
    /**
     * Get a list of all the policies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPolicies(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<PolicyRecord>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicies(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch a policy by Id
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPoliciesForDot(
      dotNumber: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<PolicyRecord>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPoliciesForDot(dotNumber, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch a policy by Id
     * @param {string} policyID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPolicyById(
      policyID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyRecord>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicyById(
        policyID,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PolicyApi - factory interface
 * @export
 */
export const PolicyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PolicyApiFp(configuration);
  return {
    /**
     * Get a list of all the policies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPolicies(options?: any): AxiosPromise<Array<PolicyRecord>> {
      return localVarFp
        .getPolicies(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch a policy by Id
     * @param {number} dotNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPoliciesForDot(
      dotNumber: number,
      options?: any,
    ): AxiosPromise<Array<PolicyRecord>> {
      return localVarFp
        .getPoliciesForDot(dotNumber, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch a policy by Id
     * @param {string} policyID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPolicyById(policyID: string, options?: any): AxiosPromise<PolicyRecord> {
      return localVarFp
        .getPolicyById(policyID, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PolicyApi - object-oriented interface
 * @export
 * @class PolicyApi
 * @extends {BaseAPI}
 */
export class PolicyApi extends BaseAPI {
  /**
   * Get a list of all the policies
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PolicyApi
   */
  public getPolicies(options?: AxiosRequestConfig) {
    return PolicyApiFp(this.configuration)
      .getPolicies(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch a policy by Id
   * @param {number} dotNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PolicyApi
   */
  public getPoliciesForDot(dotNumber: number, options?: AxiosRequestConfig) {
    return PolicyApiFp(this.configuration)
      .getPoliciesForDot(dotNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch a policy by Id
   * @param {string} policyID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PolicyApi
   */
  public getPolicyById(policyID: string, options?: AxiosRequestConfig) {
    return PolicyApiFp(this.configuration)
      .getPolicyById(policyID, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PolicySetApi - axios parameter creator
 * @export
 */
export const PolicySetApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get a list of all the policy sets
     * @param {PolicySetTab} [tab]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPolicySets: async (
      tab?: PolicySetTab,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/policy-set/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (tab !== undefined) {
        localVarQueryParameter['tab'] = tab;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PolicySetApi - functional programming interface
 * @export
 */
export const PolicySetApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PolicySetApiAxiosParamCreator(configuration);
  return {
    /**
     * Get a list of all the policy sets
     * @param {PolicySetTab} [tab]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPolicySets(
      tab?: PolicySetTab,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<PolicySetRecord>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicySets(
        tab,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PolicySetApi - factory interface
 * @export
 */
export const PolicySetApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PolicySetApiFp(configuration);
  return {
    /**
     * Get a list of all the policy sets
     * @param {PolicySetTab} [tab]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPolicySets(
      tab?: PolicySetTab,
      options?: any,
    ): AxiosPromise<Array<PolicySetRecord>> {
      return localVarFp
        .getPolicySets(tab, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PolicySetApi - object-oriented interface
 * @export
 * @class PolicySetApi
 * @extends {BaseAPI}
 */
export class PolicySetApi extends BaseAPI {
  /**
   * Get a list of all the policy sets
   * @param {PolicySetTab} [tab]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PolicySetApi
   */
  public getPolicySets(tab?: PolicySetTab, options?: AxiosRequestConfig) {
    return PolicySetApiFp(this.configuration)
      .getPolicySets(tab, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SupportApi - axios parameter creator
 * @export
 */
export const SupportApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Update Model version in the Latest Pending Application Review Overrides
     * @param {string} applicationID
     * @param {PatchModelVersionInLatestPendingAppReviewForm} patchModelVersionInLatestPendingAppReviewForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchModelVersionInLatestPendingAppReview: async (
      applicationID: string,
      patchModelVersionInLatestPendingAppReviewForm: PatchModelVersionInLatestPendingAppReviewForm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'patchModelVersionInLatestPendingAppReview',
        'applicationID',
        applicationID,
      );
      // verify required parameter 'patchModelVersionInLatestPendingAppReviewForm' is not null or undefined
      assertParamExists(
        'patchModelVersionInLatestPendingAppReview',
        'patchModelVersionInLatestPendingAppReviewForm',
        patchModelVersionInLatestPendingAppReviewForm,
      );
      const localVarPath =
        `/application_review/{applicationID}/rateml_model_version`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchModelVersionInLatestPendingAppReviewForm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SupportApi - functional programming interface
 * @export
 */
export const SupportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SupportApiAxiosParamCreator(configuration);
  return {
    /**
     * Update Model version in the Latest Pending Application Review Overrides
     * @param {string} applicationID
     * @param {PatchModelVersionInLatestPendingAppReviewForm} patchModelVersionInLatestPendingAppReviewForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchModelVersionInLatestPendingAppReview(
      applicationID: string,
      patchModelVersionInLatestPendingAppReviewForm: PatchModelVersionInLatestPendingAppReviewForm,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PatchModelVersionInLatestPendingAppReviewResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchModelVersionInLatestPendingAppReview(
          applicationID,
          patchModelVersionInLatestPendingAppReviewForm,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SupportApi - factory interface
 * @export
 */
export const SupportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SupportApiFp(configuration);
  return {
    /**
     * Update Model version in the Latest Pending Application Review Overrides
     * @param {string} applicationID
     * @param {PatchModelVersionInLatestPendingAppReviewForm} patchModelVersionInLatestPendingAppReviewForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchModelVersionInLatestPendingAppReview(
      applicationID: string,
      patchModelVersionInLatestPendingAppReviewForm: PatchModelVersionInLatestPendingAppReviewForm,
      options?: any,
    ): AxiosPromise<PatchModelVersionInLatestPendingAppReviewResponse> {
      return localVarFp
        .patchModelVersionInLatestPendingAppReview(
          applicationID,
          patchModelVersionInLatestPendingAppReviewForm,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SupportApi - object-oriented interface
 * @export
 * @class SupportApi
 * @extends {BaseAPI}
 */
export class SupportApi extends BaseAPI {
  /**
   * Update Model version in the Latest Pending Application Review Overrides
   * @param {string} applicationID
   * @param {PatchModelVersionInLatestPendingAppReviewForm} patchModelVersionInLatestPendingAppReviewForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportApi
   */
  public patchModelVersionInLatestPendingAppReview(
    applicationID: string,
    patchModelVersionInLatestPendingAppReviewForm: PatchModelVersionInLatestPendingAppReviewForm,
    options?: AxiosRequestConfig,
  ) {
    return SupportApiFp(this.configuration)
      .patchModelVersionInLatestPendingAppReview(
        applicationID,
        patchModelVersionInLatestPendingAppReviewForm,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TelematicsApi - axios parameter creator
 * @export
 */
export const TelematicsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * This endpoint is used to initiate the telematics connection flow with a TSP. It is called by the frontend when the insured selects a TSP in the consent flow and enters (if needed), the details required to make a connection to the TSP using the provided `consentKind`. NOTE: This endpoint is public because it is currently being consumed by a public screen in the frontend
     * @param {string} applicationID
     * @param {TSPConnection} [tSPConnection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDTelematicsConnectionPost: async (
      applicationID: string,
      tSPConnection?: TSPConnection,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDTelematicsConnectionPost',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/telematics_connection`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tSPConnection,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDTelematicsConsentRequestEmailPost: async (
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationID' is not null or undefined
      assertParamExists(
        'applicationApplicationIDTelematicsConsentRequestEmailPost',
        'applicationID',
        applicationID,
      );
      const localVarPath =
        `/application/{applicationID}/telematics_consent_request_email`.replace(
          `{${'applicationID'}}`,
          encodeURIComponent(String(applicationID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        telematicsConsentRequestEmailData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch vehicle stats, returns a map of VINs to list of S3 paths
     * @param {TelematicsVehicleStatKind} kind
     * @param {TelematicsIdentifierType} identifierType
     * @param {string} identifierValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchTelematicsVehicleStats: async (
      kind: TelematicsVehicleStatKind,
      identifierType: TelematicsIdentifierType,
      identifierValue: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kind' is not null or undefined
      assertParamExists('fetchTelematicsVehicleStats', 'kind', kind);
      // verify required parameter 'identifierType' is not null or undefined
      assertParamExists(
        'fetchTelematicsVehicleStats',
        'identifierType',
        identifierType,
      );
      // verify required parameter 'identifierValue' is not null or undefined
      assertParamExists(
        'fetchTelematicsVehicleStats',
        'identifierValue',
        identifierValue,
      );
      const localVarPath = `/telematics/fetch/vehicle-stats/{kind}`.replace(
        `{${'kind'}}`,
        encodeURIComponent(String(kind)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (identifierType !== undefined) {
        localVarQueryParameter['identifierType'] = identifierType;
      }

      if (identifierValue !== undefined) {
        localVarQueryParameter['identifierValue'] = identifierValue;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to get the basic info about a telematics connection using its handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend
     * @param {string} handleID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTelematicsConnectionInfo: async (
      handleID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'handleID' is not null or undefined
      assertParamExists('getTelematicsConnectionInfo', 'handleID', handleID);
      const localVarPath = `/telematics/{handleID}`.replace(
        `{${'handleID'}}`,
        encodeURIComponent(String(handleID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint creates a new non-expired consent link for an application. This link is used so an application can consent to a provider that Nirvana is able to retrieve data from the provider (e.g. Samsara)
     * @param {TelematicsApplicationConsentLinkRequest} [telematicsApplicationConsentLinkRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTelematicsApplicationConsentLink: async (
      telematicsApplicationConsentLinkRequest?: TelematicsApplicationConsentLinkRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/telematics/application/consent-link`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        telematicsApplicationConsentLinkRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to complete the OAuth telematics connection flow with a TSP (Samsara & Motive only, for now). This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the insured completes the OAuth flow with the TSP, the TSP redirects the insured to a frontend screen which calls this endpoint to complete the connection flow.
     * @param {TSPConnectionCompleteRequest} [tSPConnectionCompleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsConnectionCompletePost: async (
      tSPConnectionCompleteRequest?: TSPConnectionCompleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/telematics_connection_complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tSPConnectionCompleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to retrieve the data that is needed in the consent flow while using a telematics link. It has backward compatability with older links which assumed an application id as their token. This is a public endpoint because it is consumed when someone opens the telematics link which does not need authorization. Upon opening the link, the frontend should call this endpoint to retrieve all the links information which includes two flags (isExpired and isRevoked). Both flags correlate to the link\'s functionality. In case, the link is not expired it will include the available public information of the telematics link.
     * @param {string} token
     * @param {ProgramType} programType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsConsentInfoGet: async (
      token: string,
      programType: ProgramType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('telematicsConsentInfoGet', 'token', token);
      // verify required parameter 'programType' is not null or undefined
      assertParamExists('telematicsConsentInfoGet', 'programType', programType);
      const localVarPath = `/telematics/consent-info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      if (programType !== undefined) {
        localVarQueryParameter['programType'] = programType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to get the fleet info for an application and the telematics handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the user successfully links a new telematics connection, the frontend calls this endpoint to get Fleet information, such as DOT number, name and registered VINs.
     * @param {string} handleID
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsHandleIDFleetInfoGet: async (
      handleID: string,
      programType?: ProgramType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'handleID' is not null or undefined
      assertParamExists('telematicsHandleIDFleetInfoGet', 'handleID', handleID);
      const localVarPath = `/telematics/{handleID}/fleet_info`.replace(
        `{${'handleID'}}`,
        encodeURIComponent(String(handleID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (programType !== undefined) {
        localVarQueryParameter['programType'] = programType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the list of supported TSPs and the corresponding consent flow mechanism(s) to be followed to connect with them. This is a public endpoint because it is currently being consumed by a public screen in the frontend. This endpoint also accepts a `token` query parameter which is used to authenticate the request
     * @param {string} token
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsProvidersGet: async (
      token: string,
      programType?: ProgramType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('telematicsProvidersGet', 'token', token);
      const localVarPath = `/telematics_providers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      if (programType !== undefined) {
        localVarQueryParameter['programType'] = programType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TelematicsApi - functional programming interface
 * @export
 */
export const TelematicsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TelematicsApiAxiosParamCreator(configuration);
  return {
    /**
     * This endpoint is used to initiate the telematics connection flow with a TSP. It is called by the frontend when the insured selects a TSP in the consent flow and enters (if needed), the details required to make a connection to the TSP using the provided `consentKind`. NOTE: This endpoint is public because it is currently being consumed by a public screen in the frontend
     * @param {string} applicationID
     * @param {TSPConnection} [tSPConnection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDTelematicsConnectionPost(
      applicationID: string,
      tSPConnection?: TSPConnection,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TSPConnectionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDTelematicsConnectionPost(
          applicationID,
          tSPConnection,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applicationApplicationIDTelematicsConsentRequestEmailPost(
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsConsentRequestEmailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applicationApplicationIDTelematicsConsentRequestEmailPost(
          applicationID,
          telematicsConsentRequestEmailData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch vehicle stats, returns a map of VINs to list of S3 paths
     * @param {TelematicsVehicleStatKind} kind
     * @param {TelematicsIdentifierType} identifierType
     * @param {string} identifierValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchTelematicsVehicleStats(
      kind: TelematicsVehicleStatKind,
      identifierType: TelematicsIdentifierType,
      identifierValue: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FetchTelematicsVehicleStatsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchTelematicsVehicleStats(
          kind,
          identifierType,
          identifierValue,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint is used to get the basic info about a telematics connection using its handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend
     * @param {string} handleID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTelematicsConnectionInfo(
      handleID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsConnectionInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTelematicsConnectionInfo(
          handleID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint creates a new non-expired consent link for an application. This link is used so an application can consent to a provider that Nirvana is able to retrieve data from the provider (e.g. Samsara)
     * @param {TelematicsApplicationConsentLinkRequest} [telematicsApplicationConsentLinkRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTelematicsApplicationConsentLink(
      telematicsApplicationConsentLinkRequest?: TelematicsApplicationConsentLinkRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsApplicationConsentLinkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTelematicsApplicationConsentLink(
          telematicsApplicationConsentLinkRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint is used to complete the OAuth telematics connection flow with a TSP (Samsara & Motive only, for now). This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the insured completes the OAuth flow with the TSP, the TSP redirects the insured to a frontend screen which calls this endpoint to complete the connection flow.
     * @param {TSPConnectionCompleteRequest} [tSPConnectionCompleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async telematicsConnectionCompletePost(
      tSPConnectionCompleteRequest?: TSPConnectionCompleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TSPConnectionCompleteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.telematicsConnectionCompletePost(
          tSPConnectionCompleteRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint is used to retrieve the data that is needed in the consent flow while using a telematics link. It has backward compatability with older links which assumed an application id as their token. This is a public endpoint because it is consumed when someone opens the telematics link which does not need authorization. Upon opening the link, the frontend should call this endpoint to retrieve all the links information which includes two flags (isExpired and isRevoked). Both flags correlate to the link\'s functionality. In case, the link is not expired it will include the available public information of the telematics link.
     * @param {string} token
     * @param {ProgramType} programType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async telematicsConsentInfoGet(
      token: string,
      programType: ProgramType,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsConsentInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.telematicsConsentInfoGet(
          token,
          programType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * This endpoint is used to get the fleet info for an application and the telematics handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the user successfully links a new telematics connection, the frontend calls this endpoint to get Fleet information, such as DOT number, name and registered VINs.
     * @param {string} handleID
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async telematicsHandleIDFleetInfoGet(
      handleID: string,
      programType?: ProgramType,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TelematicsFleetInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.telematicsHandleIDFleetInfoGet(
          handleID,
          programType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns the list of supported TSPs and the corresponding consent flow mechanism(s) to be followed to connect with them. This is a public endpoint because it is currently being consumed by a public screen in the frontend. This endpoint also accepts a `token` query parameter which is used to authenticate the request
     * @param {string} token
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async telematicsProvidersGet(
      token: string,
      programType?: ProgramType,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TSPRecord>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.telematicsProvidersGet(
          token,
          programType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TelematicsApi - factory interface
 * @export
 */
export const TelematicsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TelematicsApiFp(configuration);
  return {
    /**
     * This endpoint is used to initiate the telematics connection flow with a TSP. It is called by the frontend when the insured selects a TSP in the consent flow and enters (if needed), the details required to make a connection to the TSP using the provided `consentKind`. NOTE: This endpoint is public because it is currently being consumed by a public screen in the frontend
     * @param {string} applicationID
     * @param {TSPConnection} [tSPConnection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDTelematicsConnectionPost(
      applicationID: string,
      tSPConnection?: TSPConnection,
      options?: any,
    ): AxiosPromise<TSPConnectionResponse> {
      return localVarFp
        .applicationApplicationIDTelematicsConnectionPost(
          applicationID,
          tSPConnection,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
     * @param {string} applicationID
     * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applicationApplicationIDTelematicsConsentRequestEmailPost(
      applicationID: string,
      telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
      options?: any,
    ): AxiosPromise<TelematicsConsentRequestEmailResponse> {
      return localVarFp
        .applicationApplicationIDTelematicsConsentRequestEmailPost(
          applicationID,
          telematicsConsentRequestEmailData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch vehicle stats, returns a map of VINs to list of S3 paths
     * @param {TelematicsVehicleStatKind} kind
     * @param {TelematicsIdentifierType} identifierType
     * @param {string} identifierValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchTelematicsVehicleStats(
      kind: TelematicsVehicleStatKind,
      identifierType: TelematicsIdentifierType,
      identifierValue: string,
      options?: any,
    ): AxiosPromise<FetchTelematicsVehicleStatsResponse> {
      return localVarFp
        .fetchTelematicsVehicleStats(
          kind,
          identifierType,
          identifierValue,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to get the basic info about a telematics connection using its handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend
     * @param {string} handleID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTelematicsConnectionInfo(
      handleID: string,
      options?: any,
    ): AxiosPromise<TelematicsConnectionInfo> {
      return localVarFp
        .getTelematicsConnectionInfo(handleID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint creates a new non-expired consent link for an application. This link is used so an application can consent to a provider that Nirvana is able to retrieve data from the provider (e.g. Samsara)
     * @param {TelematicsApplicationConsentLinkRequest} [telematicsApplicationConsentLinkRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTelematicsApplicationConsentLink(
      telematicsApplicationConsentLinkRequest?: TelematicsApplicationConsentLinkRequest,
      options?: any,
    ): AxiosPromise<TelematicsApplicationConsentLinkResponse> {
      return localVarFp
        .postTelematicsApplicationConsentLink(
          telematicsApplicationConsentLinkRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to complete the OAuth telematics connection flow with a TSP (Samsara & Motive only, for now). This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the insured completes the OAuth flow with the TSP, the TSP redirects the insured to a frontend screen which calls this endpoint to complete the connection flow.
     * @param {TSPConnectionCompleteRequest} [tSPConnectionCompleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsConnectionCompletePost(
      tSPConnectionCompleteRequest?: TSPConnectionCompleteRequest,
      options?: any,
    ): AxiosPromise<TSPConnectionCompleteResponse> {
      return localVarFp
        .telematicsConnectionCompletePost(tSPConnectionCompleteRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to retrieve the data that is needed in the consent flow while using a telematics link. It has backward compatability with older links which assumed an application id as their token. This is a public endpoint because it is consumed when someone opens the telematics link which does not need authorization. Upon opening the link, the frontend should call this endpoint to retrieve all the links information which includes two flags (isExpired and isRevoked). Both flags correlate to the link\'s functionality. In case, the link is not expired it will include the available public information of the telematics link.
     * @param {string} token
     * @param {ProgramType} programType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsConsentInfoGet(
      token: string,
      programType: ProgramType,
      options?: any,
    ): AxiosPromise<TelematicsConsentInfoResponse> {
      return localVarFp
        .telematicsConsentInfoGet(token, programType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to get the fleet info for an application and the telematics handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the user successfully links a new telematics connection, the frontend calls this endpoint to get Fleet information, such as DOT number, name and registered VINs.
     * @param {string} handleID
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsHandleIDFleetInfoGet(
      handleID: string,
      programType?: ProgramType,
      options?: any,
    ): AxiosPromise<TelematicsFleetInfoResponse> {
      return localVarFp
        .telematicsHandleIDFleetInfoGet(handleID, programType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the list of supported TSPs and the corresponding consent flow mechanism(s) to be followed to connect with them. This is a public endpoint because it is currently being consumed by a public screen in the frontend. This endpoint also accepts a `token` query parameter which is used to authenticate the request
     * @param {string} token
     * @param {ProgramType} [programType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    telematicsProvidersGet(
      token: string,
      programType?: ProgramType,
      options?: any,
    ): AxiosPromise<Array<TSPRecord>> {
      return localVarFp
        .telematicsProvidersGet(token, programType, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TelematicsApi - object-oriented interface
 * @export
 * @class TelematicsApi
 * @extends {BaseAPI}
 */
export class TelematicsApi extends BaseAPI {
  /**
   * This endpoint is used to initiate the telematics connection flow with a TSP. It is called by the frontend when the insured selects a TSP in the consent flow and enters (if needed), the details required to make a connection to the TSP using the provided `consentKind`. NOTE: This endpoint is public because it is currently being consumed by a public screen in the frontend
   * @param {string} applicationID
   * @param {TSPConnection} [tSPConnection]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public applicationApplicationIDTelematicsConnectionPost(
    applicationID: string,
    tSPConnection?: TSPConnection,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .applicationApplicationIDTelematicsConnectionPost(
        applicationID,
        tSPConnection,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * After the agent generates a new telematics connection link, we require  the agent to send that link to the insured either directly or indirectly. The agent may choose to have Nirvana send the consent request email  to the insured, by populating the \'email\' dictionary in the request.  Alternatively, the agent may send the consent request email themselves, in  which case this endpoint will be called with no \'email\' dictionary.
   * @param {string} applicationID
   * @param {TelematicsConsentRequestEmailData} [telematicsConsentRequestEmailData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public applicationApplicationIDTelematicsConsentRequestEmailPost(
    applicationID: string,
    telematicsConsentRequestEmailData?: TelematicsConsentRequestEmailData,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .applicationApplicationIDTelematicsConsentRequestEmailPost(
        applicationID,
        telematicsConsentRequestEmailData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch vehicle stats, returns a map of VINs to list of S3 paths
   * @param {TelematicsVehicleStatKind} kind
   * @param {TelematicsIdentifierType} identifierType
   * @param {string} identifierValue
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public fetchTelematicsVehicleStats(
    kind: TelematicsVehicleStatKind,
    identifierType: TelematicsIdentifierType,
    identifierValue: string,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .fetchTelematicsVehicleStats(
        kind,
        identifierType,
        identifierValue,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to get the basic info about a telematics connection using its handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend
   * @param {string} handleID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public getTelematicsConnectionInfo(
    handleID: string,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .getTelematicsConnectionInfo(handleID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint creates a new non-expired consent link for an application. This link is used so an application can consent to a provider that Nirvana is able to retrieve data from the provider (e.g. Samsara)
   * @param {TelematicsApplicationConsentLinkRequest} [telematicsApplicationConsentLinkRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public postTelematicsApplicationConsentLink(
    telematicsApplicationConsentLinkRequest?: TelematicsApplicationConsentLinkRequest,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .postTelematicsApplicationConsentLink(
        telematicsApplicationConsentLinkRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to complete the OAuth telematics connection flow with a TSP (Samsara & Motive only, for now). This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the insured completes the OAuth flow with the TSP, the TSP redirects the insured to a frontend screen which calls this endpoint to complete the connection flow.
   * @param {TSPConnectionCompleteRequest} [tSPConnectionCompleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public telematicsConnectionCompletePost(
    tSPConnectionCompleteRequest?: TSPConnectionCompleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .telematicsConnectionCompletePost(tSPConnectionCompleteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to retrieve the data that is needed in the consent flow while using a telematics link. It has backward compatability with older links which assumed an application id as their token. This is a public endpoint because it is consumed when someone opens the telematics link which does not need authorization. Upon opening the link, the frontend should call this endpoint to retrieve all the links information which includes two flags (isExpired and isRevoked). Both flags correlate to the link\'s functionality. In case, the link is not expired it will include the available public information of the telematics link.
   * @param {string} token
   * @param {ProgramType} programType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public telematicsConsentInfoGet(
    token: string,
    programType: ProgramType,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .telematicsConsentInfoGet(token, programType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to get the fleet info for an application and the telematics handleID. This is a public endpoint because it is currently being consumed by a public screen in the frontend. After the user successfully links a new telematics connection, the frontend calls this endpoint to get Fleet information, such as DOT number, name and registered VINs.
   * @param {string} handleID
   * @param {ProgramType} [programType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public telematicsHandleIDFleetInfoGet(
    handleID: string,
    programType?: ProgramType,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .telematicsHandleIDFleetInfoGet(handleID, programType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the list of supported TSPs and the corresponding consent flow mechanism(s) to be followed to connect with them. This is a public endpoint because it is currently being consumed by a public screen in the frontend. This endpoint also accepts a `token` query parameter which is used to authenticate the request
   * @param {string} token
   * @param {ProgramType} [programType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelematicsApi
   */
  public telematicsProvidersGet(
    token: string,
    programType?: ProgramType,
    options?: AxiosRequestConfig,
  ) {
    return TelematicsApiFp(this.configuration)
      .telematicsProvidersGet(token, programType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
