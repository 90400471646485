import { Button } from '@material-ui/core';
import { storage } from '@nirvana/ui-kit';
import { useNavigate } from 'react-router-dom';
import { SHARE_TOKEN_STORAGE_KEY } from 'src/constants';
import useAuth from 'src/hooks/useAuth';

const CompleteSignUpBanner = () => {
  const { user, logout } = useAuth();
  const isInvited = user?.userType === 'shared_link';

  const navigate = useNavigate();
  if (!isInvited) {
    return null;
  }

  const redirectToSignUp = () => {
    const shareId = storage.get(SHARE_TOKEN_STORAGE_KEY);
    logout().then(() => {
      navigate(`/sign-up/${shareId}`, { replace: true });
    });
  };

  return (
    <Button variant="contained" onClick={redirectToSignUp}>
      Complete Sign Up
    </Button>
  );
};

export default CompleteSignUpBanner;
