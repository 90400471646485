export const computeScoreTextColor = (score: number): string => {
  if (score <= 25) {
    return 'text-success-main';
  } else if (score < 50) {
    return 'text-[#82D01E]';
  } else if (score < 75) {
    return 'text-warning-main';
  }
  return 'text-error-main';
};
