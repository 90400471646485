import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const getFormattedDate = (
  dateString: string,
  formatString: string = 'MM/dd/yyyy',
) => {
  return format(parseISO(dateString), formatString);
};

export const getFormattedDateInTimezone = (
  dateString: string,
  formatString: string = 'MM/dd/yyyy',
  timezone: string = 'UTC',
) => {
  const zonedDate = utcToZonedTime(parseISO(dateString), timezone);
  return format(zonedDate, formatString);
};
