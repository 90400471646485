import { Button } from '@material-ui/core';
import { Dialog, HorizontalStepper, Switch } from '@nirvana/ui-kit';
import { useState } from 'react';
import { GoUnlock } from 'react-icons/go';
import { Feature, useFeatureEnabled } from 'src/helpers/feature-flags';
import { Login } from './Login';
import { TwoFA } from './TwoFA';

type ConnectFMCSAProps = {
  onConnectionComplete: () => void;
  shouldShowConnectFMCSA: boolean;
};

export const ConnectFMCSA = ({
  onConnectionComplete,
  shouldShowConnectFMCSA = false,
}: ConnectFMCSAProps) => {
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [browserWSEndpoint, setBrowserWSEndpoint] = useState('');
  const [twoFactorUrl, setTwoFactorUrl] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const isDriverFeatureEnabled = useFeatureEnabled(Feature.DRIVER_SAFETY_SCORE);

  const handleClose = () => {
    if (activeStep > 1) {
      setActiveStep(0);
      onConnectionComplete();
    }
    setShowConnectionModal(false);
  };

  const steps = [
    { key: 'auth', label: 'Enter your email and password' },
    { key: '2fa', label: 'Enter your 2FA code' },
  ];

  const handleNextClick = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  if (!isDriverFeatureEnabled || shouldShowConnectFMCSA) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        startIcon={<GoUnlock />}
        className="w-full md:w-auto"
        onClick={() => setShowConnectionModal(true)}
      >
        Unlock Driver Names
      </Button>
      <Dialog
        open={showConnectionModal}
        onClose={handleClose}
        maxWidth="xs"
        title="Connect with FMCSA"
        secondaryAction={
          <>
            {activeStep > 1 ? (
              <Button variant="contained" onClick={handleClose}>
                Close
              </Button>
            ) : (
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            )}
          </>
        }
      >
        <HorizontalStepper activeStep={activeStep} steps={steps} />
        <Switch>
          <Switch.Match when={activeStep === 0}>
            <Login
              onNextClick={handleNextClick}
              setBrowserWSEndpoint={setBrowserWSEndpoint}
              setTwoFactorUrl={setTwoFactorUrl}
            />
          </Switch.Match>
          <Switch.Match when={activeStep === 1}>
            <TwoFA
              browserWSEndpoint={browserWSEndpoint}
              twoFactorUrl={twoFactorUrl}
              onNextClick={handleNextClick}
            />
          </Switch.Match>
          <Switch.Match when={activeStep > 1}>
            <p>
              Thanks for connecting your FMCSA account! The violation data for
              your fleet will be updated with driver names shortly.{' '}
            </p>
            <small className="mt-8">
              Please note: depending on data updates from the FMCSA, you may
              need to re-connect your account in the future.
            </small>
          </Switch.Match>
        </Switch>
      </Dialog>
    </>
  );
};
