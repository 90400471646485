/*
 * @function
 * Mask email
 */
const maskEmail = (email: string) => {
  const maskedEmail = email.replace(
    /^(.{3})(.*)(?=@)/,
    (_: string, firstChars: string, rest: string) =>
      firstChars + rest.replace(/./g, '*'),
  );
  return maskedEmail;
};

export default maskEmail;
