import { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';

import './referralFactory.css';

export default function ReferralFactory() {
  const { user } = useAuth();

  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://referral-factory.com/assets/js/widget.js?code=c4XrFEHd';
    script.id = 'rf-script';

    if (user) {
      window.RF = {
        user: {
          first_name: user.name,
          email: user.email,
        },
      };
      document.body.appendChild(script);
    }
    return () => {
      document.body.removeChild(script);
    };
  }, [user]);

  return null;
}
