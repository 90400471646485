import setDate from 'date-fns/setDate';
import subMonths from 'date-fns/subMonths';
import { useMemo } from 'react';
import { formatPeriod } from '../helpers/dateHelpers';

export const useGetFilterDates = () => {
  const { startTime, endTime, mileagesStartTime } = useMemo(() => {
    const today = new Date();
    const twoYearsAgo = subMonths(today, 24);
    const threeMonthsAgo = subMonths(today, 3);

    return {
      startTime: setDate(twoYearsAgo, 1),
      endTime: setDate(today, 1),
      mileagesStartTime: setDate(threeMonthsAgo, 1),
    };
  }, []);

  const period = formatPeriod(startTime, endTime);

  return {
    period,
    isoUTCStartTime: startTime.toISOString(),
    isoUTCEndTime: endTime.toISOString(),
    isoUTCMileagesStartTime: mileagesStartTime.toISOString(),
  };
};
