import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import useAuth from 'src/hooks/useAuth';

export default function LaunchDarkly() {
  const client = useLDClient();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      client?.identify({
        kind: 'user',
        key: user.id,
        email: user.email,
        name: user.name,
        agencyId: user.defaultAgencyId ?? '',
      });
    }
  }, [client, user]);

  return null;
}
