import clsx from 'clsx';
import { computeScoreTextColor } from './safety-score';

// Custom Tooltip for ISS Score chart
export function CustomTooltip(props: any) {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <div className="flex items-center justify-between w-32 p-2 rounded-md bg-text-primary">
        <span className="text-primary-tint1">Score</span>
        <span
          className={clsx(
            'text-base font-bold',
            computeScoreTextColor(payload[0].value),
          )}
        >
          {payload[0].value < 50 ? 'PASSED' : payload[0].value}
        </span>
      </div>
    );
  }
  return null;
}

export function renderDots(props: any) {
  const { value, cx, cy, key } = props;
  const circleProps = { cx, cy, fill: 'white', strokeWidth: 2 };

  if (value === null) {
    return null;
  }

  if (value < 50) {
    return (
      <g fontSize={11} fontWeight={500} key={key}>
        <circle r={20} stroke="#25B24A" {...circleProps} />
        <text x={cx} y={cy + 4} textAnchor="middle" fill="#25B24A">
          PASS
        </text>
      </g>
    );
  }

  return <circle r={4} stroke="#00A0FF" key={key} {...circleProps} />;
}

export function renderActiveDots(props: any) {
  const { cx, cy, value } = props;
  const circleProps = { cx, cy, fill: '#00A0FF', strokeWidth: 2 };

  if (value < 50) {
    return null;
  }
  return <circle r={6} stroke="white" {...circleProps} />;
}
