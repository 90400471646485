import { useEffect } from 'react';
import { analytics } from 'src/helpers';
import SafetyScore from './components/safety-score';
import ISSScore from './components/iss-score';
import BasicScore from './components/basic-score';

export default function Scores() {
  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.ScoresPageView,
    });
  }, []);

  return (
    <div className="space-y-5">
      <SafetyScore />
      <ISSScore />
      <BasicScore />
    </div>
  );
}
