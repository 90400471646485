import { Button, IconButton } from '@material-ui/core';
import { Dialog, InputWithLabel } from '@nirvana/ui-kit';
import React, { useState } from 'react';
import { Close as CloseIcon } from '@material-ui/icons';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useInviteFleetUserMutation,
  useSafetyReportQuery,
} from 'src/types/graphql-types';
import { useSnackbar } from 'notistack';
import { useShowInvite } from '../hooks/useShowInvite';

const InviteSchema = z.object({
  email: z.string().email(),
});

type InviteType = z.infer<typeof InviteSchema>;

const Invite = ({
  variant = 'contained',
}: {
  variant?: 'outlined' | 'contained';
}) => {
  const { reportId: dotNumber } = useParams<{ reportId: string }>();
  const { data: safetyReportData } = useSafetyReportQuery({
    variables: { reportId: dotNumber as string },
  });
  const reportId = safetyReportData?.fleetSafetyReport?.id as string;

  const showInvite = useShowInvite();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<InviteType>({
    mode: 'onChange',
    resolver: zodResolver(InviteSchema),
  });
  const [inviteFleetUser, { loading: isLoading }] =
    useInviteFleetUserMutation();

  if (!showInvite) {
    return null;
  }

  const onSubmit = (data: InviteType) => {
    inviteFleetUser({
      variables: {
        fleetId: reportId,
        email: data.email,
      },
      onCompleted: () => {
        setIsModalOpen(false);
        reset();
        enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
      },
      onError: () => {
        reset();
        enqueueSnackbar(
          'Unexpected error while sending the invitation. Please try again later.',
          { variant: 'error' },
        );
      },
    });
  };

  return (
    <>
      <Button variant={variant} onClick={() => setIsModalOpen(true)}>
        Invite
      </Button>
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <div className="relative flex flex-col px-4 pt-2 pb-6">
          <IconButton
            aria-label="close"
            className="absolute top-0 right-0 text-gray-500"
            onClick={handleCloseModal}
            size="small"
          >
            <CloseIcon />
          </IconButton>

          <h2 className="text-xl font-semibold text-text-primary">
            Invite Teammates
          </h2>

          <div className="mt-4 mb-8 space-y-2">
            <p className="text-xs text-text-hint">
              Share, collaborate and take action on safety insights and
              compliance data.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputWithLabel
              {...register('email')}
              label="Email ID"
              formControlProps={{ fullWidth: true, className: 'mb-6' }}
              placeholder="Email"
              type="email"
              fullWidth
              helperText="Enter your team member's email address"
              error={!!errors.email}
            />
            <div className="flex justify-end">
              <Button
                type="submit"
                variant="contained"
                disabled={!isValid || isLoading}
              >
                Invite
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default Invite;
