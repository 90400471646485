import * as React from 'react';

/**
 * Render threshold line for each bar
 * Used with content prop of LabelList
 */
export default function ThresholdLine(props: any) {
  const { x, y, height, width, value } = props;

  if (!value) {
    return null;
  }

  // value = 100      =>  thresholdY = marginTop    = 20
  // value = 0        =>  thresholdY = totalHeight  = y + height
  //
  // Therefore, pixelMultiplier = (y+height-20)/100
  // Hence, value     =>  thresholdY = 20 + (pixelMultiplier * (100 - value))
  const TOTAL_HEIGHT = y + height;
  const pixelMultiplier = (TOTAL_HEIGHT - 20) / 100;
  const thresholdY = 20 + pixelMultiplier * (100 - value);

  return (
    <line
      x1={x - 5}
      x2={x + width + 5}
      y1={thresholdY}
      y2={thresholdY}
      stroke="#FA3252"
      strokeWidth={2}
    />
  );
}
