import { useEffect, useRef, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

export default function useLocalStorage<T extends any>(
  key: string,
  defaultValue?: T,
  { serialize = JSON.stringify, deserialize = JSON.parse } = {},
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      return deserialize(valueInLocalStorage);
    }
    return typeof defaultValue === 'function'
      ? defaultValue()
      : defaultValue ?? '';
  });

  const prevKeyRef = useRef(key);

  useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    prevKeyRef.current = key;
    window.localStorage.setItem(key, serialize(state));
  }, [key, state, serialize]);

  return [state, setState];
}
