export const basicTabs = [
  {
    label: 'Unsafe Driving',
    value: 'Unsafe Driving',
  },
  {
    label: 'Crash Indicator',
    value: 'Crash Indicator',
  },
  {
    label: 'HOS Compliance',
    value: 'HOS Compliance',
  },
  {
    label: 'Vehicle Maintenance',
    value: 'Vehicle Maintenance',
  },
  {
    label: 'Controlled Substances/Alcohol',
    value: 'Controlled Substances/Alcohol',
  },
  {
    label: 'HM Compliance',
    value: 'HazMat Compliance',
  },
  {
    label: 'Driver Fitness',
    value: 'Driver Fitness',
  },
];
