import { FlagSeverity } from 'src/types/graphql-types';

export const tabsList = [
  { id: 'all', label: 'All', value: '' },
  {
    id: FlagSeverity.Critical,
    label: 'Critical',
    value: FlagSeverity.Critical,
  },
  {
    id: FlagSeverity.Moderate,
    label: 'Moderate',
    value: FlagSeverity.Moderate,
  },
  { id: FlagSeverity.Minor, label: 'Minor', value: FlagSeverity.Minor },
];
