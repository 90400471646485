// We could attempt to turn this file into a factory, but it doesn't not seem worth the effort.

export const mockLocationStats = {
  __typename: 'GeoJSON',
  features: [
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-116.301676, 41.000076],
            [-117.018419, 41.000254],
            [-117.018204, 41.999715],
            [-114.041477, 41.993873],
            [-114.046614, 40.116936],
            [-116.000897, 40.127376],
            [-116.157834, 40.666389],
            [-116.158155, 40.999912],
            [-116.301676, 41.000076],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'NV007',
        inspectionRecords: [
          {
            __key: '72037e29-64f3-5b5f-8add-eb911db0b5dc',
            __typename: 'inspection',
            violations: [
              {
                __key: '041f5d22-18bb-555e-aaf7-8050736984f9',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Elko',
        violation_count: 1,
        violation_percentage: 8.333333333333332,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-84.070116, 36.028881],
            [-84.058585, 36.036304],
            [-84.093004, 36.073519],
            [-83.941711, 36.186378],
            [-83.905169, 36.160906],
            [-83.732704, 36.164583],
            [-83.723703, 36.137483],
            [-83.691433, 36.122027],
            [-83.699405, 36.109469],
            [-83.667026, 36.086238],
            [-83.710114, 36.091497],
            [-83.694599, 36.058249],
            [-83.671169, 36.053105],
            [-83.677274, 36.025592],
            [-83.650957, 35.986368],
            [-83.683627, 35.936846],
            [-83.693364, 35.9447],
            [-83.767242, 35.911862],
            [-83.850155, 35.842019],
            [-83.907542, 35.846626],
            [-83.946145, 35.876402],
            [-83.97461, 35.863054],
            [-83.99753, 35.884034],
            [-84.028739, 35.875184],
            [-84.010427, 35.864061],
            [-84.010417, 35.843128],
            [-84.047509, 35.852286],
            [-84.054959, 35.827578],
            [-84.078959, 35.85079],
            [-84.122466, 35.830873],
            [-84.130945, 35.850848],
            [-84.145659, 35.849586],
            [-84.137199, 35.807585],
            [-84.159661, 35.795196],
            [-84.268393, 35.902563],
            [-84.252359, 35.918888],
            [-84.27347, 35.939278],
            [-84.237583, 35.929646],
            [-84.244704, 35.954318],
            [-84.214766, 35.947166],
            [-84.215327, 35.97443],
            [-84.193247, 35.994992],
            [-84.147996, 35.986831],
            [-84.070116, 36.028881],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'TN093',
        inspectionRecords: [
          {
            __key: 'f9b3fef6-b1a3-5cde-880f-77ae4c3593c0',
            __typename: 'inspection',
            violations: [
              {
                __key: '54c3e174-4626-52d2-a9d4-870ff81d47e4',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'd7807a49-6183-5e1a-b292-549dad0c1fb9',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '2e925a7f-835c-51f7-97f4-43d1d2f864b6',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'bb4fe9cc-b86f-56b9-8ba9-d3ecdd22d87d',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'd44fdfbb-9e06-59c2-9cc4-9851d7e8adbf',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '0753298a-5850-523c-81c5-1cf47ebb2585',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Knox',
        violation_count: 6,
        violation_percentage: 50,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-89.985562, 39.785356],
            [-89.994506, 39.901925],
            [-89.769168, 39.90235],
            [-89.769143, 39.916865],
            [-89.701864, 39.916787],
            [-89.698259, 39.975309],
            [-89.483826, 39.976733],
            [-89.483498, 39.933151],
            [-89.404969, 39.932717],
            [-89.404984, 39.918187],
            [-89.217846, 39.91699],
            [-89.217475, 39.813217],
            [-89.248609, 39.825723],
            [-89.303122, 39.775813],
            [-89.357795, 39.770822],
            [-89.395684, 39.742443],
            [-89.425171, 39.762022],
            [-89.425051, 39.683696],
            [-89.478923, 39.683964],
            [-89.478473, 39.640842],
            [-89.535028, 39.641138],
            [-89.533655, 39.524592],
            [-89.926037, 39.522104],
            [-89.923955, 39.558678],
            [-89.984745, 39.718079],
            [-89.985562, 39.785356],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'IL167',
        inspectionRecords: [
          {
            __key: '5f8341ab-d185-57b7-8b92-0cf0d8c6195e',
            __typename: 'inspection',
            violations: [
              {
                __key: '98cacd6b-b644-5bf9-a480-0caad8391220',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
              {
                __key: '191aedbc-605e-50c4-97a5-df26055a192c',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
              {
                __key: '3c0fe5b8-425a-5ac7-82be-e7b51400361d',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Sangamon',
        violation_count: 3,
        violation_percentage: 25,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-94.471177, 35.629217],
            [-94.49313, 35.759167],
            [-93.892246, 35.75941],
            [-93.911509, 35.730754],
            [-93.96415, 35.732091],
            [-93.964805, 35.703397],
            [-93.982723, 35.704022],
            [-93.984204, 35.660783],
            [-94.073958, 35.662463],
            [-94.076209, 35.575489],
            [-94.023014, 35.574204],
            [-94.040675, 35.542604],
            [-94.028998, 35.507682],
            [-94.05793, 35.47344],
            [-94.026969, 35.460785],
            [-94.086617, 35.442486],
            [-94.148525, 35.447532],
            [-94.161632, 35.434158],
            [-94.138866, 35.408775],
            [-94.13965, 35.383782],
            [-94.190016, 35.387933],
            [-94.223751, 35.353694],
            [-94.283954, 35.343574],
            [-94.338953, 35.369502],
            [-94.341921, 35.414921],
            [-94.393479, 35.450549],
            [-94.423389, 35.439181],
            [-94.431045, 35.393735],
            [-94.471177, 35.629217],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'AR033',
        inspectionRecords: [
          {
            __key: 'dc70074d-1a8d-5dd4-8ea8-86d322d0aed1',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Crawford',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-81.970263, 41.411045],
            [-81.97058, 41.877845],
            [-81.495181, 42.096402],
            [-81.487842, 41.570051],
            [-81.391005, 41.569716],
            [-81.391518, 41.445222],
            [-81.374992, 41.445286],
            [-81.374772, 41.424427],
            [-81.391764, 41.424269],
            [-81.391694, 41.348272],
            [-81.598052, 41.351157],
            [-81.565139, 41.277758],
            [-81.878053, 41.275044],
            [-81.87696, 41.350685],
            [-81.971262, 41.351268],
            [-81.970263, 41.411045],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'OH035',
        inspectionRecords: [
          {
            __key: '06a64f85-359c-50d7-aab1-d3443fe590f9',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Cuyahoga',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-89.444892, 35.700771],
            [-89.427691, 35.740184],
            [-89.439691, 35.765915],
            [-89.401805, 35.822657],
            [-89.356033, 35.817635],
            [-89.338704, 35.789273],
            [-89.271224, 35.754922],
            [-89.190153, 35.74288],
            [-89.116978, 35.69922],
            [-89.069544, 35.692679],
            [-89.078876, 35.431428],
            [-89.182551, 35.432816],
            [-89.183944, 35.397126],
            [-89.474171, 35.403039],
            [-89.469862, 35.546566],
            [-89.502118, 35.580621],
            [-89.469993, 35.595226],
            [-89.466354, 35.682706],
            [-89.444892, 35.700771],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'TN075',
        inspectionRecords: [
          {
            __key: '86e81376-0651-5b90-8bf7-09017b086fb4',
            __typename: 'inspection',
            violations: [
              {
                __key: '448e4d99-1241-53dd-95d8-bbc8a67a6c1c',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'ad54be0e-d6bc-50e8-8739-e4b67ccbfb08',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Haywood',
        violation_count: 2,
        violation_percentage: 16.666666666666664,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-83.771457, 41.995203],
            [-83.773922, 42.08243],
            [-83.295063, 42.094237],
            [-83.199085, 42.03476],
            [-83.143956, 42.02793],
            [-83.11246, 41.95941],
            [-83.415851, 41.733794],
            [-83.76315, 41.723552],
            [-83.771457, 41.995203],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MI115',
        inspectionRecords: [
          {
            __key: '850e97e1-9d18-5885-8275-05eac374b460',
            __typename: 'inspection',
            violations: [
              {
                __key: 'ce484fe7-2d9d-5427-be09-a26edde634b9',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '3a10842a-674a-5b9e-af7d-5244e799bd98',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Monroe',
        violation_count: 2,
        violation_percentage: 16.666666666666664,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-108.458294, 46.13291],
            [-108.404795, 46.132952],
            [-108.404856, 46.23596],
            [-108.36294, 46.235584],
            [-108.362927, 46.25003],
            [-108.320952, 46.249664],
            [-108.320812, 46.263997],
            [-108.029363, 46.264055],
            [-108.02933, 46.307841],
            [-108.011597, 46.307822],
            [-108.011948, 46.336813],
            [-107.970009, 46.351474],
            [-107.970165, 46.365967],
            [-107.949322, 46.36606],
            [-107.928547, 46.395284],
            [-107.782087, 46.395182],
            [-107.781214, 46.496014],
            [-107.754824, 46.496043],
            [-107.738432, 46.467556],
            [-107.717512, 46.467508],
            [-107.6961, 46.409482],
            [-107.654159, 46.394888],
            [-107.654153, 46.365951],
            [-107.633378, 46.365993],
            [-107.633363, 46.351525],
            [-107.612411, 46.351557],
            [-107.612395, 46.308113],
            [-107.591438, 46.308156],
            [-107.591422, 46.29365],
            [-107.570495, 46.293652],
            [-107.570468, 46.264705],
            [-107.528646, 46.250245],
            [-107.507925, 46.192283],
            [-107.466131, 46.177774],
            [-107.474984, 46.152252],
            [-107.462651, 46.141426],
            [-107.484289, 46.124401],
            [-107.474487, 46.102996],
            [-107.517447, 46.059519],
            [-107.511534, 46.043403],
            [-107.674454, 46.04361],
            [-107.674468, 45.985759],
            [-107.798753, 45.98568],
            [-107.798821, 45.956723],
            [-107.84038, 45.956812],
            [-107.840375, 45.928018],
            [-107.881873, 45.928149],
            [-107.881846, 45.899233],
            [-108.047352, 45.899479],
            [-108.047559, 45.783476],
            [-108.069572, 45.783507],
            [-108.069752, 45.518535],
            [-108.193205, 45.518538],
            [-108.193191, 45.489526],
            [-108.316245, 45.489503],
            [-108.316374, 45.460509],
            [-108.698102, 45.464072],
            [-108.699283, 45.522629],
            [-108.760308, 45.522831],
            [-108.760831, 45.55183],
            [-108.801761, 45.551965],
            [-108.782, 45.638639],
            [-108.843016, 45.611042],
            [-108.843145, 45.639458],
            [-108.884427, 45.653761],
            [-108.884297, 45.682936],
            [-108.904726, 45.682821],
            [-108.901655, 45.959416],
            [-108.922324, 45.959444],
            [-108.924589, 46.13237],
            [-108.458294, 46.13291],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MT111',
        inspectionRecords: [
          {
            __key: 'aff81624-df3d-5e8e-a6d9-84b975e2a36c',
            __typename: 'inspection',
            violations: [],
          },
          {
            __key: 'f970085d-40ba-5394-9b98-dddc1c2cd2b3',
            __typename: 'inspection',
            violations: [],
          },
          {
            __key: '78eec7c7-7c17-5343-86c9-adb12b884e52',
            __typename: 'inspection',
            violations: [
              {
                __key: '5a2dd72c-09e9-5ddd-b901-7150534d1352',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 3,
        inspection_percentage: 75,
        name: 'Yellowstone',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-97.227928, 45.818671],
            [-97.228291, 45.935383],
            [-96.563672, 45.935245],
            [-96.583684, 45.819307],
            [-96.662595, 45.738682],
            [-96.838648, 45.647509],
            [-96.856834, 45.605378],
            [-96.763432, 45.520007],
            [-96.732739, 45.458737],
            [-96.690043, 45.415491],
            [-96.521787, 45.375645],
            [-96.470475, 45.326545],
            [-96.992946, 45.32688],
            [-97.007543, 45.296866],
            [-97.226244, 45.297647],
            [-97.227928, 45.818671],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'SD109',
        inspectionRecords: [
          {
            __key: 'fafa6688-2c96-5120-97ef-c82ec16f1ec5',
            __typename: 'inspection',
            violations: [
              {
                __key: '7fce1de2-69ab-58e5-bab1-ef49133303c9',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Roberts',
        violation_count: 1,
        violation_percentage: 8.333333333333332,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-109.04622, 35.660596],
            [-109.046067, 36.002701],
            [-107.308667, 35.998556],
            [-107.309386, 35.305687],
            [-107.628788, 35.304333],
            [-107.628313, 35.348314],
            [-107.734241, 35.348145],
            [-107.734224, 35.304987],
            [-108.468715, 35.306658],
            [-108.469402, 34.959081],
            [-109.045996, 34.959819],
            [-109.04622, 35.660596],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'NM031',
        inspectionRecords: [
          {
            __key: '253c47a2-2fa7-5a20-b47f-27d30d1da96b',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Mckinley',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-88.608501, 35.725923],
            [-88.606862, 35.789354],
            [-88.569047, 35.824829],
            [-88.179177, 35.816785],
            [-88.190465, 35.609088],
            [-88.226459, 35.554269],
            [-88.216759, 35.548866],
            [-88.243057, 35.528858],
            [-88.241951, 35.418654],
            [-88.360829, 35.418972],
            [-88.364005, 35.492801],
            [-88.414582, 35.482339],
            [-88.469106, 35.495882],
            [-88.529617, 35.531835],
            [-88.56557, 35.587088],
            [-88.612461, 35.586899],
            [-88.608501, 35.725923],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'TN077',
        inspectionRecords: [
          {
            __key: '9d0a35a7-58dd-599f-914f-500092968dbb',
            __typename: 'inspection',
            violations: [
              {
                __key: 'd641c5de-5027-596a-952b-a684f4b602f0',
                __typename: 'violation',
                category: 'UnsafeDriving',
              },
              {
                __key: '647425e5-ec30-5bbc-8fca-335f5d4ff24b',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
              {
                __key: '9d726ef8-835e-5e97-8a41-36c0c50b7bcf',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Henderson',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-97.462381, 36.897805],
            [-97.462346, 36.998701],
            [-96.749838, 36.998988],
            [-96.752375, 36.782092],
            [-96.822585, 36.767559],
            [-96.830383, 36.744172],
            [-96.888581, 36.751793],
            [-96.931943, 36.686097],
            [-97.032526, 36.701959],
            [-97.053235, 36.69221],
            [-97.063069, 36.655738],
            [-97.043823, 36.600671],
            [-97.057556, 36.593872],
            [-97.462459, 36.593627],
            [-97.462381, 36.897805],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'OK071',
        inspectionRecords: [
          {
            __key: '4a40052d-b8c4-5dcd-8bf8-33c5c52f2309',
            __typename: 'inspection',
            violations: [
              {
                __key: '0977843e-c7be-56ad-aeab-a27a9455d6c2',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '6676d1ad-de52-558e-9c1b-77fa005d7b8b',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '66c7a0bd-4318-5d56-af1c-3ae798ee9b57',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'ab56f04a-a49f-55ff-8c00-c9586a75303f',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '55cdf772-dca3-5803-95ee-22bdee978988',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'a8840400-13be-5b8d-945b-ce7868bca9e0',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Kay',
        violation_count: 6,
        violation_percentage: 50,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-89.094831, 30.676991],
            [-88.884533, 30.677292],
            [-88.88313, 30.418798],
            [-88.846042, 30.405654],
            [-88.795287, 30.350361],
            [-88.795147, 30.199994],
            [-88.821387, 30.21737],
            [-88.90033, 30.16998],
            [-88.88903, 30.139845],
            [-89.096476, 30.165829],
            [-89.183669, 30.212155],
            [-89.306135, 30.177941],
            [-89.306845, 30.338396],
            [-89.340847, 30.357995],
            [-89.341446, 30.64772],
            [-89.240136, 30.647646],
            [-89.240073, 30.67716],
            [-89.094831, 30.676991],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MS047',
        inspectionRecords: [
          {
            __key: '46a72582-dad9-54e4-bb6a-9744a51d3bbc',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Harrison',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-109.403297, 46.373828],
            [-109.403544, 46.490657],
            [-109.388548, 46.490638],
            [-109.389208, 46.751351],
            [-109.00957, 46.750702],
            [-109.009982, 46.621172],
            [-108.988727, 46.62124],
            [-108.988995, 46.577851],
            [-108.904904, 46.576908],
            [-108.905259, 46.489805],
            [-108.880114, 46.489737],
            [-108.881795, 46.45173],
            [-108.860801, 46.451665],
            [-108.862277, 46.364586],
            [-108.841331, 46.364563],
            [-108.83161, 46.320999],
            [-108.800203, 46.306441],
            [-108.800141, 46.277405],
            [-108.779272, 46.277398],
            [-108.779733, 46.132457],
            [-109.416227, 46.132371],
            [-109.417158, 46.044759],
            [-109.605153, 46.044994],
            [-109.60419, 46.132484],
            [-109.65384, 46.132465],
            [-109.653871, 46.219404],
            [-109.402906, 46.219706],
            [-109.403297, 46.373828],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MT037',
        inspectionRecords: [
          {
            __key: 'd9d0970a-b9ce-558c-9360-a9df85bde4e0',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Golden Valley',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-110.531035, 48.998901],
            [-109.48955, 49.000292],
            [-109.489627, 48.913968],
            [-109.505514, 48.913911],
            [-109.505333, 48.56791],
            [-109.496881, 48.525207],
            [-109.464318, 48.52526],
            [-109.464318, 48.452638],
            [-109.49673, 48.45273],
            [-109.497028, 48.394739],
            [-109.51878, 48.394726],
            [-109.51908, 48.293457],
            [-109.551644, 48.293479],
            [-109.534295, 48.134564],
            [-109.726914, 48.132859],
            [-109.726943, 48.221525],
            [-109.845812, 48.219004],
            [-109.845932, 48.305971],
            [-110.625409, 48.306479],
            [-110.625543, 48.219731],
            [-110.755873, 48.219601],
            [-110.743064, 48.998606],
            [-110.531035, 48.998901],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MT041',
        inspectionRecords: [
          {
            __key: 'c60a3e4d-11c9-5cb9-890d-3a1d53b13c6d',
            __typename: 'inspection',
            violations: [
              {
                __key: 'fca36154-b911-5138-a40f-47f237771910',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Hill',
        violation_count: 1,
        violation_percentage: 8.333333333333332,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-104.390967, 46.936439],
            [-104.327241, 47.005803],
            [-104.327277, 47.041967],
            [-104.306062, 47.042046],
            [-104.305747, 47.136815],
            [-104.31637, 47.136863],
            [-104.316166, 47.18054],
            [-104.341804, 47.2165],
            [-104.34168, 47.245392],
            [-104.449023, 47.322748],
            [-104.420347, 47.354435],
            [-104.131863, 47.353901],
            [-104.131508, 47.397403],
            [-104.044871, 47.397063],
            [-104.045234, 46.641504],
            [-104.354835, 46.641409],
            [-104.354701, 46.670381],
            [-104.418001, 46.670435],
            [-104.417998, 46.684892],
            [-104.607017, 46.684926],
            [-104.603766, 46.860853],
            [-104.496582, 46.860813],
            [-104.412124, 46.904003],
            [-104.390967, 46.936439],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MT109',
        inspectionRecords: [
          {
            __key: 'd100aeba-fe34-5101-8403-ab776776ebcd',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Wibaux',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-85.995651, 30.281311],
            [-85.992736, 30.38932],
            [-85.965326, 30.410546],
            [-85.883984, 30.410253],
            [-85.8535, 30.440623],
            [-85.487968, 30.436655],
            [-85.486358, 30.567574],
            [-85.383948, 30.566856],
            [-85.388669, 29.924359],
            [-85.446289, 29.901033],
            [-85.540031, 29.928003],
            [-85.632537, 30.014255],
            [-85.715217, 30.045766],
            [-85.826187, 30.125185],
            [-85.999893, 30.21309],
            [-85.995651, 30.281311],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'FL005',
        inspectionRecords: [
          {
            __key: 'a794f637-cea7-510c-9938-7da3842154a8',
            __typename: 'inspection',
            violations: [
              {
                __key: '4b70cd6d-bc83-50c5-aa56-1130088e9ffa',
                __typename: 'violation',
                category: 'UnsafeDriving',
              },
              {
                __key: '4eacd788-f078-5610-8ff2-64f9b4a238ad',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'f69b1803-1b56-58d1-8f52-b776f3effdbf',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '03992810-db78-54cc-9e4f-e32f3eb54e3c',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'f30432e4-3cc1-5653-a26a-5540a5cd4405',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
              {
                __key: 'e7ab7135-3c24-5d2b-8072-9f52d18341a6',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Bay',
        violation_count: 6,
        violation_percentage: 50,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-83.618483, 38.155697],
            [-83.59058, 38.171174],
            [-83.633016, 38.192625],
            [-83.56397, 38.216731],
            [-83.50716, 38.269447],
            [-83.514281, 38.292054],
            [-83.488086, 38.324496],
            [-83.499266, 38.349351],
            [-83.412097, 38.396502],
            [-83.364143, 38.328318],
            [-83.341347, 38.319521],
            [-83.30114, 38.209703],
            [-83.287978, 38.21562],
            [-83.2742, 38.195478],
            [-83.244306, 38.19238],
            [-83.264341, 38.187422],
            [-83.245887, 38.176716],
            [-83.198994, 38.168643],
            [-83.217664, 38.159415],
            [-83.222442, 38.132072],
            [-83.247928, 38.130151],
            [-83.303597, 38.08989],
            [-83.337429, 38.088167],
            [-83.342971, 38.069174],
            [-83.371368, 38.06956],
            [-83.398662, 38.038875],
            [-83.430486, 38.047067],
            [-83.463408, 38.016975],
            [-83.455647, 38.03241],
            [-83.47951, 38.0277],
            [-83.463612, 38.04984],
            [-83.498916, 38.051316],
            [-83.465194, 38.066679],
            [-83.465399, 38.084045],
            [-83.505965, 38.088003],
            [-83.513619, 38.069496],
            [-83.53442, 38.094693],
            [-83.52006, 38.116083],
            [-83.559057, 38.115841],
            [-83.548331, 38.142931],
            [-83.584087, 38.146256],
            [-83.605233, 38.123874],
            [-83.618483, 38.155697],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'KY205',
        inspectionRecords: [
          {
            __key: '580bb9dc-f105-5553-8ff4-756556efc15e',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Rowan',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-117.535262, 47.794458],
            [-117.535262, 48.047779],
            [-117.041877, 48.045466],
            [-117.039878, 47.259272],
            [-117.823629, 47.26022],
            [-117.820947, 47.825775],
            [-117.792351, 47.811025],
            [-117.778138, 47.828021],
            [-117.742659, 47.830502],
            [-117.675207, 47.889099],
            [-117.651932, 47.835255],
            [-117.619142, 47.827778],
            [-117.586519, 47.794862],
            [-117.535262, 47.794458],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'WA063',
        inspectionRecords: [
          {
            __key: '8ee28499-b4b0-57cc-b3d3-2fb712462bf8',
            __typename: 'inspection',
            violations: [],
          },
          {
            __key: '17fa037b-ef6b-539d-b723-f7988e257edb',
            __typename: 'inspection',
            violations: [
              {
                __key: '1201b755-4831-5018-892a-e7da8e0fdc61',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 2,
        inspection_percentage: 50,
        name: 'Spokane',
        violation_count: 1,
        violation_percentage: 8.333333333333332,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-102.211067, 38.268169],
            [-102.044475, 38.268749],
            [-102.041585, 37.644282],
            [-102.747615, 37.643642],
            [-102.74233, 38.266971],
            [-102.211067, 38.268169],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'CO099',
        inspectionRecords: [
          {
            __key: '8c503761-5136-5703-98b5-f3f651c4396f',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Prowers',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-84.280174, 37.101893],
            [-84.292959, 37.11356],
            [-84.279032, 37.119662],
            [-84.296869, 37.121643],
            [-84.287966, 37.158106],
            [-84.300166, 37.169642],
            [-84.265629, 37.181708],
            [-84.280989, 37.197471],
            [-84.25928, 37.205088],
            [-84.266719, 37.223551],
            [-84.251943, 37.240354],
            [-84.237175, 37.229248],
            [-84.233519, 37.248116],
            [-84.217827, 37.242432],
            [-84.217367, 37.283093],
            [-84.190483, 37.285545],
            [-84.171565, 37.307066],
            [-84.147716, 37.302697],
            [-84.120756, 37.335892],
            [-84.094326, 37.314016],
            [-84.108472, 37.31017],
            [-84.101047, 37.298963],
            [-84.048455, 37.281802],
            [-84.056477, 37.269762],
            [-83.943718, 37.250551],
            [-83.94076, 37.203276],
            [-83.968905, 37.167425],
            [-83.925405, 37.111768],
            [-83.886011, 37.118901],
            [-83.862477, 37.091673],
            [-83.887882, 37.074826],
            [-83.876232, 37.047831],
            [-83.922336, 37.036413],
            [-83.931783, 36.996177],
            [-83.959385, 36.977128],
            [-84.032556, 36.98867],
            [-84.09452, 36.955102],
            [-84.150645, 36.967488],
            [-84.162675, 36.942178],
            [-84.182256, 36.939116],
            [-84.222062, 36.96033],
            [-84.235204, 36.941023],
            [-84.258301, 36.954298],
            [-84.256725, 36.972995],
            [-84.297416, 36.945931],
            [-84.362442, 36.940897],
            [-84.349961, 36.98914],
            [-84.315049, 37.001455],
            [-84.331427, 37.025696],
            [-84.303016, 37.038396],
            [-84.317306, 37.040811],
            [-84.324597, 37.067872],
            [-84.280174, 37.101893],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'KY125',
        inspectionRecords: [
          {
            __key: '5346c32c-c614-5d80-8a8a-1efc1c5c5315',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Laurel',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-103.715254, 39.128246],
            [-103.714355, 39.566331],
            [-103.154388, 39.565678],
            [-103.172878, 38.525315],
            [-104.053921, 38.522393],
            [-104.055528, 38.868868],
            [-103.719664, 38.866827],
            [-103.715254, 39.128246],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'CO073',
        inspectionRecords: [
          {
            __key: 'cf294c20-dfd4-5f0a-915f-ad1797ecdfd9',
            __typename: 'inspection',
            violations: [
              {
                __key: 'd44c1e21-cf14-5469-889e-36889e3eb35e',
                __typename: 'violation',
                category: 'UnsafeDriving',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Lincoln',
        violation_count: 1,
        violation_percentage: 8.333333333333332,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-112.313699, 46.643627],
            [-112.320871, 46.655927],
            [-112.410638, 46.656011],
            [-112.410846, 46.699505],
            [-112.535597, 46.699482],
            [-112.535748, 46.834234],
            [-112.795642, 46.831665],
            [-112.795808, 47.177004],
            [-113.059053, 47.17957],
            [-113.059199, 47.488599],
            [-113.115431, 47.491443],
            [-113.117746, 47.570473],
            [-113.147355, 47.602264],
            [-113.12847, 47.661047],
            [-113.165874, 47.720663],
            [-113.138261, 47.745892],
            [-113.080997, 47.749917],
            [-113.082785, 47.773041],
            [-113.061411, 47.787949],
            [-113.057997, 47.870659],
            [-113.091499, 47.894282],
            [-113.086539, 47.91827],
            [-113.022115, 47.927097],
            [-112.984219, 47.9534],
            [-112.97431, 47.913308],
            [-112.952975, 47.904852],
            [-112.913428, 47.826881],
            [-112.926631, 47.761891],
            [-112.901992, 47.732207],
            [-112.91422, 47.694958],
            [-112.856907, 47.629491],
            [-112.817827, 47.607589],
            [-112.771839, 47.598395],
            [-112.732078, 47.617131],
            [-112.578402, 47.633135],
            [-112.549721, 47.651977],
            [-112.520382, 47.642719],
            [-112.508856, 47.619073],
            [-112.429506, 47.606094],
            [-112.405258, 47.57652],
            [-112.40613, 47.552684],
            [-112.319606, 47.54305],
            [-112.278, 47.505623],
            [-112.047781, 47.516034],
            [-112.044829, 47.192711],
            [-111.977382, 47.183932],
            [-111.964532, 47.158995],
            [-111.896286, 47.135741],
            [-111.896359, 47.121281],
            [-111.789278, 47.128922],
            [-111.790236, 46.913764],
            [-111.621404, 46.903177],
            [-111.603137, 46.88917],
            [-111.642219, 46.86539],
            [-111.639502, 46.832134],
            [-111.589278, 46.805599],
            [-111.509208, 46.816246],
            [-111.495765, 46.789896],
            [-111.508887, 46.759565],
            [-111.602526, 46.778259],
            [-111.639261, 46.73818],
            [-111.631906, 46.569972],
            [-112.029231, 46.568805],
            [-112.033835, 46.542813],
            [-112.062854, 46.52385],
            [-112.134763, 46.522119],
            [-112.205757, 46.499748],
            [-112.176235, 46.456342],
            [-112.215588, 46.450304],
            [-112.225497, 46.431609],
            [-112.268397, 46.414961],
            [-112.323378, 46.432939],
            [-112.304536, 46.450097],
            [-112.306136, 46.51175],
            [-112.293341, 46.517915],
            [-112.316754, 46.574418],
            [-112.293214, 46.604389],
            [-112.313699, 46.643627],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MT049',
        inspectionRecords: [
          {
            __key: '894cbc69-9bb2-5e90-b489-4cd0ef3553a1',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Lewis And Clark',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-104.041599, 45.548315],
            [-104.045547, 45.945308],
            [-102.94207, 45.945],
            [-102.957281, 45.212851],
            [-104.040004, 45.212891],
            [-104.041599, 45.548315],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'SD063',
        inspectionRecords: [
          {
            __key: '8a9e4b4c-19f7-5476-bc33-f857dbdd80e9',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Harding',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-110.990109, 48.997911],
            [-110.743064, 48.998606],
            [-110.755873, 48.219601],
            [-110.827413, 48.219566],
            [-110.827168, 48.133205],
            [-111.409097, 48.132218],
            [-111.409018, 48.219542],
            [-111.275306, 48.219531],
            [-111.269862, 48.99723],
            [-110.990109, 48.997911],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MT051',
        inspectionRecords: [
          {
            __key: 'e9074727-2f24-5a78-a939-c4f7f863ebc5',
            __typename: 'inspection',
            violations: [
              {
                __key: '424f70b6-bdea-578b-a041-774a1dff4c56',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'a254512f-a860-591c-8fbd-5906a3d8f065',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '82151e9c-43d7-5a9a-8bb3-56d0a8e56c64',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Liberty',
        violation_count: 3,
        violation_percentage: 25,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-103.066068, 44.140762],
            [-102.388045, 44.140183],
            [-102.404013, 44.156875],
            [-102.387162, 44.174828],
            [-102.401612, 44.175587],
            [-102.392632, 44.204177],
            [-102.356992, 44.230555],
            [-102.366965, 44.285613],
            [-102.347981, 44.304613],
            [-102.341635, 44.348772],
            [-102.298284, 44.376915],
            [-102.306223, 44.434085],
            [-102.226112, 44.452514],
            [-102.189438, 44.427194],
            [-102.160156, 44.428825],
            [-102.153758, 44.445233],
            [-102.108034, 44.436586],
            [-102.045933, 44.499986],
            [-102.001068, 44.510926],
            [-102.019036, 43.707878],
            [-102.035913, 43.688349],
            [-102.09889, 43.685181],
            [-102.116297, 43.712826],
            [-102.178723, 43.687296],
            [-102.816647, 43.689221],
            [-102.695874, 43.797624],
            [-102.687191, 43.855599],
            [-104.055487, 43.853476],
            [-104.054588, 44.141081],
            [-103.066068, 44.140762],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'SD103',
        inspectionRecords: [
          {
            __key: '9a6c1389-63bc-5f42-994e-e04d08751409',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Pennington',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-90.910094, 43.910635],
            [-90.912918, 44.071522],
            [-90.973107, 44.070882],
            [-90.967231, 44.119979],
            [-90.978288, 44.127989],
            [-90.947049, 44.147237],
            [-90.892788, 44.158452],
            [-90.312522, 44.155198],
            [-90.312194, 43.731479],
            [-90.910653, 43.725334],
            [-90.910094, 43.910635],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'WI081',
        inspectionRecords: [
          {
            __key: '438573d2-7e81-54c5-9a92-44cd0f8218ff',
            __typename: 'inspection',
            violations: [
              {
                __key: '29afb730-3643-5b21-af2a-8c08bab6ce54',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'aaed2dbb-d61d-56cf-91c3-dc63f5e275ee',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Monroe',
        violation_count: 2,
        violation_percentage: 16.666666666666664,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-116.719085, 46.542874],
            [-116.697892, 46.586789],
            [-116.625394, 46.628281],
            [-116.456098, 46.629097],
            [-116.451662, 46.490736],
            [-116.369311, 46.500492],
            [-116.369122, 46.466923],
            [-116.418245, 46.467292],
            [-116.367947, 46.416374],
            [-116.360239, 46.362382],
            [-116.386753, 46.340766],
            [-116.468874, 46.350985],
            [-116.492509, 46.338772],
            [-116.489671, 46.325586],
            [-116.713496, 46.325527],
            [-116.683171, 46.243448],
            [-116.702543, 46.237792],
            [-116.701819, 45.996381],
            [-116.757042, 45.942103],
            [-116.730343, 45.894105],
            [-116.750235, 45.869287],
            [-116.79141, 45.855318],
            [-116.857727, 45.904723],
            [-116.942793, 46.061115],
            [-116.981645, 46.084251],
            [-116.955424, 46.101861],
            [-116.921708, 46.166593],
            [-116.965384, 46.203122],
            [-116.95548, 46.230984],
            [-116.991195, 46.27696],
            [-116.986216, 46.29595],
            [-117.020581, 46.314789],
            [-117.027001, 46.338296],
            [-117.059875, 46.347922],
            [-117.062778, 46.365175],
            [-117.035227, 46.41143],
            [-117.039777, 46.541706],
            [-116.719085, 46.542874],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'ID069',
        inspectionRecords: [
          {
            __key: 'd6f2d803-51b2-5fce-a157-3f53c245e9ae',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Nez Perce',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-114.571802, 35.199507],
            [-114.606173, 35.359651],
            [-114.678642, 35.497628],
            [-114.65677, 35.534964],
            [-114.675667, 35.580033],
            [-114.653534, 35.609672],
            [-114.689507, 35.651429],
            [-114.680631, 35.684046],
            [-114.705597, 35.708274],
            [-114.694267, 35.756633],
            [-114.712026, 35.805529],
            [-114.695277, 35.831091],
            [-114.705856, 35.850508],
            [-114.661636, 35.871233],
            [-114.708112, 35.909933],
            [-114.740536, 35.975545],
            [-114.743005, 36.00845],
            [-114.722096, 36.028952],
            [-114.741262, 36.038044],
            [-114.735285, 36.056648],
            [-114.754508, 36.086171],
            [-114.630474, 36.142218],
            [-114.615455, 36.129653],
            [-114.57109, 36.151099],
            [-114.511218, 36.150576],
            [-114.504715, 36.127188],
            [-114.4626, 36.139644],
            [-114.445042, 36.125346],
            [-114.405624, 36.146983],
            [-114.370181, 36.142624],
            [-114.306939, 36.082487],
            [-114.313591, 36.059048],
            [-114.270862, 36.045523],
            [-114.243865, 36.015266],
            [-114.15139, 36.023133],
            [-114.137112, 36.038491],
            [-114.111998, 36.09491],
            [-114.120865, 36.11085],
            [-114.096994, 36.120823],
            [-114.066798, 36.179087],
            [-114.043944, 36.19335],
            [-114.050616, 37.000167],
            [-112.538571, 37.000758],
            [-112.529299, 36.952437],
            [-112.619728, 36.782744],
            [-112.612234, 36.736854],
            [-112.638679, 36.674478],
            [-112.620945, 36.595645],
            [-112.660401, 36.538862],
            [-112.640641, 36.488474],
            [-112.621821, 36.484902],
            [-112.647594, 36.446895],
            [-112.6241, 36.410241],
            [-112.656333, 36.357545],
            [-112.680735, 36.341658],
            [-112.692851, 36.355163],
            [-112.722574, 36.346941],
            [-112.722872, 36.31997],
            [-112.755786, 36.324347],
            [-112.793761, 36.284398],
            [-112.869234, 36.282304],
            [-112.898245, 36.254762],
            [-112.98121, 36.231769],
            [-113.019111, 36.236705],
            [-113.128005, 36.169476],
            [-113.159768, 36.173212],
            [-113.201269, 36.149573],
            [-113.201671, 36.119041],
            [-113.223742, 36.091918],
            [-113.313428, 36.100001],
            [-113.354128, 36.042089],
            [-113.339777, 35.98515],
            [-113.317259, 35.968611],
            [-113.33485, 35.913859],
            [-113.309214, 35.887325],
            [-113.334024, 35.803031],
            [-113.333508, 34.317877],
            [-113.351858, 34.299476],
            [-113.53066, 34.310228],
            [-113.599378, 34.233344],
            [-113.663972, 34.212057],
            [-113.704384, 34.209939],
            [-113.734059, 34.241224],
            [-113.83494, 34.223165],
            [-113.857702, 34.253418],
            [-114.035942, 34.260093],
            [-114.087442, 34.298559],
            [-114.138282, 34.30323],
            [-114.176909, 34.349306],
            [-114.226107, 34.365916],
            [-114.335372, 34.450038],
            [-114.375789, 34.447798],
            [-114.387407, 34.460492],
            [-114.380838, 34.529724],
            [-114.43681, 34.596074],
            [-114.424202, 34.610453],
            [-114.457985, 34.657113],
            [-114.470985, 34.712216],
            [-114.552682, 34.766871],
            [-114.586842, 34.835672],
            [-114.635176, 34.875003],
            [-114.637524, 35.027053],
            [-114.602908, 35.068588],
            [-114.646764, 35.101868],
            [-114.578263, 35.12881],
            [-114.571802, 35.199507],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'AZ015',
        inspectionRecords: [
          {
            __key: '2dd77506-13c6-5f01-ad1d-77918be2e81e',
            __typename: 'inspection',
            violations: [
              {
                __key: '90e8cc05-d993-5c4e-b460-fc8882533df5',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '38166a87-8da8-5181-89d9-215d076986d6',
                __typename: 'violation',
                category: 'UnsafeDriving',
              },
              {
                __key: '51b6d0c6-9b52-5876-9d70-4dc761a532a1',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Mohave',
        violation_count: 1,
        violation_percentage: 8.333333333333332,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-109.051478, 39.116505],
            [-109.050765, 39.366677],
            [-107.430949, 39.366178],
            [-107.430514, 39.348222],
            [-107.465214, 39.314337],
            [-107.396955, 39.294465],
            [-107.377479, 39.272208],
            [-107.400297, 39.252946],
            [-107.429584, 39.257286],
            [-107.429455, 39.241348],
            [-107.451075, 39.230673],
            [-107.484209, 39.23538],
            [-107.542842, 39.182198],
            [-107.619108, 39.154886],
            [-107.687577, 39.088037],
            [-107.76549, 39.043294],
            [-107.858555, 39.080165],
            [-107.907415, 39.060533],
            [-107.982173, 39.059878],
            [-108.094663, 38.980021],
            [-108.087305, 38.96318],
            [-108.140481, 38.925158],
            [-108.141122, 38.906484],
            [-108.378953, 38.829108],
            [-108.379136, 38.499987],
            [-109.060119, 38.499989],
            [-109.051478, 39.116505],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'CO077',
        inspectionRecords: [
          {
            __key: '8e349b74-3c05-5b9e-9e42-66d8b807fa68',
            __typename: 'inspection',
            violations: [
              {
                __key: 'c2df7144-1a97-53cf-8b39-bbda71a603ac',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
              {
                __key: 'd78f7690-ac3b-5ff0-9167-2e87644b0831',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Mesa',
        violation_count: 2,
        violation_percentage: 16.666666666666664,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-94.110319, 39.004557],
            [-94.104823, 39.143507],
            [-94.045566, 39.14084],
            [-94.024206, 39.154537],
            [-94.030606, 39.190809],
            [-93.968961, 39.200011],
            [-93.962442, 39.183369],
            [-93.988892, 39.152786],
            [-93.967014, 39.142376],
            [-93.940043, 39.17969],
            [-93.899417, 39.184539],
            [-93.855434, 39.212874],
            [-93.71121, 39.206262],
            [-93.652816, 39.248252],
            [-93.505069, 39.216819],
            [-93.487885, 39.228875],
            [-93.495937, 39.272913],
            [-93.477233, 39.292796],
            [-93.497404, 38.928334],
            [-93.834719, 38.937909],
            [-93.836319, 38.908709],
            [-94.114175, 38.917304],
            [-94.110319, 39.004557],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MO107',
        inspectionRecords: [
          {
            __key: '7772eb7d-f158-535d-ad82-85e980e82fd8',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Lafayette',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-102.610462, 41.141084],
            [-102.610413, 41.221614],
            [-102.055535, 41.221547],
            [-102.051717, 41.002359],
            [-102.621033, 41.002597],
            [-102.610462, 41.141084],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'NE049',
        inspectionRecords: [
          {
            __key: '7ba41fed-dbde-54d0-87ec-a850ba20fcd4',
            __typename: 'inspection',
            violations: [
              {
                __key: '1386433f-181b-5a74-877c-2a5ffa8d4348',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'c3445a19-5480-5af6-8471-a73d667d343a',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '46527969-8991-5da0-906a-1be9863c3ca9',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '082cb259-518d-5b73-b9c4-fc6182945db0',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Deuel',
        violation_count: 4,
        violation_percentage: 33.33333333333333,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-117.667244, 34.734334],
            [-117.667292, 34.822526],
            [-117.632011, 34.82227],
            [-117.634872, 35.62216],
            [-117.616195, 35.680856],
            [-117.652319, 35.680782],
            [-117.651986, 35.709934],
            [-117.634251, 35.709927],
            [-117.632996, 35.797251],
            [-115.735891, 35.79362],
            [-115.735745, 35.809097],
            [-115.648357, 35.809211],
            [-114.633487, 35.001857],
            [-114.634382, 34.87289],
            [-114.586842, 34.835672],
            [-114.552682, 34.766871],
            [-114.470477, 34.711368],
            [-114.457985, 34.657113],
            [-114.424202, 34.610453],
            [-114.43681, 34.596074],
            [-114.380838, 34.529724],
            [-114.386699, 34.457911],
            [-114.335372, 34.450038],
            [-114.226107, 34.365916],
            [-114.176909, 34.349306],
            [-114.138282, 34.30323],
            [-114.131489, 34.260387],
            [-114.164648, 34.259699],
            [-114.229715, 34.186928],
            [-114.41168, 34.110031],
            [-114.435429, 34.079727],
            [-115.316065, 34.077843],
            [-115.316212, 34.03411],
            [-116.929558, 34.034113],
            [-116.929357, 34.004914],
            [-117.225372, 34.004314],
            [-117.225373, 34.018715],
            [-117.375416, 34.019399],
            [-117.375278, 34.033875],
            [-117.558312, 34.033451],
            [-117.558377, 33.98842],
            [-117.610386, 33.971614],
            [-117.610947, 33.925114],
            [-117.655386, 33.925114],
            [-117.654885, 33.888815],
            [-117.676286, 33.888815],
            [-117.673749, 33.870831],
            [-117.793578, 33.95379],
            [-117.802539, 33.975551],
            [-117.785062, 34.004809],
            [-117.767483, 34.004611],
            [-117.76769, 34.023506],
            [-117.730125, 34.021371],
            [-117.646374, 34.28917],
            [-117.667244, 34.734334],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'CA071',
        inspectionRecords: [
          {
            __key: '5c51d6db-9fbf-59e8-a256-702e4b02f9d1',
            __typename: 'inspection',
            violations: [],
          },
          {
            __key: 'cb1a6412-3a4a-5b68-a0d9-905748176c29',
            __typename: 'inspection',
            violations: [
              {
                __key: 'fe6c93c8-395f-53cc-b6f2-195966fa1162',
                __typename: 'violation',
                category: 'DriverFitness',
              },
              {
                __key: '0a5fd359-641e-5582-aba2-9b9ce10ac3e8',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
              {
                __key: '9b7ad63e-7c27-5c3c-aac6-127761013b31',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
          {
            __key: '8e2d459f-1899-5b3a-aeae-0aef06dfd414',
            __typename: 'inspection',
            violations: [
              {
                __key: 'eb00a6ac-c2cd-5077-9084-8c13e3ebd49b',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
          {
            __key: '66fcba8d-5764-5d40-a9a7-71a3ce696333',
            __typename: 'inspection',
            violations: [
              {
                __key: '44fbf67d-ca71-55e5-a40f-b155da8632c0',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 4,
        inspection_percentage: 100,
        name: 'San Bernardino',
        violation_count: 5,
        violation_percentage: 41.66666666666667,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-82.348084, 41.428568],
            [-82.348122, 41.700057],
            [-81.97058, 41.877845],
            [-81.971262, 41.351268],
            [-81.87696, 41.350685],
            [-81.878053, 41.275044],
            [-81.972485, 41.274829],
            [-81.973895, 41.199831],
            [-82.072465, 41.19985],
            [-82.074266, 41.136456],
            [-82.169875, 41.137097],
            [-82.171492, 41.063537],
            [-82.336496, 41.065761],
            [-82.348084, 41.428568],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'OH093',
        inspectionRecords: [
          {
            __key: 'a37930bd-2dc8-5774-85ea-2b8342598048',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Lorain',
        violation_count: 0,
        violation_percentage: 0,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-86.069594, 39.926985],
            [-85.937587, 39.927137],
            [-85.938067, 39.869812],
            [-85.954013, 39.869646],
            [-85.95208, 39.638345],
            [-86.326341, 39.632177],
            [-86.326289, 39.924042],
            [-86.069594, 39.926985],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'IN097',
        inspectionRecords: [
          {
            __key: '8c709c00-99d5-5a21-83bf-395a2834867d',
            __typename: 'inspection',
            violations: [
              {
                __key: 'fff4cd28-2516-5439-bbc3-f3701aa62bf9',
                __typename: 'violation',
                category: 'DriverFitness',
              },
              {
                __key: '30b7abf4-94e3-5874-aab8-95e496d7b7e7',
                __typename: 'violation',
                category: 'UnsafeDriving',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Marion',
        violation_count: 2,
        violation_percentage: 16.666666666666664,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-111.999436, 41.575976],
            [-111.982471, 41.534294],
            [-111.937288, 41.551155],
            [-111.916352, 41.538406],
            [-111.905169, 41.49685],
            [-111.875708, 41.489928],
            [-111.878505, 41.474097],
            [-111.916958, 41.464087],
            [-111.881564, 41.441188],
            [-111.884188, 41.426963],
            [-111.926355, 41.412535],
            [-111.9591, 41.433706],
            [-111.975799, 41.384469],
            [-111.96318, 41.365335],
            [-112.028477, 41.336549],
            [-112.238065, 41.336552],
            [-112.493515, 41.076888],
            [-112.799359, 40.999939],
            [-114.042029, 40.999896],
            [-114.041477, 41.993873],
            [-112.173148, 42.001515],
            [-112.008696, 41.794902],
            [-112.032996, 41.76699],
            [-112.051869, 41.699906],
            [-112.015894, 41.658411],
            [-111.999436, 41.575976],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'UT003',
        inspectionRecords: [
          {
            __key: 'e6184fe6-99d1-5ff0-8755-887579181a50',
            __typename: 'inspection',
            violations: [
              {
                __key: 'b352d206-c55c-54ca-a762-2c98eae576fa',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'e0b0e6c3-4e1f-55f8-8b48-0882384ec173',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'daaf7473-7125-51b9-adb6-7a23f0301e2b',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'd10a8a71-ebf5-57ff-930a-ce0eff10e664',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'a8411c86-0a24-5d87-b745-c32cb303bc5b',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
          {
            __key: 'a45bb7fe-b3db-5eb1-aae8-72ec74759a23',
            __typename: 'inspection',
            violations: [],
          },
          {
            __key: '6ea8379c-5646-5d6a-8dd4-c76a8f5377f7',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 3,
        inspection_percentage: 75,
        name: 'Box Elder',
        violation_count: 5,
        violation_percentage: 41.66666666666667,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-108.249629, 36.999711],
            [-107.420915, 37],
            [-107.42997, 36.970904],
            [-107.457159, 36.954964],
            [-107.445692, 36.915369],
            [-107.456967, 36.898075],
            [-107.522919, 36.875468],
            [-107.530648, 36.853869],
            [-107.561675, 36.855753],
            [-107.599874, 36.832525],
            [-107.591584, 36.813811],
            [-107.603445, 36.799002],
            [-107.618181, 36.804267],
            [-107.626511, 36.000288],
            [-109.046067, 36.002701],
            [-109.045172, 36.998977],
            [-108.249629, 36.999711],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'NM045',
        inspectionRecords: [
          {
            __key: 'ec9e472f-952c-5e33-9578-0172510ed50e',
            __typename: 'inspection',
            violations: [
              {
                __key: '8a75a59a-359c-536d-9b86-c3c4356ccd95',
                __typename: 'violation',
                category: 'UnsafeDriving',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'San Juan',
        violation_count: 1,
        violation_percentage: 8.333333333333332,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-73.147555, 41.367696],
            [-73.218235, 41.42907],
            [-73.29776, 41.448276],
            [-73.299593, 41.470111],
            [-73.327114, 41.484527],
            [-73.320519, 41.503981],
            [-73.155492, 41.514304],
            [-73.164676, 41.557088],
            [-73.092353, 41.570496],
            [-73.05801, 41.588647],
            [-73.059469, 41.606179],
            [-73.01865, 41.614097],
            [-73.021471, 41.627104],
            [-72.938501, 41.644311],
            [-72.94635, 41.556797],
            [-72.883885, 41.563812],
            [-72.84428, 41.544725],
            [-72.848541, 41.567329],
            [-72.827644, 41.570999],
            [-72.819145, 41.554298],
            [-72.801243, 41.573098],
            [-72.752181, 41.578894],
            [-72.733886, 41.485368],
            [-72.746141, 41.423297],
            [-72.642257, 41.429236],
            [-72.650416, 41.418964],
            [-72.612328, 41.386179],
            [-72.614269, 41.32962],
            [-72.585943, 41.324014],
            [-72.585599, 41.307348],
            [-72.527902, 41.250115],
            [-72.527901, 41.177774],
            [-72.999547, 41.087108],
            [-73.121453, 41.177597],
            [-73.109953, 41.229396],
            [-73.065051, 41.297596],
            [-73.147555, 41.367696],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'CT009',
        inspectionRecords: [
          {
            __key: '0f822e2b-4531-50c5-a6e7-d4f2905d34a1',
            __typename: 'inspection',
            violations: [
              {
                __key: 'ab980ddc-ccce-5ad1-95e3-245096813f66',
                __typename: 'violation',
                category: 'UnsafeDriving',
              },
              {
                __key: 'd0ad0f91-ae10-5fb5-8b75-b10615d89000',
                __typename: 'violation',
                category: 'HOSCompliance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'New Haven',
        violation_count: 2,
        violation_percentage: 16.666666666666664,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-112.923225, 45.934768],
            [-112.938955, 45.993258],
            [-112.877609, 45.999503],
            [-112.852763, 46.048434],
            [-112.777717, 46.048313],
            [-112.77712, 46.135826],
            [-112.681004, 46.135672],
            [-112.640342, 46.174398],
            [-112.594616, 46.165188],
            [-112.505649, 46.18441],
            [-112.519997, 46.136394],
            [-112.466329, 46.092979],
            [-112.476952, 46.051715],
            [-112.441988, 46.029479],
            [-112.436223, 45.938992],
            [-112.404191, 45.887784],
            [-112.426653, 45.843982],
            [-112.190038, 45.74922],
            [-112.461816, 45.742584],
            [-112.498432, 45.759765],
            [-112.549717, 45.684169],
            [-112.687054, 45.624854],
            [-112.701192, 45.67654],
            [-112.747716, 45.696098],
            [-112.73548, 45.702464],
            [-112.753649, 45.71146],
            [-112.748101, 45.730147],
            [-112.784373, 45.763278],
            [-112.997176, 45.806568],
            [-113.084649, 45.855335],
            [-113.024083, 45.871393],
            [-112.923225, 45.934768],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'MT093',
        inspectionRecords: [
          {
            __key: '87be5464-a39d-5ef0-8a24-2340ba1ad18f',
            __typename: 'inspection',
            violations: [
              {
                __key: 'd111dff3-7e2a-5c80-81bd-3c624482c8c7',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
          {
            __key: 'dc9b39bd-cfd3-5a2d-83ec-2189c438dc51',
            __typename: 'inspection',
            violations: [],
          },
        ],
        inspection_count: 2,
        inspection_percentage: 50,
        name: 'Silver Bow',
        violation_count: 1,
        violation_percentage: 8.333333333333332,
      },
      type: 'Feature',
    },
    {
      __typename: 'GeoFeature',
      geometry: {
        __typename: 'Geometry',
        coordinates: [
          [
            [-111.431957, 40.684554],
            [-111.430396, 40.665002],
            [-111.473009, 40.644136],
            [-111.489546, 40.599298],
            [-111.553266, 40.609313],
            [-111.604411, 40.666523],
            [-111.592711, 40.699179],
            [-111.623299, 40.731245],
            [-111.621038, 40.75795],
            [-111.649061, 40.773275],
            [-111.646484, 40.79757],
            [-111.611034, 40.811057],
            [-111.557838, 40.78069],
            [-111.528468, 40.785161],
            [-111.479555, 40.858288],
            [-111.509538, 40.911169],
            [-111.528754, 40.918956],
            [-111.524772, 40.95375],
            [-111.583772, 40.961326],
            [-111.592981, 40.993015],
            [-111.566632, 41.000484],
            [-111.555108, 41.032156],
            [-111.523642, 41.056231],
            [-111.381068, 41.086794],
            [-111.366915, 41.129943],
            [-111.232407, 41.142972],
            [-111.140944, 41.187115],
            [-111.133234, 41.206822],
            [-111.101335, 41.20769],
            [-111.061612, 41.251077],
            [-111.046766, 41.251627],
            [-111.046815, 40.997875],
            [-110.000717, 40.99745],
            [-110.000711, 40.813678],
            [-110.102622, 40.807901],
            [-110.293546, 40.833366],
            [-110.342735, 40.823356],
            [-110.373845, 40.788162],
            [-110.424303, 40.792005],
            [-110.432782, 40.778278],
            [-110.487114, 40.780289],
            [-110.523512, 40.761504],
            [-110.547031, 40.773733],
            [-110.561377, 40.758365],
            [-110.607022, 40.757265],
            [-110.625204, 40.769559],
            [-110.656482, 40.740275],
            [-110.750733, 40.747706],
            [-110.80641, 40.711599],
            [-110.89198, 40.727123],
            [-110.8856, 40.715293],
            [-110.904197, 40.706411],
            [-110.893768, 40.679748],
            [-110.944431, 40.672604],
            [-110.975761, 40.593143],
            [-111.032327, 40.575854],
            [-111.107197, 40.594087],
            [-111.150615, 40.548494],
            [-111.368623, 40.628944],
            [-111.393613, 40.69036],
            [-111.431957, 40.684554],
          ],
        ],
        type: 'Polygon',
      },
      properties: {
        __typename: 'Properties',
        countyCode: 'UT043',
        inspectionRecords: [
          {
            __key: 'cf688c8e-f0a4-5eca-8017-228028d4b794',
            __typename: 'inspection',
            violations: [
              {
                __key: 'f0cb5d16-ee47-56cb-a84b-f54f1a01fe55',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'fe288361-f406-5b73-abc9-14607875ce77',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '7abbeb5e-b24d-58c6-978e-9e8bacae7efe',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '30f82f99-6fdf-5462-a985-907f2709fc97',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'b411ba90-514d-5d1e-a113-becb560037be',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'b28a2aed-e8a3-5adb-90fd-977c8f35cb4b',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '32a4d126-6294-5138-ade3-73007c6ba465',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '7414aee4-4c72-5786-9ece-446aaaa61bf7',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '03b26de3-567d-56f9-a7ca-87df9618f26f',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: 'b5f995b9-4260-500a-a1c1-0b3c5038fc58',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '0e209607-1c1c-5c99-a892-a7726ae1d056',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
              {
                __key: '84d4bcc2-1798-5067-b31a-f1e06b85acc3',
                __typename: 'violation',
                category: 'VehicleMaintenance',
              },
            ],
          },
        ],
        inspection_count: 1,
        inspection_percentage: 25,
        name: 'Summit',
        violation_count: 12,
        violation_percentage: 100,
      },
      type: 'Feature',
    },
  ],
};
