export default function CustomizedLabel(props: any) {
  const { height, data } = props;

  if (data.length) {
    return (
      <g>
        <text x={6} y={height - 24} fontSize={12} fill="#3350A1">
          Changes from
        </text>
        <text x={6} y={height - 10} fontSize={12} fill="#3350A1">
          previous month
        </text>
      </g>
    );
  }

  return null;
}
