import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Breadcrumbs, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    ...theme.typography.h4,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.divider,
  },
}));

const Insights = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const title = pathname.split('/')[3];

  return (
    <>
      <div className="mb-8">
        <Breadcrumbs classes={{ separator: classes.separator }}>
          <p className="text-3xl font-bold text-text-disabled">Insights</p>
          <p className="text-3xl font-bold text-text-secondary">
            {title.charAt(0).toUpperCase()}
            {title.slice(1)}
          </p>
        </Breadcrumbs>
      </div>
      <div className="flex flex-col flex-1">
        <Outlet />
      </div>
    </>
  );
};

export default Insights;
