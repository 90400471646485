import { Factory } from 'fishery';
import { subMonths } from 'date-fns';
import { IssScore } from '../../../src/types/graphql-types';

const issScores = [
  74, 74, 91, 91, 91, 91, 88, 88, 88, 88, 88, 88, 88, 89, 74, 74, 88, 88, 90,
  88, 74, 88, 88, 91, 97,
];

export const issScoresFactory = Factory.define<IssScore[]>(() => {
  const currentDate = new Date();
  const scores: IssScore[] = issScores.map((value, index) => {
    const date = subMonths(currentDate, index);
    return {
      __typename: 'ISSScore',
      value,
      month: date.getMonth(),
      year: date.getFullYear(),
    };
  });
  scores.reverse();
  return scores;
});
