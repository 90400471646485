import clsx from 'clsx';
import { useMemo } from 'react';
import { Button } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import {
  HiChevronRight,
  HiOutlineArrowSmDown,
  HiOutlineArrowSmUp,
  HiOutlineInformationCircle,
  HiOutlineStar,
} from 'react-icons/hi';
import { ResponsiveContainer, Pie, PieChart } from 'recharts';

import { Show, Switch, Tooltip } from '@nirvana/ui-kit';
import { ScoreTrendItem } from 'src/types/graphql-types';
import { computeScoreTextColor } from 'src/helpers';
import { getPieChartData } from '../utils';

const ScoreDescriptions = ({ score }: { score: number }) => {
  if (!score) {
    return <></>;
  }

  if (score <= 25) {
    return (
      <div className="flex px-4 py-3 rounded-lg bg-success-extraLight">
        <div>
          <div className="p-1 mr-3 text-2xl rounded-md text-success-main bg-success-main/20">
            <HiOutlineStar />
          </div>
        </div>
        <p className="text-success-dark">
          Compared to all fleets nationwide, your fleet is operating in the top{' '}
          {score}%. Keep up the great work!
        </p>
      </div>
    );
  }

  if (score <= 50) {
    return (
      <div className="flex px-4 py-3 rounded-lg bg-gold-tint">
        <div>
          <div className="p-1 mr-3 text-2xl rounded-md text-gold-main bg-gold-light/50">
            <HiOutlineInformationCircle />
          </div>
        </div>
        <p>
          Compared to all fleets nationwide, your fleet is operating in the top{' '}
          {score}%. Great job keeping risk low!
        </p>
      </div>
    );
  }

  if (score <= 75) {
    return (
      <div className="flex px-4 py-3 rounded-lg bg-gold-tint">
        <div>
          <div className="p-1 mr-3 text-2xl rounded-md text-gold-main bg-gold-light/50">
            <HiOutlineInformationCircle />
          </div>
        </div>
        <p>
          <b>Risk is moderately high.</b> Compared to all fleets nationwide,
          your fleet is operating in the bottom {100 - score}%.
        </p>
      </div>
    );
  }

  return (
    <div className="flex px-4 py-3 rounded-lg bg-error-extraLight">
      <div>
        <div className="p-1 mr-3 text-2xl rounded-md text-error-main bg-error-dark/30">
          <HiOutlineInformationCircle />
        </div>
      </div>
      <p>
        <b>Risk is high.</b> Compared to all fleets nationwide, your fleet is
        operating in the bottom {100 - score}%.
      </p>
    </div>
  );
};

type RSPieChartProps = {
  data: ScoreTrendItem | undefined;
  prevMonthScore: number;
  hasTelematicsConnection: boolean;
};

export default function RSPieChart({
  data,
  prevMonthScore,
  hasTelematicsConnection,
}: RSPieChartProps) {
  const { reportId = '' } = useParams();
  const score = data?.score ?? 0;
  const changeInScore = score - prevMonthScore;
  const pieProps = {
    data: getPieChartData(score),
    dataKey: 'score',
    cy: '50%',
    endAngle: 0,
    startAngle: 180,
    innerRadius: '94%',
    outerRadius: '100%',
    cornerRadius: 6,
  };

  const renderRemark = useMemo(() => {
    if (score >= 75) {
      return <p className="text-base font-semibold">Poor</p>;
    } else if (score >= 50 && score < 75) {
      return <p className="text-base font-semibold">Fair</p>;
    } else if (score > 25 && score < 50) {
      return <p className="text-base font-semibold">Good</p>;
    } else {
      return (
        <p className="text-base font-semibold text-success-main">Excellent!</p>
      );
    }
  }, [score]);

  return (
    <div className="p-4 border rounded-md">
      <p className="mb-4 font-bold text-text-primary">
        Your Nirvana Safety Score
      </p>
      <div className="relative mb-4 h-48 xl:h-72">
        <ResponsiveContainer className="pointer-events-none !h-2x">
          <PieChart>
            <Pie {...pieProps} />
          </PieChart>
        </ResponsiveContainer>
        <div
          className={clsx(
            'absolute flex flex-col items-center justify-center space-y-2 left-[20%] right-[20%] -bottom-6 md:bottom-0 lg:-bottom-2',
          )}
        >
          <Switch>
            <Switch.Match when={!hasTelematicsConnection}>
              <div className="w-24 h-10 bg-gray-100" />
              <div className="w-48 h-6 max-w-sm bg-gray-100" />
              <div className="w-full h-6" />
            </Switch.Match>

            <Switch.Match when={hasTelematicsConnection}>
              <div className="flex items-center justify-center space-x-2">
                <p
                  className={clsx(
                    'text-5xl font-bold',
                    computeScoreTextColor(score),
                  )}
                >
                  {score}
                </p>

                <Tooltip title="Change since last month">
                  <div className="inline-flex items-center px-2 py-1 rounded bg-primary-extraLight">
                    <span
                      className={clsx(
                        'text-lg mr-0.5',
                        changeInScore > 0
                          ? 'text-error-main'
                          : 'text-success-main',
                      )}
                    >
                      <Show
                        when={changeInScore > 0}
                        fallback={<HiOutlineArrowSmDown />}
                      >
                        <HiOutlineArrowSmUp />
                      </Show>
                    </span>
                    <span className="font-semibold text-primary-main">
                      {Math.abs(changeInScore)}
                    </span>
                  </div>
                </Tooltip>
              </div>
              {renderRemark}
              <Link to={`/${reportId}/insights/scores`}>
                <Button endIcon={<HiChevronRight />}>View Trends</Button>
              </Link>
            </Switch.Match>
          </Switch>
        </div>
      </div>
      <ScoreDescriptions score={score} />
    </div>
  );
}
