import * as React from 'react';
import {
  FormControl,
  FormControlProps,
  InputLabel,
  InputLabelProps,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import clsx from 'clsx';

const inputLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    transform: 'none',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
  },
  successText: {
    color: `${theme.palette.success.main} !important`,
  },
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.5),
  },
}));

export interface InputWithLabelProps extends OutlinedInputProps {
  label: React.ReactNode;
  formControlProps?: FormControlProps;
  inputLabelProps?: InputLabelProps;
  helperText?: string;
  success?: boolean;
  error?: boolean;
  noMargin?: boolean;
}

/**
 * Common text input UI component, which is an extension of Mui Input
 * Refer: https://next.material-ui.com/components/text-fields for usage and props
 * Note: This component makes sure that we always render an outlined text input.
 * @component
 *
 * @param {Object} props - Material UI Input props.
 *
 * @example
 * return <InputWithLabel placeholder="Enter something" />
 */
const InputWithLabel = React.forwardRef<HTMLInputElement, InputWithLabelProps>(
  (
    {
      label,
      formControlProps,
      inputLabelProps,
      helperText,
      success,
      error,
      noMargin = false,
      ...rest
    },
    ref,
  ) => {
    const inputLabelClasses = inputLabelStyles();

    return (
      <>
        <FormControl
          {...formControlProps}
          variant="standard"
          sx={{ marginBottom: noMargin ? 0 : '24px' }}
        >
          <InputLabel
            shrink
            className={inputLabelClasses.root}
            {...inputLabelProps}
          >
            {label}
          </InputLabel>
          <CustomInput {...rest} inputRef={ref} error={error} />
          {!!helperText && (
            <FormHelperText
              error={error}
              className={clsx({ [inputLabelClasses.successText]: success })}
            >
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      </>
    );
  },
);

export default InputWithLabel;
