import { Navigate, RouteObject } from 'react-router-dom';

import AppShell from 'src/components/app-shell';
import { AuthGuard } from 'src/components/guards';
import Insights from 'src/components/insights';

import {
  Auth,
  ForgotPassword,
  VerificationEmail,
  ResetPassword,
  Login,
} from 'src/pages/auth';
import Flags from 'src/pages/flags';
import Heatmap from 'src/pages/heatmap';
import Home from 'src/pages/home';
import Overview from 'src/pages/overview';
import NotificationPreferences from 'src/pages/settings';
import Perks from 'src/pages/perks';
import Profile from 'src/pages/profile';
import Recommendations from 'src/pages/recommendations';
import Scores from 'src/pages/scores';
import Vehicles from 'src/pages/vehicles';
import Violations from 'src/pages/violations';
import TelematicsComplete from 'src/pages/telematics-complete';
import Thanks from 'src/pages/temp-thanks';
import { DriversPage } from 'src/pages/drivers';

import { Training } from 'src/pages/training';
import SignUp from 'src/pages/auth/components/signUp/sign-up';
import { TelematicsSignUp } from 'src/pages/auth/TelematicsSignUp';
import { Claims } from 'src/pages/claims/Claims';
import { NewClaim } from 'src/pages/claims/NewClaim';
import { ClaimsRedirect } from 'src/pages/claims-redirect/ClaimsRedirect';
import React from 'react';

export const getRoutes = (Layout: React.FC): RouteObject[] => {
  const reportIdChildren = [
    { path: '/:reportId', element: <Navigate to="overview" /> },
    { path: 'overview', element: <Overview /> },
    { path: 'profile', element: <Profile /> },
    { path: 'recommendations', element: <Recommendations /> },
    { path: 'flags', element: <Flags /> },
    {
      path: 'insights',
      element: <Insights />,
      children: [
        { path: 'scores', element: <Scores /> },
        { path: 'violations', element: <Violations /> },
        { path: 'heatmap', element: <Heatmap /> },
        { path: 'vehicles', element: <Vehicles /> },
        {
          path: 'drivers',
          element: <DriversPage />,
        },
      ],
    },
    { path: 'perks', element: <Perks /> },
    { path: 'training', element: <Training /> },
    { path: 'claims', element: <Claims /> },
    { path: 'claims/new', element: <NewClaim /> },
  ];

  return [
    {
      element: <Layout />,
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <Auth>
              <Login />
            </Auth>
          ),
        },
        {
          path: '/forgot-password',
          element: (
            <Auth>
              <ForgotPassword />
            </Auth>
          ),
        },
        {
          path: '/verification-email',
          element: (
            <Auth>
              <VerificationEmail />
            </Auth>
          ),
        },
        {
          path: '/reset-password',
          element: (
            <Auth>
              <ResetPassword />
            </Auth>
          ),
        },
        {
          path: '/sign-up/:shareId',
          element: (
            <Auth>
              <SignUp />
            </Auth>
          ),
        },
        {
          path: '/telematics-sign-up/:shareId',
          element: <TelematicsSignUp />,
        },
        {
          path: '/search',
          element: (
            <AuthGuard>
              <Home />
            </AuthGuard>
          ),
        },
        {
          path: '/telematics-connection-complete',
          element: (
            <AuthGuard>
              <TelematicsComplete />
            </AuthGuard>
          ),
        },
        {
          path: '/claims',
          element: (
            <AuthGuard>
              <ClaimsRedirect />
            </AuthGuard>
          ),
        },

        {
          path: '/:reportId',
          element: (
            <AuthGuard>
              <AppShell />
            </AuthGuard>
          ),
          children: reportIdChildren,
        },
        {
          path: '/account/notifications',
          element: (
            <AuthGuard>
              <NotificationPreferences />
            </AuthGuard>
          ),
        },
        { path: '/:reportId/insights', element: <Navigate to="scores" /> },
        // TODO(jose): remove '/thanks-yes' and '/thanks-no' once Proactive Claims Update pilot finishes (ETA: end of Oct 2024)
        { path: '/thanks-yes', element: <Thanks /> },
        { path: '/thanks-no', element: <Thanks /> },
      ],
    },
  ];
};
