import { useEffect } from 'react';
import { analytics } from 'src/helpers';
import useAuth from 'src/hooks/useAuth';

export const Analytics = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      analytics.trackUserIdentify(user);
    }
  }, [user]);

  return null;
};
