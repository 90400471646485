import { useEffect } from 'react';
import { analytics } from 'src/helpers';
import Zones from '../../components/zones';

const Heatmap = () => {
  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.HeatmapPageView,
    });
  }, []);

  return (
    <div className="mb-5">
      <Zones />
    </div>
  );
};

export default Heatmap;
