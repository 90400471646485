import { Link } from 'react-router-dom';
import lockEye from 'src/assets/icons/lock-eye.svg';
import { VehicleStat } from 'src/types/graphql-types';
import { Chip, Switch, Tooltip } from '@nirvana/ui-kit';
import { getSafetyScoreChipProps } from 'src/helpers';
import { createColumnHelper } from '@tanstack/react-table';

export interface VehicleData extends VehicleStat {
  driver?: { name: string };
}

export function leaderboardColumns(
  reportId: string,
  hasTelematicsConnection: boolean,
) {
  const columnHelper = createColumnHelper<VehicleData>();
  return [
    columnHelper.accessor('vehicleTSPName', {
      header: 'Truck Number',
      cell: ({ getValue }) => (
        <Switch
          fallback={
            <p className="text-sm font-normal text-gray-600">{getValue()}</p>
          }
        >
          <Switch.Match when={!hasTelematicsConnection}>
            <Tooltip title="Requires Telematics Connection" arrow>
              <img src={lockEye} className="w-5 h-5" />
            </Tooltip>
          </Switch.Match>
          <Switch.Match when={getValue() === null}>
            <Tooltip title="Data not available" arrow>
              <span>N/A</span>
            </Tooltip>
          </Switch.Match>
        </Switch>
      ),
    }),
    columnHelper.accessor('vIN', {
      header: 'Vehicle',
      cell: ({ getValue, row }) => {
        return (
          <div>
            <p className="text-sm font-normal text-secondary-dark">
              {getValue()}
            </p>
            <p className="text-sm font-normal text-text-hint">
              {row.original.vehicleName}
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor('driver.name', {
      header: 'Driver',
      cell: ({ getValue }) => <div className="text-sm">{getValue()}</div>,
      sortUndefined: 'last',
    }),
    columnHelper.accessor('riskScore', {
      header: 'Driver Safety Score',
      sortUndefined: 'last',
      sortDescFirst: false,
      meta: {
        tooltipText:
          "An individual vehicle's Nirvana Safety Score. A lower score indicates safer driving practices.",
      },
      cell: ({ getValue }) => (
        <Switch
          fallback={<Chip {...getSafetyScoreChipProps(getValue() ?? 0)} />}
        >
          <Switch.Match when={!hasTelematicsConnection}>
            <Tooltip title="Requires Telematics Connection" arrow>
              <img src={lockEye} className="w-5 h-5" />
            </Tooltip>
          </Switch.Match>
          <Switch.Match when={getValue() === undefined}>
            <Tooltip title="Data not available" arrow>
              <span>N/A</span>
            </Tooltip>
          </Switch.Match>
        </Switch>
      ),
    }),
    columnHelper.accessor('violationSeverityTimeWeighted', {
      header: 'Violation Score',
      meta: {
        tooltipText:
          'Violations and their weighting will take effect once the latest BASIC scores are published by the FMCSA',
      },
      cell: ({ getValue, row }) => {
        return (
          <Link
            to={`/${reportId}/insights/violations?vINs=${row.original.vIN}`}
            className="px-3 py-1 font-medium rounded text-primary-dark bg-primary-extraLight hover:bg-primary-main hover:text-white"
          >
            {getValue()}
          </Link>
        );
      },
    }),
  ];
}
