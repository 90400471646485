import { useEffect } from 'react';
import { analytics } from 'src/helpers';
import DotRating from './components/dot-rating';
import InsuranceSummary from './components/insurance-summary';
import Trends from './components/trends';
import Shippers from './components/shippers';

export default function Profile() {
  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.ProfilePageView,
    });
  }, []);

  return (
    <>
      <p className="mb-4 text-3xl font-bold text-gray-800">Profile</p>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-12">
        <DotRating />
        <InsuranceSummary />
        <Trends />
        <Shippers />
      </div>
    </>
  );
}
