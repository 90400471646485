import { analytics } from 'src/helpers';
import { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  ClaimsByDotNumberQuery,
  ReportsQuery,
  RoleGroupEnum,
  useClaimsByDotNumberQuery,
  useReportsQuery,
} from 'src/types/graphql-types';
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table } from 'src/components/table/Table';
import { Button, TablePagination } from '@material-ui/core';
import { Feature, useFeatureEnabled } from 'src/helpers/feature-flags';
import { useDOTSpecificFeature } from 'src/hooks/useDotSpecificFeature';
import { Show, TableTabs } from '@nirvana/ui-kit';
import useAuth from 'src/hooks/useAuth';
import { getClaimsColumns, getReportsColumns } from './constants/columns';
import { EmptyResults } from './components/EmptyResults';
import { Tabs } from './constants/types';
import { useTab } from './hooks/useTab';

export const Claims = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const { enabled: fnolReportsAvailale } = useDOTSpecificFeature(
    Feature.REPORT_FNOL_ENABLED_BY_DOT,
    {
      allowUserOverride: true,
    },
  );

  const [selectedTab, setSelectedTab] = useTab();

  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.ClaimsPageView,
    });
  }, []);

  const claimsColumns = useMemo(() => getClaimsColumns(), []);
  const reportsColumns = useMemo(() => getReportsColumns(), []);

  const showFeedback = useFeatureEnabled(Feature.CLAIMS_FEEDBACK);

  const { data: claims, loading: areClaimsLoading } = useClaimsByDotNumberQuery(
    {
      variables: { dotNumber: reportId as string },
    },
  );

  const { data: reports, loading: areReportsLoading } = useReportsQuery({
    skip: !fnolReportsAvailale,
  });

  const claimsData =
    claims?.claimsByDOTNumber ??
    ([] as ClaimsByDotNumberQuery['claimsByDOTNumber']);
  const reportsData = reports?.fnols ?? ([] as ReportsQuery['fnols']);

  const claimsTable = useReactTable({
    columns: claimsColumns,
    data: claimsData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      sorting: [
        {
          id: 'status',
          desc: false,
        },
      ],
    },
  });

  const reportsTable = useReactTable({
    columns: reportsColumns,
    data: reportsData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const isLoading = areClaimsLoading || areReportsLoading;

  useEffect(() => {
    claimsTable.setColumnVisibility({
      feedback: showFeedback,
    });
  }, [showFeedback, claimsTable]);

  useEffect(() => {
    if (!fnolReportsAvailale && selectedTab === Tabs.Reports) {
      setSelectedTab(Tabs.Claims);
    }
  }, [selectedTab, setSelectedTab, fnolReportsAvailale]);

  const activeTable = selectedTab === Tabs.Reports ? reportsTable : claimsTable;

  const { user } = useAuth();

  const showReportClaimsButton = useMemo(() => {
    const isNirvanaSuperuser = user?.roles?.nirvanaRoles
      ? user.roles.nirvanaRoles.filter(
          ({ role }) => role === RoleGroupEnum.SuperuserRole,
        )?.length > 0
      : false;
    const isFleetAdmin = user?.roles?.fleetRoles
      ? user.roles.fleetRoles.filter(
          ({ role }) => role === RoleGroupEnum.FleetAdminRole,
        )?.length > 0
      : false;
    return fnolReportsAvailale && (isNirvanaSuperuser || isFleetAdmin);
  }, [fnolReportsAvailale, user?.roles]);

  const ReportClaimButton = () => (
    <Show when={showReportClaimsButton}>
      <Link data-testid="report-button" to="new">
        <Button variant="contained">Report a Claim</Button>
      </Link>
    </Show>
  );

  return (
    <div>
      <header>
        <div className="flex flex-row justify-between">
          <h1 className="text-3xl font-bold text-secondary-main">Claims</h1>
          <ReportClaimButton />
        </div>
        <Show when={fnolReportsAvailale}>
          <div className="flex items-center justify-between">
            <div className="inline-block">
              <TableTabs
                tabs={[
                  { id: 'Claims', label: 'Claims', value: Tabs.Claims },
                  {
                    id: 'Reports',
                    label: 'Online Incident Reports',
                    value: Tabs.Reports,
                  },
                ]}
                onChange={(newTab) => {
                  setSelectedTab(newTab);
                }}
                value={selectedTab}
              />
            </div>
          </div>
        </Show>
      </header>

      <Show when={selectedTab === Tabs.Reports}>
        <p className="py-4">
          These are the incidents you&apos;ve reported online directly to
          Nirvana. Once an adjuster is assigned, the claim will appear in the
          Claims tab.
        </p>
      </Show>

      {!isLoading && activeTable.getRowModel().rows.length === 0 ? (
        <div className="flex flex-col items-center">
          <EmptyResults type={selectedTab} />
          <ReportClaimButton />
        </div>
      ) : (
        <div className="flex-1 mt-4 mb-4 overflow-auto bg-white rounded-md shadow">
          {selectedTab === Tabs.Reports && (
            <Table table={reportsTable} loading={isLoading} />
          )}
          {selectedTab === Tabs.Claims && (
            <Table table={claimsTable} loading={isLoading} />
          )}
          <div className="flex justify-start">
            <TablePagination
              data-testid="pagination"
              colSpan={3}
              rowsPerPageOptions={[10, 25, 50]}
              count={activeTable.getFilteredRowModel().rows.length}
              page={activeTable.getState().pagination.pageIndex}
              rowsPerPage={activeTable.getState().pagination.pageSize}
              onPageChange={(_, page) => {
                activeTable.setPageIndex(page);
              }}
              onRowsPerPageChange={(e) => {
                activeTable.setPageSize(Number(e.target.value));
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
