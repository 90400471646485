import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from '../../assets/themes';

export const useStyles = makeStyles((theme: ITheme) => ({
  dateRangeContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    justifyContent: 'space-between',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      borderColor: theme.palette.text.primary,
    },
  },
}));
