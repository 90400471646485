import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Button, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Dialog } from '@nirvana/ui-kit';

import useAuth from 'src/hooks/useAuth';
import { analytics } from 'src/helpers';
import Navbar from 'src/components/navbar';
import { ReactComponent as CircleIcon } from 'src/assets/icons/circle.svg';
import { ReactComponent as SquareIcon } from 'src/assets/icons/squares.svg';
import { UserProfileResponseUserTypeEnum } from '@nirvana/api/auth';
import RecentSearch from './components/starred-reports';
import SearchDot from './components/search-dot';
import { offerings } from './constants';

export default function Home() {
  const { user } = useAuth();
  const { show } = useIntercom();
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);

  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.HomePageView,
    });
  }, []);

  // Don't show search page to fleet users
  if (
    user?.userType === 'fleet' ||
    (user?.userType === UserProfileResponseUserTypeEnum.SharedLink &&
      user.reportId)
  ) {
    return <Navigate to={`/${user.reportId}/overview`} />;
  }

  return (
    <div className="flex flex-col bg-gray-50">
      <Navbar />
      <div className="absolute top-0 left-0 right-0 z-0 overflow-hidden h-[60vh] bg-primary-extraLight">
        <CircleIcon className="absolute w-32 h-32 -left-24 top-16" />
        <CircleIcon className="absolute w-16 h-16 -right-4 bottom-6" />
        <CircleIcon className="absolute w-8 h-8 -right-4 top-1/2" />
        <CircleIcon className="absolute left-16 bottom-16" />
      </div>

      <div className="relative flex flex-wrap justify-between flex-1 px-4 overflow-hidden md:px-12 2xl:px-32">
        <div className="flex flex-col px-2 pb-4 overflow-hidden lg:w-1/2 pt-14">
          <p className="mb-6 text-3xl font-semibold text-text-primary">
            Proactively manage risk with a unified view of safety performance
          </p>
          <SearchDot />
          <RecentSearch />
          <SquareIcon className="absolute left-1/2 top-1/4" />
        </div>

        <div className="flex flex-col items-center w-full pb-4 sm:w-1/2 pt-14">
          <p className="mb-2 text-lg font-semibold text-text-primary">
            Nirvana Safety helps fleets mitigate risk before it escalates
          </p>
          <hr className="w-16 mb-6 border-2 bg-gold-main border-gold-main" />

          <div className="relative mb-6 space-y-10">
            {offerings.map(({ icon: Icon, title, description }) => (
              <div
                key={title}
                className="relative flex max-w-md p-4 space-x-4 border-2 border-white rounded bg-white/40 even:-left-10"
              >
                <Icon className="flex-none w-20" />
                <div>
                  <p className="mb-2 font-semibold">{title}</p>
                  <p>{description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-center space-x-4">
            <Button
              variant="contained"
              startIcon={<PlayCircleOutlineIcon />}
              onClick={() => setIsVideoDialogOpen(true)}
            >
              Watch Overview
            </Button>
            <Button variant="outlined" onClick={show}>
              Help Center
            </Button>
          </div>
        </div>
      </div>

      <Dialog
        maxWidth="lg"
        open={isVideoDialogOpen}
        onClose={() => setIsVideoDialogOpen(false)}
      >
        <div className="relative w-full p-4 mb-4">
          <IconButton
            size="small"
            className="absolute text-gray-500 -top-2 -right-2"
            onClick={() => setIsVideoDialogOpen(false)}
          >
            <CloseIcon />
          </IconButton>

          <iframe
            src="https://player.vimeo.com/video/607647970?h=406aa87d26"
            width="720"
            height="480"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Dialog>
    </div>
  );
}
