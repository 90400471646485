import * as React from 'react';
import AccordionContext from './accordion-context';

type AccordionProps = {
  expanded?: boolean;
  children: React.ReactNode;
};

export default function Accordion({ expanded, children }: AccordionProps) {
  const [isOpen, setIsOpen] = React.useState(expanded ?? false);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  return (
    <AccordionContext.Provider value={{ isOpen, handleClick }}>
      <div className="mb-4 overflow-hidden border rounded-lg">{children}</div>
    </AccordionContext.Provider>
  );
}
