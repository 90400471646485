import * as React from 'react';
import clsx from 'clsx';
import {
  Step as MuiStep,
  StepIconProps,
  StepLabel,
  makeStyles,
  withStyles,
  alpha,
} from '@material-ui/core';
import MuiStepper, { StepperProps } from '@material-ui/core/Stepper';
import StepConnector, {
  stepConnectorClasses,
} from '@material-ui/core/StepConnector';
import DoneIcon from '@material-ui/icons/Done';

import { ITheme } from '../../assets/themes';

const useIconStyles = makeStyles((theme: ITheme) => ({
  icon: {
    height: theme.typography.pxToRem(10),
    width: theme.typography.pxToRem(10),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '50%',
  },
  iconActive: {
    height: theme.typography.pxToRem(20),
    width: theme.typography.pxToRem(20),
    backgroundColor: theme.palette.gold.main,
    border: `4px solid ${theme.palette.gold.light}`,
    marginLeft: -5,
  },
  iconCompleted: {
    height: theme.typography.pxToRem(14),
    width: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.success.main,
    border: `2px solid ${theme.palette.success.main}`,
    color: theme.palette.common.white,
    marginLeft: -2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  doneIcon: {
    fontSize: theme.typography.pxToRem(10),
  },
}));

function MuiStepIcon(props: StepIconProps) {
  const classes = useIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.icon, {
        [classes.iconActive]: active,
        [classes.iconCompleted]: completed,
      })}
    >
      {completed ? <DoneIcon className={classes.doneIcon} /> : null}
    </div>
  );
}

const MuiStepperConnector = withStyles(({ palette, typography }) => ({
  root: {
    marginLeft: typography.pxToRem(4),

    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: palette.success.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: palette.success.main,
      },
    },
  },
  stepLabel: {
    padding: 0,
  },
  line: {
    borderLeftStyle: 'dotted',
    borderLeftWidth: 2,
    borderLeftColor: alpha(palette.primary.light, 0.3),
    height: typography.pxToRem(40),
  },
}))(StepConnector);

const Step = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(MuiStep);

const MuiStepLabel = withStyles(({ palette, typography }) => ({
  root: {
    padding: 0,
    '&.Mui-disabled': {
      cursor: 'pointer',
    },
  },
  label: {
    color: palette.grey[400],
    fontWeight: typography.fontWeightRegular,

    '&.MuiStepLabel-completed': {
      color: palette.grey[400],
      fontWeight: typography.fontWeightRegular,
    },

    '&.MuiStepLabel-active': {
      color: palette.secondary.main,
      fontWeight: typography.fontWeightMedium,
    },
  },
}))(StepLabel);

interface IStep {
  key: string | number;
  label: React.ReactNode;
  extra?: () => React.ReactNode;
  action?: React.ReactNode;
}

export interface IStepperProps
  extends Omit<StepperProps, 'backButton' | 'nextButton' | 'onChange'> {
  steps: Array<IStep>;
  activeStep: number;
  onChange?: (index: number) => void;
  dataTestId?: string;
}

/**
 * The stepper UI component, which is an extension of Mui Stepper, styled to our theme.
 * Refer: https://next.material-ui.com/components/steppers for usage and props
 * @component
 *
 * @param {Object} props
 * @param {string[]} props.steps - List of steps to be displayed.
 * @param {Object} props.activeStep - Zero based index of currently active step.
 * @param {Object} [props.onChange] - Callback invoked on step change.
 *
 * @example
 * return <Stepper steps={['Start', 'End']} activeStep={0} />
 */
const VerticalStepper = ({
  steps = [],
  activeStep = 0,
  onChange = () => null,
  dataTestId,
}: IStepperProps) => {
  return (
    <MuiStepper
      data-testid={dataTestId}
      activeStep={activeStep}
      orientation="vertical"
      connector={<MuiStepperConnector />}
    >
      {steps.map((step: IStep, index: number) => (
        <Step key={step.key} onClick={() => onChange(index)}>
          <MuiStepLabel StepIconComponent={MuiStepIcon}>
            {step.label}
          </MuiStepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default VerticalStepper;
