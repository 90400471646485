import React from 'react';

export const ChartHeader = ({
  title,
  score,
  periodPicker,
}: {
  title: React.ReactNode;
  score: React.ReactNode;
  periodPicker: React.ReactNode;
}) => {
  return (
    <div className="flex flex-wrap items-center justify-between px-6 py-4 text-base border-b md:flex-nowrap">
      <div className="flex items-center md:w-1/4 lg:w-auto">{title}</div>

      <div className="items-center md:flex md:justify-end">
        <div className="md:ml-2 ">{score}</div>

        <div className="hidden w-px mx-6 h-7 bg-primary-tint3 md:block" />
        {periodPicker}
      </div>
    </div>
  );
};
