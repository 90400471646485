import { Fragment } from 'react';

import { HiOutlineXCircle } from 'react-icons/hi';
import {
  Button,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  Column,
  ColumnFiltersState,
  Table,
  Updater,
} from '@tanstack/react-table';
import { Checkbox, getFormattedDate } from '@nirvana/ui-kit';

export type KeyValueMappings = {
  [key: string]:
    | {
        [key: string]: string;
      }
    | undefined;
};
const Filter = ({
  column,
  keyValueMappings,
}: {
  column: Column<any, unknown>;
  keyValueMappings: KeyValueMappings;
}) => {
  const { meta } = column.columnDef;
  const { filterVariant, placeholder } = meta ?? {};

  const { id } = column;

  const sortedUniqueValues = Array.from(
    column.getFacetedUniqueValues().keys(),
  ).sort((a, b) =>
    (keyValueMappings[id]?.[a] ?? a) > (keyValueMappings[id]?.[b] ?? b)
      ? 1
      : -1,
  );
  if (!filterVariant) return null;
  else if (filterVariant === 'select') {
    const columnFilterValue = (
      column.getFilterValue() as string | undefined
    )?.split(',');

    return (
      <Select
        multiple
        displayEmpty
        className=""
        variant="outlined"
        placeholder={placeholder}
        data-testid={`filter-${id}`}
        value={columnFilterValue ?? []}
        onChange={(e) => {
          column.setFilterValue(
            e.target.value.filter((value) => value).join(','),
          );
        }}
        renderValue={(selected?: string[]) => {
          if (!selected || selected.length === 0) return <em>{placeholder}</em>;
          return sortedUniqueValues
            .filter((option) => selected.includes(option))
            .map((option) => keyValueMappings[id]?.[option] ?? option)
            .join(', ');
        }}
      >
        {sortedUniqueValues.map((value) => (
          <MenuItem
            key={`${value}-${columnFilterValue?.includes(value)}`}
            value={value}
          >
            <Checkbox
              size="small"
              disableRipple
              checked={columnFilterValue?.includes(value)}
            />
            <ListItemText primary={keyValueMappings[id]?.[value] ?? value} />
          </MenuItem>
        ))}
      </Select>
    );
  }

  console.error('Unknown filter variant', filterVariant);
  return null;
};

type FilterBarProps = {
  table: Table<any>;
  filters: ColumnFiltersState;
  setFilter: (cb: Updater<ColumnFiltersState>) => void;
  keyValueMappings: KeyValueMappings;
};

export default function FilterBar({
  table,
  filters,
  setFilter,
  keyValueMappings,
}: FilterBarProps) {
  function handleClearAll() {
    setFilter([]);
  }

  function handleFilterClose(id: string) {
    setFilter((filters) => filters.filter((filter) => filter.id !== id));
  }

  const labelsForFilters = (id: string, filters: string | number) => {
    return String(filters)
      .split(',')
      .map((value) => {
        try {
          return getFormattedDate(value);
        } catch (e) {
          return keyValueMappings[id]?.[value] ?? value;
        }
      })
      .join(', ');
  };

  const chipTitles = table.getHeaderGroups().reduce(
    (acc, headerGroup) => ({
      ...acc,
      ...headerGroup.headers.reduce(
        (acc, header) => ({
          ...acc,
          [header.id]: header.column.columnDef.header,
        }),
        {},
      ),
    }),
    {},
  );

  return (
    <>
      <div className="grid gap-2 px-4 mb-4 px-first-line:5 md:grid-cols-2 lg:grid-cols-4">
        {table
          .getHeaderGroups()
          .map((headerGroup) =>
            headerGroup.headers.map((header, index) => (
              <Fragment key={index}>
                {header.column.getCanFilter() ? (
                  <Filter
                    column={header.column}
                    keyValueMappings={keyValueMappings}
                  />
                ) : null}
              </Fragment>
            )),
          )}
      </div>

      <div className="flex flex-wrap items-center px-5 py-2 space-x-3 border-t">
        <p className="font-normal text-primary-light">Applied:</p>
        {Object.values(filters).map(({ id, value }) => (
          <div
            key={id}
            className="flex items-center text-primary-main font-normal px-2 py-0.5 space-x-1 text-xs border rounded bg-primary-extraLight"
          >
            <p className="font-medium text-primary-dark">
              {chipTitles[id as keyof typeof chipTitles]}
            </p>
            <p
              className="truncate"
              title={labelsForFilters(id, value as string)}
            >
              {labelsForFilters(id, value as string)}
            </p>
            <IconButton
              size="small"
              className="text-primary-main"
              onClick={() => handleFilterClose(id)}
            >
              <HiOutlineXCircle />
            </IconButton>
          </div>
        ))}
        <div className="flex-1" />
        <Button onClick={handleClearAll} variant="outlined">
          Clear all
        </Button>
      </div>
    </>
  );
}
