import {
  UpperBound,
  LowerBound,
} from 'src/pages/overview/components/reference-label';

export const issReferenceLines = [
  {
    y: 75,
    stroke: '#FA3252',
    strokeDasharray: '5 3',
    label: <UpperBound value={75} />,
  },
  {
    y: 50,
    stroke: '#FF823C',
    strokeDasharray: '5 3',
    label: <LowerBound value={50} />,
  },
];

export const riskReferenceLines = [
  {
    y: 70,
    stroke: '#FA3252',
    strokeDasharray: '5 3',
    label: <UpperBound value={70} />,
  },
  {
    y: 40,
    stroke: '#FF823C',
    strokeDasharray: '5 3',
    label: <LowerBound value={40} />,
  },
];
