/* eslint-disable no-use-before-define */
import * as React from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  AppBar,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Box,
  Typography,
  Badge,
  TabProps,
  TabsProps,
  alpha,
} from '@material-ui/core';
import Skeleton from '@material-ui/core/Skeleton';
import Show from '../show';

interface StyledTabProps extends Omit<TabProps, 'disableRipple' | 'wrapped'> {}

const AntTabs = styled(MuiTabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: '0 !important',

  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.secondary.main,
    height: 3,
  },
}));

const AntTab = styled((props: StyledTabProps) => (
  <MuiTab {...props} disableRipple wrapped />
))(({ theme }) => ({
  textTransform: 'none',
  minWidth: 50,
  [theme.breakpoints.up('sm')]: {
    minWidth: 50,
  },
  minHeight: 0,
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  padding: theme.spacing(1.5, 2),

  '&:hover': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSkeleton-root': {
    marginLeft: theme.spacing(1),
    borderRadius: theme.typography.pxToRem(4),
  },

  '&.Mui-selected': {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,

    '& .MuiBadge-root': {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
    },
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },

  '& .MuiTab-wrapper': {
    flexDirection: 'row-reverse',
    alignItems: 'center',

    '& > :first-of-type': {
      marginBottom: 0,
    },
  },

  '& .MuiBadge-root': {
    height: theme.typography.pxToRem(20),
    minWidth: theme.typography.pxToRem(20),
    padding: theme.spacing(0, 0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    backgroundColor: alpha(theme.palette.grey[200], 0.25),
    borderRadius: theme.typography.pxToRem(4),
    color: theme.palette.grey[400],
  },
}));

function TabPanel(props: any) {
  const { children, value, selected, ...other } = props;

  return (
    <div role="tabpanel" {...other}>
      {value === selected && <Box>{children}</Box>}
    </div>
  );
}

/**
 * Tabs UI component, which is an extension of Mui Tabs
 * Refer: https://next.material-ui.com/components/tabs for usage and props
 * @component
 *
 * @param {Object} props - Material UI chip props.
 * @param {Object[]} props.tabs - List of tabs that should be rendered.
 * @param {string} props.value - Value of currently selected tab.
 * @param {Function} props.onChange - Callback triggered when tab is changed. The value of currently selected tab is passed in as argument to the function.
 *
 * @example
 * const tabs = [{ label: 'Tab 1', value: 0 }, { label: 'Tab 2', value: 1 }];
 * return <Tabs tabs={tabs} value={0} onChange={(selectedTab) => console.log(selectedTab)} />
 */
export default function TableTabs({
  value,
  onChange,
  tabs,
  displayCount = true,
  tabProps,
  tabsProps,
  isCountLoading = false,
}: {
  value: any;
  onChange: (index: any) => void;
  tabs: Array<any>;
  displayCount?: boolean;
  tabProps?: TabProps;
  tabsProps?: TabsProps;
  isCountLoading?: boolean;
}) {
  return (
    <AppBar color="transparent" position="static" elevation={0}>
      <AntTabs
        {...tabsProps}
        value={value}
        scrollButtons="auto"
        variant="scrollable"
        onChange={(_, value) => onChange(value)}
      >
        {tabs.map((tab: any) => {
          let tabIcon;
          const icon = tab.icon;

          if (displayCount && (tab.count || tab.count === 0)) {
            tabIcon = (
              <>
                <Show when={isCountLoading}>
                  <Skeleton variant="rectangular" width={15} height={15} />
                </Show>
                <Show when={!isCountLoading}>
                  <Badge>
                    <Typography variant="caption" fontWeight="400">
                      {isCountLoading || tab.count || 0}
                    </Typography>
                    <Show when={icon}>{icon}</Show>
                  </Badge>
                </Show>
              </>
            );
          } else if (tab.icon) {
            tabIcon = tab.icon;
          } else {
            tabIcon = <></>;
          }

          return (
            <AntTab
              {...tabProps}
              key={tab.value}
              label={tab.label}
              value={tab.value}
              icon={tabIcon}
            />
          );
        })}
      </AntTabs>

      {tabs.map((tab: any) => (
        <TabPanel value={tab.value} selected={value} key={tab.value}>
          {tab.panel}
        </TabPanel>
      ))}
    </AppBar>
  );
}
