/**
 * Checks if `value` is classified as a `Number` primitive or object.
 *
 * @param value - the value to be checked
 * @returns boolean whether the value is number or not
 */
function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export default isNumber;
