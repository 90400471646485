import { forwardRef, useState } from 'react';
import type { ForwardedRef, ReactNode } from 'react';
import ReactMapGL, {
  FullscreenControl,
  Layer,
  NavigationControl,
  Source,
} from 'react-map-gl';
import type { MapLayerMouseEvent, MapRef } from 'react-map-gl';
import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';

type MapChartProps = {
  children?: ReactNode;
  geojson?: FeatureCollection<Geometry, GeoJsonProperties>;
  onHover?: (event: MapLayerMouseEvent) => void;
  onClick?: (event: MapLayerMouseEvent) => void;
  onMouseEnter?: (event: MapLayerMouseEvent) => string;
  onMouseLeave?: () => void;
};

const initialViewState = {
  latitude: 40.36,
  longitude: -97.33,
  zoom: 3,
};

const MapChart = forwardRef(
  (
    {
      children,
      geojson,
      onHover,
      onClick,
      onMouseEnter,
      onMouseLeave,
    }: MapChartProps,
    ref: ForwardedRef<MapRef | null>,
  ) => {
    const [cursor, setCursor] = useState('grab');

    function handleMouseEnter(e: MapLayerMouseEvent) {
      if (onMouseEnter) {
        const cursor = onMouseEnter(e);
        setCursor(cursor);
      }
    }

    function handleMouseLeave() {
      setCursor('grab');
      if (onMouseLeave) {
        onMouseLeave();
      }
    }

    return (
      <div className="w-full mb-4 overflow-hidden rounded h-96">
        <ReactMapGL
          ref={ref}
          cursor={cursor}
          onClick={onClick}
          onMouseMove={onHover}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          interactiveLayerIds={['geojson']}
          initialViewState={initialViewState}
          mapStyle="mapbox://styles/mapbox/light-v9"
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
        >
          <Source id="county" type="geojson" data={geojson}>
            <Layer
              id="geojson"
              type="fill"
              paint={{
                'fill-color': ['get', 'fill'],
                'fill-opacity': 0.5,
                'fill-outline-color': '#000000',
              }}
            />
          </Source>
          {children}
          <FullscreenControl position="top-right" />
          <NavigationControl position="bottom-right" showCompass={false} />
        </ReactMapGL>
      </div>
    );
  },
);

export default MapChart;
