import Navbar from 'src/components/navbar';
import ContactInfo from './contact-info';
import { NotificationPreferences } from './notification-preferences';

export default function Settings() {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center w-full">
        <ContactInfo />
        <NotificationPreferences />
      </div>
    </>
  );
}
