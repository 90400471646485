import * as React from 'react';
import { Divider, Typography, Box, makeStyles } from '@material-ui/core';
import { ITheme } from '../../assets/themes';

const useStyles = makeStyles((theme: ITheme) => ({
  fieldSet: {
    padding: theme.spacing(1, 0),
    position: 'relative',
  },
  fieldSetHeader: {
    position: 'absolute',
    top: 0,
    width: '100%',
    marginTop: 10,
  },
  title: {
    position: 'absolute',
    left: 0,
    top: -10,
    backgroundColor: theme.palette.common.white,
    paddingRight: theme.spacing(1.5),
  },
}));

/**
 * Nirvana FieldSet. Can be a logo or text.
 * @component
 *
 *
 * @example
 * return <FieldSet />
 */
export const FieldSet = ({
  children,
  title = '',
}: {
  children?: React.ReactNode;
  title?: string;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.fieldSet}>
      <Box className={classes.fieldSetHeader}>
        <Typography
          variant="caption"
          fontWeight="600"
          color="text.hint"
          className={classes.title}
        >
          {title}
        </Typography>
        <Divider />
      </Box>
      {children}
    </Box>
  );
};
