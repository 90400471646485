import { useGetDOTFromParams } from 'src/hooks/useGetDOTFromParams';
import { useDriversQuery } from 'src/types/graphql-types';
import { useMemo, useState, ReactNode, useEffect } from 'react';
import { Show, TableTabs, TableV8, Tooltip } from '@nirvana/ui-kit';
import { Wrapper } from 'src/components/Wrapper';

import Switch from '@nirvana/ui-kit/src/components/switch-match/switch';

import { analytics, VehicleRiskScoreBuckets } from 'src/helpers';
import { HiChevronRight } from 'react-icons/hi';
import { Banner } from 'src/components/Banner';
import clsx from 'clsx';
import useLocalStorage from 'src/hooks/use-local-storage';
import { mapInspectionsToDrivers } from './helpers/mapInspectionsToDrivers';
import {
  driversColumns,
  unassignedInspectionsColumns,
  unassignedSafetyScoresColumns,
} from './constants/columns';
import {
  mapInspectionsToUnassigned,
  mapRiskVinToUnassignedSafetyScores,
} from './helpers/mapInspectionsToUnassigned';
import { Tabs } from './constants/types';
import { NumberBox } from './components/NumberBox';
import { generateViolationStats } from './helpers/generateViolationStats';
import { useGetFilterDates } from './hooks/useGetFilterDates';
import { generateRiskScoresPercentagesBuckets } from './helpers/generateRiskScoresPercentagesBuckets';
import { AggregatedLineChart } from './components/AggregatedLineChart';
import { ScoresDescription } from './components/ScoresDescription';
import {
  getThreeMonthsPeriodSinceDate,
  mostPopularDateForVinPercentiles,
} from './helpers/dateHelpers';
import { NoAssignedDrivers } from './components/NoAssignedDrivers';

const Title = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <h3 className={clsx('text-xl font-bold text-gray-800', className)}>
    {children}
  </h3>
);

export const DriversPage = () => {
  const { dotNumber } = useGetDOTFromParams();
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Inspections);
  const { period, isoUTCStartTime, isoUTCEndTime, isoUTCMileagesStartTime } =
    useGetFilterDates();
  const { data: driversData, loading: isLoading } = useDriversQuery({
    variables: {
      dotNumber,
      startTime: isoUTCStartTime,
      endTime: isoUTCEndTime,
      mileagesStartTime: isoUTCMileagesStartTime,
    },
    skip: !dotNumber,
  });
  const {
    mappedDrivers,
    mappedUnassignedInspections,
    mappedUnassignedSafetyScores,
    dateForScores,
  } = useMemo(() => {
    return {
      mappedDrivers: mapInspectionsToDrivers(driversData?.fleetSafetyReport),
      mappedUnassignedInspections: mapInspectionsToUnassigned(
        driversData?.fleetSafetyReport,
      ),
      mappedUnassignedSafetyScores: mapRiskVinToUnassignedSafetyScores(
        driversData?.fleetSafetyReport,
      ),
      dateForScores: mostPopularDateForVinPercentiles(
        driversData?.fleetSafetyReport?.telematicsRiskVinPercentiles,
      ),
    };
  }, [driversData]);

  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.DriversPageView,
    });
  }, []);

  const {
    totalInspections,
    totalCleanInspections,
    totalInspectionsWithViolations,
    riskScoreAsPercentages,
  } = useMemo(() => {
    return {
      ...generateViolationStats(mappedDrivers),
      riskScoreAsPercentages:
        generateRiskScoresPercentagesBuckets(mappedDrivers),
    };
  }, [mappedDrivers]);

  const Period = ({
    period,
    className,
  }: {
    period?: string;
    className?: string;
  }) => {
    if (!period) {
      return null;
    }
    return (
      <div className={clsx('flex justify-end pb-2 text-text-hint', className)}>
        Period: {period}
      </div>
    );
  };

  const formatLineChartData = (scores: Record<string, number>) => {
    return VehicleRiskScoreBuckets.sort((a, b) => b.order - a.order).map(
      (bucket) => ({
        value: scores[bucket.name] ?? 0,
        color: bucket.bgClass,
      }),
    );
  };

  const [
    shouldHideAboutSafetyScoreQuestion,
    setShouldHideAboutSafetyScoreQuestion,
  ] = useLocalStorage('drivers-question-about-safety-scores', false);

  const trackAndHide = (event: analytics.SegmentEventTrack) => {
    analytics.trackEvent({
      event,
    });
    setShouldHideAboutSafetyScoreQuestion(true);
  };

  if (!isLoading && mappedDrivers.length === 0) {
    return <NoAssignedDrivers />;
  }

  return (
    <>
      <Show when={isLoading || mappedDrivers.length > 0}>
        <Title>Quick View</Title>

        <Banner
          title="About Safety Scores"
          allowDismiss
          storageKey="drivers-about-safety-scores"
          cta={
            <Show when={!shouldHideAboutSafetyScoreQuestion}>
              <button
                className="text-primary-main"
                onClick={() => {
                  trackAndHide(
                    analytics.SegmentEventTrack.AboutSafetyScoreHelpful,
                  );
                }}
              >
                Yes
              </button>
              <button
                className="ml-2 text-primary-main"
                onClick={() => {
                  trackAndHide(
                    analytics.SegmentEventTrack.AboutSafetyScoreNotHelpful,
                  );
                }}
              >
                No
              </button>
            </Show>
          }
        >
          Your Safety Score reflects overall risk, not just violations. We
          consider recent driving patterns, industry benchmarks, and predict
          future risk.
        </Banner>
        <Period
          className="mt-2"
          period={getThreeMonthsPeriodSinceDate(dateForScores)}
        />
        <Wrapper>
          <div className="flex items-center gap-2">
            <h3 className="py-4 font-bold">Driver Safety Score Distribution</h3>

            <Tooltip
              title="Hover over each bar to see the number of drivers within each safety rating bracket. "
              includeIcon
            >
              <span />
            </Tooltip>
          </div>
          <AggregatedLineChart
            data={formatLineChartData(riskScoreAsPercentages)}
            isLoading={isLoading}
          />
          <ScoresDescription />
        </Wrapper>
        <Period className="mb-2" period={period} />
        <div className="flex space-x-4">
          <NumberBox
            loading={isLoading}
            label="Inspections"
            value={totalInspections}
          />
          <NumberBox
            loading={isLoading}
            label="Clean Inspections"
            value={totalCleanInspections}
          />
          <NumberBox
            loading={isLoading}
            label="Inspections w/ Violations"
            value={totalInspectionsWithViolations}
          />
        </div>

        <Title className="mt-4">Driver List</Title>

        <Wrapper>
          <Period className="mb-2" period={period} />
          <TableV8
            columns={driversColumns()}
            data={mappedDrivers}
            isLoading={isLoading}
            pageOptions={{
              pageSize: 10,
            }}
            initialState={{
              sorting: [{ id: 'score', desc: true }],
            }}
          />
        </Wrapper>
      </Show>
      <Wrapper variant="clean">
        <Title>Unassigned</Title>
        <TableTabs
          tabs={[
            {
              id: 'inspections',
              label: 'Inspections',
              value: Tabs.Inspections,
              count: mappedUnassignedInspections.length,
            },
            {
              id: 'safety-scores',
              label: 'Safety Scores',
              value: Tabs.SafetyScores,
              count: mappedUnassignedSafetyScores.length,
            },
          ]}
          onChange={setSelectedTab}
          value={selectedTab}
        />
      </Wrapper>
      <div className="-mt-4">
        <Banner
          title="Driver Assignment needed"
          cta={
            <a
              href="https://nvna.info/kb-samsara-assign-driver-to-vehicle"
              target="_blank"
              rel="noreferrer"
              className="flex items-center text-center"
              onClick={() => {
                analytics.trackEvent({
                  event: analytics.SegmentEventTrack.ShowMeHowAssignDriver,
                });
              }}
            >
              Show me how
              <HiChevronRight className="ml-0.5" />
            </a>
          }
        >
          To assign a driver, you’ll need to assign drivers to vehicles in your
          Telematics Provider’s platform.
        </Banner>
      </div>
      <Wrapper>
        <Period className="mb-2" period={period} />
        <Switch>
          <Switch.Match when={selectedTab === Tabs.Inspections}>
            <TableV8
              columns={unassignedInspectionsColumns()}
              data={mappedUnassignedInspections}
              isLoading={isLoading}
              pageOptions={{
                pageSize: 20,
              }}
            />
          </Switch.Match>
          <Switch.Match when={selectedTab === Tabs.SafetyScores}>
            <TableV8
              columns={unassignedSafetyScoresColumns()}
              data={mappedUnassignedSafetyScores}
              isLoading={isLoading}
              pageOptions={{
                pageSize: 20,
              }}
            />
          </Switch.Match>
        </Switch>
      </Wrapper>
    </>
  );
};
