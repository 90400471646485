import { CoverageType } from '@nirvana/api/quoting';
import { IChipColors } from '../components/chip/chip';

export const CoveragesOrder = [
  CoverageType.CoverageAutoLiability,
  CoverageType.CoverageAutoPhysicalDamage,
  CoverageType.CoverageMotorTruckCargo,
  CoverageType.CoverageGeneralLiability,
];

export interface GetSortedCoverageParams<T> {
  coverages?: Array<T>;
  getCoverageType: (data: T) => CoverageType;
}

export function getSortedCoverages<T>({
  coverages,
  getCoverageType,
}: GetSortedCoverageParams<T>) {
  if (!coverages) return [];

  return coverages.sort((a: T, b: T) => {
    const aIndex = CoveragesOrder.indexOf(getCoverageType(a));
    const bIndex = CoveragesOrder.indexOf(getCoverageType(b));
    return aIndex - bIndex;
  });
}

export const CoverageLabels: {
  [key: string]: string;
} = {
  [CoverageType.CoverageAutoLiability]: 'Auto Liability',
  [CoverageType.CoverageAutoPhysicalDamage]: 'Physical Damage',
  [CoverageType.CoverageGeneralLiability]: 'General Liability',
  [CoverageType.CoverageMotorTruckCargo]: 'Motor Truck Cargo',
  [CoverageType.CoverageUnderinsuredMotoristBodilyInjury]: 'UIM BI',
  [CoverageType.CoverageUninsuredMotoristBodilyInjury]: 'UM BI',
  [CoverageType.CoverageUninsuredMotoristPropertyDamage]: 'UM PD',
  [CoverageType.CoverageMedicalPayments]: 'Med Pay',
  [CoverageType.CoveragePersonalInjuryProtection]: 'PIP',
  [CoverageType.CoverageTrailerInterchange]: 'Trailer Interchange',
  [CoverageType.CoverageBroadenedPollution]: 'Broadened Pollution',
  [CoverageType.CoverageUiia]: 'UIIA',
};

export const getCoverageLabel = (coverage: CoverageType): string => {
  return CoverageLabels[coverage] || '';
};

export const CoverageLabelShort: {
  [key: string]: string;
} = {
  [CoverageType.CoverageAutoLiability]: 'AL',
  [CoverageType.CoverageAutoPhysicalDamage]: 'APD',
  [CoverageType.CoverageGeneralLiability]: 'GL',
  [CoverageType.CoverageMotorTruckCargo]: 'MTC',
  [CoverageType.CoverageUnderinsuredMotoristBodilyInjury]: 'UIM BI',
  [CoverageType.CoverageUninsuredMotoristBodilyInjury]: 'UM BI',
  [CoverageType.CoverageUninsuredMotoristPropertyDamage]: 'UM PD',
  [CoverageType.CoverageMedicalPayments]: 'Med Pay',
  [CoverageType.CoveragePersonalInjuryProtection]: 'PIP',
  [CoverageType.CoverageTrailerInterchange]: 'Trailer Interchange',
  [CoverageType.CoverageBroadenedPollution]: 'Broadened Pollution',
  [CoverageType.CoverageUiia]: 'UIIA',
};

export const getCoverageLabelShort = (coverage: CoverageType): string => {
  return CoverageLabelShort[coverage] || '';
};

export const getCoverageChipColor = (coverage: CoverageType): IChipColors => {
  switch (coverage) {
    case CoverageType.CoverageAutoLiability:
      return 'default';
    case CoverageType.CoverageAutoPhysicalDamage:
      return 'tint';
    case CoverageType.CoverageGeneralLiability:
      return 'success';
    default:
      return 'default';
  }
};
