import * as React from 'react';

import LogoWhite from './../../assets/logo/Nirvana_Lockup--Logotype--Inverted.svg';
import LogoPrimary from './../../assets/logo/Nirvana_Lockup--Logotype.svg';
import LogoIcon from './../../assets/logo/Nirvana_Lockup--Tertiary_Lockup.svg';
import SafetyIcon from './../../assets/logo/Nirvana_Lockup--Logotype--Safety.svg';
import LogoSafetyPrimary from '../../assets/logo/Nirvana_Lockup--Logotype--Safety--Inverted.svg';

export enum BrandingTypes {
  Default = 'default',
  Inverted = 'inverted',
  Icon = 'icon',
  Safety = 'safety',
  SafetyInverted = 'safetyInverted',
}

/**
 * Nirvana branding. Can be a logo or text.
 * @component
 *
 * @param {boolean} [inverted] - Optional. Inverses color of logo.
 * @param {boolean} [icon] - Optional. Show/Hide icon.
 *
 * @example
 * return <Branding />
 */
const Branding = ({
  brandingType = BrandingTypes.Default,
}: {
  brandingType: BrandingTypes;
}) => {
  switch (brandingType) {
    case BrandingTypes.Icon:
      return <img src={LogoIcon} alt="Nirvana" />;
    case BrandingTypes.Inverted:
      return <img src={LogoWhite} alt="Nirvana" />;
    case BrandingTypes.Safety:
      return <img src={SafetyIcon} alt="Nirvana" />;
    case BrandingTypes.SafetyInverted:
      return <img src={LogoSafetyPrimary} alt="Nirvana" />;
    default:
      return <img src={LogoPrimary} alt="Nirvana" />;
  }
};

export default Branding;
