import React from 'react';

type SettingsBlockProps = {
  title: string;
  children: React.ReactNode;
};

export default function SettingsBlock({ title, children }: SettingsBlockProps) {
  return (
    <div className="flex flex-col items-center p-4 mt-5 mb-4 overflow-auto bg-white rounded-md shadow w-full">
      <div className="max-w-screen-sm w-full">
        <div className="mb-5 text-lg w-full">{title}</div>
        {children}
      </div>
    </div>
  );
}
