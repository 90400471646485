import { BaseSyntheticEvent, Fragment, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Chip, Show, storage } from '@nirvana/ui-kit';
import Accordion from 'src/components/accordion';
import { analytics, getChipProps } from 'src/helpers';
import { RecommendationImpact } from 'src/types/graphql-types';
import { IconButton, Stack, Tooltip } from '@material-ui/core';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { Feature, useFeatureEnabled } from 'src/helpers/feature-flags';
import RecommendationMap from './recommendation-map';
import ProjectionChart from './projection-chart';
import RecommendationFeedbackModal from './recommendations-feedback-modal';

export type RecommendationData = {
  id: string;
  title: string;
  impact: RecommendationImpact;
  engine: string | number;
  sections: Array<{ markdown: string; chart?: any }>;
};

type RecommendationAccordionProps = {
  data: RecommendationData;
  expanded: boolean;
};

export enum IconHelpfulnessState {
  Helpful = 'helpful',
  Unhelpful = 'unhelpful',
}

export default function RecommendationAccordion({
  data,
  expanded,
}: RecommendationAccordionProps) {
  const showRecommendationsFeedbackModal = useFeatureEnabled(
    Feature.RECOMMENDATIONS_FEEDBACK_MODAL,
  );

  const handleSummaryClick = (isOpen: boolean) => {
    analytics.trackEvent({
      event: isOpen
        ? analytics.SegmentEventTrack.RecommendationDetailsExpand
        : analytics.SegmentEventTrack.RecommendationDetailsCollapse,
      properties: {
        recommendationId: data.id,
      },
    });
  };

  const [helpfulness, setHelpfulness] = useState(storage.get(data.id));

  const updateHelpfulness = (helpfulness: IconHelpfulnessState) => {
    storage.set(data.id, helpfulness);
    setHelpfulness(helpfulness);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (event: BaseSyntheticEvent) => {
    // Stop onClick event that expands Accordian
    event.stopPropagation();
    setIsModalOpen(true);
  };

  const closeModel = () => {
    setIsModalOpen(false);
  };

  const onPositiveFeedback = (event: BaseSyntheticEvent) => {
    // Stop onClick event that expands Accordian
    event.stopPropagation();
    updateHelpfulness(IconHelpfulnessState.Helpful);
    analytics.trackEvent({
      event: analytics.SegmentEventTrack.RecommendationFeedback,
      properties: {
        helpful: IconHelpfulnessState.Helpful,
        recommendation: data.title,
        id: data.id,
      },
    });
  };

  const thumbUp =
    helpfulness === IconHelpfulnessState.Helpful ? (
      <ThumbUpIcon fontSize="small" />
    ) : (
      <ThumbUpOutlinedIcon fontSize="small" />
    );
  const thumbDown =
    helpfulness === IconHelpfulnessState.Unhelpful ? (
      <ThumbDownIcon fontSize="small" />
    ) : (
      <ThumbDownOutlinedIcon fontSize="small" />
    );

  return (
    <Accordion expanded={expanded}>
      <Accordion.Summary onClick={handleSummaryClick} id={data.id}>
        <div className="grid flex-grow grid-cols-12">
          <div className="flex-grow flex items-center col-span-9 text-sm font-semibold text-secondary-main">
            {data.title}
          </div>
          <div className="col-span-3">
            <Stack
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { xs: 'flex-start', md: 'center' },
              }}
            >
              <Chip className="mt-1 mr-4" {...getChipProps(data.impact)} />
              <Show when={showRecommendationsFeedbackModal}>
                <Tooltip title="This recommendation is helpful" placement="top">
                  <IconButton onClick={onPositiveFeedback}>
                    {thumbUp}
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="This recommendation is unhelpful"
                  placement="top"
                >
                  <IconButton onClick={openModal}>{thumbDown}</IconButton>
                </Tooltip>
              </Show>
            </Stack>
          </div>
        </div>
      </Accordion.Summary>
      <Accordion.Details>
        <div className="prose-sm prose prose-slate max-w-none prose-headings:after:content-[''] prose-headings:after:w-16 prose-headings:after:h-1 prose-headings:after:block prose-headings:after:bg-gold-light">
          {data.sections.map((section, index) => (
            <Fragment key={data.id + '_' + index}>
              <Show when={section.markdown}>
                <ReactMarkdown>{section.markdown}</ReactMarkdown>
              </Show>
              <Show when={section.chart !== null}>
                <div className="mb-5 not-prose">
                  <ProjectionChart chart={section.chart} />
                  <RecommendationMap chart={section.chart} />
                </div>
              </Show>
            </Fragment>
          ))}
        </div>
      </Accordion.Details>
      <RecommendationFeedbackModal
        open={isModalOpen}
        onClose={closeModel}
        updateHelpfulness={updateHelpfulness}
        data={data}
      />
    </Accordion>
  );
}
