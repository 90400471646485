import * as React from 'react';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ITheme } from '../../assets/themes';
import Show from '../show';

const useStyles = makeStyles((theme: ITheme) => ({
  noDivider: {
    '&> td, &> th': {
      border: 0,
    },
  },
  tableRow: {
    '&> th': {
      ...theme.typography.body2,
      backgroundColor: theme.palette.primary.extraLight,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1, 3),

      '&:first-child': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
      '&:last-child': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
    },
    '&> td': {
      ...theme.typography.body2,
      color: theme.palette.text.primary,
      padding: theme.spacing(1, 3),
      verticalAlign: 'top',
    },
  },
  subTableContainer: {
    backgroundColor: alpha(theme.palette.primary.extraLight, 0.4),

    '&> th': {
      padding: 0,
    },
    '&> td': {
      padding: 0,
    },
  },
}));

export interface TableViewColumn {
  key?: string;
  align?: 'left' | 'right' | 'center';
  content: React.ReactNode;
  styles?: React.CSSProperties;
}

export interface TableViewRow {
  key?: string;
  columns: Array<TableViewColumn>;
  subTable?: ITableViewProps;
}

export interface ITableViewProps {
  headers?: Array<TableViewColumn>;
  rows: Array<TableViewRow>;
  dividerColor?: string;
  styles?: React.CSSProperties;
}

const renderCell = (cell: TableViewColumn, cellIndex: number) => {
  const cellKey = cell.key || `row-cell-${cellIndex}`;
  return (
    <TableCell key={cellKey} align={cell.align} style={cell.styles}>
      {cell.content}
    </TableCell>
  );
};

const renderHeaderCell = (cell: TableViewColumn, cellIndex: number) => {
  const cellKey = cell.key || `header-cell-${cellIndex}`;
  return (
    <TableCell key={cellKey} align={cell.align} style={cell.styles}>
      {cell.content}
    </TableCell>
  );
};

/**
 * Table component that renders a table using Material UI components.
 * Refer: https://next.material-ui.com/components/tables for usage and props
 * @component
 *
 * @param {Object} props
 * @param {Object[]} props.headers - List of header columns (Refer: TableViewColumn for details).
 * @param {Object[]} props.rows - List of rows to be renderd (Refer: TableViewRow for details).
 *
 * @example
 * const headers = [{
 *   key: 'radius',
 *   content: 'Radius'
 * }, {
 *   key: 'percentage',
 *   content: 'Percentage'
 * }];
 * const rows = [{
 *    content: '100mi'
 * }, {
 *    content: '50%'
 * }];
 * return <TableView headers={headers} rows={rows} />
 */
const TableBasic = ({
  headers,
  rows,
  dividerColor,
  styles,
}: ITableViewProps) => {
  const classes = useStyles();

  return (
    <Table size="small" style={styles}>
      <TableHead>
        <TableRow className={clsx(classes.noDivider, classes.tableRow)}>
          {headers?.map(renderHeaderCell)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row: TableViewRow, index) => (
          <>
            <TableRow
              key={index}
              className={clsx(classes.noDivider, classes.tableRow)}
            >
              {row.columns.map(renderCell)}
            </TableRow>
            {row.subTable && (
              <React.Fragment>
                <TableRow
                  className={clsx(
                    classes.noDivider,
                    classes.tableRow,
                    classes.subTableContainer,
                  )}
                >
                  <TableCell colSpan={row.columns.length}>
                    <Table size="small">
                      <TableHead>
                        <TableRow
                          className={clsx(classes.noDivider, classes.tableRow)}
                        >
                          {row.subTable.headers?.map(renderHeaderCell)}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.subTable?.rows?.map(
                          (includedRow: TableViewRow) => (
                            <TableRow
                              key={includedRow.key}
                              className={clsx(
                                classes.noDivider,
                                classes.tableRow,
                              )}
                            >
                              {includedRow.columns?.map(renderCell)}
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                {
                  <Show when={index !== rows.length - 1}>
                    <TableRow>
                      <TableCell colSpan={row.columns.length}>
                        <Divider sx={{ my: 4, borderColor: dividerColor }} />
                      </TableCell>
                    </TableRow>
                  </Show>
                }
              </React.Fragment>
            )}
          </>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableBasic;
