import {
  LoginRequest,
  Configuration,
  AuthApi,
  ForgotPasswordRequest,
  VerifyTokenRequest,
} from '@nirvana/api/auth';
import { apiService } from 'src/helpers';

export const login = async (credentials: LoginRequest) => {
  const { data } = await apiService.authLoginPost(credentials);

  return data;
};

export const fetchUserProfile = async (apiKey: string) => {
  const configOptions = new Configuration({
    apiKey,
  });
  const authenticatedApiService = new AuthApi(configOptions);
  const { data } = await authenticatedApiService.meGet();

  return data;
};

export const forgotPassword = async (payload: ForgotPasswordRequest) => {
  const { data } = await apiService.authForgotPasswordPost(payload);

  return data;
};

export const resetPassword = async (payload: VerifyTokenRequest) => {
  const { data } = await apiService.authVerifyTokenPost(payload);

  return data;
};
