import { useSearchParams } from 'react-router-dom';
import { Tabs } from '../constants/types';

export const useTab: () => [Tabs, (tab: Tabs) => void] = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = [Tabs.Reports, Tabs.Claims].includes(
    searchParams.get('tab') as Tabs,
  )
    ? (searchParams.get('tab') as Tabs)
    : Tabs.Claims;

  const setTab = (tab: Tabs) => {
    setSearchParams({ tab });
  };

  return [selectedTab, setTab];
};
