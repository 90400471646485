import { createContext } from 'react';

const AccordionContext = createContext<{
  isOpen: boolean;
  handleClick: () => void;
}>({
  isOpen: false,
  handleClick: () => {},
});

AccordionContext.displayName = 'AccordionContext';

export default AccordionContext;
