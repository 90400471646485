import clsx from 'clsx';
import React from 'react';

type WrapperProps = {
  children: React.ReactNode;
  variant?: 'clean' | 'white';
};

export const Wrapper = ({ children, variant = 'white' }: WrapperProps) => {
  return (
    <section
      className={clsx('w-full p-4 mt-5 mb-4 overflow-auto', {
        'bg-white rounded-md shadow': variant === 'white',
      })}
    >
      {children}
    </section>
  );
};
