import { useParams } from 'react-router-dom';
import { Feature, useFeatureEnabled } from 'src/helpers/feature-flags';
import useAuth from 'src/hooks/useAuth';

export const useShowInvite = () => {
  const showInviteFlag = useFeatureEnabled(Feature.INVITE);
  const { user } = useAuth();
  const isInvited = user?.userType === 'shared_link';
  const { reportId } = useParams<{ reportId: string }>();

  return showInviteFlag && !isInvited && Boolean(reportId);
};
