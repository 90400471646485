import { AggregatedInspection } from './mapInspectionsToDrivers';

export const generateViolationStats = (
  mappedDrivers: AggregatedInspection[],
) => {
  return {
    totalInspections: mappedDrivers.reduce(
      (acc, driver) => acc + driver.inspections.total,
      0,
    ),
    totalCleanInspections: mappedDrivers.reduce(
      (acc, driver) => acc + driver.inspections.clean,
      0,
    ),
    totalInspectionsWithViolations: mappedDrivers.reduce(
      (acc, driver) => acc + driver.inspections.withViolations,
      0,
    ),
  };
};
