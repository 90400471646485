import { CircularProgress } from '@material-ui/core';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { BarChart, Tooltip } from '@nirvana/ui-kit';
import { ViolationScoreRecord } from 'src/helpers';

type ViolationScoreProps = {
  data: Array<ViolationScoreRecord>;
  isLoading: boolean;
};

export default function ViolationScore({
  data,
  isLoading,
}: ViolationScoreProps) {
  return (
    <div className="p-4 border rounded-lg">
      <div className="flex items-center mb-4 text-base text-text-primary">
        <p className="mr-2 font-bold">Violation Score Distribution</p>
        <Tooltip
          placement="right"
          title="Violation Scores are severity-weighted totals of the number of FMCSA violations, and quantify the impact each driver/vehicle has on BASICs, and ultimately insurance premiums."
        >
          <button className="text-primary-main">
            <HiOutlineInformationCircle />
          </button>
        </Tooltip>
      </div>
      <div className="px-3 py-2 text-xs rounded-md text-text-hint bg-primary-extraLight">
        <span className="mr-5">Score</span>
        <span># of Vehicles</span>
      </div>
      <div className="flex items-center justify-center h-64">
        {isLoading ? (
          <CircularProgress size={32} />
        ) : (
          <BarChart
            data={data}
            xField="value"
            layout="vertical"
            yField={['value']}
            margin={{ top: 10, bottom: 20 }}
            barConfig={[{ barSize: 25, radius: 5 }]}
            getCellConfig={(record) => ({ fill: record.color })}
            xAxis={{ type: 'number', hide: true, padding: { left: 70 } }}
            yAxis={{ type: 'category', dataKey: 'range', fontSize: '12px' }}
            labelList={[
              { dataKey: 'value', position: 'left', fill: '#000000', dx: -42 },
              {
                dataKey: 'percentage',
                position: 'left',
                fill: '#878B95',
                fontSize: 12,
                formatter: (value: number) => {
                  if (Number.isNaN(value)) {
                    return '';
                  }
                  return `(${value}%)`;
                },
              },
            ]}
          />
        )}
      </div>
    </div>
  );
}
